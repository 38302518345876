import { useRef, type ReactElement } from 'react';
import type { ControlledTableProps, FetcherState, RowType, TablePageProps } from './types';
import { serializeQs, useQueryState } from './useQueryState';
import { TablePage } from './TablePage';
import { Navigate, useLocation } from 'react-router';
import { setStoredState, getStoredState } from './storage';
import equal from 'fast-deep-equal';

const defaultPageSizes = [15, 30, 60];
const defaultFilter = {};

export function ControlledTable<R extends RowType>(props: ControlledTableProps<R>): ReactElement {
  const {
    pageSize: constantPageSize,
    defaultPageSize = 15,
    pageSizeOptions: providedPageSizeOptions = defaultPageSizes,
    initialFilter = defaultFilter,
    storageKey,
    ...rest
  } = props;

  const initialState = {
    pageIndex: 1,
    pageSize: constantPageSize || defaultPageSize,
    filter: initialFilter,
    sort: undefined,
  };

  const ref = useRef(true);
  const firstRender = ref.current;
  ref.current = false;

  const location = useLocation();

  // restore stored state
  // on first render if nothing in query and
  // grab filter here and restore url befor QueryTablePage renders
  if (storageKey && firstRender && !location.search) {
    const restoredState = getStoredState(storageKey);
    if (restoredState) {
      return (
        <Navigate
          to={`${location.pathname}${serializeQs({
            ...initialState,
            ...restoredState,
            filter: {
              ...initialState.filter,
              ...restoredState.filter,
            },
          })}`}
          replace={true}
        />
      );
    }
  }
  // wiret back to storage when state changes
  const onStateChange = storageKey
    ? (state: FetcherState) => setStoredState(storageKey, equal(state, initialState) ? undefined : state)
    : undefined;

  return (
    <QueryTablePage
      initialState={initialState}
      onStateChange={onStateChange}
      // no pageSizeOptions if page size is constant
      pageSizeOptions={constantPageSize || providedPageSizeOptions === null ? undefined : providedPageSizeOptions}
      initialFilter={initialFilter}
      {...rest}
    />
  );
}

function QueryTablePage({
  initialState,
  onStateChange,
  ...props
}: Omit<TablePageProps<any>, 'state' | 'setState'> & {
  initialState: FetcherState;
  onStateChange?: (state: FetcherState) => void;
}): ReactElement {
  const [state, setState] = useQueryState(initialState, onStateChange);
  return <TablePage state={state} setState={setState} {...props} />;
}

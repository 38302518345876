import { useEntity, Heading, SubNav, ClientTitle } from 'components';
import { NavLinkProps } from 'react-router-dom';
import { ReactElement } from 'react';
import type { ClientAgreement } from 'modules/client/agreement/types';

const nav: NavLinkProps[] = [
  {
    to: '',
    end: true,
    children: 'Update',
  },
  {
    to: 'fees',
    end: true,
    children: 'Fees & rates',
  },
];

export function Nav(): ReactElement {
  const { item } = useEntity<ClientAgreement>();

  return (
    <SubNav nav={nav}>
      <Heading>
        <h2>
          <ClientTitle {...item} /> <small className="text-muted">#{item.number}</small>
        </h2>
      </Heading>
    </SubNav>
  );
}

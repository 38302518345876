import { atom, PrimitiveAtom } from 'jotai';
import type { BlockDetails, PlanningConfig } from '../types';
import type { EntityContextType } from 'components';
import type { CampaignDetails } from 'modules/campaign/campaign/types';
import { NEVER } from 'utilities';
import { api } from 'services';
import type { Language } from 'types';
import { Options } from 'options';
import { useSyncToAtoms } from 'utilities/useSyncToAtoms';

// FIXME: ??
export const languageOptionsAtom = atom<Promise<Options<Language>>>(async (_get) => {
  const languageResult = await api.get<any, unknown>(`core/languages/`);
  if (languageResult.ok) {
    return languageResult.data.results;
  } else {
    throw languageResult.error;
  }
});

// FIXME: get rid of these
export const blockContextAtom: PrimitiveAtom<EntityContextType<BlockDetails>> = atom(NEVER);
export const campaignAtom: PrimitiveAtom<CampaignDetails> = atom(NEVER);
export const planningConfigAtom: PrimitiveAtom<PlanningConfig> = atom(NEVER);

export function StateSync({
  ctx,
  campaign,
  planning_config,
}: {
  ctx: EntityContextType<BlockDetails>;
  campaign: CampaignDetails;
  planning_config: PlanningConfig;
}): null {
  useSyncToAtoms([blockContextAtom, ctx], [campaignAtom, campaign], [planningConfigAtom, planning_config]);

  return null;
}

import type { EmbeddedCompany, MinimalCompany } from 'types';
import { CompanyTitle } from './CompanyTitle';
import { createSelectField } from 'components/fields/createSelectField';

export const companyFieldProps = {
  getOptionLabel: (a: MinimalCompany) => a.title,
  getOptionValue: (a: MinimalCompany) => a.id.toString(),
  formatOptionLabel: CompanyTitle,
};

export const CompanyField = createSelectField<EmbeddedCompany>('core/companies/', companyFieldProps);

import { ReactElement } from 'react';
import { Icon } from 'components';
import { BlockEntry, Log } from 'modules/audit';
import { parseBig } from 'utilities';
import { Link } from 'react-router-dom';
import { useMembership } from 'services';
import { useAtomValue } from 'jotai';
import { blockIdAtom } from '../atoms';

function Description(entry: BlockEntry): ReactElement | null {
  const { slug } = useMembership();
  switch (entry.action) {
    case 'CREATED':
      return <>created block</>;

    case 'BRIEF_UPDATED':
      if (entry.context?.message) {
        return (
          <>
            updated brief: <strong>{entry.context.message}</strong>
          </>
        );
      }
      return <>updated brief</>;

    case 'ASSIGNED':
      return <>assigned block</>;

    case 'PLAN_UPDATED':
      return <>updated plan</>;

    case 'SUBMITTED':
      return <>submitted plan</>;

    case 'UNSUBMITTED':
      return <>unsubmitted plan</>;

    case 'APPROVED':
      return <>approved plan</>;

    case 'REJECTED':
      return (
        <>
          rejected plan: <strong>{entry.context.message}</strong>{' '}
          {entry.context.brief_updated && <small>(brief updated)</small>}
        </>
      );

    case 'REPLAN':
      return (
        <>
          requested replan: <strong>{entry.context.message}</strong>{' '}
          {entry.context.brief_updated && <small>(brief updated)</small>}
        </>
      );

    case 'UPDATE_PRICES':
      return (
        <>
          price updated: <strong>{entry.context.message}</strong>
        </>
      );

    case 'CANCELED':
      return (
        <>
          block canceled: <strong>{entry.context.message}</strong>
        </>
      );

    case 'DONE':
      return <>marked as done</>;

    case 'NOT_DONE':
      return <>marked as NOT done</>;

    case 'DELETED':
      return <>deleted block</>;

    case 'CORRECTION':
      return (
        <ul className="compact-list">
          {entry.context.map(({ amount, invoice, row }) => {
            const [prev, next] = amount;
            const diff = parseBig(next).minus(prev);
            const p = diff.s === 1;

            return (
              <li key={row.id}>
                {prev} → {next} (
                <span className={p ? 'text-success' : 'text-danger'}>
                  {p && '+'}
                  {diff.toFixed(2)}
                </span>
                ) corrected provider price of{' '}
                {row.product && `${row.product.department.name} / ${row.provider?.title} `}
                in
                <span className="ml-1">
                  <Icon icon="file-invoice" className={`text-${invoice.direction}`} />{' '}
                  <Link to={`/${slug}/invoices/${invoice.direction}/${invoice.id}`}>{invoice.number}</Link>
                </span>
              </li>
            );
          })}
        </ul>
      );
    default:
      return null;
  }
}

export function History(): ReactElement {
  const id = useAtomValue(blockIdAtom);
  const url = `campaign/blocks/${id}/history/`;

  return <Log<BlockEntry> url={url} Description={Description} />;
}

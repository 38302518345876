import { Column } from '@ff-it/grid';
import { IndexScene, ListFilter, ModelScene } from 'components';
import { never } from 'core/permissions';
import { TextField } from '@ff-it/form';

export interface Conflict {
  id: number;
  identifier: string;
  message: string;
}

const model = {
  endpoint: 'invoicing/expense/conflicts',
  title: 'Conflict',
  pluralTitle: 'Synchronization Conflicts',
  entityTitle: (c: Conflict) => c.identifier,
};

const columns: Column<Conflict>[] = [
  {
    content: 'Meta-invoice',
    key: 'identifier',
  },
  {
    content: 'Message',
    key: 'message',
  },
];

export const route = {
  path: 'conflicts',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene<Conflict> canCreate={never} columns={columns}>
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
          </ListFilter>
        </IndexScene>
      ),
    },
  ],
};

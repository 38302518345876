import { useField } from '@ff-it/form';
import type { EmbeddedCompany } from 'types';

export function useFinalClientId(): number | undefined {
  const client = useField<EmbeddedCompany | null>('client', { subscription: { value: true } });
  const endClient = useField<EmbeddedCompany | null>('end_client', { subscription: { value: true } });

  const value = endClient.input.value?.id || client.input.value?.id;
  return value;
}

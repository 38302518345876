import { createSceneRoute, ListFilter } from 'components';
import permissions from 'modules/core/membership/permissions';
import { columns, Form } from 'modules/core/membership';

export const route = createSceneRoute('membership', {
  endpoint: 'agency/membership/',
  title: 'Membership',
  pluralTitle: 'Membership',
  entityTitle: ({ user }) => user.email,
  permissions,
  index: { columns, filter: <ListFilter /> },
  form: <Form />,
  initialValues: {
    user: null,
    groups: [],
  },
});

import { Dispatch, SetStateAction, ReactElement, ReactNode } from 'react';
import { Form, SelectField } from '@ff-it/form';
import { usePeriod } from '../usePeriod';
import { DatePartPickerField, FilterControl } from 'components';
import { reportBlockStateOptions } from 'configuration';
import type { BlockState } from 'modules/campaign/block/types';

import { Options } from 'options';
import { ActivityType } from 'types';

export interface FilterState {
  year: string;
  state: BlockState[];
}

interface FilterProps {
  filter: FilterState;
  setFilter: Dispatch<SetStateAction<FilterState>>;
  url: string;
  defaultFilter: any;
  types: Options<ActivityType>;
  children?: ReactNode;
}
const onSubmit = async (): Promise<void> => undefined;

export function Filter({ filter, setFilter, defaultFilter, types, children }: FilterProps): ReactElement {
  const { date_from: minDate, date_to: maxDate } = usePeriod();
  return (
    <Form onSubmit={onSubmit} initialValues={filter} className="form-inline list-filter" noValidate>
      <div className="flex-column">
        <div className="d-flex flex-row">
          <DatePartPickerField part="year" name="year" minDate={minDate} maxDate={maxDate} />
          <SelectField
            name="state"
            placeholder="Any"
            options={reportBlockStateOptions}
            simple
            isMulti
            className="ml-3"
          />
          {children}
        </div>

        <div className="d-flex flex-row">
          <SelectField name="types" placeholder="Types" options={types} simple isMulti />
        </div>
      </div>

      <FilterControl filter={filter} setFilter={setFilter} defaultFilter={defaultFilter} />
    </Form>
  );
}

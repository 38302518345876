import { ReactElement } from 'react';
import { RenderCellProps } from 'components/Grid';
import { useFieldUpdater } from '../../../../hooks';
import { SelectEditor } from '../../../Editor';
import { TransparencyOption } from './TransparencyOption';
import { transparencyOptions } from 'options';
import type { Row } from 'modules/campaign/row';

const renderOptionLabel = (opt: any): any => <TransparencyOption transparency={opt.value} />;

export function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.transparency;
  const setValue = useFieldUpdater(props.row.id, 'transparency');

  return (
    <SelectEditor
      value={value}
      stopEdit={props.stopEdit}
      setValue={setValue}
      options={transparencyOptions}
      getOptionLabel={renderOptionLabel}
      isSearchable={false}
    />
  );
}

import { useMemo } from 'react';
import { CalculatedColumn } from './types';
import { FROZEN_COLS } from './const';
import { handleColumn } from './handleColumn';
import { GridColumn } from './types';

export function useCalculatedColumns(columns: readonly GridColumn<any>[]): CalculatedColumn[] {
  return useMemo(() => {
    const calculatedColums: CalculatedColumn[] = [];
    let currentLeft = 0;

    [handleColumn, ...columns].forEach((col, i) => {
      const frozen = i < FROZEN_COLS + 1;

      if (frozen) {
        calculatedColums.push({
          ...col,
          frozen: true,
          lastFrozen: i + 1 == FROZEN_COLS + 1,
          left: currentLeft,
        });
      } else {
        calculatedColums.push({
          ...col,
          frozen: false,
          lastFrozen: undefined,
          left: undefined,
        });
      }

      // minWidth should be safe if we have h-scroll we are not growing
      if (col.width) {
        currentLeft += col.width;
      } else if (col.minWidth) {
        currentLeft += col.minWidth;
      }
    });

    return calculatedColums;
  }, [columns]);
}

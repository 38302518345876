import { ReactElement } from 'react';
import { AttachmentField, FormInline, AgencyActivityTypesField, FormInlineMemberError, RemoveButton } from 'components';
import {
  TextField,
  DateRangePickerField,
  IntField,
  SelectField,
  DecimalField,
  useForm,
  useOnFieldChange,
} from '@ff-it/form';
import { transparencyOptions } from 'options';
import { useFinalClientId } from 'modules/client/useFinalClientId';
import { CompanyField } from 'modules/core/company/components';
import useSWR from 'swr';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';
import { required } from 'utilities';
import type { EmbeddedProduct } from 'modules/supplier/products';
import { departmentProductFieldOptions } from 'modules/supplier/products/ProductField';
import { BrandField } from '../brand/components';

const initialTypeTerms = {
  type: null,
  terms: '',
};

const initialPerson = {
  brand: null,
  first_name: '',
  last_name: '',
};

function DiscountDepartmentProductRow({
  name,
  products,
}: {
  name: string;
  products: EmbeddedProduct[];
}): React.ReactElement {
  return (
    <div className="form-row">
      <div className="col-5" />
      <SelectField
        name={`${name}.product`}
        placeholder="Product"
        className="col-md-3 mb-1"
        required
        validate={required}
        options={products}
        {...departmentProductFieldOptions}
      />
      <DecimalField
        name={`${name}.client_discount`}
        placeholder="Client discount"
        className="col-md-2 mb-1"
        precision={2}
      />
    </div>
  );
}

function DiscountedDepartmentRow({ name, remove }: { name: string; remove?: () => void }): React.ReactElement {
  const form = useForm();

  const department = useOnFieldChange(`${name}.department`, () => {
    form.change(`${name}.discounted_products`, []);
  });
  const type = useOnFieldChange(`${name}.type`, () => {
    form.change(`${name}.discounted_products`, []);
  });

  const { data: products } = useSWR(
    department && type
      ? {
          url: 'supplier/products/',
          method: 'GET',
          queryParams: {
            type,
            department: department.id,
          },
        }
      : null,
  );

  return (
    <div className="bg-light px-3 pt-2 mb-1 border">
      <div className="d-flex flex-row border-bottom pb-1 mb-1">
        <div className="flex-grow-1">
          <FormInlineMemberError name={name} />
          <div className="form-row">
            <DepartmentField
              name={`${name}.department`}
              placeholder="Department"
              className="col-md-3 mb-0"
              required
              validate={required}
            />
            <AgencyActivityTypesField
              name={`${name}.type`}
              placeholder="Type"
              className="col-md-2 mb-0"
              required
              validate={required}
            />
            <div className="col-md-3" />
            <DecimalField
              name={`${name}.client_discount`}
              placeholder="Client discount"
              className="col-md-2 mb-0"
              precision={2}
              required
            />
          </div>
        </div>
        <div className="form-group ml-2 d-flex align-items-start">
          <RemoveButton data-test-id="remove-inline" onClick={remove} disabled={!remove} />
        </div>
      </div>
      {products && products.length > 0 && (
        <FormInline
          flush
          name={`${name}.discounted_products`}
          initialValues={{}}
          testId="discounted_products"
          addLabel="Add product"
          className="mb-1"
        >
          {(name) => <DiscountDepartmentProductRow name={name} products={products} />}
        </FormInline>
      )}
    </div>
  );
}

export function Form(): ReactElement {
  const companyId = useFinalClientId();

  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <CompanyField name="client" label="Client" className="col-md-3" required disabled />
        <CompanyField
          name="end_client"
          label="End client"
          className="col-md-3"
          help="Add if final recipient of proxy service is another company"
        />
        <TextField name="number" label="Number" className="col-md-3" help="Leave blank to assign" />
        <DateRangePickerField label="Period" fromName="date_from" toName="date_to" className="col-md-3" required />
      </div>
      <div className="form-row">
        <div className="col-md-6">
          <div className="form-row">
            <SelectField
              name="transparency"
              label="Transparency"
              options={transparencyOptions}
              simple
              className="col-md-6"
              required
            />
            <IntField label="Invoice payment days" name="invoice_payment_days" className="col-md-6" />
          </div>
        </div>
        <div className="col-md-6">
          <AttachmentField name="attachments" label="Attachments" />
        </div>
      </div>

      <div className="form-row">
        <TextField
          name="terms"
          className="col-6"
          label="Terms and conditions"
          multiline
          autosize
          help="General terms and conditions. Visible in campaign blocks."
        />
        <TextField
          name="notes"
          className="col-6"
          label="Notes"
          multiline
          autosize
          help="Private notes. Not visible in campaign."
        />
      </div>
      <div className="row">
        <div className="col-md-6">
          <FormInline
            name="type_terms"
            title="Terms and conditions"
            initialValues={initialTypeTerms}
            help="Set terms and conditions to for particular block type"
            testId="terms"
          >
            {(name) => (
              <div className="form-row">
                <AgencyActivityTypesField
                  name={`${name}.type`}
                  placeholder="Type"
                  className="col-md-3"
                  required
                  size="sm"
                />
                <TextField
                  name={`${name}.terms`}
                  className="col"
                  placeholder="Terms and conditions"
                  multiline
                  autosize
                  required
                  size="sm"
                />
              </div>
            )}
          </FormInline>
        </div>
        <div className="col-md-6">
          <FormInline
            name="signature_persons"
            title="Signature persons"
            initialValues={initialPerson}
            help="Will be shown in acceptance certificate"
            testId="persons"
          >
            {(name) => (
              <div className="form-row">
                <TextField
                  name={`${name}.first_name`}
                  placeholder="First name"
                  className="col-md-4"
                  required
                  size="sm"
                />
                <TextField name={`${name}.last_name`} placeholder="Last name" className="col-md-4" required size="sm" />
                <BrandField
                  filter={{ client: companyId }}
                  name={`${name}.brand`}
                  placeholder="Any brand"
                  className="col-md-4"
                  size="sm"
                />
              </div>
            )}
          </FormInline>
        </div>
      </div>
      <div className="form-row">
        <DecimalField name="client_discount" label="Client discount %" className="col-md-2" precision={2} />
      </div>
      <FormInline
        className="mb-3 pb-2 px0-3"
        name="discounted_departments"
        initialValues={{
          discounted_products: [],
        }}
        testId="discounted_departments"
        addLabel="Add department"
        Row={DiscountedDepartmentRow}
        title="Discounts"
        header={
          <div className="px-3 py-1 mb-1 border bg-light">
            <div className="form-row" style={{ paddingRight: '29px' }}>
              <div className="col-3">Department</div>
              <div className="col-2">Type</div>
              <div className="col-3">Product</div>
              <div className="col-2">Override discount</div>
            </div>
          </div>
        }
      />
    </div>
  );
}

import { EntityScene, IndexScene, ListFilter, ModelScene, DetailsScene } from 'components';
import { Company } from 'types';
import { permissions } from './permissions';
import { Nav } from './Nav';
import { baseColumns } from 'routes/Agency/Preferences/Companies';
import { never } from 'core/permissions';
import { ViewCompany } from 'components/company';
import { Agreements } from './Agreements';
import { CheckField, TextField } from '@ff-it/form';
import { Persons } from './Persons';

const model = {
  endpoint: 'supplier/providers/',
  title: 'Provider',
  pluralTitle: 'Providers',
  entityTitle: ({ title }: Company) => title,
  permissions,
};

export const route = {
  path: 'providers',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene columns={baseColumns} canCreate={never} storageKey="contacts_providers">
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
            <div className="mx-2 form-group">
              <CheckField name="has_agreements" fieldLabel="Has agreement" variant="switch" />
            </div>
          </ListFilter>
        </IndexScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <DetailsScene heading={null}>
              <ViewCompany />
            </DetailsScene>
          ),
        },
        {
          path: 'agreements/*',
          element: <Agreements />,
        },
        {
          path: 'persons/*',
          element: <Persons />,
        },
      ],
    },
  ],
};

import { useEffect, useRef, useMemo, ReactElement } from 'react';
import { useField } from 'react-final-form';
import type { Company } from 'types';
import type { BoundState } from 'modules/campaign/common/types';
import { DatePickerField, DatePickerFieldProps, SelectFieldProps, SelectField } from '@ff-it/form';
import { useAPI } from '@ff-it/api';
import { createSelectField } from './createSelectField';
import { boundStateOptions } from 'configuration';
import type { Option } from 'options';
import { formatISO, parseISO, addDays } from 'date-fns';
import { add, add_advance } from 'modules/invoicing/income/permissions';
import { useHasPerms } from 'core/permissions';
import { useAgencyVatOptions } from 'modules/core/useAgencyVAT';
import type { InvoiceState, InvoiceType } from 'modules/invoicing/common/types';
import type { ClientAgreement } from 'modules/client/agreement/types';
import { invoiceStateOptions, invoiceTypeOptions } from 'modules/invoicing/common/options';

export function IncomeTypeField(
  props: Omit<SelectFieldProps<Option<InvoiceType>>, 'simple' | 'options'>,
): ReactElement {
  const [canAdd, canAddAdvance] = useHasPerms(add, add_advance);

  const options = useMemo(
    () =>
      invoiceTypeOptions.map((opt) => {
        let isDisabled;
        if (canAdd) {
          // pass
          isDisabled = undefined;
        } else if (canAddAdvance) {
          isDisabled = opt.value === 'ADVANCE' ? undefined : true;
        }
        return { isDisabled, ...opt };
      }),
    [canAdd, canAddAdvance],
  );
  return <SelectField options={options} {...props} simple />;
}

export function ExpenseTypeField(
  props: Omit<SelectFieldProps<Option<InvoiceType>>, 'simple' | 'options'>,
): ReactElement {
  // const options = useMemo(
  //   () =>
  //     invoiceTypeOptions.map((opt) => {
  //       return { isDisabled: ['INVOICE', 'ADVANCE'].indexOf(opt.value) === -1, ...opt };
  //     }),
  //   [],
  // );
  return <SelectField options={invoiceTypeOptions} {...props} simple />;
}

type VatRateFieldProps = Omit<SelectFieldProps<Option<number>>, 'simple' | 'options'>;
export function VatRateField(props: VatRateFieldProps): ReactElement {
  const options = useAgencyVatOptions();
  return <SelectField options={options} {...props} simple />;
}

export const BoundStateFilterField = createSelectField<Option<BoundState | 'OVERSPENT'>>(boundStateOptions, {
  simple: true,
});
export const InvoiceStateField = createSelectField<Option<InvoiceState>>(invoiceStateOptions, { simple: true });

export function PayerVatRateField(props: Omit<VatRateFieldProps, 'name'>): ReactElement {
  const api = useAPI();
  const {
    input: { value: payer },
    meta: { dirty },
  } = useField<Company | null>('payer', { subscription: { value: true, dirty: true } });

  const {
    input: { value, onChange },
  } = useField('vat_rate', { subscription: { value: true } });

  const payerId = payer?.id;

  useEffect(
    () => {
      if (payerId && (dirty || value === null || value === '')) {
        api
          .request<number, unknown>({
            url: `core/companies/${payerId}/resolve_vat/`,
            method: 'GET',
          })
          .then((res) => {
            if (res.ok) {
              const rate = res.data;
              if (rate !== value) {
                onChange(rate);
              }
            } else {
              throw res.error;
            }
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payerId],
  );

  return <VatRateField name="vat_rate" {...props} />;
}

export function PaymentDateField(props: DatePickerFieldProps): ReactElement {
  const api = useAPI();
  const fetchIdRef = useRef(0);
  const {
    input: { value: payer },
    meta: { dirty: payerDirty },
  } = useField<Company | null>('payer', { subscription: { value: true, dirty: true } });

  const {
    input: { value: checkout },
    meta: { dirty: checkoutDirty },
  } = useField<string | null>('checkout_date', { subscription: { value: true, dirty: true } });

  const {
    input: { value, onChange },
  } = useField<string | null>(props.name, { subscription: { value: true } });

  const dirty = payerDirty || checkoutDirty || value === '';

  useEffect(
    () => {
      const resolve = async (): Promise<void> => {
        const fetchId = ++fetchIdRef.current;

        if (dirty) {
          let payment_date = null;
          if (payer && checkout) {
            const res = await api.get<ClientAgreement[], unknown>('client/agreements/', {
              queryParams: {
                client: payer.id,
                date: checkout,
              },
            });
            if (res.ok && res.data.length > 0) {
              const agreement = res.data[0];
              if (agreement.invoice_payment_days !== null) {
                payment_date = formatISO(addDays(parseISO(checkout), agreement.invoice_payment_days), {
                  representation: 'date',
                });
              }
            }
          }
          if (payment_date && fetchId === fetchIdRef.current) {
            onChange(payment_date);
          }
        }
      };

      resolve();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, payer, checkout, dirty],
  );

  return <DatePickerField {...props} />;
}

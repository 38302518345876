import { ReactElement, useCallback } from 'react';
import { Actions } from 'components';
import { rowSelection, selectionAtom } from '../../atoms/controls';
import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { CurrentSelection } from './CurrentSelection';
import { useSelectionActions } from './useSelectionActions';

export function SelectionControls({ scrollToRow }: { scrollToRow: (rowId: number) => void }): ReactElement {
  const clear = useAtomCallback(
    useCallback((_get, set) => {
      set(rowSelection, new Set());
    }, []),
  );
  const state = useAtomValue(selectionAtom);
  const numRows = state.rows.length;

  const actions = useSelectionActions(state, clear, scrollToRow);

  return (
    <div className="d-flex flex-row align-items-center">
      <Actions actions={actions} />

      {numRows > 0 && <CurrentSelection numRows={numRows} locked={state.locked} clear={clear} />}
    </div>
  );
}

import { ReactElement } from 'react';
import { TicketFactory } from 'modules/support/TicketFactory';
import initialValues from './initialValues';
import { Form } from './Form';
import { Alert } from '@ff-it/ui';

export function FromTicket(): ReactElement {
  return (
    <TicketFactory
      category="ADD_COMPANY"
      resolveContext={({
        data: {
          title,
          internal_name,
          code,
          kind,
          country,
          registration_number,
          vat_number,
          legal_address,
          office_address,
          office_same_as_legal,
          bank,
          bank_swift,
          bank_account_number,
        },
      }) => {
        const message =
          !bank && bank_swift ? (
            <Alert variant="warning">
              Bank with SWIFT code: <strong>{bank_swift}</strong> not found
            </Alert>
          ) : null;
        return [
          {
            ...initialValues,
            title,
            internal_name,
            code,
            kind,
            country,
            registration_number,
            vat_number,
            legal_address,
            office_address,
            office_same_as_legal,
            bank,
            bank_account_number,
          },
          message,
        ];
      }}
    >
      <Form />
    </TicketFactory>
  );
}

import { FocusEvent, KeyboardEvent, ReactElement, useRef } from 'react';
import { EditorBaseProps } from './types';
import { Icon } from 'components';
import { parseDecimalString } from 'utilities';

type Value = string | null;

interface DecimalEditorProps extends EditorBaseProps<Value> {
  precision?: number;
  resetValue?: string | null;
  normalize?: boolean;
}

export function DecimalEditor({
  value,
  setValue,
  stopEdit,
  precision = 2,
  normalize = false,
  resetValue,
}: DecimalEditorProps): ReactElement {
  const resetRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>();

  function autoFocusAndSelect(input: HTMLInputElement | null): void {
    input?.focus();
    input?.select();
    if (input) {
      inputRef.current = input;
    }
  }

  async function commit(): Promise<void> {
    if (inputRef.current) {
      const newVal = parseDecimalString(inputRef.current?.value, precision, normalize);
      if (newVal !== value) {
        await setValue(newVal);
      }
    }
    stopEdit();
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      stopEdit();
    } else if (event.key === 'Enter') {
      event.stopPropagation();
      commit();
    }
  }

  async function onBlur(e: FocusEvent<HTMLInputElement>): Promise<void> {
    if (resetRef.current && e.relatedTarget === resetRef.current && resetValue) {
      await setValue(parseDecimalString(resetValue, precision, normalize));
      stopEdit();
      return;
    }
    commit();
  }

  const reset = Boolean(resetValue && resetValue !== value);
  return (
    <>
      {reset && (
        <button className="btn grd__editor__reset" ref={resetRef}>
          <Icon icon="rotate-left" size="xs" />
        </button>
      )}

      <input
        className="grd__editor"
        ref={autoFocusAndSelect}
        defaultValue={value || ''}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    </>
  );
}

import { useCallback } from 'react';
import { maybeActionErrorOrThrow } from 'utilities';
import { updateRowAtom } from './atoms/plan';
import { useSetAtom } from 'jotai';
import type { ConfigField, Row, RowField } from 'modules/campaign/row';
import { HTTPMethod, RequestBody, RequestResult } from '@ff-it/api';
import { PlanState } from './types';

export function fieldIsEditable(row: Row, field: RowField): boolean {
  // not all fields have config
  let locked = row.config[field as ConfigField]?.locked;
  if (typeof locked === 'undefined') {
    locked = true;
  }
  return !locked;
}

export type RowFieldUpdater = (
  body: RequestBody | undefined,
  valueId?: number | string,
  method?: HTTPMethod,
) => Promise<RequestResult<PlanState, any>>;

export function useFieldUpdater(
  rowId: number,
  field: RowField,
  cb: false | typeof maybeActionErrorOrThrow = maybeActionErrorOrThrow,
): RowFieldUpdater {
  const rowUpdate = useSetAtom(updateRowAtom);

  return useCallback(
    async (
      body: RequestBody | undefined,
      valueId?: number | string,
      method: HTTPMethod = 'PUT',
    ): Promise<RequestResult<PlanState, any>> => {
      const run = rowUpdate(rowId, field, body, valueId, method);
      if (cb) {
        run.then(cb);
      }
      return await run;
    },
    [rowId, field],
  );
}

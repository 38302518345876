import { parseISO } from 'date-fns';
import { atom, PrimitiveAtom, Getter } from 'jotai';
import { strategies } from 'modules/campaign/block/strategy';
import type { PlanResolution, PlanStrategyType } from 'modules/campaign/block/types';
import { actionErrorAndThrow, NEVER, reselectAtom } from 'utilities';
import { blockAtom, requestHandlerAtom } from '../../../atoms';
import type { CreatePlanRow, PlanState } from '../types';
import { Row, RowField } from 'modules/campaign/row';
import eq from 'fast-deep-equal';
import { HTTPMethod, RequestBody, RequestResult } from '@ff-it/api';
import { applyReducer, Operation, deepClone } from 'fast-json-patch';
import invariant from 'tiny-invariant';
import { rowSelection } from './controls';

export const planAtom: PrimitiveAtom<PlanState> = atom(NEVER);

export const createRowAtom = atom(null, async (get, set, values: CreatePlanRow): Promise<Row> => {
  const api = get(requestHandlerAtom);

  const result = await api<Operation[]>({
    method: 'POST',
    url: `plan/rows/`,
    body: values,
  });

  if (result.ok) {
    set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));

    // FIXME: max id for last row
    return get(planAtom).rows.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
  }
  actionErrorAndThrow(result);
});

export const removeRowsAtom = atom(null, async (get, set, ids: number[]): Promise<void> => {
  const api = get(requestHandlerAtom);

  const result = await api<Operation[]>({
    url: 'plan/rows/',
    method: 'DELETE',
    queryParams: {
      id: ids,
    },
  });

  if (result.ok) {
    // clean up selection
    set(rowSelection, new Set());
    set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));

    return;
  }
  actionErrorAndThrow(result);
});

export const moveRowAtom = atom(null, async (get, set, rowId: number, afterId: number) => {
  const api = get(requestHandlerAtom);
  const result = await api<Operation[]>({
    method: 'POST',
    url: `plan/rows/${rowId}/move/${afterId}/`,
  });

  if (result.ok) {
    set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));

    return;
  }
  actionErrorAndThrow(result);
});

export const updateRowAtom = atom(
  null,
  async (
    get,
    set,
    rowId: number,
    field: RowField,
    body: RequestBody | undefined,
    valueId?: number | string,
    method: HTTPMethod = 'PUT',
  ): Promise<RequestResult<PlanState, any>> => {
    const api = get(requestHandlerAtom);

    // @TODO optimistic update
    // figure out if we want it, might just delay update instead of rendering stale data
    // const currentValue = await snapshot.getPromise(rowState(rowId));
    // set(rowState(rowId), { ...currentValue, ...payload });
    const result = await api({
      method,
      url: `plan/rows/${rowId}/${field}${typeof valueId !== 'undefined' ? `/${valueId}/` : '/'}`,
      body,
    });

    if (result.ok) {
      set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));
    } else {
      // revert optimistic update
      // set(rowState(rowId), currentValue);
    }
    return result;
  },
);

// read only
export const rowsAtom = reselectAtom(
  (get) => get(planAtom).rows,
  (next, prev) => {
    const current = prev.reduce((acc: Record<number, Row>, row) => {
      acc[row.id] = row;
      return acc;
    }, {});
    const res = [];

    for (let i = 0; i < next.length; i++) {
      const nextRow = next[i];
      const currentRow = current[nextRow.id];
      if (eq(currentRow, nextRow)) {
        res.push(currentRow);
      } else {
        res.push(nextRow);
      }
    }
    return res;
  },
);

export const planIntervalAtom = reselectAtom((get) => {
  const { date_from, date_to } = get(planAtom);
  return {
    start: parseISO(date_from),
    end: parseISO(date_to),
  };
});

export const planIsEditableAtom = atom((get) => {
  const { state, role } = get(blockAtom);
  // @TODO update prices
  return state === 'PLANNING' && role.plan;
});

export const planSettingsAtom = atom(
  null,
  async (get, set, settings: Partial<Pick<PlanState, 'plan_strategy' | 'plan_resolution'>>) => {
    const api = get(requestHandlerAtom);
    const result = await api({
      method: 'PATCH',
      url: `plan/`,
      body: settings,
    });

    if (result.ok) {
      set(planAtom, result.data);
    } else {
      actionErrorAndThrow(result);
    }
  },
);

export const planStrategyAtom = atom(
  (get) => get(planAtom).plan_strategy,
  (_get, set, plan_strategy: PlanStrategyType) => set(planSettingsAtom, { plan_strategy }),
);
export const planResolutionAtom = atom(
  (get) => get(planAtom).plan_resolution,
  (_get, set, plan_resolution: PlanResolution) => set(planSettingsAtom, { plan_resolution }),
);

export const strategyAtom = atom((get) => strategies[get(planStrategyAtom)]);
export function getRow(rowId: number, get: Getter): Row {
  const rows = get(rowsAtom);
  const row = rows.find((r) => r.id === rowId);
  invariant(row);
  return row;
}

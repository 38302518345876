import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import { TextField } from '@ff-it/form';
import {
  AllActivityTypesField,
  CreateScene,
  DeleteScene,
  EntityScene,
  IndexScene,
  ListFilter,
  ModelScene,
  UpdateScene,
} from 'components';
import permissions from 'modules/supplier/department/permissions';
import { DepartmentForm, Products, Nav, Factors } from 'modules/supplier/department';
import type { Service } from 'modules/supplier/department/types';
import { ListExport, ListImport } from 'components/ListActions';

const columns: Column<Service>[] = [
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    render: ({ id, name }: Service): ReactElement => <Link to={`${id}`}>{name}</Link>,
  },
];

const initialValues = {
  name: '',
};

const model = {
  endpoint: 'supplier/service/',
  title: 'Service department',
  pluralTitle: 'Service departments',
  entityTitle: ({ name }: Service) => name,
  permissions,
};

export const route = {
  path: 'services',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          toolbar={
            <>
              <ListImport permission={permissions.import_products} endpoint={`${model.endpoint}import_products/`}>
                Product import
              </ListImport>
              <ListExport permission={permissions.export_products} endpoint={`${model.endpoint}export_products/`}>
                Product export
              </ListExport>
            </>
          }
        >
          <ListFilter>
            <TextField name="search" placeholder="Search.." />
            <AllActivityTypesField name="type" placeholder="Type" className="ml-1" />
          </ListFilter>
        </IndexScene>
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <DepartmentForm />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <DepartmentForm />
            </UpdateScene>
          ),
        },
        {
          path: 'products/*',
          element: <Products />,
        },
        {
          path: 'factors/*',
          element: <Factors />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};

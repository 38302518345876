import { ReactElement, ReactNode, useCallback } from 'react';
import cx from 'clsx';
import { useModel } from './context';
import { Grid, GridProps, DrfPageHandler, PageHandler, GridPagination, ErrorHandler } from '@ff-it/grid';
import { RequestError } from '@ff-it/api';
import { Alert, Button } from '@ff-it/ui';
import { Perm, useHasPerms } from 'core/permissions';
import { PermissionDenied } from './errors';
import { Breadcrumb, Page, Heading } from '../layout';
import { Icon } from '../ui';
import { Link } from 'react-router-dom';
import { Entity } from 'types';
import eq from 'fast-deep-equal';
import { toast } from 'react-toastify';

interface IndexSceneProps<T extends Entity>
  extends Omit<GridProps<T>, 'url' | 'children' | 'pageSizes' | 'pageHandler' | 'errorHandler'> {
  perm?: Perm;
  canCreate?: Perm;
  children?: ReactNode;
  className?: string;
  pageSizes?: number[] | null;
  after?: ReactNode;
  toolbar?: ReactNode;
  pageHandler?: PageHandler<T>;
}
const empty = <Alert variant="info">Nothing here</Alert>;

export function IndexScene<T extends Entity = any>({
  perm,
  canCreate,
  children,
  toolbar,
  pageHandler = DrfPageHandler,
  ...props
}: IndexSceneProps<T>): ReactElement {
  const { endpoint, permissions, title, pluralTitle } = useModel();

  const [hasIndex, hasCreate] = useHasPerms(perm || permissions?.view, canCreate || permissions?.add);
  if (!hasIndex) {
    return <PermissionDenied />;
  }

  return (
    <IndexContainer url={endpoint} pageHandler={pageHandler} {...props}>
      <Heading title={pluralTitle}>
        <div className="btn-toolbar">
          {toolbar}
          {hasCreate && (
            <Button variant="outline-primary" size="sm" to="create" component={Link}>
              {`Create ${title}`} <Icon className="ml-1" icon="circle-plus" />
            </Button>
          )}
        </div>
      </Heading>
      {children}
    </IndexContainer>
  );
}

const defaultPageSizes = [15, 30, 60];

interface IndexContainerProps extends Omit<GridProps<any>, 'children' | 'pageSizes' | 'errorHandler' | 'pageHandler'> {
  children?: ReactNode;
  className?: string;
  pageSizes?: number[] | null;
  pageHandler?: PageHandler<any>;
  breadCrumb?: ReactNode | null;
}

export function IndexContainer({
  className,
  children,
  pageSizes = defaultPageSizes,
  pageHandler = DrfPageHandler,
  defaultPageSize = 30,
  breadCrumb = 'Index',
  ...props
}: IndexContainerProps): ReactElement {
  const errorHandler: ErrorHandler<any, any> = useCallback(
    (error: any, _state, setState, { filter }, setQueryState) => {
      // we could compare keys here?
      if (
        error instanceof RequestError &&
        error.status === 400 &&
        filter &&
        Object.keys(filter).length !== 0 &&
        !eq(filter, props.defaultFilter)
      ) {
        setQueryState((queryState) => ({
          ...queryState,
          filter: props.defaultFilter,
        }));
        toast.error('Resetting filter');
      } else {
        setState(() => {
          throw error;
        });
      }
    },
    [props.defaultFilter],
  );

  return (
    <Page className={cx('scene scene-index', className)}>
      {breadCrumb && <Breadcrumb to={null}>{breadCrumb}</Breadcrumb>}
      <Grid
        pageHandler={pageHandler}
        errorHandler={errorHandler}
        {...props}
        pagination={
          pageSizes === null ? null : (
            // FIXME
            <div className="pb-3 py-3">
              <GridPagination pageSizes={pageSizes} />
            </div>
          )
        }
        defaultPageSize={defaultPageSize}
        emptyElement={empty}
      >
        {children}
      </Grid>
    </Page>
  );
}

import { ReactElement } from 'react';
import cx from 'clsx';
import './CompanyTitle.scss';

export interface CompanyTitleProps {
  id: number;
  title: string;
  internal_name: string;
}

export function CompanyTitle({
  id,
  title,
  internal_name,
  className,
}: CompanyTitleProps & { className?: string }): ReactElement {
  return (
    <div className={cx('company-title', className)} data-testid={`company-${id}`}>
      <div className="title">{title}</div>
      {internal_name && <div className="text-muted internal-name">{internal_name}</div>}
    </div>
  );
}

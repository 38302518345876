import { ReactElement, Fragment } from 'react';
import { Fieldset, ItemLoader, Period } from 'components';
import { nl2br } from 'utilities';
import { useAtomValue } from 'jotai';
import { blockTypeAtom, campaignAtom } from '../atoms';
import { ClientAgreement } from 'modules/client/agreement/types';
import { DepartmentTitle } from 'modules/supplier/department/DepartmentTitle';
import { ProductTitle } from 'modules/supplier/products';

export function Agreement(): ReactElement {
  const type = useAtomValue(blockTypeAtom);
  const { id } = useAtomValue(campaignAtom);

  const url = `campaign/campaigns/${id}/agreement/`;
  return (
    <ItemLoader<ClientAgreement> url={url}>
      {({ item: agreement }) => {
        const blockTerms = agreement.type_terms.find((terms) => terms.type === type);
        return (
          <div className="container ml-0">
            <h4>
              {agreement.number} <Period date_from={agreement.date_from} date_to={agreement.date_to} flat />
            </h4>
            <div className="row">
              {agreement.terms && (
                <div className="pt-3 col-md-6">
                  <Fieldset title="General terms">{nl2br(agreement.terms)}</Fieldset>
                </div>
              )}

              {blockTerms && (
                <div className="pt-3 col-md-6">
                  <Fieldset title={`${type} terms`}>{nl2br(blockTerms.terms)}</Fieldset>
                </div>
              )}
            </div>
            <Fieldset title="Discounts">
              <div className="row">
                <div className="col-md-2 d-flex">
                  <small className="font-weight-bold">Base discount</small>
                  <span className="ml-auto">{agreement.client_discount || '—'}</span>
                </div>
              </div>
              {agreement.discounted_departments.map((dep, idx) => (
                <Fragment key={idx}>
                  <div className="row py-1" key={idx}>
                    <div className="col-md-2" />
                    <div className="col-md-2 d-flex">
                      <small className="font-weight-bold">
                        <DepartmentTitle {...dep.department} />
                      </small>
                      <span className="ml-auto">{dep.client_discount || '—'}</span>
                    </div>
                  </div>
                  {dep.discounted_products.length > 0 &&
                    dep.discounted_products.map((product) => (
                      <div className="row py-1" key={product.product.id}>
                        <div className="col-md-4" />
                        <div className="col-md-2 d-flex">
                          <small className="font-weight-bold">
                            <ProductTitle product={product.product} />
                          </small>
                          <span className="ml-auto">{product.client_discount || '—'}</span>
                        </div>
                      </div>
                    ))}
                </Fragment>
              ))}
            </Fieldset>
          </div>
        );
      }}
    </ItemLoader>
  );
}

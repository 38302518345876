import { ReactElement } from 'react';
import cx from 'clsx';
import './DepartmentTitle.scss';
import type { Department } from '../types';

export function DepartmentTitle({ id, name, kind, className }: Department & { className?: string }): ReactElement {
  return (
    <div className={cx('department-title', className)} data-testid={`department-${id}`}>
      <div className="department">{name}</div>
      <div className="kind text-muted">{kind}</div>
    </div>
  );
}

import { memo, ReactElement, RefObject, useEffect, useRef } from 'react';
import invariant from 'tiny-invariant';
import { DPR } from './const';

export type Draw = (ctx: CanvasRenderingContext2D) => void;

export function useCanvas(draw: Draw): RefObject<HTMLCanvasElement> {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d', { alpha: true });

    invariant(canvas && ctx);

    let queuedRender: number | null = null;
    const render = (): void => {
      queuedRender = window.requestAnimationFrame(() => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.scale(DPR, DPR);
        try {
          ctx.save();
          try {
            draw(ctx);
          } finally {
            ctx.restore();
          }
        } finally {
          ctx.restore();
          queuedRender = null;
        }
      });
    };

    render();

    return () => {
      queuedRender && window.cancelAnimationFrame(queuedRender);
    };
  }, [draw]);
  return canvasRef;
}

type CanvasProps = {
  width: number;
  height: number;
  draw: Draw;
};

export const Canvas = memo(function Canvas({ draw, width, height }: CanvasProps): ReactElement {
  const canvasRef = useCanvas(draw);

  return (
    <canvas
      ref={canvasRef}
      width={width * DPR}
      height={height * DPR}
      style={{
        width,
        height,
      }}
    />
  );
});

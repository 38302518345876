import Big from 'big.js';
import { SetStateAction } from 'jotai';
import { Dispatch } from 'react';
import { Period } from 'types';

export const facets = ['BLOCK', 'ROW', 'DEPARTMENT', 'ACTIVITY'] as const;

export type Facet = (typeof facets)[number];

export type Template = {
  facet: Facet;
  expandFees: boolean;
  appendComment: boolean;
  altActivityName: boolean;
  transparentUnits: boolean;
};

export type Selection = Record<string, string[]>;

export type BuilderState = {
  selection: Selection;
  template: Template;
  select: (blockId: string, month: string) => void;
  setTemplate: Dispatch<SetStateAction<Template>>;
  clear?: () => void;
};

export type AmountFields = {
  target: Big;
  invoiced: Big;
  invoiceable: Big;
};

export type MonthAmount = {
  invoiceable: Big;
  target: Big;
  quantity: string | null;
};

export type BuilderMonth = AmountFields & {
  amounts: Record<string, MonthAmount>;
};

export type BuilderBlock = AmountFields & {
  id: string;
  // sums: BlockSums;
  months: Record<string, BuilderMonth>;
};

export type BuilderContext = {
  period: Period | null;
  blocks: BuilderBlock[];
};

import { ReactElement, useMemo } from 'react';
import { RequestArgs, RequestBody, RequestFailure, useAPI } from '@ff-it/api';
import invariant from 'tiny-invariant';
import type { Action } from './Actions';
import { MaybePromiseAction, PomiseActionsProps, PromiseActions } from './PromiseActions';
import { actionErrorAndThrow } from 'utilities';

// true for resolved, undefied for canceled
export type RequestDialogResult = RequestBody | true | undefined;
export interface RequestDialog {
  (): Promise<RequestDialogResult>;
}

export interface RequestAction extends Action {
  request: RequestArgs;
  onRetry?: (f: RequestFailure<any>, requestArgs: RequestArgs) => RequestArgs | undefined;
  requestDialog?: RequestDialog;
  successMessage?: string;
}

export type MaybeRequestAction = MaybePromiseAction | RequestAction;

export function isRequestAction(action: MaybeRequestAction): action is RequestAction {
  return (action as RequestAction).request !== undefined;
}

// Binds request args to promises
export function useRequestActions(actions: Array<MaybeRequestAction>): MaybePromiseAction[] {
  const api = useAPI();

  return useMemo(
    () =>
      actions.map((action) => {
        if (isRequestAction(action)) {
          const { request, requestDialog, onRetry, ...rest } = action;
          invariant(!action.button?.onClick, "onClick handler present, can't bind action");

          const promise = async (): Promise<any> => {
            let payload = undefined;
            if (requestDialog) {
              const res = await requestDialog();
              if (typeof res === 'undefined') {
                // bail
                return;
              }
              if (payload != true) {
                payload = res;
              }
            }

            const requestArgs = {
              ...request,
              body: payload || request.body,
            };
            let res = await api.request(requestArgs);
            /// @FIXME get rid of this and use self-containted onClick handler
            if (!res.ok && onRetry) {
              const retryArgs = onRetry(res, requestArgs);
              if (retryArgs) {
                res = await api.request(retryArgs);
              }
            }

            if (res.ok) {
              return res.data;
            }

            actionErrorAndThrow(res);
          };
          return {
            lock: true,
            promise,
            ...rest,
          };
        }
        return action;
      }),
    [actions],
  );
}

export type RequestActionsProps = Omit<PomiseActionsProps, 'actions'> & {
  actions: MaybeRequestAction[];
};

export function RequestActions({ actions, ...props }: RequestActionsProps): ReactElement {
  const boundActions = useRequestActions(actions);
  return <PromiseActions {...props} actions={boundActions} />;
}

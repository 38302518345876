import { Field, useEntity, Fieldset } from 'components';
import invariant from 'tiny-invariant';
import type { Ticket } from 'modules/support/types';
import type { ReactElement } from 'react';
import { SupplierTitle } from 'modules/supplier/supplier/components';

export function AddClient(): ReactElement {
  const {
    item: { data },
  } = useEntity<Ticket>();
  invariant(data, 'data present');

  return (
    <Fieldset title="Add client">
      <div className="form-row">
        <Field name="enterprise.company.title" label="Agency" className="col-md-4" />
        <Field name="data.account_manager" label="Account managers" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="data.company.title" label="Company" className="col-md-4" />
        <Field name="data.end_client.title" label="End client" className="col-md-4" />
        <Field name="data.brand" label="Brand" className="col-md-4" />
      </div>
      <Field name="data.smart_rates" label="Smart rates">
        {(c: any[]) =>
          c.length > 0 ? (
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Supplier</th>
                  <th>Target activity</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {c.map(({ target_activity: activity, supplier, type, rate }: any, idx) => (
                  <tr key={idx}>
                    <td data-testid="type">{type}</td>
                    <td data-testid="supplier">
                      {/* FIXME legact data */}
                      {supplier.product && supplier.provider && supplier.activity ? (
                        <SupplierTitle {...supplier} />
                      ) : null}
                    </td>
                    <td data-testid="activity">{activity ? activity.name : '-'}</td>
                    <td data-testid="rate">{rate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            '-'
          )
        }
      </Field>
      <Field name="data.rates" label="Specialist rate">
        {(c: any[]) =>
          c.length > 0 ? (
            <table className="table table-sm">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Target activity</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                {c.map(({ activity, type, rate }: any, idx) => (
                  <tr key={idx}>
                    <td data-testid="type">{type}</td>
                    <td data-testid="activity">{activity ? activity.name : '-'}</td>
                    <td data-testid="rate">{rate}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            '-'
          )
        }
      </Field>
      <div className="form-row">
        <Field
          name="data.terms"
          className="col-6"
          label="Terms & conditions"
          help="Describe any terms & conditions this agreements contains"
        />
        <Field label="Invoice payment days" name="data.invoice_payment_days" className="col-3" />
      </div>

      <div className="form-row">
        <Field name="data.extra_info" label="Extra info" help="Any other info to know" className="col-md-4" />
      </div>
    </Fieldset>
  );
}

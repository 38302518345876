import { type Dispatch, type SetStateAction, useState } from 'react';
import type { FilterState } from './types';

export const initialFilter: FilterState = {
  client: [],
  end_client: null,
  type: null,
  activity: null,
  department: null,
  provider: null,
  block: null,
};

export function useFilterState(
  initialState?: Partial<FilterState>,
): [filter: FilterState, setFilter: Dispatch<SetStateAction<FilterState>>] {
  return useState<FilterState>({
    ...initialFilter,
    ...initialState,
  });
}

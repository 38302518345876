import { ReactElement, Children } from 'react';
import { ValueContainerProps, components } from 'react-select';
import { CheckboxOption } from './CheckboxOption';

export function HeadValueContainer({ children, ...rest }: ValueContainerProps<any, any, any>): ReactElement {
  const values: ReactElement[] = [];
  const tail: ReactElement[] = [];
  Children.forEach(children as ReactElement[], (element) => {
    if (element?.type == components.MultiValue) {
      values.push(element);
    } else {
      tail.push(element);
    }
  });

  let content = children;

  if (values.length > 1) {
    if (rest.selectProps.menuIsOpen) {
      content = tail;
    } else {
      content = [
        <components.Placeholder {...(rest as any)} key="placeholder">
          {rest.selectProps.placeholder} <span className="badge badge-primary ml-32">{values.length}</span>
        </components.Placeholder>,
        ...tail,
      ];
    }
  } else if (values.length === 1) {
    content = [<components.SingleValue key={values[0].key} {...values[0].props} />, ...tail];
  }

  return (
    <components.ValueContainer {...rest} isMulti={false} className="flex-nowrap">
      {content}
    </components.ValueContainer>
  );
}

export const headValueSelectProps = {
  components: {
    DropdownIndicator: null,
    Option: CheckboxOption,
    MultiValueRemove: () => null,
    ValueContainer: HeadValueContainer,
  },
  hideSelectedOptions: false,
  isMulti: true,
  blurInputOnSelect: true,
};

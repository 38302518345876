import { FocusEvent, KeyboardEvent, ReactElement, useRef } from 'react';
import { EditorBaseProps } from './types';

type Value = string | null;

export function TextEditor({ value, setValue, stopEdit }: EditorBaseProps<Value>): ReactElement {
  const inputRef = useRef<HTMLInputElement>();

  function autoFocusAndSelect(input: HTMLInputElement | null): void {
    input?.focus();
    // input?.select(); we do this with decimal
    if (input) {
      inputRef.current = input;
    }
  }

  async function commit(): Promise<void> {
    if (inputRef.current) {
      const newVal = inputRef.current?.value;
      if (newVal !== value) {
        await setValue(newVal);
      }
    }
    stopEdit();
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      stopEdit();
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.stopPropagation();
      commit();
    }
  }

  function onBlur(_e: FocusEvent<HTMLInputElement>): void {
    commit();
  }

  return (
    <input
      className="grd__editor"
      ref={autoFocusAndSelect}
      defaultValue={value || ''}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    />
  );
}

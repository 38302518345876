import { CSSProperties, memo, ReactElement } from 'react';
import type { CalculatedColumn } from './types';
import { HeaderCell } from './Cell';

export interface HeaderRowProps {
  columns: readonly CalculatedColumn[];
}

export const HeaderRow = memo(function HeaderRow({ columns }: HeaderRowProps): ReactElement {
  const cellElements = [];
  for (let colIdx = 0; colIdx < columns.length; colIdx++) {
    const column = columns[colIdx];
    const { key, header } = column;
    cellElements.push(
      <HeaderCell key={key} colIdx={colIdx} column={column}>
        {header}
      </HeaderCell>,
    );
  }

  return (
    <div
      className="grd__r grd__r--header"
      style={
        {
          ['--grd-row-start']: 2,
        } as unknown as CSSProperties
      }
    >
      {cellElements}
    </div>
  );
});

import { ReactElement } from 'react';
import cx from 'clsx';
import { CalendarContext, PositionError, Segment, SegmentSelectionStateType } from '../types';
import { positionErrors } from '../const';
import { Row } from 'modules/campaign/row';
import { calcRowSegments, calcRowSelection } from '../util';

export function Segments({
  row,
  ctx,
  rowSelection,
}: {
  row: Row;
  ctx: CalendarContext;
  rowSelection: SegmentSelectionStateType | null;
}): ReactElement | null {
  const segments = calcRowSegments(row, ctx);

  if (segments.length == 0) {
    return null;
  }

  const selection = rowSelection ? calcRowSelection(row, rowSelection, ctx) : undefined;

  return (
    <>
      {segments.map((s) => {
        const active = selection?.id == s.id;
        const segment: Segment & {
          error?: PositionError | null;
        } = selection?.id == s.id ? selection : s;
        const { id, left, width, quantity, error } = segment;
        return (
          <div
            key={id}
            className={cx('positions__seg', {
              'positions__seg--active': active,
              'positions__seg--invalid': error,
            })}
            title={quantity.toString()}
            style={{
              left,
              width,
            }}
          >
            <span className="contain">{quantity}</span>

            {error && <span className="error">{positionErrors[error]}</span>}
          </div>
        );
      })}
    </>
  );
}

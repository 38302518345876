import { ActionDropdown } from 'components';
import { ReactElement } from 'react';
import { useImportActions } from './useImportActions';
import { useRowActions } from './useRowActions';
import { RowActionProps } from './types';

export function RowActions(props: RowActionProps): ReactElement {
  const importActions = useImportActions(props);
  const rowActions = useRowActions(props);
  return (
    <>
      <ActionDropdown
        actions={importActions}
        variant="outline-secondary"
        className="ml-3"
        size="sm"
        icon="file-import"
        testId="import-actions"
      >
        Import
      </ActionDropdown>
      <ActionDropdown
        actions={rowActions}
        variant="primary"
        className="ml-3"
        size="sm"
        icon="plus"
        testId="row-actions"
      >
        Add row
      </ActionDropdown>
    </>
  );
}

import { superuser } from 'core/permissions';
import type { IconProp } from 'components';

export const menu = {
  to: '/system/',
  label: 'System',
  icon: ['far', 'wrench'] as IconProp,
  children: [
    { to: '/system/agencies', label: 'Agencies' },
    { to: '/system/users', label: 'Users' },
  ],
  visible: superuser,
};

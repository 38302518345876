import { ReactElement } from 'react';
import { Form, Submit, useSubmitHandler } from '@ff-it/form';
import { Button, DialogBody, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { DepartmentField } from '../DepartmentField';
import { required } from 'utilities';

import { DepartmentKind } from 'types';
import { Department } from '../types';

type MoveDepartmentDialogProps = RenderProps<Department> & {
  endpoint: string;
  departmentId: number;
  departmentKind: DepartmentKind;
};

export function MoveDepartmentDialog({
  endpoint,
  onDismiss,
  departmentId,
  departmentKind,
  onSubmit,
}: MoveDepartmentDialogProps): ReactElement {
  const submitHanlder = useSubmitHandler<{ department: Department }, undefined>(
    {
      url: `${endpoint}move/`,
      method: 'POST',
    },
    {
      onSuccess: (_, form) => onSubmit(form.getState().values.department),
    },
  );

  return (
    <Form onSubmit={submitHanlder}>
      <DialogHeader title="Move product" />
      <DialogBody>
        <DepartmentField
          label="Department"
          name="department"
          required
          validate={required}
          menuPortalTarget={document.body}
          filter={{
            kind: departmentKind,
            exclude: departmentId,
          }}
          autoFocus
        />
      </DialogBody>
      <DialogFooter>
        <Submit>Move</Submit>
        <Button type="button" variant="outline-secondary" size="sm" className="ml-1 ml-auto" onClick={onDismiss}>
          Cancel
        </Button>
      </DialogFooter>
    </Form>
  );
}

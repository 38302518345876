export default {
  supplier: null,
  date_from: null,
  date_to: null,
  quantity: null,
  bruto_price: null,
  client_price: null,
  provider_price: null,
  fee: null,
  comment: '',
};

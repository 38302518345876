import { ActivityIcon, Badge, EntityContextType, Period, TabNav, useDownloadHandler, useEntity } from 'components';
import { BlockActions } from '../../BlockActions';
import { BlockDetails } from '../../types';
import { BudgetProgress } from './BudgetProgress';
import type { SubNavItem } from 'components';
import { fmtPeriodDistance, formatDate } from 'utilities';
import { PlanAttachments } from './PlanAttachment';
import { ReactElement } from 'react';
import { useAtomValue } from 'jotai';
import { campaignPeriodAtom } from '../../atoms';

function useBlockNav(entityContext: EntityContextType<BlockDetails>): SubNavItem[] {
  const {
    item: { etag },
    endpoint,
    setLoading,
  } = entityContext;

  const exportRows = useDownloadHandler(
    {
      url: `${endpoint}export/`,
      headers: {
        'If-Match': etag,
      },
    },
    setLoading,
  );

  const exportPlan = useDownloadHandler(
    {
      url: `${endpoint}plan/rows/xlsx/`,
      headers: {
        'If-Match': etag,
      },
    },
    setLoading,
  );

  return [
    {
      end: true,
      to: '',
      children: 'Plan',
    },
    {
      to: 'brief',
      children: 'Brief',
    },
    {
      to: 'agreement',
      children: 'Agreement',
    },
    {
      to: 'history',
      children: 'History',
    },
    {
      children: 'Export',
      items: [
        {
          children: 'Plan export',
          onClick: exportPlan,
        },
        {
          children: 'Row export',
          onClick: exportRows,
        },
      ],
    },
  ];
}
export function BlockHeader(): ReactElement {
  const period = useAtomValue(campaignPeriodAtom);
  const ctx = useEntity<BlockDetails>();
  const {
    item: {
      state,
      type,
      title,
      code,
      budget,
      date_from,
      date_to,
      deadline,
      planners,
      target_groups,
      suggested_media,
      languages,
    },
    endpoint,
  } = ctx;

  const nav: SubNavItem[] = useBlockNav(ctx);

  return (
    <div className="container-fluid header header--block">
      <div className="d-flex flex-row align-items-center mt-1">
        <h3 className="mt-0">
          <small>
            <ActivityIcon type={type} />
          </small>{' '}
          <strong>{type}</strong> {title}{' '}
          <span className="text-muted">
            <small>#{code}</small>
          </span>
        </h3>
        <Badge state={state} dataTestId="block-state" className="ml-2" />

        <dl className="ml-auto">
          <dt>
            <Period date_from={date_from} date_to={date_to} flat />
          </dt>
          <dd className="text-muted">{fmtPeriodDistance({ date_from, date_to })}</dd>
          <dt>deadline</dt>
          <dd className="font-weight-bold">{formatDate(deadline)}</dd>
          <dt>planned by</dt>
          <dd>{planners.map(({ first_name, last_name }) => `${first_name} ${last_name.charAt(0)}`).join(', ')}</dd>
        </dl>
      </div>

      <div className="mb-1 mt-1 d-flex">
        <dl>
          {target_groups && (
            <>
              <dt>target groups</dt>
              <dd className="font-weight-bold" data-test-id="field-target_groups">
                {target_groups}
              </dd>
            </>
          )}
          {suggested_media && (
            <>
              <dt>suggested media</dt>
              <dd className="font-weight-bold" data-test-id="field-suggested_media">
                {suggested_media}
              </dd>
            </>
          )}
          <dt>languages</dt>
          <dd className="font-weight-bold" data-test-id="field-languages">
            {languages.join(', ')}
          </dd>
        </dl>
        <PlanAttachments endpoint={endpoint} className="ml-auto" />
      </div>

      <div className="d-flex flex-row">
        <TabNav nav={nav} />
        <div className="mx-auto d-flex">
          <BudgetProgress budget={budget} />
        </div>
        <BlockActions campaignPeriod={period} />
      </div>
    </div>
  );
}

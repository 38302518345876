import clients from 'modules/client/permissions';
import { permissions as providers } from './Providers/permissions';
import type { IconProp } from 'components';

export const menu = {
  to: '/contacts',
  label: 'Contacts',
  icon: ['far', 'user-group'] as IconProp,
  children: [
    { to: '/contacts/clients', label: 'Clients', visible: clients.view },
    { to: '/contacts/providers', label: 'Providers', visible: providers.view },
  ],
};

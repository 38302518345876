import { ReactElement } from 'react';
import { PopupCell } from '../../../Editor';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { getOptionValue, renderValues } from './util';
import { useEditableChoiceProps } from '../useEditableChoiceProps';
import { Selector } from './Selector';
import { Editor } from './Editor';
import { fieldIsEditable, useFieldUpdater } from '../../../../hooks';
import type { Placement, Row } from 'modules/campaign/row';

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.placement;
  const options = props.row.config.placement?.resolved_value as null | Placement[];
  const update = useFieldUpdater(props.row.id, 'placement', false);

  const { edit, select } = useEditableChoiceProps(options, value, update, getOptionValue);

  return (
    <PopupCell {...props} cellContent={renderValues(value, false)} onRequestClose={props.stopEdit}>
      {/* hide if no options */}
      {select.options.length > 0 && <Selector {...select} />}
      <Editor {...edit} />
    </PopupCell>
  );
}

export const placement: GridColumn<Row> = {
  key: 'placement',
  header: 'Placement',
  width: 116,
  renderCell: (props) =>
    props.row.kind === 'MEDIA' ? (
      props.editing ? (
        <Edit {...props} />
      ) : (
        renderValues(props.row.placement, !props.isEditable)
      )
    ) : null,
  editable: (row) => fieldIsEditable(row, 'placement'),
};

import invariant from 'tiny-invariant';
import Big from 'big.js';
import { parseBig } from 'utilities';
import { VATStrategy } from 'types';

export interface RowLike {
  quantity: string;
  unit_price: string;
}
export function getRowTotal({ quantity, unit_price }: RowLike): Big {
  return parseBig(quantity).mul(parseBig(unit_price)).round(2, 1);
}

export interface InvoiceLike {
  vat_rate: number;
  rows: RowLike[];
  vat_strategy: VATStrategy;
}

interface TotalSums {
  totalInvoiced: Big;
  vatRate: number;
  vatAmount: Big;
  totalFinal: Big;
}

interface InvoiceCalculator {
  (invoice: InvoiceLike): TotalSums;
}

const strategyMap: Record<VATStrategy, InvoiceCalculator> = {
  LINE_VAT: ({ vat_rate: rawVatRate, rows }) => {
    const parsedVatRate = parseBig(rawVatRate).div(100);
    let vatAmount = Big(0);

    const totalInvoiced = rows.reduce((acc: Big, row: any) => {
      const amount = getRowTotal(row);
      vatAmount = vatAmount.plus(amount.mul(parsedVatRate).round(2, 1));
      return acc.plus(amount);
    }, Big(0));

    const totalFinal = totalInvoiced.plus(vatAmount);

    return {
      totalInvoiced,
      vatRate: rawVatRate,
      vatAmount,
      totalFinal,
    };
  },
  GROUP_VAT: ({ vat_rate: rawVatRate, rows }) => {
    // for now we have only one vat group
    const parsedVatRate = parseBig(rawVatRate).div(100);

    const totalInvoiced = rows.reduce((acc: Big, row: any) => {
      const amount = getRowTotal(row);
      return acc.plus(amount);
    }, Big(0));

    const vatAmount = totalInvoiced.mul(parsedVatRate).round(2, 1);
    const totalFinal = totalInvoiced.plus(vatAmount);

    return {
      totalInvoiced,
      vatRate: rawVatRate,
      vatAmount,
      totalFinal,
    };
  },
};

export function calculateTotals({
  // FIXME: deleting last rown in form results in undefied rows
  rows = [],
  ...rest
}: InvoiceLike): TotalSums {
  invariant(rest.vat_strategy && strategyMap[rest.vat_strategy]);
  return strategyMap[rest.vat_strategy]({
    rows,
    ...rest,
  });
}

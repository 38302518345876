import { Box, Check, Dialog, DialogBody, DialogClose, DialogContent, DialogHeader } from '@ff-it/ui';
import { ReactElement, useState } from 'react';
import { Summary } from './Summary';
import { useAtom } from 'jotai';
import { summaryOpenAtom } from '../atoms/summary';

export function SummaryDialog(): ReactElement | null {
  const [showMonths, setShowMonths] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useAtom(summaryOpenAtom);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="right">
        <DialogHeader title="Plan summary">
          <Box marginLeft="md" marginTop="xs">
            <Check
              checked={showMonths}
              onChange={(e) => setShowMonths(e.target.checked)}
              label="Expand months"
              inline
              variant="switch"
            />
          </Box>
          <DialogClose className="ml-auto" size="sm" />
        </DialogHeader>
        <DialogBody>
          <Summary showMonths={showMonths} />
        </DialogBody>
      </DialogContent>
    </Dialog>
  );
}

import { ReactElement } from 'react';
import { CheckField, DecimalField, useField } from '@ff-it/form';
import { required } from 'utilities';

export function Form(): ReactElement {
  const {
    input: { value: alt_price },
  } = useField('alt_prices', { subscription: { value: true } });
  return (
    <div>
      <div className="form-row">
        <DecimalField
          name="quantity"
          required
          validate={required}
          label="Quantity"
          precision={4}
          className="col-6"
          size="sm"
        />
        <DecimalField
          name="total"
          required
          validate={required}
          label="Total"
          precision={18}
          className="col-6"
          size="sm"
        />
      </div>
      <div className="border-top mt-2 pt-3">
        <CheckField name="alt_prices" fieldLabel="Split client price" variant="switch" className="ml-2" />
      </div>
      {alt_price && (
        <div className="form-row mt-3 mb-0">
          <DecimalField
            name="alt_quantity"
            required
            validate={required}
            label="Client quantity"
            precision={4}
            className="col-6"
            size="sm"
          />
          <DecimalField
            name="alt_total"
            required
            validate={required}
            label="Client total"
            precision={18}
            className="col-6"
            size="sm"
          />
        </div>
      )}
    </div>
  );
}

import { useCallback, MouseEvent } from 'react';
import { getDRFFormError } from '@ff-it/form';
import { openModal } from '@ff-it/ui';
import { differenceInDays, formatISO, max, min } from 'date-fns';
import { actionErrorOrThrow, maybeActionErrorOrThrow } from 'utilities';
import { PositionForm } from './PositionForm';
import { Segment, SelectionStateType } from '../types';
import { getPositionError, getSegmentMouseHit, shiftInterval, calcRowSegments, calcRowSelection } from '../util';
import { useFieldUpdater } from 'modules/campaign/block/views/Plan/hooks';
import { useAtomCallback } from 'jotai/utils';
import { calendarContextAtom, segmentSelectionStateAtom, positionSelectionStateAtom } from '../atoms';
import { getRow } from 'modules/campaign/block/views/Plan/atoms/plan';
import { DialogForm } from 'components';

function usePositionHandler(rowId: number): {
  createDialog: (selection: SelectionStateType | null) => Promise<void>;
  updateDialog: (position: Segment) => Promise<void>;
  updateInterval: () => Promise<void>;
} {
  const updater = useFieldUpdater(rowId, 'positions', false);

  const createDialog = useCallback(
    async (selection: SelectionStateType | null) => {
      if (selection && selection.error) {
        // invariant
        return;
      }
      await openModal((props) => (
        <DialogForm
          {...props}
          initialValues={{
            quantity: null,
            date_from: selection ? formatISO(selection.start, { representation: 'date' }) : selection,
            date_to: selection ? formatISO(selection.end, { representation: 'date' }) : selection,
          }}
          submitHandler={async (values) => {
            const res = await updater(values as any, undefined, 'POST');
            if (!res.ok) {
              const formError = getDRFFormError(res);
              if (formError) {
                return formError;
              }
              actionErrorOrThrow(res);
            }
          }}
        >
          <PositionForm />
        </DialogForm>
      ));
    },
    [updater],
  );

  const updateDialog = useCallback(
    async (position: Segment) => {
      await openModal((props) => (
        <DialogForm
          {...props}
          initialValues={position}
          submitHandler={async (values) => {
            const res = await updater(values as any, position.id, 'PUT');
            if (!res.ok) {
              const formError = getDRFFormError(res);
              if (formError) {
                return formError;
              }
              actionErrorOrThrow(res);
            }
          }}
          onRemove={async () => {
            await updater(undefined, position.id, 'DELETE').then(maybeActionErrorOrThrow);
          }}
        >
          <PositionForm />
        </DialogForm>
      ));
    },
    [updater],
  );

  const updateInterval = useAtomCallback(
    useCallback(
      async (get) => {
        const selectionState = get(segmentSelectionStateAtom);
        if (!selectionState || selectionState.rowId !== rowId) {
          return;
        }
        const selection = calcRowSelection(getRow(rowId, get), selectionState, get(calendarContextAtom));
        if (!selection) {
          // invariant
          return;
        }
        if (!selection.error) {
          await updater(selection as any, selection.id, 'PUT').then(maybeActionErrorOrThrow);
        }
      },
      [updater],
    ),
  );

  return { createDialog, updateDialog, updateInterval };
}
let isDrag = false;
export function useMouseDownHandler(rowId: number): (event: MouseEvent) => void {
  const handler = usePositionHandler(rowId);

  return useAtomCallback(
    useCallback(
      (get, set, event) => {
        const ctx = get(calendarContextAtom);
        const { interval, pixelToDate } = ctx;
        const rowLeft = event.currentTarget.getBoundingClientRect().left;
        const x = event.clientX - rowLeft;
        // else selection
        const day = pixelToDate(x);
        const segments = calcRowSegments(getRow(rowId, get), ctx);

        const hit = getSegmentMouseHit(x, segments);

        if (hit) {
          // segment update
          const [segment, handle] = hit;

          // start move or bind edit
          let didMove = false;
          let delta = 0;
          const handleMoveSegment = (event: MouseEvent): void => {
            isDrag = true;
            const newDelta = differenceInDays(pixelToDate(event.clientX - rowLeft), day);
            if (newDelta != delta) {
              delta = newDelta;
              const newInterval = shiftInterval({ start: segment.start, end: segment.end }, handle, newDelta);
              // calendar bounds
              if (newInterval.start < interval.start || newInterval.end > interval.end) {
                return;
              }
              // if (getSegmentIntervalHit(segments, newInterval, segment.id)) {
              //   return;
              // }

              didMove = true;
              set(segmentSelectionStateAtom, {
                rowId,
                segmentId: segment.id,
                error: getPositionError(segments, newInterval, segment.id),
                handle,
                delta,
              });
            }
          };
          window.addEventListener('mousemove', handleMoveSegment as any);
          window.addEventListener(
            'mouseup',
            (e) => {
              e.preventDefault();
              window.removeEventListener('mousemove', handleMoveSegment as any);
              if (didMove) {
                handler.updateInterval().finally(() => {
                  set(segmentSelectionStateAtom, null);
                  isDrag = false;
                });
              } else {
                (e.shiftKey ? handler.createDialog(null) : handler.updateDialog(segment)).finally(() => {
                  set(positionSelectionStateAtom, null);
                  isDrag = false;
                });
              }
            },
            { once: true },
          );

          return;
        } else {
          let selection: SelectionStateType = {
            rowId,
            anchor: day,
            start: day,
            end: day,
            error: getPositionError(segments, { start: day, end: day }),
          };
          // start drag
          set(positionSelectionStateAtom, selection);

          const handleUpdateSelection = (event: MouseEvent): void => {
            isDrag = true;
            // @TODO figure out how to bail here as soon as possible
            const x = event.clientX - rowLeft;

            const day = pixelToDate(x);

            const start = min([selection.anchor, day]);
            const end = max([selection.anchor, day]);

            if (selection.start.getTime() !== start.getTime() || selection.end.getTime() !== end.getTime()) {
              // bail if we hit any of the existing positions
              // if (getSegmentIntervalHit(segments, { start, end })) {
              //   return;
              // }

              selection = {
                ...selection,
                error: getPositionError(segments, { start, end }),
                start,
                end,
              };
              set(positionSelectionStateAtom, selection);
            }
          };

          window.addEventListener('mousemove', handleUpdateSelection as any);
          window.addEventListener(
            'mouseup',
            (e) => {
              window.removeEventListener('mousemove', handleUpdateSelection as any);
              const positionState = get(positionSelectionStateAtom);
              const rowSelectionState = positionState?.rowId == rowId ? positionState : null;
              handler.createDialog(e.shiftKey ? null : rowSelectionState).finally(() => {
                set(positionSelectionStateAtom, null);
                isDrag = false;
              });
            },
            { once: true },
          );
        }
      },
      [handler],
    ),
  );
}

export function useMouseMoveHandler(rowId: number): (event: MouseEvent) => void {
  return useAtomCallback(
    useCallback(
      (get, set, event: any) => {
        // bail if mouse down
        if (isDrag) {
          return;
        }

        // bind to ref or something? we probably want to cache this
        const { left, top } = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;

        const segments = calcRowSegments(getRow(rowId, get), get(calendarContextAtom));
        const hit = getSegmentMouseHit(x, segments, y);

        if (hit) {
          const [segment, handle] = hit;
          const prev = get(segmentSelectionStateAtom);
          if (!prev || prev.rowId != rowId || prev.segmentId !== segment.id || prev.handle != handle) {
            set(segmentSelectionStateAtom, {
              rowId,
              segmentId: segment.id,
              handle,
              delta: null,
              error: null,
            });
          }
        } else {
          set(segmentSelectionStateAtom, null);
        }
      },
      [rowId],
    ),
  );
}

import type { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { Icon } from '../ui/Icon';
import type { Action } from './Actions';
import { buttonVariantToIconColor } from 'utilities';
import type { IconProp } from 'components';
import { Button, ButtonVariant, MenuItem, MenuList, Popover, Size } from '@ff-it/ui';

type ActionDropdownProps = {
  actions: Action[];
  children?: ReactNode;
  icon?: IconProp;
  testId?: string;
  className?: string;
  variant?: ButtonVariant;
  size?: Size;
};

export function ActionDropdown({
  actions,
  children,
  icon,
  testId,
  className,
  variant,
  size,
}: ActionDropdownProps): ReactElement | null {
  if (actions.length === 0) {
    return null;
  }

  return (
    <Popover
      interaction="click"
      placement="auto"
      placementOptions={{ allowedPlacements: ['bottom-start', 'bottom-end'] }}
      content={
        <MenuList>
          {actions.map(({ action, button: { icon, className, children, variant, ...button } = {} }) => (
            <MenuItem
              key={action}
              testId={`action-${action}`}
              {...(button as any)}
              prefix={
                icon ? (
                  <Icon icon={icon} className={cx('mr-1', variant && buttonVariantToIconColor[variant])} />
                ) : undefined
              }
              label={children}
            />
          ))}
        </MenuList>
      }
    >
      <Button testId={testId} className={className} variant={variant} size={size}>
        {children}
        {icon && <Icon className={children ? 'ml-2' : undefined} icon={icon} />}
      </Button>
    </Popover>
  );
}

import { CSSProperties, forwardRef, ReactElement, useCallback, useMemo, useState } from 'react';
import './Grid.scss';
import type { GridProps, CalculatedColumn, SelectRowEvent, CellCoords } from './types';
import { SummaryRow } from './SummaryRow';
import { Row } from './Row';
import { SUMMARY_HEIGHT, HEADER_HEIGHT, ROW_HEIGHT, FROZEN_COLS } from './const';
import { HeaderRow } from './HeaderRow';
import { useCalculatedColumns } from './useCalculatedColumns';
import { CellSelectionChangeProvider, RowSelectionChangeProvider, RowSelectionProvider } from './selection';
import { wrap } from './style.css';

function getFrozenDims(calculatedColums: CalculatedColumn[]): CSSProperties {
  const props: Record<string, string> = {};
  const frozenCols = FROZEN_COLS + 1;
  for (let colIdx = 0; colIdx < frozenCols; colIdx++) {
    props[`--grd-frozen-left-${colIdx}`] = `${calculatedColums[colIdx].left}px`;
  }

  return props;
}

function gridTemplateRows(numRows: number): CSSProperties['gridTemplateRows'] {
  const rowCss = numRows > 0 ? `repeat(${numRows}, var(--grd-row-height))` : '';

  return `
  var(--grd-summary-height)
  var(--grd-header-height)
  ${rowCss}
  var(--grd-summary-height)
  `;
}

export const Grid = forwardRef<HTMLDivElement, GridProps<any>>((props, ref): ReactElement | null => {
  const { columns, rows, cursor, RowComponent = Row, getRowClassName, selectedRows, onSelectedRowsChange } = props;

  // const isSelectable = Boolean(selectedRows || onSelectedRowsChange);

  const allRowsSelected = useMemo((): boolean => {
    const { length } = rows;
    return (
      length !== 0 &&
      selectedRows != null &&
      selectedRows.size >= length &&
      rows.every((row) => selectedRows.has(row.id))
    );
  }, [rows, selectedRows]);

  const selectRow = useCallback(
    (event: SelectRowEvent): void => {
      if (!onSelectedRowsChange) return;
      onSelectedRowsChange((selectedRows) => {
        if (event.type === 'HEADER') {
          const newSelectedRows: Set<number> = new Set();

          if (event.checked) {
            for (const row of rows) {
              newSelectedRows.add(row.id);
            }
          }
          return newSelectedRows;
        }

        const { rowId, checked, modifier } = event;

        if (checked) {
          const newSelectedRows: Set<number> = modifier ? new Set(selectedRows) : new Set();
          newSelectedRows.add(rowId);
          return newSelectedRows;
        } else {
          const newSelectedRows: Set<number> = new Set(selectedRows);
          newSelectedRows.delete(rowId);
          return newSelectedRows;
        }
      });
    },
    [onSelectedRowsChange, rows],
  );

  const calculatedColumns = useCalculatedColumns(columns);

  const [selectedCell, selectCell] = useState<CellCoords | null>(null);

  if (!calculatedColumns.length) {
    return null;
  }

  const rowsElements: any = [];
  let gridRowStart = 2;
  for (let rowIdx = 0; rowIdx < rows.length; rowIdx++) {
    gridRowStart += 1;
    const row = rows[rowIdx];
    const className = getRowClassName && getRowClassName(row);
    const isRowSelected = selectedRows?.has(row.id) ?? false;

    const selectedColumnKey = selectedCell && selectedCell.rowId == row.id ? selectedCell.columnKey : undefined;

    rowsElements.push(
      <RowComponent
        key={row.id}
        row={row}
        gridRowStart={gridRowStart}
        columns={calculatedColumns}
        className={className}
        selectedColumnKey={selectedColumnKey}
        selectCell={selectCell}
        isRowSelected={isRowSelected}
      />,
    );
  }

  return (
    <div className={wrap}>
      <div
        className="grd"
        ref={ref}
        style={
          {
            cursor,
            '--grd-summary-height': `${SUMMARY_HEIGHT}px`,
            '--grd-header-height': `${HEADER_HEIGHT}px`,
            '--grd-row-height': `${ROW_HEIGHT}px`,
            gridTemplateColumns: calculatedColumns
              .map(({ width, minWidth }) => (width ? `${width}px` : minWidth ? `minmax(${minWidth}px, auto)` : 'auto'))
              .join(' '),
            gridTemplateRows: gridTemplateRows(rows.length),
            ...getFrozenDims(calculatedColumns),
          } as any
        }
      >
        <RowSelectionChangeProvider value={selectRow}>
          <CellSelectionChangeProvider value={selectCell}>
            <SummaryRow columns={calculatedColumns} gridRowStart={1} />
            <RowSelectionProvider value={allRowsSelected}>
              <HeaderRow columns={calculatedColumns} />
            </RowSelectionProvider>
            {rowsElements}
            <SummaryRow columns={calculatedColumns} gridRowStart={gridRowStart + 1} />
          </CellSelectionChangeProvider>
        </RowSelectionChangeProvider>
      </div>
    </div>
  );
});

Grid.displayName = 'Grid';

import { GridColumn } from 'components/Grid';
import { Header, Summary } from './Header';
import { View, Edit } from './Row';
import { fieldIsEditable } from '../../../../hooks';
import type { Row } from 'modules/campaign/row';

export const positions: GridColumn<Row> = {
  key: 'positions',
  summary: <Summary />,
  header: <Header />,
  className: 'grd__c--positions',
  renderCell: (props) => {
    const kind = props.row.kind;
    if (kind === 'SMART') {
      return null;
    } else if (kind === 'MEDIA' || kind == 'SERVICE') {
      const isEditable = fieldIsEditable(props.row, 'positions');

      // figure out if we want to mark cell editable? can't use class
      return isEditable ? <Edit {...props} /> : <View {...props} />;
    } else {
      throw Error(`uknown kind ${kind}`);
    }
  },
};

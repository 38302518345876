import { ReactElement } from 'react';
import type { MinimalCompany } from 'types';

export function CompanyWidget({ title, internal_name }: MinimalCompany): ReactElement {
  return (
    <>
      <div className="text-nowrap">{title}</div>
      <div className="text-nowrap d-block small text-muted">{internal_name}</div>
    </>
  );
}

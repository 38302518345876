import { atom } from 'jotai';
import {
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  max,
  min,
  differenceInDays,
  eachMonthOfInterval,
  endOfMonth,
} from 'date-fns';
import { DAY_WIDTH } from './const';
import type { Bounds, CalendarColumn, Interval, SegmentSelectionStateType, SelectionStateType } from './types';
import { calculateBounds } from './util';
import { planIntervalAtom, planResolutionAtom } from '../../../../atoms/plan';

export const calendarContextAtom = atom((get) => {
  const interval = get(planIntervalAtom);
  const resolution = get(planResolutionAtom);
  const dayWidth = DAY_WIDTH[resolution];

  const days: CalendarColumn[] = calculateBounds(
    eachDayOfInterval(interval).map((date) => {
      return {
        start: date,
        end: date,
        width: dayWidth,
      };
    }),
  );

  const weeks: CalendarColumn[] = calculateBounds(
    eachWeekOfInterval(interval, { weekStartsOn: 1 }).map((date) => {
      const start = max([interval.start, date]);
      const end = min([interval.end, endOfWeek(date, { weekStartsOn: 1 })]);
      const lengthInDays = differenceInDays(end, start) + 1;
      const width = lengthInDays * dayWidth + (lengthInDays - 1);
      return {
        start,
        end,
        width,
      };
    }),
  );

  const months: CalendarColumn[] = calculateBounds(
    eachMonthOfInterval(interval).map((date) => {
      const start = max([interval.start, date]);
      const end = min([interval.end, endOfMonth(date)]);
      const lengthInDays = differenceInDays(end, start) + 1;
      const width = lengthInDays * dayWidth + (lengthInDays - 1);
      return {
        start,
        end,
        width,
      };
    }),
  );

  const numDays = days.length - 1;

  function pixelToDate(x: number): Date {
    let dayIndex = Math.floor(x / (dayWidth + 1));
    if (dayIndex < 0) {
      dayIndex = 0;
    } else if (dayIndex > numDays) {
      dayIndex = numDays;
    }

    return days[dayIndex].start;
  }

  function intervalToBounds({ start, end }: Interval): Bounds {
    const left = differenceInDays(start, interval.start) * (dayWidth + 1);
    const width = (differenceInDays(end, start) + 1) * (dayWidth + 1);
    const right = left + width;
    return {
      left,
      width,
      right,
    };
  }

  return {
    interval,
    resolution,
    days,
    weeks,
    months,
    dayWidth,
    width: days[days.length - 1].right,
    pixelToDate,
    intervalToBounds,
  };
});

export const positionSelectionStateAtom = atom<SelectionStateType | null>(null);
export const segmentSelectionStateAtom = atom<SegmentSelectionStateType | null>(null);

import type { EmbeddedUser } from 'modules/core/types';
import { ReactElement, Fragment } from 'react';

export function UserTitle({ email, first_name, last_name }: EmbeddedUser): ReactElement {
  return (
    <Fragment>
      {email}
      {(first_name || last_name) && (
        <small className="d-block">
          {first_name} {last_name}
        </small>
      )}
    </Fragment>
  );
}

import { useAtomValue, useSetAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { ReactElement, Suspense, useCallback, useEffect, useRef } from 'react';
import { blockIdAtom, blockScopeAtom, blockStateAtom, languageOptionsAtom, requestHandlerAtom } from '../../atoms';
import { Toolbar } from './Toolbar';
import { PlanGrid } from './PlanGrid';
import invariant from 'tiny-invariant';
import { PlanState } from './types';
import { planAtom, planIsEditableAtom } from './atoms/plan';
import { FactorManager } from './Factors';
import { NEVER, prompStale } from 'utilities';
import { useQuery } from 'hooks';
import { SummaryDialog } from './Summary';

function PlanContainer(): ReactElement | null {
  // makse sure plan is loaded
  useAtomValue(planAtom);
  useAtomValue(languageOptionsAtom);
  const id = useAtomValue(blockIdAtom);

  const isEditable = useAtomValue(planIsEditableAtom);

  const gridRef = useRef<HTMLDivElement>(null);
  function scrollToRow(rowId: number | string): void {
    // make sure we have rendered
    setTimeout(() => {
      if (gridRef.current) {
        // can't scroll to row container since it's display: contents
        const cellElement = gridRef.current.querySelector(`[data-testid="row-${rowId}"] .grd__r__hndl`);
        if (cellElement) {
          (cellElement as any).focus({ preventScroll: true });
          cellElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    });
  }
  const query = useQuery();
  const restored = useRef(null);

  useEffect(() => {
    const rowId = query.get('rowId');
    if (rowId) {
      if (rowId != restored.current) {
        restored.current == rowId;
        scrollToRow(rowId);
      }
    }
  }, [query]);

  return (
    <div
      className="container-fluid flex-grow-1 d-flex flex-column"
      data-test-id={`plan-${id}`}
      data-plan-editable={isEditable}
    >
      <FactorManager isEditable={isEditable} />
      <SummaryDialog />
      <Toolbar isEditable={isEditable} scrollToRow={scrollToRow} />
      <PlanGrid ref={gridRef} editable={isEditable} />
    </div>
  );
}

export function Plan(): ReactElement {
  const id = useAtomValue(blockIdAtom);
  const state = useAtomValue(blockStateAtom);
  const scope = useAtomValue(blockScopeAtom);
  const setPlan = useSetAtom(planAtom);

  const fetchPlan = useAtomCallback(
    useCallback(async (get) => {
      const fetch = get(requestHandlerAtom);
      const res = await fetch<PlanState, unknown>({
        method: 'GET',
        url: 'plan/',
      });
      // fixme
      if (res.status == 412) {
        prompStale();
        return NEVER;
      }
      invariant(res.ok);
      return res.data;
    }, []),
  );

  useEffect(() => {
    let ignore = false;

    async function loadPlan(): Promise<void> {
      const res = await fetchPlan();
      if (!ignore) {
        setPlan(res);
      }
    }

    loadPlan();

    return () => {
      ignore = true;
    };
  }, [
    id,
    // FIXME: block state (and price update) changes affect row level locks
    state,
    scope,
  ]);

  return (
    <Suspense>
      <PlanContainer />
    </Suspense>
  );
}

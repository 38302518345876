import { ReactElement } from 'react';
import { EntityScene, IndexScene, ListFilter, ModelScene, DetailsScene, UserField } from 'components';
import permissions from 'modules/client/permissions';
import { ViewCompany } from 'components/company';
import { Nav } from './Nav';
import { never } from 'core/permissions';
import { baseColumns } from 'routes/Agency/Preferences/Companies';
import { Brands } from './Brands';
import { Managers } from './Managers';
import { Planners } from './Planners';
import { ClientManager, ListClient } from 'modules/client/types';
import { Column } from '@ff-it/grid';
import { CheckField, TextField } from '@ff-it/form';
import { Agreements } from './Agreements';
import { Persons } from './Persons';

const model = {
  endpoint: 'client/clients/',
  title: 'Client',
  pluralTitle: 'Clients',
  entityTitle: ({ title }: ListClient) => title,
  permissions,
};

function renderManagers(managers: ClientManager[]): ReactElement {
  return (
    <ul className="compact-list">
      {managers.map(({ id, user: { email, first_name, last_name } }) => (
        <li key={id} className="d-flex">
          <div className="col px-0">
            {first_name} {last_name}
            {<small className="d-block">{email}</small>}
          </div>
        </li>
      ))}
    </ul>
  );
}

const columns: Column<ListClient>[] = [
  ...baseColumns,
  {
    content: 'Managers',
    key: 'has_managers',
    sortable: true,
    className: 'py-0',
    render: ({ managers }) => renderManagers(managers),
  },
  {
    content: 'Planners',
    key: 'has_planners',
    sortable: true,
    className: 'py-0',
    render: ({ planners }) => renderManagers(planners),
  },
];

export const route = {
  path: 'clients',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene columns={columns} canCreate={never} storageKey="contacts_clients">
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
            <UserField name="manager" placeholder="Manager" className="ml-1" size="sm" simple />
            <UserField name="planner" placeholder="Planner" className="ml-1" size="sm" simple />
            <div className="mx-2 form-group">
              <CheckField name="has_agreements" fieldLabel="Has agreement" variant="switch" />
            </div>
          </ListFilter>
        </IndexScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <DetailsScene heading={null}>
              <ViewCompany />
            </DetailsScene>
          ),
        },
        {
          path: 'brands',
          element: <Brands />,
        },
        {
          path: 'managers',
          element: <Managers />,
        },
        {
          path: 'planners',
          element: <Planners />,
        },
        {
          path: 'agreements/*',
          element: <Agreements />,
        },
        {
          path: 'persons/*',
          element: <Persons />,
        },
      ],
    },
  ],
};

import { ReactElement } from 'react';
import { Bank } from 'types';
import { createSceneRoute, ListFilter } from 'components';
import { Column } from '@ff-it/grid';
import { TextField } from '@ff-it/form';
import { Link } from 'react-router-dom';
import permissions from 'modules/core/bank/permissions';
import { ListExport } from 'components/ListActions';

const columns: Column<Bank>[] = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: Bank) => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Code',
    key: 'code',
  },
];

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-6" required />
        <TextField name="code" label="SWIFT Code" className="col-md-6" required />
      </div>
    </div>
  );
}

const initialValues = {
  code: '',
  title: '',
};

export const route = createSceneRoute('banks', {
  endpoint: 'core/banks/',
  title: 'Bank',
  pluralTitle: 'Banks',
  entityTitle: ({ title }) => title,
  permissions,
  index: { columns, filter: <ListFilter />, toolbar: <ListExport permission={permissions.export} /> },
  form: <Form />,
  initialValues,
});

import { ReactElement } from 'react';
import type { ActionMeta } from 'react-select';
import { ChoiceSelectEditor, PopupCell } from '../../../Editor';
import { RenderCellProps } from 'components/Grid';
import { renderValues, getOptionValue, formatOptionLabel } from './util';
import { applicableSmartStateAtom } from './atoms';
import { useAtomValue } from 'jotai';
import { fieldIsEditable, useFieldUpdater } from '../../../../hooks';
import type { Row } from 'modules/campaign/row';

export function ViewToggle(props: RenderCellProps<Row>): ReactElement {
  const applied_to = props.row.applied_to;

  const smartOptions = useAtomValue(applicableSmartStateAtom);

  const value = smartOptions.filter((o) => applied_to.includes(o.id));
  const editable = fieldIsEditable(props.row, 'applied_to');

  return <span className="contain">{renderValues(value, !editable)}</span>;
}

export function EditToggle(props: RenderCellProps<Row>): ReactElement {
  const applied_to = props.row.applied_to;
  const smartOptions = useAtomValue(applicableSmartStateAtom);
  const update = useFieldUpdater(props.row.id, 'applied_to');

  const onChange = async (_v: any, action: ActionMeta<any>): Promise<void> => {
    switch (action.action) {
      case 'deselect-option':
        await update(applied_to.filter((v) => v !== action.option.id));
        return;
      // remove
      case 'select-option':
        await update([...applied_to, action.option.id]);
        return;
      default:
        throw new Error();
    }
  };

  const value = smartOptions.filter((o) => applied_to.includes(o.id));

  return (
    <PopupCell
      testId="smart"
      {...props}
      cellContent={<span className="contain">{renderValues(value, false)}</span>}
      onRequestClose={props.stopEdit}
    >
      <ChoiceSelectEditor
        value={value}
        onChange={onChange}
        options={smartOptions}
        isSimple={false}
        isSearchable={false}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        label="Smart rows"
      />
    </PopupCell>
  );
}

import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAPI } from '@ff-it/api';
import { auth } from 'services';

export function Logout(): ReactElement | null {
  const navigate = useNavigate();
  const api = useAPI();

  useEffect(() => {
    api.post('auth/logout/').then((res) => {
      if (res.ok) {
        auth.signout(() => {
          navigate('/');
        });
      } else {
        throw res.error;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

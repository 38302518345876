import cx from 'clsx';
import { forwardRef, memo, ReactElement } from 'react';
import { Grid, GridProps, RowRenderProps, Row } from 'components/Grid';
import { useAtomValue, atom, useSetAtom, useAtom } from 'jotai';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CSSProperties } from 'react';
import { segmentSelectionStateAtom } from './columns/common/positions/atoms';
import './PlanGrid.scss';
import { columnsAtom } from '../atoms/columns';
import { rowsAtom, moveRowAtom } from '../atoms/plan';
import type { Row as RowType } from 'modules/campaign/row';
import { rowSelection } from '../atoms/controls';

export const DndRow = memo(function DndRow({ className, ...props }: RowRenderProps<RowType>): ReactElement {
  const {
    row: { id: rowId, kind },
  } = props;
  const move = useSetAtom(moveRowAtom);

  const [{ isDragging }, drag] = useDrag({
    type: 'ROW_DRAG',
    item: { rowId, kind },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop(drop: { rowId: number; kind: string }) {
      move(drop.rowId, rowId);
    },
    canDrop(drop: { rowId: number; kind: string }) {
      if (drop.rowId === rowId || (kind === 'SMART' ? drop.kind !== 'SMART' : drop.kind === 'SMART')) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Row
      className={cx(className, {
        'grd__r--dragging': isDragging,
        'grd__r--over': isOver && canDrop,
      })}
      ref={(ref) => {
        // can't drag row itself because `display: contents;` ?
        if (ref) {
          drag(ref.firstElementChild);
          drop(ref);
        }
      }}
      {...props}
    />
  );
});

export const cursorAtom = atom<CSSProperties['cursor']>((get) => {
  const selection = get(segmentSelectionStateAtom);
  if (selection) {
    return selection.handle === 'middle' ? (selection.delta ? 'grabbing' : 'pointer') : 'col-resize';
  }
  return undefined;
});

const getRowClassName = (row: RowType): string => `grd__r--${row.kind}`;

type PlanGridProps = Omit<GridProps<RowType>, 'columns' | 'rows' | 'cursor' | 'RowComponent' | 'getRowClassName'>;

export const PlanGrid = forwardRef<HTMLDivElement, PlanGridProps>((props, ref): ReactElement => {
  const columns = useAtomValue(columnsAtom);
  const rows = useAtomValue(rowsAtom);
  const cursor = useAtomValue(cursorAtom);
  const [selectedRows, setSelectRows] = useAtom(rowSelection);

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <Grid
        {...props}
        cursor={cursor}
        rows={rows}
        columns={columns}
        ref={ref}
        RowComponent={DndRow}
        getRowClassName={getRowClassName}
        selectedRows={selectedRows}
        onSelectedRowsChange={setSelectRows}
      />
    </DndProvider>
  );
});

PlanGrid.displayName = 'PlanGrid';

import { ReactElement, useCallback } from 'react';
import cx from 'clsx';
import { differenceInDays } from 'date-fns';
import { useAtomValue } from 'jotai';
import { Scale } from './Scale';
import './Positions.scss';
import { useMouseDownHandler, useMouseMoveHandler } from './useRowDownHandlers';
import { Segments } from './Segments';
import { positionErrors } from '../const';
import { calendarContextAtom, positionSelectionStateAtom, segmentSelectionStateAtom } from '../atoms';
import { RenderCellProps } from 'components/Grid';
import type { Row } from 'modules/campaign/row';
import { useReslectAtom } from 'utilities';
import eq from 'fast-deep-equal';

export function Edit(props: RenderCellProps<Row>): ReactElement {
  const ctx = useAtomValue(calendarContextAtom);
  const { interval, dayWidth, width } = ctx;

  const onMouseDown = useMouseDownHandler(props.row.id);
  const onMouseMove = useMouseMoveHandler(props.row.id);

  const rowSelection = useReslectAtom(
    segmentSelectionStateAtom,
    useCallback((v) => (v && v.rowId == props.row.id ? v : null), [props.row.id]),
    eq,
  );

  return (
    <div
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      className="positions__row"
      style={{
        width: width,
      }}
    >
      <Scale {...ctx} />
      <Segments row={props.row} ctx={ctx} rowSelection={rowSelection} />
      <Selection dayWidth={dayWidth} intervalStart={interval.start} rowId={props.row.id} />
    </div>
  );
}

export function View({ row }: RenderCellProps<Row>): ReactElement {
  const ctx = useAtomValue(calendarContextAtom);
  const rowSelection = useReslectAtom(
    segmentSelectionStateAtom,
    useCallback((v) => (v && v.rowId == row.id ? v : null), [row.id]),
  );

  const { width } = ctx;

  return (
    <div
      className="positions__row"
      style={{
        width: width,
      }}
    >
      <Scale {...ctx} />
      <Segments row={row} ctx={ctx} rowSelection={rowSelection} />
    </div>
  );
}

type SegmentProps = {
  intervalStart: Date;
  dayWidth: number;
  rowId: number;
};

function Selection({ intervalStart, dayWidth, rowId }: SegmentProps): ReactElement | null {
  const selection = useReslectAtom(
    positionSelectionStateAtom,
    useCallback((v) => (v && v.rowId == rowId ? v : null), [rowId]),
  );

  if (!selection) {
    return null;
  }
  const { start, end, error } = selection;
  const left = differenceInDays(start, intervalStart) * (dayWidth + 1);
  const days = differenceInDays(end, start) + 1;
  const width = days * (dayWidth + 1);
  return (
    <div
      className={cx('positions__seg positions__seg--selection', {
        'positions__seg--invalid': error,
      })}
      style={{
        left,
        width,
      }}
    >
      <span className="contain">{days}d</span>
      {error && <span className="error">{positionErrors[error]}</span>}
    </div>
  );
}

import { ReactElement, ReactNode, useState, useId } from 'react';
import { useField } from 'react-final-form';
import { getControlClassName, Size } from '@ff-it/ui';
import { FormGroup, useIsDisabled, fieldSubscription } from '@ff-it/form';
import { FieldValidator } from 'final-form';
import invariant from 'tiny-invariant';
import cx from 'clsx';
import type { ActivityType, ProductKind } from 'types';
import { SupplierSelector } from '../SupplierSelector';
import type { Supplier } from '../../types';
import './SupplierField.scss';
import { SupplierTitle } from '../SupplierTitle';

type SupplierFieldProps = {
  name: string;
  type: ActivityType;
  kind?: ProductKind;
  id?: string;
  label?: ReactNode;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  size?: Size;
  help?: ReactNode;
  placeholder?: string;
  validate?: FieldValidator<Supplier | null>;
};

export function SupplierField({
  name,
  type,
  kind,
  className,
  label,
  help,
  required,
  placeholder = 'Select supplier...',
  size,
  validate,
  ...rest
}: SupplierFieldProps): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const _id = useId();
  const id = rest.id || _id;
  const disabled = useIsDisabled(rest.disabled || !type);

  const {
    input: { value, onChange },
    meta,
  } = useField<Supplier | null, any>(name, {
    subscription: fieldSubscription,
    validate,
    allowNull: true,
  });

  // disabled, empty, focused, hocer
  let selector = null;
  if (isOpen) {
    invariant(type);
    selector = (
      <SupplierSelector
        onRequestClose={() => {
          setIsOpen(false);
        }}
        onChange={(v) => {
          onChange(v);
          setIsOpen(false);
        }}
        type={type}
        kind={kind}
        value={value}
        required={required}
      />
    );
  }

  const content = value ? <SupplierTitle {...value} /> : placeholder;

  return (
    <FormGroup className={className} label={label} id={rest.id} help={help} meta={meta} required={required}>
      <div
        id={id}
        className={getControlClassName(
          size,
          cx({
            'form-control-supplier': true,
            disabled,
            open: isOpen,
            empty: !value,
          }),
          meta.invalid && meta.touched ? false : undefined,
        )}
        onClick={
          !isOpen && !disabled
            ? () => {
                setIsOpen(true);
              }
            : undefined
        }
      >
        {selector}
        {content}
        {/* selectable by tests */}
        <input type="hidden" name={name} />
      </div>
    </FormGroup>
  );
}

import type { ReactNode } from 'react';
import { sepFormat } from 'utilities';
import { TransparencyOption } from './TransparencyOption';
import type { Row } from 'modules/campaign/row';

export function renderValue({ transparency, sums: { planned_income, target_income } }: Row): ReactNode | null {
  return (
    <TransparencyOption transparency={transparency}>
      <div className="ml-auto">
        <div data-testid="planned" className={target_income == planned_income ? 'font-weight-bold' : undefined}>
          {sepFormat(planned_income)}
        </div>
        {target_income != planned_income && (
          <div data-testid="target" className="font-weight-bold">
            {sepFormat(target_income)}
          </div>
        )}
      </div>
    </TransparencyOption>
  );
}

export function renderSmartValue({ sums: { planned_income, target_income } }: Row): ReactNode {
  return (
    <div className="d-flex flex-row align-items-center transp-opt">
      <div className="px-2 labels">
        <div>Planned</div>
        <div className="font-weight-bold">Actual</div>
      </div>
      <div className="ml-auto">
        <div data-testid="planned">{sepFormat(planned_income)}</div>
        <div className="font-weight-bold" data-testid="target">
          {sepFormat(target_income)}
        </div>
      </div>
    </div>
  );
}

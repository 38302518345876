import { ReactNode } from 'react';
import { Placement } from 'modules/campaign/row';

export const getOptionLabel = (v: null | Placement): any => (v ? v.title : v);
export const getOptionValue = getOptionLabel;

export function renderValues(value: Placement[] | null, locked: boolean): ReactNode {
  return value && value.length > 0 ? (
    <span className="contain">{value.map(getOptionLabel).join(', ')}</span>
  ) : locked ? (
    '—'
  ) : (
    ''
  );
}

import { DecimalField, Form, FormGroup, useField, useFormState } from '@ff-it/form';
import { Box, Button, DialogBody, DialogClose, DialogFooter, DialogHeader, Input, RenderProps } from '@ff-it/ui';
import Big from 'big.js';
import { ButtonGroupField } from 'components/fields';
import { Icon } from 'components/ui';
import { createOptions } from 'options';
import { ReactElement, useId, useState } from 'react';
import { toast } from 'react-toastify';
import { parseBig } from 'utilities';

const fields = ['quantity', 'price', 'total'] as const;

type Field = (typeof fields)[number];

const fieldOptions = createOptions(fields);

const initialValues = {
  field: 'quantity' as Field,
  quantity: '',
  price: '',
  total: '',
};

type FromShape = typeof initialValues;

// @TODO DRY WITH mapTransparentRow

function FromFields(): ReactElement {
  const solvedId = useId();
  const {
    input: { value: field },
  } = useField<Field>('field', { subscription: { value: true } });

  const [solved, setSolved] = useState<string>('');

  useFormState<FromShape>({
    onChange: ({ values: { field, quantity, price, total } }) => {
      const rawTotal = parseBig(total);
      const rawUnitPrice = parseBig(price);
      const rawQauntity = parseBig(quantity);

      let precision = 2;

      switch (field) {
        case 'quantity':
          if (rawUnitPrice.eq(0) || rawTotal.eq(0)) {
            setSolved('');
            break;
          }
          const startQuantity = rawTotal.minus(Big('0.005')).div(rawUnitPrice);
          let quantity = '';

          // !IMPORTANT: this is actually caped to 4 sings in system
          while (precision <= 16) {
            const currentQuantity = startQuantity.round(precision, 1);
            if (currentQuantity.mul(rawUnitPrice).round(2, 1).eq(rawTotal)) {
              quantity = currentQuantity.toFixed();
              break;
            }
            precision++;
          }

          setSolved(quantity);
          break;

        case 'total':
          if (rawUnitPrice.eq(0) || rawUnitPrice.eq(0)) {
            setSolved('');
            break;
          }

          setSolved(rawQauntity.mul(rawUnitPrice).round(2, 1).toFixed());
          break;

        case 'price':
          if (rawTotal.eq(0) || rawQauntity.eq(0)) {
            setSolved('');
            break;
          }

          const startPrice = rawTotal.minus(Big('0.005')).div(rawQauntity);
          let unitPrice = '';
          while (precision <= 16) {
            const currentPrice = startPrice.round(precision, 1);
            if (currentPrice.mul(rawQauntity).round(2, 1).eq(rawTotal)) {
              unitPrice = currentPrice.toFixed();
              break;
            }
            precision++;
          }
          setSolved(unitPrice);
          break;
      }
    },
  });

  const solvedElement = (
    <Box display="flex">
      <Input value={solved} readOnly id={solvedId} />
      <Button
        variant="outline-primary"
        size="sm"
        className="ml-1"
        disabled={!solved}
        onClick={async () => {
          try {
            await navigator.clipboard.writeText(solved);
            toast.info(`Value copied to clipboard: ${solved}`);
          } catch (err) {
            toast.error('Failed to copy to clipboard.');
          }
        }}
      >
        <Icon icon="copy" />
      </Button>
    </Box>
  );

  return (
    <Box maxWidth="sm">
      <div className="form-row">
        {field === 'quantity' ? (
          <FormGroup label="Quantity" className="col">
            {solvedElement}
          </FormGroup>
        ) : (
          <DecimalField name="quantity" className="col" label="Quantity" precision={4} />
        )}
        {field === 'price' ? (
          <FormGroup label="Price" className="col">
            {solvedElement}
          </FormGroup>
        ) : (
          <DecimalField name="price" className="col" label="Price" precision={16} />
        )}
        {field === 'total' ? (
          <FormGroup label="Total" className="col">
            {solvedElement}
          </FormGroup>
        ) : (
          <DecimalField name="total" className="col" label="Total" />
        )}
      </div>
    </Box>
  );
}

export function SolverDialog(_props: RenderProps): ReactElement {
  return (
    <Form onSubmit={() => {}} initialValues={initialValues} autoComplete="off">
      <DialogHeader title="Solver" />
      <DialogBody>
        <FromFields />
      </DialogBody>
      <DialogFooter>
        <ButtonGroupField name="field" options={fieldOptions} variant="outline-primary" className="mb-0" />
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

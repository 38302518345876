import { ReactElement } from 'react';
import { TextField, DatePickerField } from '@ff-it/form';
import { AttachmentField, ExpenseTypeField, VatRateField } from 'components';
import { InvoiceFields, CurrencyRateField, TotalsFields } from './Fields';
import { useNumberValidator } from '../useNumberValidator';
import { CompanyField } from 'modules/core/company/components';
import { Sidebar } from 'modules/invoicing/common/Sidebar';

interface FormProps {
  update?: boolean;
  credit?: boolean;
  external?: boolean;
}

export function Form({ update, credit, external }: FormProps): ReactElement {
  const validateNumberUnique = useNumberValidator();
  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="container px-0 ml-0">
        <div className="form-row">
          <ExpenseTypeField name="type" label="Type" className="col-md-2" required disabled={update || credit} />
          <TextField
            name="number"
            label="Number"
            className="col-md-3"
            required
            validate={validateNumberUnique}
            disabled={external}
          />
          <CompanyField name="partner" label="Provider" className="col-md-3" required disabled={credit || external} />
          <DatePickerField
            name="checkout_date"
            label="Invoice date"
            className="col-md-2"
            required
            disabled={external}
          />
          <DatePickerField name="payment_date" label="Payment date" className="col-md-2" required disabled={external} />
        </div>
        <div className="row">
          <div className="col-6">
            <TextField name="comment" label="Comment" multiline autosize rows={2} />
          </div>
          <div className="col-6">
            <InvoiceFields />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-row">
              <CurrencyRateField className="col-md-4" disabled={external} />
              <VatRateField name="vat_rate" label="VAT rate" className="col-md-4" required disabled={external} />
            </div>
            <TotalsFields external={external} />
            <div className="form-row">
              <AttachmentField name="attachments" label="Attachments" className="col-md-12" required />
            </div>
          </div>
          <div className="col-6"></div>
        </div>
      </div>
      {update && <Sidebar />}
    </div>
  );
}

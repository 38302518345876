import { ReactElement } from 'react';
import { Page, useEntity } from 'components';
import { Alert, LoadingContainer } from '@ff-it/ui';
import { useLoader } from '@ff-it/api';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { Config } from './Config';
import { RowOptions } from './Rows';
import type { Enterprise } from 'modules/core/types';

function ConfigurationRoutes({ enterpriseId }: { enterpriseId: number }): ReactElement {
  const { loading, item: options } = useLoader<RowOptions>({
    url: `onec/config/${enterpriseId}/`,
    method: 'OPTIONS',
  });

  return (
    <LoadingContainer loading={loading}>
      {options ? (
        <Routes>
          <Route path="income" element={<Config direction="income" options={options} enterpriseId={enterpriseId} />} />
          <Route
            path="expense"
            element={<Config direction="expense" options={options} enterpriseId={enterpriseId} />}
          />
          <Route path="" element={<Navigate to="income" />} />
        </Routes>
      ) : null}
    </LoadingContainer>
  );
}

// @TODO: refactor into ItemLoader and UpdateContainer
export function OneCIntegration(): ReactElement {
  const { item } = useEntity<Enterprise>();

  if (!item.onec_endpoint) {
    return (
      <Page>
        <Alert variant="danger">1C Sync endpoint not defined</Alert>
      </Page>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <Alert variant="danger" heading="Do not touch theese." className="border-0 mt-2">
          Since we allow for partial configuration incomplete values are not persisted.
        </Alert>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <NavLink className="nav-link" to="income">
              Income
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="expense">
              Expense
            </NavLink>
          </li>
        </ul>
      </div>
      <ConfigurationRoutes enterpriseId={item.id} />
    </>
  );
}

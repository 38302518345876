import { ReactElement, ReactNode } from 'react';
import { TextEditor } from '../../Editor';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { useFieldUpdater, fieldIsEditable } from '../../../hooks';
import type { Row } from 'modules/campaign/row';

function renderValue(props: RenderCellProps<Row>): ReactNode {
  const value = props.row.tg;

  // dash for locked
  return value ? <span className="contain">{value}</span> : props.isEditable ? '' : '—';
}

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.tg;
  const setValue = useFieldUpdater(props.row.id, 'tg');

  return <TextEditor value={value} stopEdit={props.stopEdit} setValue={setValue} />;
}

export const tg: GridColumn<Row> = {
  key: 'tg',
  header: 'TG',
  width: 156,
  editable: (row) => fieldIsEditable(row, 'tg'),
  renderCell: (props) => (props.row.kind === 'MEDIA' ? props.editing ? <Edit {...props} /> : renderValue(props) : null),
};

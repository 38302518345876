import { ReactElement, useRef } from 'react';
import { Row as RowBox, Button, Col } from '@ff-it/ui';
import { Icon } from 'components';
import Big from 'big.js';
import { fmt } from 'utilities';
import { TextField, DecimalField, useIsDisabled, SelectField } from '@ff-it/form';
import cx from 'clsx';
import { useDrag, useDrop } from 'react-dnd';
import { rtbUnitOptions } from 'options';
import type { InvoiceRowKind } from 'modules/invoicing/income/types';
import { InvoiceRowKindIcon } from 'modules/invoicing/income/InvoiceRowKindIcon';
import { InvoiceWidget } from 'modules/invoicing/common/InvoiceWidget';
import { EmbeddedInvoice } from 'modules/invoicing/types';

interface RowProps<FV = any> {
  index: number;
  name: string;
  kind: InvoiceRowKind;
  remove: (index: number) => FV;
  move: (from: number, to: number) => void;
  total: Big;
  destination?: EmbeddedInvoice;
}

function Row({ name, kind, index, remove, move, destination, total }: RowProps): ReactElement {
  const isDisabled = useIsDisabled();
  const handleRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLTableRowElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'ROW_DRAG',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'ROW_DRAG',
    drop(drop: { index: number }) {
      if (drop.index !== index) {
        move(drop.index, index);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  drag(handleRef);
  drop(rowRef);
  return (
    <tr className={cx({ dragging: isDragging, over: isOver })} ref={rowRef}>
      <td className="align-middle text-center">
        <InvoiceRowKindIcon kind={kind} />
      </td>
      <td className="align-middle">
        <RowBox alignItems="center">
          {destination && (
            <Col width="1/5" marginRight="md">
              <InvoiceWidget link invoice={destination} />
            </Col>
          )}
          <Col>
            <TextField name={`${name}.title`} required size="sm" />
          </Col>
        </RowBox>
      </td>
      <td className="sum">
        <DecimalField name={`${name}.quantity`} precision={4} required size="sm" />
      </td>
      <td className="sum">
        <SelectField name={`${name}.unit`} options={rtbUnitOptions} required size="sm" simple />
      </td>
      <td className="sum">
        <DecimalField name={`${name}.unit_price`} precision={16} required size="sm" />
      </td>
      <td className="sum">
        <div className="form-control-plaintext">{fmt(total)}</div>
      </td>

      <td className="tools position-relative">
        <Button
          variant="outline-danger"
          type="button"
          onClick={() => remove(index)}
          className="border-0"
          size="sm"
          disabled={isDisabled}
          testId="remove-row"
        >
          <Icon icon="xmark" />
        </Button>
        <div className={cx(`drag-handle`, { 'd-none': isDisabled })} ref={handleRef}>
          <Icon icon="grip-lines" />
        </div>
      </td>
    </tr>
  );
}

export default Row;

import { ReactElement } from 'react';
import { useLoader } from '@ff-it/api';
import { LoadingContainer } from '@ff-it/ui';
import { useEntity } from 'components';
import { BlockDetails } from 'modules/campaign/block/types';
import { CampaignDetails } from '../types';
import { CampaignOverview } from './CampaignOverview';

export function Overview(): ReactElement {
  const ctx = useEntity<CampaignDetails>();

  const {
    item: blocks,
    setItem: setBlocks,
    loading,
  } = useLoader<BlockDetails[]>({
    url: `${ctx.endpoint}blocks/`,
    method: 'GET',
  });

  return (
    <LoadingContainer loading={loading} className="flex-grow-1 flex-column d-flex">
      {blocks && <CampaignOverview ctx={ctx} blocks={blocks} setBlocks={setBlocks} />}
    </LoadingContainer>
  );
}

import { ReactElement } from 'react';
import { Icon, UserField, LanguageField } from 'components';
import { useLoader } from '@ff-it/api';
import { TextField, DecimalField, DatePickerField, DateRangePickerField } from '@ff-it/form';
import { addDays } from 'date-fns';
import { useField } from 'react-final-form';
import { parseDate, identity } from 'utilities';
import { FieldsProps } from './types';
import { ActivityType } from 'types';

function TargetGroups({ type }: { type: ActivityType }): ReactElement {
  const groupLoader = useLoader<string[]>({
    url: `campaign/campaigns/target_groups/${type}/`,
    method: 'GET',
  });
  const groups = groupLoader.item || [];

  return <TextField name="target_groups" label="Target groups" datalist={groups} required autoComplete="off" />;
}

export function DeadlineWarning({
  date_from,
  deadline,
}: {
  date_from: string | null;
  deadline: string | null;
}): ReactElement | null {
  if (deadline) {
    const d = parseDate(deadline);
    if (d) {
      const from = date_from ? parseDate(date_from) : undefined;
      if (from && from < d) {
        return (
          <div className="invalid-feedback text-warning d-block">
            <Icon icon="triangle-exclamation" /> Deadline after start of period
          </div>
        );
      }
      if (d < addDays(new Date(), 3)) {
        return (
          <div className="invalid-feedback text-warning d-block">
            <Icon icon="triangle-exclamation" /> Deadline in less than 3 days
          </div>
        );
      }
    }
  }
  return null;
}

function FormDeadlineWarning(): ReactElement | null {
  const {
    input: { value: date_from },
  } = useField('date_from', { subscription: { value: true } });
  const {
    input: { value: deadline },
  } = useField('deadline', { subscription: { value: true } });

  return <DeadlineWarning deadline={deadline} date_from={date_from} />;
}

export function CommonFields({ campaignPeriod, type }: FieldsProps): ReactElement {
  return (
    <>
      <div className="form-row">
        <TextField name="title" label="Title" parse={identity} className="col-md-6" />
        <UserField
          name="planners"
          label="Planner"
          className="col-md-6"
          required
          isMulti
          defaultOptions
          filter={{ plan_campaign_block: true }}
        />
      </div>

      {type !== 'SEO' && <TargetGroups type={type} />}
      <div className="form-row">
        <DateRangePickerField
          fromName="date_from"
          toName="date_to"
          label="Period "
          className="col-md-8"
          required
          {...campaignPeriod}
        />
        <DatePickerField
          name="deadline"
          label="Deadline"
          className="col-md-4"
          required
          help={<FormDeadlineWarning />}
          popperPlacement="bottom-end"
        />
      </div>
      <div className="form-row">
        <DecimalField
          name="budget"
          label="Budget"
          className="col-md-6"
          required
          help={
            <>
              Total EUR (incl. prod., fee, etc.) <strong>w/o</strong> VAT
            </>
          }
        />
        <LanguageField name="languages" label="Languages" className="col-md-6" required isMulti />
      </div>
    </>
  );
}

import { ReactElement, useMemo, memo } from 'react';
import {
  useModel,
  useEntity,
  ItemLoader,
  UpdateContainer,
  ActivityField,
  FormInline,
  AgencyActivityTypesField,
} from 'components';
import { DecimalField, useOnFieldChange, useForm } from '@ff-it/form';
import { Alert } from '@ff-it/ui';
import { useField } from 'react-final-form';
import { useHasPerm } from 'core/permissions';
import { required } from 'utilities';
import { SupplierField } from 'modules/supplier/supplier/components';
import { ClientAgreement } from 'modules/client/agreement/types';

const SmartRow = memo(({ name }: { name: string }): ReactElement => {
  const form = useForm();
  const type = useOnFieldChange(`${name}.type`, (v) => {
    if (v && form.getFieldState(`${name}.supplier`)?.value?.product.type !== v) {
      form.change(`${name}.supplier`, null);
    }
  });

  return (
    <div className="form-row">
      <AgencyActivityTypesField
        name={`${name}.type`}
        placeholder="Type"
        className="col-md-2"
        size="sm"
        required
        validate={required}
      />
      {/* any provider? */}
      <SupplierField
        name={`${name}.supplier`}
        className="col-md-5"
        size="sm"
        type={type}
        required
        validate={required}
        disabled={!type}
        kind="SMART"
      />
      <ActivityField
        name={`${name}.target_activity`}
        placeholder="Target activity"
        className="col-md-3"
        size="sm"
        // required
        // validate={required}
      />
      <DecimalField
        name={`${name}.rate`}
        placeholder="Rate"
        className="col-md-1"
        size="sm"
        required
        validate={required}
      />
    </div>
  );
});

// @TODO: move exports to modules, reused in tickets

export function SmartRateInlines({ title = 'Smart rates', flush }: { title?: string; flush?: boolean }): ReactElement {
  const {
    input: { value: rates },
  } = useField('smart_rates', { subscription: { value: true } });

  const rate = rates[rates.length - 1]?.rate;
  const initialValues = useMemo(
    () => ({
      type: null,
      target_activity: null,
      supplier: null,
      rate,
    }),
    [rate],
  );
  return (
    <FormInline
      name="smart_rates"
      title={title}
      initialValues={initialValues}
      flush={flush}
      help="Set default rate for smart services"
      testId="smart_rates"
    >
      {(name) => <SmartRow name={name} />}
    </FormInline>
  );
}
const RateRow = memo(
  ({ name }: { name: string }): ReactElement => (
    <div className="form-row">
      <AgencyActivityTypesField
        name={`${name}.type`}
        placeholder="Type"
        className="col-md-2"
        required
        size="sm"
        validate={required}
      />
      <ActivityField
        name={`${name}.activity`}
        placeholder="Activity"
        className="col-md-3"
        size="sm"
        required
        validate={required}
      />
      <DecimalField
        name={`${name}.rate`}
        placeholder="Rate"
        className="col-md-1"
        size="sm"
        required
        validate={required}
      />
    </div>
  ),
);

export function RateInlines({
  title = 'Default client price',
  flush,
}: {
  title?: string;
  flush?: boolean;
}): ReactElement {
  const {
    input: { value: rates },
  } = useField('rates', { subscription: { value: true } });

  const rate = rates[rates.length - 1]?.rate;
  const initialValues = useMemo(
    () => ({
      type: null,
      activity: null,
      rate,
    }),
    [rate],
  );
  return (
    <FormInline
      name="rates"
      title={title}
      flush={flush}
      initialValues={initialValues}
      help="Set hourly fee (EUR) for specialist work"
      testId="rates"
    >
      {(name) => <RateRow name={name} />}
    </FormInline>
  );
}

function FeeForm(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <Alert variant="info">Defined rates will be automatically resolved into rows during planning.</Alert>
      <SmartRateInlines />
      <RateInlines />
    </div>
  );
}

export function Fees(): ReactElement {
  const { permissions } = useModel();
  const { endpoint } = useEntity<ClientAgreement>();

  const hasUpdate = useHasPerm(permissions?.change);
  const url = `${endpoint}fees/`;
  return (
    <ItemLoader<any> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer url={url} initialValues={item} updateItem={setItem} method="POST" disabled={!hasUpdate}>
          <FeeForm />
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}

import { ReactElement } from 'react';
import { Field } from 'react-final-form';
import { Box, DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { CheckField, Form, Submit, TextField, useSubmitHandler } from '@ff-it/form';
import type { BlockDetails } from '../types';

import { BriefFields } from './BriefForm';
import { sticky } from './style.css';

export type Action = 'reject' | 'replan';

const TITLES: Record<Action, string> = {
  reject: 'Reject plan',
  replan: 'Replan',
};

interface MaybeUpdateBlockDialogProps extends RenderProps<BlockDetails> {
  block: BlockDetails;
  campaignPeriod: {
    minDate: Date;
    maxDate: Date;
  };
  action: Action;
}

export function MaybeUpdateBlockDialog({
  onSubmit: onSuccess,
  block,
  campaignPeriod,
  action,
}: MaybeUpdateBlockDialogProps): ReactElement {
  const submitHandler = useSubmitHandler<any, BlockDetails>(
    {
      url: `campaign/blocks/${block.id}/${action}/`,
      method: 'POST',
      headers: {
        'If-Match': block.etag,
      },
    },
    { onSuccess },
  );

  return (
    <Form
      onSubmit={submitHandler}
      noValidate
      initialValues={block}
      style={{
        position: 'relative',
      }}
    >
      <DialogHeader title={TITLES[action]} />
      <DialogBody>
        <TextField name="message" label="Motivation" multiline autosize required />
        <Field name="update_brief" subscription={{ value: true }}>
          {({ input: { value } }) => (value ? <BriefFields type={block.type} campaignPeriod={campaignPeriod} /> : null)}
        </Field>
      </DialogBody>
      <Box className={sticky}>
        <DialogFooter>
          <Submit>Update</Submit>
          <Box marginLeft="md">
            <CheckField name="update_brief" fieldLabel="Update brief" variant="switch" />
          </Box>

          <DialogClose className="ml-auto" />
        </DialogFooter>
      </Box>
    </Form>
  );
}

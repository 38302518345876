import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import cx from 'clsx';
import { parseDate, formatDate, useField } from '@ff-it/form';
import { DatePicker, DatePickerProps } from '@ff-it/ui';
import { format, startOfYear } from 'date-fns';
import { InputButton } from './InputButton';

type Part = 'year' | 'month';

interface DatePartPickerFieldProps {
  name: string;
  minDate?: Date;
  maxDate?: Date;
  part: Part;
  className?: string;
}

type Config = Partial<Omit<DatePickerProps, 'onChange' | 'selected' | 'minDate'>> & {
  getCustomInput: (value: Date | null | undefined) => ReactNode;
  getMinDate: (value: Date | undefined) => Date | undefined;
};

const cfg: Record<Part, Config> = {
  year: {
    showYearPicker: true,
    getCustomInput: (value) => <InputButton>{value?.getFullYear() || 'Year'}</InputButton>,
    getMinDate: (value) => (value ? startOfYear(value) : undefined),
  },
  month: {
    showMonthYearPicker: true,
    getCustomInput: (value) => <InputButton>{value ? format(value, 'MMM. yyyy') : 'Month'}</InputButton>,
    getMinDate: (value) => (value ? startOfYear(value) : undefined),
  },
};

export function DatePartPickerField({
  name,
  minDate,
  maxDate,
  part,
  className,
}: DatePartPickerFieldProps): ReactElement {
  const {
    input: { value, onChange },
  } = useField(name, { subscription: { value: true } });

  const onDateChange = useCallback(
    (date: Date) => {
      onChange(parseDate(date));
    },
    [name],
  );

  const date = useMemo(() => formatDate(value), [value]);

  const { getCustomInput, getMinDate, ...props } = cfg[part];

  return (
    <div className={cx('d-flex form-group', className)}>
      <DatePicker
        onChange={onDateChange}
        customInput={getCustomInput(date)}
        selected={date}
        minDate={getMinDate(minDate)}
        maxDate={maxDate}
        {...props}
      />
    </div>
  );
}

import { InvoiceRowKindIcon } from 'modules/invoicing/income/InvoiceRowKindIcon';
import { getRowTotal } from 'modules/invoicing/income/calculate';
import { ReactElement } from 'react';
import { useField } from 'react-final-form';
import Totals from 'routes/Agency/Invoices/Income/Form/Totals';
import { sepFormat, fmt } from 'utilities';

export function Preview(): ReactElement {
  const {
    input: { value: rows },
  } = useField<any[]>('rows', { subscription: { value: true } });

  return (
    <table className="form-table table">
      <thead className="thead-dark">
        <tr>
          <th style={{ width: '30px' }} />
          <th>Title</th>
          <th className="sum">Quantity</th>
          <th className="sum">Unit</th>
          <th className="sum">Unit price</th>
          <th className="sum">Total</th>
          <th className="tools"></th>
        </tr>
      </thead>
      <tbody>
        {rows.map(({ title, kind, quantity, unit, unit_price }, idx) => {
          const total = getRowTotal({ quantity, unit_price });
          return (
            <tr key={idx}>
              <td className="align-middle text-center">
                <InvoiceRowKindIcon kind={kind} />
              </td>
              <td>{title}</td>
              <td className="sum" data-testid="quantity">
                {quantity}
              </td>
              <td className="sum">{unit}</td>
              <td className="sum" data-testid="unit_price">
                {sepFormat(unit_price)}
              </td>
              <td className="sum" data-testid="total">
                {fmt(total)}
              </td>
              <td className="tools"></td>
            </tr>
          );
        })}
      </tbody>
      <Totals />
    </table>
  );
}

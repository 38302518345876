import './AppContainer.scss';

import { BreadcrumbsProvider, Spinner } from '@ff-it/ui';
import { ReactElement, ReactNode, Suspense } from 'react';

interface AppContainerProps {
  beforeContent?: ReactNode;
  beforeMain?: ReactElement;
  children: ReactNode;
}

export function AppContainer({ children, beforeContent, beforeMain }: AppContainerProps): ReactElement {
  return (
    <BreadcrumbsProvider>
      <div className="d-flex align-items-stretch flex-grow-1" id="app-wrap">
        {beforeContent}
        <div id="content-wrap">
          {beforeMain}
          <main role="main">
            <Suspense
              fallback={
                <div className="d-flex justify-content-center py-5">
                  <Spinner color="primary" />
                </div>
              }
            >
              {children}
            </Suspense>
          </main>
        </div>
      </div>
    </BreadcrumbsProvider>
  );
}

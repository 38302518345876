import { memo, CSSProperties, ReactElement, forwardRef, Ref, createElement } from 'react';
import cx from 'clsx';
import type { RowRenderProps } from './types';
import { Cell } from './Cell';
import { RowSelectionProvider } from './selection';

export const Row = memo(
  forwardRef(function Row(
    { gridRowStart, className, isRowSelected, columns, selectedColumnKey, row, selectCell }: RowRenderProps<any>,
    ref: Ref<HTMLDivElement>,
  ): ReactElement {
    const cellElements = [];
    for (let colIdx = 0; colIdx < columns.length; colIdx++) {
      const column = columns[colIdx];
      const { key } = column;
      const cellProps = {
        key,
        colIdx,
        row,
        editing: selectedColumnKey == key,
        selectCell,
        column,
      };
      cellElements.push(createElement(Cell, cellProps));
    }

    return (
      <RowSelectionProvider value={isRowSelected}>
        <div
          data-testid={`row-${row.id}`}
          className={cx('grd__r', className)}
          ref={ref}
          style={
            {
              ['--grd-row-start']: gridRowStart,
            } as unknown as CSSProperties
          }
        >
          {cellElements}
        </div>
      </RowSelectionProvider>
    );
  }),
);

import { ReactElement } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { planConfigAtom } from 'modules/campaign/block/atoms';
import { strategies } from 'modules/campaign/block/strategy';
import type { PlanResolution } from 'modules/campaign/block/types';
import { Button, Popover, MenuList, MenuItem } from '@ff-it/ui';
import { createOptions } from 'options';
import { planResolutionAtom, planStrategyAtom } from '../../atoms/plan';

function StrategyToggle({ empty }: { empty: boolean }): ReactElement {
  const [currentKey, update] = useAtom(planStrategyAtom);
  const { available_strategies: available, lock } = useAtomValue(planConfigAtom);
  if (!empty || lock || available.length == 1) {
    return (
      <span className="text-muted">
        <small data-testid="current-strategy">{strategies[currentKey].TITLE}</small>
      </span>
    );
  }

  return (
    <Popover
      interaction="click"
      placement="auto"
      placementOptions={{ allowedPlacements: ['bottom-start', 'bottom-end'] }}
      content={
        <MenuList>
          {available.map((key) => (
            <MenuItem
              key={key}
              onClick={() => update(key)}
              active={key == currentKey}
              label={strategies[key].TITLE}
            ></MenuItem>
          ))}
        </MenuList>
      }
    >
      <Button size="sm" variant="outline-secondary" testId="current-strategy">
        {strategies[currentKey].TITLE}
      </Button>
    </Popover>
  );
}

const options = createOptions<PlanResolution>(['DAY', 'WEEK', 'MONTH']);

export function ResolutionToggle(): ReactElement {
  const [current, update] = useAtom(planResolutionAtom);

  return (
    <div className="btn-group">
      {options.map(({ value, label }) => (
        <Button
          key={value}
          variant="outline-secondary"
          size="sm"
          active={value === current}
          onClick={() => update(value)}
        >
          {label}
        </Button>
      ))}
    </div>
  );
}

export function PlanStrategyTools({ empty }: { empty: boolean }): ReactElement {
  return (
    <>
      <ResolutionToggle />
      <div className="ml-3">
        <StrategyToggle empty={empty} />
      </div>
    </>
  );
}

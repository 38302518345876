import { useAtomValue } from 'jotai';
import { ReactElement, ReactNode } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { PopupCell, ChoiceSelectEditor } from '../../Editor';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import type { Language } from 'types';
import { languageOptionsAtom } from 'modules/campaign/block/atoms';
import type { Row } from 'modules/campaign/row';

function renderValue(props: RenderCellProps<Row>): ReactNode {
  const value = props.row.language;

  // dash for locked
  return value ? <span className="contain">{value.join(', ')}</span> : !props.isEditable ? '—' : '';
}

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.language as any;
  const options = props.row.config.language.resolved_value as Language[];
  const allLanguageOptions = useAtomValue(languageOptionsAtom);
  const setValue = useFieldUpdater(props.row.id, 'language');

  const render = value && value.length > 0 ? value.join(', ') : '';

  const availableOptions = options ? allLanguageOptions.filter((o) => options.includes(o.value)) : allLanguageOptions;

  return (
    <PopupCell {...props} cellContent={<span className="contain">{render}</span>} onRequestClose={props.stopEdit}>
      <ChoiceSelectEditor
        value={value}
        setValue={setValue}
        options={availableOptions}
        isSimple
        isSearchable={true}
        label={options ? 'Product languages' : 'Languages'}
      />
    </PopupCell>
  );
}

export const language: GridColumn<Row> = {
  key: 'language',
  header: 'Language',
  width: 76,
  renderCell: (props) => (props.row.kind === 'MEDIA' ? props.editing ? <Edit {...props} /> : renderValue(props) : null),
  editable: (row) => fieldIsEditable(row, 'language'),
};

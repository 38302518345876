import { useAtomValue } from 'jotai';
import { ReactElement, ReactNode } from 'react';
import { summaryAtom, Result } from '../atoms/summary';
import { Box, Col, Row } from '@ff-it/ui';
import { header } from 'styles/style.css';
import { fmt, fmtMonth } from 'utilities';
import * as style from './style.css';

type RowProps = {
  title: ReactNode;
  income: ReactNode;
  expense: ReactNode;
  className?: string;
};

function RowContainer({ title, income, expense, className }: RowProps): ReactElement {
  return (
    <Row paddingX="sm" className={className}>
      <Col padding="xs" width="1/2">
        {title}
      </Col>
      <Col padding="xs" textAlign="right">
        {income}
      </Col>
      <Col padding="xs" textAlign="right">
        {expense}
      </Col>
    </Row>
  );
}

function ResultRow({ title, income, expense, className }: Result & { className?: string }): ReactElement {
  return <RowContainer title={title} income={fmt(income)} expense={fmt(expense)} className={className} />;
}

export function Summary({ showMonths }: { showMonths: boolean }): ReactElement {
  const providers = useAtomValue(summaryAtom);
  return (
    <Box textSize="sm" className={style.container}>
      <RowContainer title="Provider / Activity" income="Income" expense="Expense" className={header.dark} />
      <ul className={style.group}>
        {providers.map(({ children: activities, ...provider }, idx) => {
          return (
            <li key={idx}>
              <ResultRow {...provider} className={header.light} />
              <ul className={style.group}>
                {activities.map(({ children: months, ...activity }, idx) => {
                  return (
                    <li key={idx}>
                      <ResultRow {...activity} className={header.default} />
                      {showMonths && (
                        <ul className={style.group}>
                          {months.map(({ title, ...month }, idx) => {
                            return (
                              <li key={idx}>
                                <ResultRow title={fmtMonth(title)} {...month} />
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </Box>
  );
}

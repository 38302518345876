import { SelectField, useForm, useOnFieldChange } from '@ff-it/form';
import { ReactElement } from 'react';
import { activityFieldProps } from 'components';
import { EmbeddedProduct } from 'modules/supplier/products';
import useSWR from 'swr/immutable';
import { Activity } from 'types';

function filterActivites(
  allActivites: Activity[] | undefined,
  product: EmbeddedProduct | null,
): Activity[] | undefined {
  if (product) {
    return product.activities.length ? product.activities : allActivites?.filter((a) => a.kind == product?.kind) || [];
  }
}

export function ProductActivityField(): ReactElement {
  const form = useForm();
  const { data: allActivites } = useSWR<Activity[]>({
    url: `core/activities/`,
    method: 'GET',
  });

  const product = useOnFieldChange<EmbeddedProduct | null>(
    'product',
    (value) => {
      if (value) {
        // guess activity
        let activity: Activity | null = null;
        const activites = filterActivites(allActivites, value);
        if (activites) {
          if (activites.length == 1) {
            activity = activites[0];
          } else if (value.kind == 'MEDIA') {
            // first standard
            activity = activites.find((a) => !a.non_standard) || null;
          }
        }
        form.change('activity', activity);
      }
    },
    false, // clearing does not handle blur and we reset with with kind
  );

  const activityOptions = filterActivites(allActivites, product);

  return (
    <SelectField
      label="Activity"
      name="activity"
      menuPortalTarget={document.body}
      options={activityOptions}
      {...activityFieldProps}
      disabled={!product}
      required
      openMenuOnFocus
    />
  );
}

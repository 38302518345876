import { useEntity, ListFilter, IndexContainer, Heading } from 'components';
import type { Enterprise, MembershipLog as MembershipLogType } from 'modules/core/types';
import { Column } from '@ff-it/grid';
import { timestamp } from 'components/columns';
import { ReactElement } from 'react';
import { ListExport } from 'components/ListActions';

const columns: Column<MembershipLogType>[] = [
  {
    content: 'Id',
    key: 'membership_id',
    width: 80,
  },
  {
    content: 'User',
    key: 'user_repr',
  },
  timestamp('Created', 'date_from', { sortable: true }),
  {
    content: 'Created by',
    key: 'created_by_repr',
    width: 200,
  },
  timestamp('Removed', 'date_to', { sortable: true }),
  {
    content: 'Removed by',
    key: 'removed_by_repr',
    width: 200,
  },
];

export function MembershipLog(): ReactElement {
  const { endpoint: entityEndpoint } = useEntity<Enterprise>();
  const endpoint = `${entityEndpoint}membership_log/`;

  return (
    <IndexContainer url={endpoint} columns={columns}>
      <Heading title="Membership log">
        <div className="btn-toolbar">
          <ListExport endpoint={`${endpoint}export/`} />
        </div>
      </Heading>
      <ListFilter />
    </IndexContainer>
  );
}

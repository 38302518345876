import { ReactElement } from 'react';
import cx from 'clsx';
import { Alert, Variant } from '@ff-it/ui';
import { Icon } from 'components';
import { BlockDetails, ChangeScope } from '../../types';
import './BlockNotice.scss';
import type { IconProp } from 'components';

const SCOPE_TITLES: Record<ChangeScope, string> = {
  PLAN: 'New plan',
  PRICES: 'Prices updated',
  PRICES_UPDATE: 'Price update',
  BRIEF_UPDATE: 'Brief update',
  REJECTED_PLAN: 'Rejected plan',
  REPLAN: 'Replan',
};

const SCOPE_VARIANTS: Record<ChangeScope, Variant> = {
  PLAN: 'danger',
  PRICES: 'warning',
  PRICES_UPDATE: 'warning',
  BRIEF_UPDATE: 'danger',
  REJECTED_PLAN: 'danger',
  REPLAN: 'danger',
};

const SCOPE_ICONS: Partial<Record<ChangeScope, [IconProp, string]>> = {
  PRICES: ['circle-info', 'text-primary'],
  PRICES_UPDATE: ['circle-info', 'text-primary'],
};

type BlockNoticeProps = Pick<BlockDetails, 'state' | 'scope' | 'version' | 'motivation' | 'brief_updated'> & {
  className?: string;
  overview?: boolean;
};

export function BlockNotice({
  scope,
  state,
  motivation,
  brief_updated,
  className,
  overview = false,
}: BlockNoticeProps): ReactElement | null {
  if (!['PLANNING', 'SUBMITTED', 'APPROVED'].includes(state)) {
    return null;
  }

  if (state == 'APPROVED') {
    if (scope != 'PRICES' && scope != 'PRICES_UPDATE') {
      return null;
    }
    // FIXME doesn't make any sense
    if (scope == 'PRICES' && overview) {
      return null;
    }
  } else if (scope === 'PLAN') {
    return null;
  }

  let icon = null;
  if (scope in SCOPE_ICONS) {
    // eslint-disable-next-line
    // @ts-ignore
    const [iconProp, className] = SCOPE_ICONS[scope];
    icon = <Icon icon={iconProp} className={cx('mr-1', className)} />;
  } else if (brief_updated) {
    // FIXME
    icon = <Icon icon="triangle-exclamation" className="mr-1 text-danger" title="Brief has been updated" />;
  }

  return (
    <Alert variant={SCOPE_VARIANTS[scope]} className={cx('block-notice', className)}>
      {icon}
      <strong>{SCOPE_TITLES[scope]}</strong> ·{' '}
      {motivation && (
        <span className="ml-1" data-change-scope={scope}>
          {motivation}
        </span>
      )}
    </Alert>
  );
}

import { createOptions } from 'options';
import type { BoundState as BoundStateType } from 'modules/campaign/common/types';
import { Column } from '@ff-it/grid';
import { BlockWidget, UserTitle, BlockWidgetProps } from 'components';
import { fmtMonth } from 'utilities';
import type { EmbeddedUser } from 'modules/core/types';

export const initialFilter = {
  manager: null,
  client: null,
  provider: null,
  month: null,
  state: null,
};

export const boundStateOptions = createOptions(['NONE', 'PARTIAL', 'OVERSPENT']);

export const sumProps = {
  width: 120,
  className: 'text-right align-middle',
  headerClassName: 'text-right',
};

export type RowType = {
  id: string;
  block: BlockWidgetProps;
  manager: EmbeddedUser;
  month: string;
  inoivced: string;
  amount: string;
  remainder: string;
  state: BoundStateType;
  is_overspent: boolean;
};

export const startColumns: Column<RowType>[] = [
  {
    key: 'block',
    content: 'Block',
    render: ({ block }) => <BlockWidget {...block} link />,
  },
  {
    key: 'manager',
    content: 'Manager',
    render: ({ manager }) => <UserTitle {...manager} />,
  },
];

export const endColumns: Column<RowType>[] = [
  {
    key: 'month',
    content: 'Month',
    className: 'align-middle',
    render: ({ month }) => fmtMonth(month),
    sortable: true,
    width: 120,
  },
  {
    key: 'amount',
    content: 'Amount',
    sortable: true,
    ...sumProps,
  },
  {
    key: 'invoiced',
    content: 'Consumed',
    sortable: true,
    ...sumProps,
  },
  {
    key: 'remainder',
    content: 'Remainder',
    sortable: true,
    ...sumProps,
    className: 'text-right align-middle font-weight-bold',
  },
];

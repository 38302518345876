import { createElement, ReactElement, ReducerWithoutAction, useEffect, useReducer } from 'react';
import Big from 'big.js';
import { parseISO, format, formatDistanceStrict, isValid } from 'date-fns';
import { ApiClient, RequestFailure, RequestResult } from '@ff-it/api';
import { toast } from 'react-toastify';
import { Entity } from 'types';
import { GridQueryState, PageResult } from '@ff-it/grid';
import { ButtonVariant } from '@ff-it/ui';
import { atomWithReducer } from 'jotai/utils';
import { atom, Atom, Getter, useStore } from 'jotai';
import eq from 'fast-deep-equal';

export type Validator = (value: any) => string | undefined;

export function composeValidators(...validators: Validator[]): Validator {
  return (value: any): string | undefined =>
    validators.reduce((error: string | undefined, validator) => error || validator(value), undefined);
}

export function identity<T = any>(value: T): T {
  return value;
}

export function parseBig(inp?: string | number | null): Big {
  if (inp) {
    try {
      return Big(inp);
    } catch (e) {}
  }
  return Big(0);
}

export function decimalPlaces(x: Big): number {
  // @TODO don't cast to string use exp
  const str = x.toFixed();
  const index = str.indexOf('.');
  if (index >= 0) {
    return str.length - index - 1;
  } else {
    return 0;
  }
}

export function fmtMonth(date: string): string {
  return format(parseISO(date), 'MMM. yyyy');
}

export function formatDate(isoDate: string): string {
  return format(parseISO(isoDate), 'dd.MM.yyyy');
}

export function formatDateTime(isoDate: string): string {
  return format(parseISO(isoDate), 'dd.MM.yyyy HH:mm');
}

export function fmtPeriodDistance({ date_from, date_to }: { date_from: string; date_to: string }): string {
  return formatDistanceStrict(parseISO(date_from), parseISO(date_to));
}

const zeroRegex = /^0\.?0*$/;

export function isZero(v: string): boolean {
  return v.match(zeroRegex) !== null;
}
export function sepFormat(v?: string | null, hideZero = false, hideTrailing = false): string {
  if (v === null || v === undefined || (hideZero && isZero(v))) {
    return '';
  }
  const arr = v.split('.');
  arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const res = arr.join('.');
  if (hideTrailing) {
    return res.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)?.[0] || '';
  }
  return res;
}

export function fmt(num?: Big, isPercentage = false, sepGroup = true): string {
  if (!num) {
    return '—';
  }

  let val = num.toFixed(2);
  if (sepGroup) {
    val = sepFormat(val);
  }

  return `${val}${isPercentage ? '%' : ''}`;
}

const newlineRegex = /(\r\n|\r|\n)/g;

export function nl2br(inp: string): Array<string | ReactElement> {
  return inp.split(newlineRegex).map(function (line: string, index: number) {
    if (line.match(newlineRegex)) {
      return createElement('br', { key: index });
    }
    return line;
  });
}

export const isEmpty = (value: any): boolean => value === undefined || value === null || value === '';

export const required = (value: any): string | undefined => (isEmpty(value) ? 'This field is required' : undefined);
export const requiredAtLeastOne: Validator = (value) =>
  value && value.length > 0 ? undefined : 'At least one value required';

export const gt =
  (than: Big) =>
  (value: any): string | undefined =>
    !isEmpty(value) ? (!parseBig(value).gt(than) ? `Has to be greater than ${than.toFixed(2)}` : undefined) : undefined;

export const positive = (value: any): string | undefined =>
  required(value) || (parseBig(value).lte(0) ? `Has to be greater than zero` : undefined);

export async function ListPageHandler<T extends Entity>(
  url: string,
  { page, size: page_size, sort, filter }: GridQueryState,
  api: ApiClient,
): Promise<PageResult<T>> {
  const result = await api.get<T[], unknown>(url, {
    queryParams: {
      page_size,
      page,
      ordering: sort ? `${sort.order === 'DESC' ? '-' : ''}${sort.key.replace('.', '__')}` : undefined,
      ...filter,
    },
  });

  if (result.ok) {
    return {
      data: result.data,
      count: result.data.length,
    };
  } else {
    throw result.error;
  }
}

export function prompStale(message = ''): void {
  if (window.confirm(`${message}\nYou seem to be editing stale data. Refresh?`)) {
    window.location.reload();
  }
}

export function checkActionError(res: RequestFailure<any>): boolean {
  if ([400, 403, 405, 409, 412].includes(res.status)) {
    let message: string;
    if (typeof res.data === 'string') {
      message = res.data;
    } else if (Array.isArray(res.data)) {
      message = res.data.join(' ,');
    } else {
      if (res.data.detail) {
        message = res.data.detail;
      } else {
        // FIXME
        message = JSON.stringify(res.data);
      }
    }

    if (res.status == 412) {
      prompStale(message);
    } else {
      toast.error(message);
    }
    return true;
  }
  return false;
}

export function maybeActionErrorOrThrow(res: RequestResult<any, any>): void {
  if (!res.ok) {
    actionErrorOrThrow(res);
  }
}

export function actionErrorOrThrow(res: RequestFailure<any>): void {
  if (!checkActionError(res)) {
    throw res.error;
  }
}

export function actionErrorAndThrow(res: RequestFailure<any>): never {
  checkActionError(res);
  throw res.error;
}

// FIXME
export function parseDate(str: string): Date | undefined {
  if (str.match(/\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])/)) {
    const parsed = parseISO(str);
    if (isValid(parsed)) {
      return parsed;
    }
  }
  return undefined;
}

const MAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export function isValidEmail(email: string): boolean {
  return MAIL_REGEXP.test(email);
}

export const cmpStrings = new Intl.Collator().compare;

export default class Mutex {
  private _locking: Promise<void>;
  private _locks: number;
  constructor() {
    this._locking = Promise.resolve();
    this._locks = 0;
  }

  public isLocked(): boolean {
    return this._locks > 0;
  }

  //fixme
  public lock(): Promise<() => void> {
    this._locks += 1;

    let unlockNext: any;

    const willLock = new Promise<void>(
      (resolve) =>
        (unlockNext = () => {
          this._locks -= 1;

          resolve();
        }),
    );

    const willUnlock = this._locking.then(() => unlockNext);

    this._locking = this._locking.then(() => willLock);

    return willUnlock;
  }
}

export const buttonVariantToIconColor: Partial<Record<ButtonVariant, string>> = {
  success: 'text-success',
  danger: 'text-danger',
  'outline-danger': 'text-danger',
  'outline-success': 'text-success',
};

export const coerceEmptyArrayToNull = (v: any): any => {
  return v && v.length == 0 ? null : v;
};

export function partition<T>(arr: Array<T>, predicate: (val: T) => boolean): [Array<T>, Array<T>] {
  const partitioned: [Array<T>, Array<T>] = [[], []];
  arr.forEach((val: T) => {
    const partitionIndex: 0 | 1 = predicate(val) ? 0 : 1;
    partitioned[partitionIndex].push(val);
  });
  return partitioned;
}

export const replaceItemAtIndex = <T>(arr: T[], index: number, newValue: T): T[] => {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
};

export const removeItemAtIndex = <T>(arr: T[], index: number): T[] => {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export function parseDecimalString(v: string | null, precision: number, normalize: boolean): string | null {
  if (v == null) {
    return null;
  }
  const negative = v.startsWith('-');
  const strip = v.replace(',', '.').replace(/[^\d.]/g, '');
  if (strip === '' || strip == '.') {
    return null;
  }
  const rgx = new RegExp(`^(\\d*.\\d{0,${precision}}).*$`, 'g');
  let value = strip.replace(rgx, '$1');

  if (value.startsWith('.')) {
    value = '0' + value;
  }

  if (normalize) {
    // eslint-disable-next-line
    const tail = value.match(/^0*(\d+(?:\.(?:(?!0+$)\d)+)?)/)![0];
    return `${negative ? '-' : ''}${tail}`;
  } else if (precision) {
    const sep = value.indexOf('.');
    let tail = value;
    if (sep === -1) {
      tail = `${value}.${'0'.repeat(precision)}`;
    } else {
      while (tail.length - sep <= precision) {
        tail += '0';
      }
    }
    return `${negative ? '-' : ''}${tail}`;
  }

  return `${negative ? '-' : ''}${value}`;
}

export const NEVER = new Promise<never>(() => {
  // Never fulfills
}) as any;

export function atomWithCompare<Value>(
  initialValue: Value,
  areEqual: (prev: Value, next: Value) => boolean,
): Atom<Value> {
  return atomWithReducer(initialValue, (prev: Value, next: Value) => {
    if (areEqual(prev, next)) {
      return prev;
    }

    return next;
  });
}

export function reselectAtom<Value>(
  resolve: (get: Getter) => Value,
  selector?: (next: Awaited<Value>, prev: Awaited<Value>) => Awaited<Value>,
): Atom<Value> {
  const EMPTY = Symbol();

  function selectValue([next, prev]: readonly [Awaited<Value>, Awaited<Value> | typeof EMPTY]): Awaited<Value> {
    if (prev == EMPTY) {
      return next;
    }

    if (selector) {
      return selector(next, prev);
    }

    return eq(next, prev) ? prev : next;
  }

  const derivedAtom: Atom<Value | Promise<Value> | typeof EMPTY> & {
    init?: typeof EMPTY;
  } = atom((get) => {
    const prev = get(derivedAtom);
    const next = resolve(get);
    if (next instanceof Promise || prev instanceof Promise) {
      return Promise.all([next, prev] as const).then(selectValue);
    }
    return selectValue([next as Awaited<Value>, prev as Awaited<Value>] as const);
  });

  // HACK to read derived atom before initialization
  derivedAtom.init = EMPTY;
  return derivedAtom as Atom<Value>;
}

type Store = ReturnType<typeof useStore>;
type Options = {
  store?: Store;
};

export function useReslectAtom<Value, Slice>(
  atom: Atom<Value>,
  selector: (v: Value, prev?: Slice) => Slice,
  equalityFn: (a: Slice, b: Slice) => boolean = Object.is,
  options?: Options,
): Slice {
  const store = useStore(options);
  const [[valueFromReducer, storeFromReducer, atomFromReducer, selectorFromReducer], rerender] = useReducer<
    ReducerWithoutAction<readonly [Slice, Store, typeof atom, typeof selector]>,
    undefined
  >(
    (prev) => {
      const nextValue = selector(store.get(atom), prev[0]);
      if (equalityFn(prev[0], nextValue) && prev[1] === store && prev[2] === atom) {
        return prev;
      }
      return [nextValue, store, atom, selector];
    },
    undefined,
    () => [selector(store.get(atom)), store, atom, selector],
  );

  let value = valueFromReducer;
  if (storeFromReducer !== store || atomFromReducer !== atom || selectorFromReducer !== selector) {
    rerender();
    value = selector(store.get(atom));
  }

  useEffect(() => {
    const unsub = store.sub(atom, () => {
      rerender();
    });
    rerender();
    return unsub;
  }, [store, atom]);

  return value;
}

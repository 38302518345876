import { ReactElement } from 'react';
import { DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { FileField, Form, Submit } from '@ff-it/form';
import { required } from 'utilities';
import { SubmissionErrors } from 'final-form';

export function ImportPlanDialog({
  onSubmit,
  submitHandler,
}: RenderProps & {
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
}): ReactElement {
  return (
    <Form onSubmit={(v) => submitHandler(v).then(() => onSubmit())}>
      <DialogHeader title="Import plan" />
      <DialogBody>
        <FileField name="file" label="File" required validate={required} />
      </DialogBody>
      <DialogFooter>
        <Submit>Import</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

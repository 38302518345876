import { ReactElement } from 'react';
import { EmbeddedCompany } from 'types';
import { TitleContainer } from './TitleContainer';

type ClientProps = Pick<EmbeddedCompany, 'internal_name' | 'title'>;

interface ClientTitleProps {
  client: ClientProps;
  end_client: ClientProps | null;
  className?: string;
  flat?: boolean;
}

export function ClientTitle({ client, end_client, className, flat }: ClientTitleProps): ReactElement {
  if (end_client) {
    return (
      <TitleContainer
        title={end_client.internal_name || end_client.title}
        subtitle={`via ${client.internal_name || client.title}`}
        className={className}
        flat={flat}
      />
    );
  }
  return <TitleContainer title={client.internal_name || client.title} className={className} flat={flat} />;
}

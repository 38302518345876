import { ReactElement } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { DecimalEditor } from '../../Editor';
import { sepFormat } from 'utilities';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import type { Row } from 'modules/campaign/row';

function View(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.client_price;
  const price = props.row.config.client_price?.resolved_value;

  const resetPrice = Boolean(price && price !== value);

  return (
    <>
      {resetPrice && <span className="reset-badge" />}
      {value ? sepFormat(value) : null}
    </>
  );
}

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.client_price;
  const price = props.row.config.client_price?.resolved_value;
  const setValue = useFieldUpdater(props.row.id, 'client_price');

  return (
    <DecimalEditor
      value={value}
      stopEdit={props.stopEdit}
      setValue={setValue}
      precision={16}
      normalize
      resetValue={price}
    />
  );
}

export const client_price: GridColumn<Row> = {
  key: 'client_price',
  header: 'Net client per unit',
  minWidth: 96,
  className: 'text-right',
  renderCell: (props) =>
    props.row.kind === 'SMART' ? null : props.editing ? <Edit {...props} /> : <View {...props} />,
  editable: (row) => fieldIsEditable(row, 'client_price'),
};

import { StylesConfig } from 'react-select';

export const styles: StylesConfig<any, any, any> = {
  control: (base) => ({
    ...base,
    margin: '0.5rem 0.5rem 0.2rem 0.5rem',
    boxShadow: undefined,
    // padding: '0.25rem 0.5rem',
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: undefined,
  }),
  singleValue: (base) => ({
    ...base,
    whiteSpace: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  }),
  menu: () => ({}),
};

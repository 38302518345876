import { Icon } from 'components';
import { IconBadge } from 'components/IconBadge/IconBadge';
import type { ListInvoice } from 'modules/invoicing/types';
import { ReactElement } from 'react';

type IsLinkedStateProps = {
  invoice: ListInvoice;
};

export function IsLinkedState({ invoice }: IsLinkedStateProps): ReactElement {
  return (
    <IconBadge>
      {invoice.sums.link_state !== null ? (
        <Icon
          data-link-state={invoice.linked_at ? 'linked' : 'not-linked'}
          icon={invoice.linked_at ? 'link' : 'link-slash'}
          className={invoice.linked_at ? 'text-success' : 'text-danger'}
          title={invoice.linked_at ? 'Linked' : 'Not linked'}
        />
      ) : null}
    </IconBadge>
  );
}

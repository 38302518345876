import { Row } from './types';
import { sumColumns } from '../common';
import { Link } from 'react-router-dom';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<Row> = [
  {
    content: 'Agency',
    key: 'enterprise_slug',
    width: 80,
  },
  {
    content: 'Client',
    key: 'client_internal_name',
    sortable: true,
  },
  {
    content: 'End client',
    key: 'end_client_internal_name',
    sortable: true,
  },
  {
    content: 'Brand',
    key: 'brand_title',
    sortable: true,
  },
  {
    content: 'Campaign',
    key: 'campaign_title',
    sortable: true,
    render: ({ enterprise_slug, campaign_id, campaign_title }) => (
      <Link to={`/${enterprise_slug}/planning/campaigns/${campaign_id}/planning`}>{campaign_title}</Link>
    ),
  },
  ...sumColumns,
];

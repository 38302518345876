import { WritableAtom } from 'jotai';
import { useAtomCallback, useHydrateAtoms } from 'jotai/utils';
import { useCallback, useEffect, useRef } from 'react';

type AnyWritableAtom = WritableAtom<unknown, any[], any>;

// const cache = new Map()

// export function useSyncToAtoms(...values: Array<readonly [AnyWritableAtom, unknown]>): void {
//   const store = useStore();
//   const ref = useRef(true);
//   if (ref.current) {
//     ref.current = false;
//     for (const [atom, value] of values) {
//       store.set(atom, value);
//     }
//   }

//   useEffect(() => {
//     if (!ref.current) {
//       values.forEach(([atom, value]) => {
//         store.set(atom, value);
//       });
//     }
//   });
// }

export function useSyncToAtoms(...values: Array<readonly [AnyWritableAtom, unknown]>): void {
  useHydrateAtoms(values);
  const sync = useAtomCallback(
    useCallback(
      (_get, set) => {
        for (const [a, v] of values) {
          set(a, v);
        }
      },
      [values],
    ),
  );
  const isInitial = useRef(true);
  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
    } else {
      sync();
    }
  }, [sync]);
}

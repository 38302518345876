import { ReactElement } from 'react';
import { DateRangePickerField } from '@ff-it/form';
import { PeriodPickerField } from 'components';
import { usePeriod } from '../usePeriod';

export function DateRangePeriodPicker(): ReactElement {
  const { date_from: minDate, date_to: maxDate } = usePeriod();

  return (
    <>
      <DateRangePickerField fromName="date_from" toName="date_to" minDate={minDate} maxDate={maxDate} />
      <div className="ml-1 d-flex align-items-center">
        <PeriodPickerField fromName="date_from" toName="date_to" minDate={minDate} maxDate={maxDate} />
      </div>
    </>
  );
}

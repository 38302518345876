import cx from 'clsx';
import { Check } from '@ff-it/ui';
import { OptionProps } from 'react-select';
import { ReactElement } from 'react';

export function CheckboxOption({
  innerRef,
  isDisabled,
  isFocused,
  isSelected,
  children,
  className,
  innerProps,
}: OptionProps<any, any, any>): ReactElement {
  return (
    <div
      className={cx(
        {
          option: true,
          'option--checkbox': true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      <Check variant="checkbox" checked={isSelected} onChange={(e) => e.preventDefault()} />
      <div className="option__label">{children}</div>
    </div>
  );
}

export interface Entity {
  id: number;
}

export interface Permission extends Entity {
  name: string;
  codename: string;
  content_type: number;
}

export interface Country {
  code: string;
  name: string;
}

export type CompanyKind = 'individual' | 'company';

export interface MinimalCompany extends Entity {
  title: string;
  internal_name: string;
}

export interface EmbeddedCompany extends MinimalCompany {
  kind: CompanyKind;
  code: string | null;
  invoice_template: string;
  acceptance_act_template: string;
  registration_number: string;
}

export interface Company extends EmbeddedCompany {
  country: Country;
  registration_number: string;
  vat_number: string;
  legal_address: string;
  office_address: string;
  office_same_as_legal: string;
  bank_account_number: string;
  bank: Bank | null;
  created_at?: string;
  modified_at?: string;
}

export type VATStrategy = 'LINE_VAT' | 'GROUP_VAT';

export const productKinds = ['MEDIA', 'SERVICE', 'SMART'] as const;
export type ProductKind = (typeof productKinds)[number];
export type DepartmentKind = Extract<ProductKind, 'MEDIA' | 'SERVICE'>;

export const productDepartmentKindMap: Record<ProductKind, DepartmentKind> = {
  MEDIA: 'MEDIA',
  SERVICE: 'SERVICE',
  SMART: 'SERVICE',
};

export const activityTypes = [
  'TV',
  'RADIO',
  'OOH',
  'PRINT',
  'WEB',
  'SOCIAL',
  'CREATIVE',
  'RTB',
  'OTHER',
  'BTL',
  'SEO',
] as const;
export type ActivityType = (typeof activityTypes)[number];

export const genericUnits = ['UNIT', 'HOUR'] as const;
export type GenericUnit = (typeof genericUnits)[number];

export const RTBUnits = [
  'CPM',
  'CPC',
  'DAY',
  'WEEK',
  'MONTH',
  'PERIOD',
  'PER_CONTACT',
  'CPV',
  'PUBLICATION',
  'VCPM',
  'CPL',
  'CPA',
  'CPI',
  'CPS',
] as const;
export type RTBUnit = (typeof RTBUnits)[number];

export type Unit = GenericUnit | RTBUnit;

// Smart application direction
export const directions = ['AGENCY', 'CLIENT'] as const;
export type Direction = (typeof directions)[number];

export const applications = ['RATE', 'UNIT'] as const;
export type Application = (typeof applications)[number];

export const agreementKinds = ['ARRANGEMENT', 'CONTRACT'] as const;
export type AgreementKind = (typeof agreementKinds)[number];

export type Language = string;

export interface Activity extends Entity {
  kind: DepartmentKind;
  name: string;
  name_alternative: string;
  non_standard: boolean;
  is_fee: boolean;
}

export interface Bank extends Entity {
  code: string;
  title: string;
}

export interface TargetGroup extends Entity {
  title: string;
}

export type Period = {
  start: string;
  end: string;
};

export type IntersectionState = 'start' | 'end' | 'middle' | 'complete' | 'empty';

import { ReactElement, useState, useRef } from 'react';
import useSWR from 'swr/immutable';
import { SelectField, Submit, useForm, useOnFieldChange } from '@ff-it/form';
import { Box, Button, SelectInstance, DialogBody, DialogClose, DialogFooter, DialogHeader } from '@ff-it/ui';
import type { ActivityType, EmbeddedCompany, ProductKind } from 'types';
import { Icon } from 'components';
import { ProductField } from 'modules/supplier/products/ProductField';
import { EmbeddedProduct } from 'modules/supplier/products';
import { productKindOptions } from 'options';
import { CompanyField, companyFieldProps } from 'modules/core/company/components';
import { Precedence } from './types';
import { ProductActivityField } from './ProductActivityField';
import { isComplete } from './isComplete';

export const initialState = {
  product: null,
  provider: null,
  activity: null,
};

type FieldProps = { type: ActivityType; kind: ProductKind; required?: boolean };

function ProductFields({ type, kind, required }: FieldProps): React.ReactElement {
  const form = useForm();
  const providerFieldRef = useRef<SelectInstance<any>>(null);

  const product = useOnFieldChange<EmbeddedProduct | null>(
    'product',
    (value) => {
      if (value) {
        form.change('provider', null);
        providerFieldRef.current?.focus();
      } else {
        form.initialize(initialState);
      }
    },
    false, // clearing does not handle blur and we reset with with kind
  );

  const { data: providers } = useSWR<EmbeddedCompany[]>(
    product
      ? {
          url: `supplier/providers/`,
          method: 'GET',
          queryParams: {
            product: product.id,
          },
        }
      : null,
  );

  const disabled = !product;

  return (
    <>
      <ProductField
        label="Product"
        type={type}
        kind={kind}
        name="product"
        menuPortalTarget={document.body}
        defaultOptions
        required={required}
        key={kind}
      />
      <SelectField<EmbeddedCompany>
        label="Provider"
        name="provider"
        menuPortalTarget={document.body}
        options={providers || []}
        {...companyFieldProps}
        disabled={disabled}
        required
        selectRef={providerFieldRef}
        openMenuOnFocus
      />
      <ProductActivityField />
    </>
  );
}

function ProviderFields({ type, kind, required }: FieldProps): React.ReactElement {
  const form = useForm();
  const productFieldRef = useRef<SelectInstance<any>>(null);
  const provider = useOnFieldChange<EmbeddedCompany | null>(
    'provider',
    (value) => {
      if (value) {
        form.change('product', null);
        productFieldRef.current?.focus();
      } else {
        form.initialize(initialState);
      }
    },
    false, // clearing does not handle blur and we reset with with kind
  );

  const disabled = !provider;

  return (
    <>
      <CompanyField
        label="Provider"
        name="provider"
        menuPortalTarget={document.body}
        required={required}
        openMenuOnFocus
      />
      <ProductField
        label="Product"
        type={type}
        kind={kind}
        provider={provider?.id}
        name="product"
        menuPortalTarget={document.body}
        defaultOptions
        required
        key={`kind-${provider?.id || 0}`}
        disabled={disabled}
        openMenuOnFocus
        selectRef={productFieldRef}
      />
      <ProductActivityField />
    </>
  );
}

export type FormProps = {
  type: ActivityType;
  kind?: ProductKind;
  required?: boolean;
};

export function Form({ type, kind: outerKind, required }: FormProps): ReactElement {
  const form = useForm();
  const [kind, setKind] = useState<ProductKind>(outerKind || 'MEDIA');
  const [precedence, setPrecedence] = useState<Precedence>('PRODUCT');

  function onKindChange(kind: ProductKind): void {
    // reset product on kind change
    form.initialize(initialState);
    setKind(kind);
  }

  function onPrecedenceChange(): void {
    if (!isComplete(form.getState().values as any)) {
      form.initialize(initialState);
    }
    setPrecedence((current) => (current == 'PRODUCT' ? 'PROVIDER' : 'PRODUCT'));
  }

  return (
    <>
      <DialogHeader title="Supplier">
        <Box marginLeft="auto">
          {!outerKind && (
            <div className="btn-group mr-1">
              {productKindOptions.map(({ value, label }) => (
                <Button
                  key={value}
                  variant="outline-primary"
                  size="sm"
                  active={value === kind}
                  onClick={() => onKindChange(value)}
                >
                  {label}
                </Button>
              ))}
            </div>
          )}
          <Button
            size="sm"
            variant="outline-secondary"
            title={`Precedence - ${precedence}`}
            onClick={onPrecedenceChange}
          >
            <Icon icon={['far', 'swap']} />
          </Button>
        </Box>
      </DialogHeader>
      <DialogBody>
        {precedence === 'PRODUCT' ? (
          <ProductFields type={type} kind={kind} required={required} />
        ) : (
          <ProviderFields type={type} kind={kind} required={required} />
        )}
      </DialogBody>
      <DialogFooter>
        <Submit>Update</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </>
  );
}

import { useEffect, type ReactElement } from 'react';
import { Box } from '@ff-it/ui';
import { CampaignWidget, CampaignWidgetProps, EntityFormActions } from 'components';
import useSWR from 'swr';
import { sepFormat } from 'utilities';
import { Card } from 'components/Card';
import { verticalClamp } from './style.css';
import { unlinkAll } from '../actions';

type LinkedCampaignsProps = {
  endpoint: string;
  bound: string;
};

export function LinkedCampaigns({ endpoint, bound }: LinkedCampaignsProps): ReactElement {
  const { data, mutate } = useSWR<{ amount: string; campaign: CampaignWidgetProps }[], any, { suspense: true }>(
    {
      url: `${endpoint}campaigns/`,
      method: 'GET',
    },
    { suspense: true },
  );

  useEffect(() => {
    mutate();
  }, [bound]);

  return (
    <Card
      title={
        <Box display="flex" alignItems="center">
          Linked campaigns
          <Box marginLeft="auto" marginY="-sm">
            <EntityFormActions actions={[unlinkAll]} />
          </Box>
        </Box>
      }
      testId="linked-campaigns"
    >
      <Box className={verticalClamp}>
        <table className="form-table table mb-0">
          <tbody>
            {data.map(({ amount, campaign }) => (
              <tr key={campaign.id}>
                <td>
                  <CampaignWidget {...campaign} link />
                </td>
                <td className="text-right">{sepFormat(amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Card>
  );
}

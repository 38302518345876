import { ReactElement, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Td, Tr, openModal } from '@ff-it/ui';
import type { BlockDetails } from 'modules/campaign/block/types';
import { CreateBlockDialog, RemoveBlockDialog, UpdateBlockDialog } from 'modules/campaign/block/dialogs';
import {
  ActivityIcon,
  BoundStates,
  Period,
  useEntityActions,
  useRequestActions,
  usePromiseActions,
  ActionDropdown,
  EntityContextType,
} from 'components';
import type { CampaignPeriod } from '../types';
import { useManagerActions } from 'modules/campaign/block/BlockActions/useManagerActions';
import { BlockNotice } from 'modules/campaign/block/layout';
import { sepFormat } from 'utilities';

export interface BlockControlProps {
  itemContext: EntityContextType<BlockDetails>;
  campaignId: number;
  campaignPeriod: CampaignPeriod;
  remove: (block: BlockDetails) => void;
  add: (block: BlockDetails) => void;
}

function BlockControls({
  itemContext,
  campaignId,
  campaignPeriod,
  remove,
  add,
}: BlockControlProps): ReactElement | null {
  const actions = usePromiseActions(
    useRequestActions(
      useEntityActions(itemContext, [
        {
          action: 'edit',
          button: {
            children: 'Update brief',
            icon: ['far', 'pencil'],
          },
          promise: () =>
            openModal(
              (props) => (
                <UpdateBlockDialog
                  {...props}
                  block={itemContext.item}
                  campaignPeriod={campaignPeriod}
                  remove={async (): Promise<any> => {
                    const res = await openModal<BlockDetails>((props) => (
                      <RemoveBlockDialog {...props} block={itemContext.item} />
                    ));
                    if (res) {
                      remove(res);
                      toast.success('Block has been removed.', { toastId: 'remove-block-toast' });
                      return res;
                    }
                  }}
                />
              ),
              { className: 'right', canDismiss: false },
            ),
          successMessage: 'Block has been updated.',
          visible: ({ state, role: { manage } }: BlockDetails) => manage && (state == 'NEW' || state == 'PLANNING'),
          permKey: null, // we look at role
        },
        {
          action: 'copy',
          button: {
            children: 'Copy brief',
            icon: ['far', 'copy'],
          },
          successMessage: 'Block has been updated.',
          visible: ({ role: { manage } }: BlockDetails) => manage,
          permKey: null, // we look at role
          promise: () =>
            openModal<BlockDetails>(
              (props) => {
                const source = itemContext.item;
                return (
                  <CreateBlockDialog
                    {...props}
                    campaignId={campaignId}
                    type={source.type}
                    campaignPeriod={campaignPeriod}
                    copyValues={{
                      ...source,
                      id: undefined,
                      date_from: null,
                      date_to: null,
                      deadline: null,
                      brief_attachments: [],
                    }}
                  />
                );
              },
              { className: 'right', canDismiss: false },
            ).then((res) => {
              if (res) {
                add(res);
                toast.success('Block has been added.', { toastId: 'add-block-toast' });
              }
            }),
        },
        // sep
        ...useManagerActions(campaignPeriod),
      ]),
    ),
    itemContext.setLoading,
    itemContext.setItem,
  );
  if (actions.length === 0) {
    return null;
  }

  return (
    <ActionDropdown
      actions={actions}
      variant="outline-secondary"
      className="border-0"
      icon={['far', 'ellipsis-vertical']}
      testId="block-overview-actions"
    />
  );
}

export function Block(props: BlockControlProps): ReactElement {
  const block = props.itemContext.item;

  const {
    id,
    type,
    code,
    title,
    date_from,
    date_to,
    sums,
    planners,
    role: { manage, plan },
  } = block;
  const notice = <BlockNotice {...block} overview />;
  return (
    <Fragment key={id}>
      <Tr className="block-overview" data-block-id={id} data-role-plan={plan} data-role-manage={manage}>
        <Td className="controls" rowSpan={2}>
          {manage && <BlockControls {...props} />}
        </Td>
        <Td className="state">{sums && <BoundStates {...sums} />}</Td>
        <Td className="type">
          <ActivityIcon type={type} /> <strong>{type}</strong>
        </Td>
        <Td className="code">
          <Link to={`${id}`}>{code}</Link>
        </Td>
        <Td>{title}</Td>
        <Td className="period">
          <Period date_from={date_from} date_to={date_to} flat />
        </Td>
        <Td>{planners.map(({ first_name, last_name }) => `${first_name} ${last_name.charAt(0)}`).join(', ')}</Td>
        <Td textAlign="end" className="client">
          {sepFormat(sums?.income_total)}
        </Td>
        <Td textAlign="end" className="agency">
          {sepFormat(sums?.expense_total)}
        </Td>
        <Td textAlign="end" className="revenue">
          {sepFormat(sums?.revenue)}
        </Td>
        <Td className="navigate">
          <Link to={`${id}`}>PLAN</Link>
        </Td>
      </Tr>
      <Tr className="block-notice">
        <Td flush colSpan={10}>
          {notice}
        </Td>
      </Tr>
    </Fragment>
  );
}

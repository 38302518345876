import { ReactElement } from 'react';
import cx from 'clsx';
import type { Supplier } from 'modules/supplier/supplier/types';
import { ProductTitle } from 'modules/supplier/products';
import { TitleContainer } from 'components/TitleContainer';
import { Box } from '@ff-it/ui';
import { half } from './style.css';

type SupplierTitleProps = Supplier & {
  className?: string;
};

export function SupplierTitle({ product, provider, className, activity }: SupplierTitleProps): ReactElement {
  return (
    <Box
      display="flex"
      flexDirection="row"
      className={cx('supplier-title', className)}
      data-productid={product.id}
      data-providerid={provider.id}
      data-activityid={activity.id}
    >
      <ProductTitle product={product} className={half} />
      <TitleContainer title={provider.title} subtitle={activity.name} className={half} />
    </Box>
  );
}

import { ReactElement } from 'react';
import { Company } from 'types';
import { useHasPerms } from 'core/permissions';
import { permissions } from './permissions';
import { AttachmentField, UserField, useDefaultOptions } from 'components';
import { TextField, DateRangePickerField } from '@ff-it/form';
import { CompanyField } from 'modules/core/company/components';
import { ClientBrandField } from 'modules/client/brand/components';
import { ClientPersonField } from 'modules/client/persons/components/ClientPersonField';

export function Form(): ReactElement {
  const [canCreate, manage] = useHasPerms(permissions.add, 'campaign.manage_campaign');
  // Precedence for superuser on in case user has both perms
  const isManager = manage && !canCreate;
  const defaultClients = useDefaultOptions<Company>('client/clients/', { managed: true });
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-3" required />
        <CompanyField name="client" label="Client" defaultOptions={defaultClients} className="col-md-3" required />
        <CompanyField
          name="end_client"
          label="End client"
          className="col-md-3"
          help="Final recipient of proxy service "
        />
        <DateRangePickerField label="Period" fromName="date_from" toName="date_to" className="col-md-3" required />
      </div>
      <div className="form-row">
        <ClientBrandField name="brand" label="Brand" className="col-md-3 offset-md-3" />
        <ClientPersonField name="ordered_by" label="Person" help="Ordered by" className="col-md-3" />
      </div>
      <div className="form-row">
        <UserField
          name="manager"
          label="Manager"
          className="col-md-3"
          required
          disabled={isManager}
          filter={{ manage_campaign: true }}
        />
        <UserField
          name="involved"
          label="Involved managers"
          className="col-md-6"
          filter={{ manage_campaign: true }}
          isMulti
        />
      </div>
      <div className="form-row">
        <TextField name="comment" label="Comment" multiline autosize className="col-md-6" />
        <AttachmentField name="attachments" label="Attachments" className="col-md-6" />
      </div>
    </div>
  );
}

import { ReactElement } from 'react';
import { Format } from 'modules/campaign/row';
import { ChoiceSelectEditor } from '../../../Editor';
import { getOptionLabel, getOptionValue } from './util';
import { SelectProps } from '../useEditableChoiceProps';
import { FormatOption } from './FormatOption';

export function Selector({ options, value, onChange }: SelectProps<Format>): ReactElement {
  return (
    <ChoiceSelectEditor<Format>
      isSearchable
      label="Product formats"
      options={options}
      onChange={onChange}
      isSimple={false}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
      Option={FormatOption}
    />
  );
}

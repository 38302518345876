import { atom } from 'jotai';
import Big from 'big.js';
import { PlanSummaryRow } from '../types';
import { rowsAtom } from './plan';
import { cmpStrings, parseBig } from 'utilities';

export const summaryRowAtom = atom<PlanSummaryRow | undefined>((get) => {
  const rows = get(rowsAtom);

  let gross_total = Big(0);
  let client_total = Big(0);
  let provider_total = Big(0);
  let hasError = false;
  rows.forEach(({ sums: sum }) => {
    if (!sum) {
      hasError = true;
      return;
    }

    gross_total = gross_total.plus(parseBig(sum.gross_total));
    client_total = client_total.plus(parseBig(sum.target_income));
    provider_total = provider_total.plus(parseBig(sum.target_expense));
  });

  if (hasError) {
    return undefined;
  }

  const discount_rate = gross_total.eq(0) ? undefined : gross_total.minus(client_total).div(gross_total).mul(100);

  const revenue = client_total.minus(provider_total);

  const margin = client_total.eq(0) ? undefined : revenue.div(client_total).mul(100);

  return {
    gross_total,
    client_total,
    provider_total,
    discount_rate,
    revenue,
    margin,
  };
});

export const summaryOpenAtom = atom<boolean>(false);

// https://gitlab.ffit.lv/ff-it/init/-/blob/00b275db84dc54f36993d99c61f5ff8bfb8c439d/client/src/modules/campaign/block/Summary/Container.tsx

type Totals = {
  income: Big;
  expense: Big;
};

function reduceTotals(rows: Totals[]): Totals {
  let income = Big(0);
  let expense = Big(0);

  rows.forEach((row) => {
    income = income.plus(row.income);
    expense = expense.plus(row.expense);
  });
  return {
    income,
    expense,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type Result<C = void> = {
  title: string;
} & Totals &
  (C extends void
    ? unknown
    : {
        children: C[];
      });

type Month = Result;
type Activity = Result<Month>;
type Provider = Result<Activity>;

function sortResult(a: Result, b: Result): number {
  return cmpStrings(a.title, b.title);
}

export const summaryAtom = atom<Provider[]>((get) => {
  const rows = get(rowsAtom);

  const providerMap: Record<string, string> = {};
  const acitvityMap: Record<string, string> = {};

  // provider.activity.month.sum
  const map: Record<string, Record<string, Record<string, Totals>>> = {};

  rows.forEach((row) => {
    if (!row.supplier) {
      return;
    }
    const { activity, provider } = row.supplier;

    if (!(activity.id in acitvityMap)) {
      acitvityMap[activity.id] = activity.name;
    }

    if (!(provider.id in providerMap)) {
      providerMap[provider.id] = provider.title;
    }

    Object.keys(row.months).map((month) => {
      const value = row.months[month];
      const income = parseBig(value.target_income);
      const expense = parseBig(value.target_expense);

      const amount = {
        income,
        expense,
      };

      // upsertIn?
      if (provider.id in map) {
        if (activity.id in map[provider.id]) {
          if (month in map[provider.id][activity.id]) {
            const c = map[provider.id][activity.id][month];
            map[provider.id][activity.id][month] = {
              income: income.plus(c.income),
              expense: expense.plus(c.expense),
            };
          } else {
            map[provider.id][activity.id][month] = amount;
          }
        } else {
          map[provider.id][activity.id] = {
            [month]: amount,
          };
        }
      } else {
        map[provider.id] = {
          [activity.id]: {
            [month]: amount,
          },
        };
      }
    });
  });

  const providers: Provider[] = Object.keys(map)
    .map((providerId) => {
      const children: Activity[] = Object.keys(map[providerId])
        .map((acitvityId) => {
          const children: Month[] = Object.keys(map[providerId][acitvityId])
            .map((month) => ({
              title: month,
              ...map[providerId][acitvityId][month],
            }))
            .sort(sortResult);

          return {
            title: acitvityMap[acitvityId] || 'Unknown',
            children,
            ...reduceTotals(children),
          };
        })
        .sort(sortResult);

      return {
        title: providerMap[providerId] || 'Unknown',
        children,
        ...reduceTotals(children),
      };
    })
    .sort(sortResult);
  return providers;
});

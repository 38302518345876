import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import { TextField } from '@ff-it/form';
import { createSceneRoute, ListFilter } from 'components';
import permissions from 'modules/client/brand/permissions';
import type { Brand } from 'modules/client/types';

const columns: Column<Brand>[] = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: Brand) => <Link to={`${id}`}>{title}</Link>,
  },
];

export function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-6" required />
      </div>
    </div>
  );
}

const initialValues = {
  code: '',
  title: '',
};

export const route = createSceneRoute('brands', {
  endpoint: 'client/brands/',
  title: 'Brand',
  pluralTitle: 'Brands',
  entityTitle: ({ title }) => title,
  permissions,
  index: { columns, filter: <ListFilter /> },
  form: <Form />,
  initialValues,
});

import { Icon } from 'components';
import { ReactElement } from 'react';

export function CurrentSelection({
  numRows,
  locked,
  clear,
}: {
  numRows: number;
  locked: boolean;
  clear: () => void;
}): ReactElement {
  return (
    <span className="badge badge-pill badge-primary font-weight-normal ml-3 py-1">
      {numRows} row{numRows > 1 ? 's' : ''} selected {locked && <Icon icon="lock" size="sm" className="ml-1" />}
      <Icon icon="xmark" size="sm" className="ml-1" role="button" onClick={clear} />
    </span>
  );
}

import { ReactElement, useCallback } from 'react';
import { useAtomValue } from 'jotai';
import { HEADER_HEIGHT, SUMMARY_HEIGHT } from 'components/Grid/const';
import { CalendarColumn } from './types';
import { format, isWeekend } from 'date-fns';
import { Draw, Canvas } from './Canvas';
import { calendarContextAtom, positionSelectionStateAtom } from './atoms';

const renderDay = (c: CalendarColumn): string => c.start.getDate().toString();
const renderWeek = (c: CalendarColumn): string => `w${format(c.start, 'I')}`;
const renderMonth = (c: CalendarColumn): string => `${format(c.start, 'MMM. yyyy')}`;

export function Header(): ReactElement | null {
  const { width, weeks, days } = useAtomValue(calendarContextAtom);
  const selection = useAtomValue(positionSelectionStateAtom);

  const draw: Draw = useCallback(
    (ctx) => {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      // weeks
      ctx.font = '12px Roboto';

      weeks.map((col) => {
        const { left, width } = col;
        ctx.fillStyle = 'white';
        ctx.fillRect(left, 0, width, 21);
        const text = renderWeek(col);
        ctx.fillStyle = '#343A40';
        ctx.fillText(text, left + width / 2, 21 / 2);
      });

      // days
      ctx.font = '10px Roboto';

      days.map((col) => {
        const { start, left, width } = col;
        const text = renderDay(col);

        // @TODO optimize this - render on top or bail
        if (selection && selection.start <= start && selection.end >= start) {
          ctx.fillStyle = '#007BFF';
          ctx.fillRect(left, 22, width, 42);

          ctx.fillStyle = '#ffffff';
          ctx.fillText(text, left + width / 2, 22 + 22 / 2);
        } else {
          if (isWeekend(start)) {
            ctx.fillStyle = '#EFEEEE';
          } else {
            ctx.fillStyle = '#ffffff';
          }

          ctx.fillRect(left, 22, width, 42);

          ctx.fillStyle = '#343A40';
          ctx.fillText(text, left + width / 2, 22 + 22 / 2);
        }
      });
    },
    [weeks, selection, days],
  );

  return <Canvas draw={draw} width={width} height={HEADER_HEIGHT - 1} />;
}

export function Summary(): ReactElement | null {
  const { width, months } = useAtomValue(calendarContextAtom);

  const draw: Draw = useCallback(
    (ctx) => {
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';

      // weeks
      ctx.font = '13px Roboto';

      months.map((col) => {
        const { left, width } = col;
        ctx.fillStyle = 'white';
        ctx.fillRect(left, 0, width, SUMMARY_HEIGHT);
        const text = renderMonth(col);
        ctx.fillStyle = '#343A40';
        ctx.fillText(text, left + width / 2, SUMMARY_HEIGHT / 2);
      });
    },
    [months],
  );
  return <Canvas draw={draw} width={width} height={SUMMARY_HEIGHT - 1} />;
}

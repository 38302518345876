import { useAtomValue } from 'jotai';
import { ReactElement } from 'react';
import { PlanStrategyTools } from './PlanStrategyTools';
import { RowActions } from './RowActions';
import { controlsStateAtom } from '../../atoms/controls';

export function PlanControls({ scrollToRow }: { scrollToRow: (rowId: number) => void }): ReactElement {
  const { empty, locked, type } = useAtomValue(controlsStateAtom);

  return (
    <div className="d-flex flex-row align-items-center ml-3">
      <PlanStrategyTools empty={empty} />
      <RowActions empty={empty} locked={locked} type={type} scrollToRow={scrollToRow} />
    </div>
  );
}

import { RouteObject } from 'react-router';
import type { ListCampaignRow } from 'modules/campaign/row';
import { ModelScene, IndexScene } from 'components';
import { columns } from './columns';
import { permissions } from '../Campaigns';
import { never } from 'core/permissions';
import { RowFilter } from 'modules/campaign/common/Filter';
import { ListExport } from 'components/ListActions';

const model = {
  endpoint: 'campaign/rows/',
  title: 'Row',
  pluralTitle: 'Rows',
  entityTitle: (e: ListCampaignRow) => `${e.block.code}: ${e.id}`,
  permissions,
};

export const route: RouteObject = {
  path: 'rows',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          perm={permissions.view}
          canCreate={never}
          storageKey="planning_rows"
          toolbar={<ListExport permission={permissions.export} />}
        >
          <RowFilter />
        </IndexScene>
      ),
    },
  ],
};

import { ReactElement } from 'react';
import { EntityActions } from 'components';
import type { CampaignPeriod } from '../../campaign/types';
import { usePlannerActions } from './usePlannerActions';
import { useManagerActions } from './useManagerActions';

interface BlockActionsProps {
  campaignPeriod: CampaignPeriod;
}

export function BlockActions({ campaignPeriod }: BlockActionsProps): ReactElement {
  const plannerActions = usePlannerActions();
  const managerActions = useManagerActions(campaignPeriod);
  return (
    <div className="ml-auto my-auto">
      <EntityActions actions={managerActions} className="btn-group-sm ml-3" group />
      <EntityActions actions={plannerActions} className="btn-group-sm ml-3" group />
    </div>
  );
}

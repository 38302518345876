import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { SubmissionErrors } from 'final-form';
import { Button, openModal } from '@ff-it/ui';
import { Icon, DialogForm, IconProp } from 'components';
import './RowChoiceEditor.scss';

type RowChoiceEditorProps<T> = {
  label: ReactNode;
  addLabel: ReactNode;
  dialogHeader: ReactNode;
  form: ReactNode;
  formatOptionLabel: (data: any) => ReactNode;
  initialValues?: any;
  value: T[];
  updateValue: (index: number, value: T) => Promise<SubmissionErrors | void>;
  removeValue: (index: number) => Promise<void>;
  addValue: (v: T) => Promise<SubmissionErrors | void>;
  getIcon?: (v: T) => IconProp;
  createOnEmptyOpen?: boolean;
};

export function RowChoiceEditor<T = any>({
  label,
  addLabel,
  form,
  value,
  removeValue,
  addValue,
  updateValue,
  formatOptionLabel,
  dialogHeader,
  initialValues,
  getIcon,
  createOnEmptyOpen,
}: RowChoiceEditorProps<T>): ReactElement {
  const isCalledRef = useRef(false);

  const onAdd = (): Promise<void> =>
    openModal((props) => (
      <DialogForm {...props} dialogHeader={dialogHeader} initialValues={initialValues} submitHandler={addValue}>
        {form}
      </DialogForm>
    ));

  const onUpdate = (idx: number, item: T): Promise<void> =>
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader={dialogHeader}
        submitHandler={(value) => updateValue(idx, value)}
        initialValues={item}
        onRemove={() => removeValue(idx)}
      >
        {form}
      </DialogForm>
    ));

  useEffect(() => {
    if (!isCalledRef.current && createOnEmptyOpen && (!value || value.length == 0)) {
      isCalledRef.current = true;
      onAdd();
    }
  }, []);

  return (
    <div className="row-choice-editor">
      <label className="block-label">{label}</label>
      {value &&
        value.map((item, idx) => (
          <div key={idx} className="option option--edit-choice">
            <div className="option__label">{formatOptionLabel(item)}</div>
            <div className="option__control text-primary" role="button" onClick={() => onUpdate(idx, item)}>
              <Icon icon={getIcon ? getIcon(item) : 'pencil'} />
            </div>
          </div>
        ))}

      <div className="p-1">
        <Button size="sm" variant="outline-primary" onClick={onAdd} testId="add-row-choice">
          {addLabel} <Icon icon="plus" className="ml-1" />
        </Button>
      </div>
    </div>
  );
}

import { ReactElement, ReactNode } from 'react';
import { useAtomValue } from 'jotai';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fmt, sepFormat } from 'utilities';
import { summaryRowAtom } from '../../../atoms/summary';
import type { Row } from 'modules/campaign/row';

function Summary(): ReactElement {
  const summary = useAtomValue(summaryRowAtom);
  return <strong>{fmt(summary?.gross_total)}</strong>;
}

function view(props: RenderCellProps<Row>): ReactNode {
  const {
    row: { sums },
  } = props;
  return sepFormat(sums?.gross_total);
}
export const gross_total: GridColumn<Row> = {
  key: 'gross_total',
  summary: <Summary />,
  header: 'Gross Total',
  minWidth: 96,
  className: 'text-right',
  renderCell: (props: RenderCellProps<Row>) => (props.row.kind === 'SMART' ? null : view(props)),
};

import { Dispatch, SetStateAction, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import equal from 'fast-deep-equal';
import { urlon } from '@ff-it/ui';
import { FetcherState } from './types';

export function parseQs(qs: string): FetcherState | undefined {
  const s = qs.replace(/^\?/, '');

  if (s.length !== 0) {
    try {
      const { p: pageIndex, s: pageSize, o: sort, f: filter } = urlon.parse(s);
      if (!pageIndex || !pageSize) {
        return undefined;
      }
      return { pageIndex, pageSize, sort, filter };
    } catch (_e) {
      return undefined;
    }
  }

  return undefined;
}

export function serializeQs({ pageIndex, pageSize, sort, filter }: FetcherState): string {
  const str =
    urlon.stringify({
      p: pageIndex,
      s: pageSize,
      o: sort,
      f: filter,
    }) || '';
  return str.length > 0 ? '?' + str : '';
}

export function useQueryState(
  initialState: FetcherState,
  onStateChange?: (state: FetcherState) => void,
): [FetcherState, Dispatch<SetStateAction<FetcherState>>] {
  const location = useLocation();
  const navigate = useNavigate();

  const maybeUpdateQuery = (newState: FetcherState, isDirty: boolean): void => {
    const qs = !isDirty ? '' : serializeQs(newState);
    if (location.search !== qs) {
      navigate(`${location.pathname}${qs}`, { replace: false });
    }
  };

  const [state, setInnerState] = useState<FetcherState>(() => {
    // reads qs on initialization
    if (location.search) {
      const queryState = parseQs(location.search);
      if (queryState) {
        return {
          ...initialState,
          ...queryState,
        };
      }
    }

    return initialState;
  });

  const setState: Dispatch<SetStateAction<FetcherState>> = (value) => {
    const newState = typeof value === 'function' ? value(state) : value;
    const isDirty = !equal(newState, initialState);
    // does order matter?
    maybeUpdateQuery(newState, isDirty);
    setInnerState(newState);

    // FIXME: we compare in storage callback regardless :(
    onStateChange && onStateChange(isDirty ? newState : initialState);
  };
  return [state, setState];
}

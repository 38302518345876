import { TransparencyIcon } from 'components';
import type { Transparency } from 'modules/core/types';
import './TransparencyOption.scss';

const titles = {
  DELIVERED: 'Delivered',
  PLANNED: 'Planned',
} as const;

export function TransparencyOption({
  transparency,
  children,
}: {
  transparency: Transparency;
  children?: React.ReactNode;
}): React.ReactElement {
  return (
    <div className="d-flex flex-row align-items-center transp-opt" data-transparency={transparency}>
      <TransparencyIcon transparency={transparency} />
      <div className="px-1 labels">
        <div>{titles[transparency]}</div>
      </div>
      {children}
    </div>
  );
}

import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';

type RowProps = {
  className?: string;
  group?: ReactNode;
  total?: ReactNode;
  bound?: ReactNode;
  remainder?: ReactNode;
  children?: ReactNode;
  kind?: 'header' | 'subheader';
};

export function Row({ className, group, total, bound, remainder, children, kind }: RowProps): ReactElement {
  const C = kind == 'header' ? 'th' : 'td';
  return (
    <tr className={cx('f__r', kind && `f__r--${kind}`, className)}>
      <C className="f__c--group">{group}</C>
      <C className="f__c--total">{total}</C>
      <C className="f__c--bound">{bound}</C>
      <C className="f__c--remainder">{remainder}</C>
      {children}
    </tr>
  );
}

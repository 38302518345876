import { createConfirmDialog, EntityAction } from 'components';
import { parseISO } from 'date-fns';
import type { CampaignPeriod } from 'modules/campaign/campaign/types';
import { cancelBlockOptions, MaybeUpdateBlockDialog, MotivationDialog } from '../dialogs';
import type { BlockDetails } from '../types';
import permissions from 'modules/campaign/block/permissions';
import { checkPerm } from 'core/permissions';
import { AuthState } from 'services';
import { openModal } from '@ff-it/ui';

export function useManagerActions(campaignPeriod: CampaignPeriod): EntityAction<BlockDetails>[] {
  return [
    {
      action: 'assign',
      button: {
        variant: 'primary',
        children: 'Assign plan',
        icon: ['far', 'paper-plane'],
      },
      successMessage: 'Plan has been assigned.',
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}assign/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state === 'NEW',
      permKey: null, // we look at role
    },
    {
      action: 'approve',
      button: {
        variant: 'success',
        children: 'Approve plan',
        icon: 'thumbs-up',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}approve/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      successMessage: 'Plan has been approved.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state == 'SUBMITTED',
      permKey: null, // we look at role
    },
    ({ item: { sums } }) => {
      const has_remainder = !sums?.all_bound;

      return {
        action: 'done',
        button: {
          children: 'Mark done',
          icon: 'check',
          variant: 'outline-success',
          disabled: has_remainder,
          title: has_remainder ? 'Invalid bound state' : undefined,
        },
        createRequest: ({ item, endpoint }) => ({
          url: `${endpoint}done/`,
          method: 'POST',
          headers: {
            'If-Match': item.etag,
          },
        }),
        dialog: createConfirmDialog(),
        successMessage: 'Plan has been marked as done.',
        visible: ({ state, role: { manage }, date_to }: BlockDetails) =>
          manage && state == 'APPROVED' && parseISO(date_to) < new Date(),
        permKey: null, // we look at role
      };
    },
    ({ item }) => ({
      action: 'reject',
      button: {
        children: 'Reject plan',
        icon: 'thumbs-down',
        variant: 'outline-danger',
      },
      lock: false,
      promise: () =>
        openModal(
          (props) => <MaybeUpdateBlockDialog {...props} block={item} action="reject" campaignPeriod={campaignPeriod} />,
          {
            canDismiss: false,
          },
        ),
      successMessage: 'Block has been rejected',
      visible: ({ state, role: { manage } }) => manage && state === 'SUBMITTED',
      permKey: null, // we look at role
    }),
    ({ item }) => ({
      action: 'replan',
      button: {
        children: 'Replan',
        icon: 'rotate-left',
        variant: 'outline-danger',
      },
      lock: false,
      promise: () =>
        openModal(
          (props) => <MaybeUpdateBlockDialog {...props} block={item} action="replan" campaignPeriod={campaignPeriod} />,
          {
            canDismiss: false,
          },
        ),
      successMessage: 'Block has been returned to planning',
      visible: ({ state, role: { manage } }) => manage && state === 'APPROVED',
      permKey: null, // we look at role
    }),
    {
      action: 'cancel',
      button: {
        children: 'Cancel',
        variant: 'outline-danger',
        icon: 'ban',
      },
      lock: false,
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}cancel/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      requestDialog: () =>
        openModal((props) => <MotivationDialog {...props} label="Motivation to cancel" options={cancelBlockOptions} />),
      successMessage: 'Plan has been canceled.',
      visible: ({ state, role: { manage } }: BlockDetails) => manage && state !== 'CANCELED',
      permKey: null, // we look at role
    },
    {
      action: 'undo',
      button: {
        children: 'Not done',
        variant: 'outline-danger',
        icon: 'triangle-exclamation',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}undo/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      successMessage: 'Plan has been marked as not done.',
      visible: ({ state }: BlockDetails) => state === 'DONE',
      permKey: (sess: AuthState): boolean => checkPerm(sess, permissions.undo),
    },
  ];
}

import { useField } from '@ff-it/form';
import { Alert } from '@ff-it/ui';
import { ReactElement } from 'react';

const subscription = {
  submitError: true,
  dirtySinceLastSubmit: true,
  error: true,
  dirty: true,
  touched: true,
};

export function FormInlineError({ name }: { name: string }): ReactElement | null {
  const {
    meta: { error, touched, submitError, dirtySinceLastSubmit },
  } = useField(name, { subscription });

  const sync = error && touched ? error : undefined;
  const submit = submitError && !dirtySinceLastSubmit ? submitError : undefined;
  const err = sync || submit;

  /*
      // Direct field errors
      name: ["This field is required."]
    */
  if (err && typeof err[0] === 'string') {
    return <Alert variant="danger">{err}</Alert>;
  }

  return null;
}

export function FormInlineMemberError({ name }: { name: string }): ReactElement | null {
  const {
    meta: { error, touched, submitError, dirtySinceLastSubmit },
  } = useField(name, { subscription });

  const sync = error && touched ? error : undefined;
  const submit = submitError && !dirtySinceLastSubmit ? submitError : undefined;
  const err = sync || submit;

  if (err && err.non_field_errors) {
    return <Alert variant="danger">{err.non_field_errors}</Alert>;
  }

  return null;
}

import { useField, useOnFieldChange } from '@ff-it/form';
import { useFieldArray } from 'react-final-form-arrays';
import useSWR from 'swr';
import type { InvoiceType } from 'modules/invoicing/common/types';
import type { EmbeddedCompany } from 'types';
import type { LinkCandidate } from 'modules/applicator/types';
import type { InvoiceRow } from 'modules/invoicing/income/types';
import { createCompensationRow } from './createCompensationRow';

function useCandidates(type: InvoiceType | null, payer: EmbeddedCompany | null, exclude?: number): LinkCandidate[] {
  const valid = type === 'INVOICE' && payer !== null;

  const { data } = useSWR<LinkCandidate[]>(
    valid
      ? {
          method: 'GET',
          url: `applicator/candidates/${payer.id}/credit/`,
          queryParams: {
            exclude,
          },
        }
      : null,
  );
  if (!valid || !data) {
    return [];
  }
  return data;
}

export function useFormController(): {
  candidates: LinkCandidate[];
  createRow: (item: LinkCandidate) => void;
} | null {
  const {
    input: { value: id },
  } = useField('id', { subscription: { value: true } });

  const {
    fields: { value: rowsValue, push, remove },
  } = useFieldArray('rows', {
    subscription: {
      value: true,
    },
  });
  const rows: InvoiceRow[] = rowsValue || [];

  const truncateImplicitCompensations = (): void => {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.compensated_to !== null) {
        remove(i);
      }
    }
  };

  const type = useOnFieldChange<InvoiceType | null>('type', truncateImplicitCompensations, true, true);
  const payer = useOnFieldChange<EmbeddedCompany | null>('payer', truncateImplicitCompensations, true, true);

  const appliedIds = rows.reduce((acc, i) => {
    if (i.kind === 'COMPENSATION' && i.compensated_to) {
      acc.push(i.compensated_to.destination.id);
    }
    return acc;
  }, [] as number[]);

  const allCandidates = useCandidates(type, payer, id ? id : undefined);

  const candidates = allCandidates.filter((c) => !appliedIds.includes(c.destination.id));
  if (candidates.length === 0) {
    return null;
  }

  const createRow = (item: LinkCandidate): void => push(createCompensationRow(item));
  return { candidates, createRow };
}

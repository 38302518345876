import { atom } from 'jotai';
import { SubmissionErrors } from 'final-form';
import { applyReducer, Operation, deepClone } from 'fast-json-patch';
import { RequestFailure } from '@ff-it/api';
import { getDRFFormError } from '@ff-it/form';
import { actionErrorAndThrow, actionErrorOrThrow, reselectAtom } from 'utilities';
import { requestHandlerAtom } from '../../../atoms';
import { planAtom } from './plan';
import type { PlanFactor } from 'modules/campaign/block/types';
import type { DepartmentFactor } from 'modules/supplier/factors';
import type { Department } from 'modules/supplier/department/types';

function _formFailure(result: RequestFailure<unknown>): SubmissionErrors | void {
  const formError = getDRFFormError(result);
  if (formError) {
    return formError;
  }
  actionErrorOrThrow(result);
}

export const createFactorAtom = atom(null, async (get, set, payload: any): Promise<SubmissionErrors | void> => {
  const api = get(requestHandlerAtom);

  const result = await api<Operation[]>({
    method: 'POST',
    url: `plan/factors/`,
    body: payload,
  });

  if (result.ok) {
    set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));
  } else {
    return _formFailure(result);
  }
});

export const createPlanFactorFromDepartmentFactorAtom = atom(
  null,
  async (get, set, { id: _, ...payload }: DepartmentFactor & { department: Department }): Promise<PlanFactor> => {
    const api = get(requestHandlerAtom);

    const result = await api<Operation[]>({
      method: 'POST',
      url: `plan/factors/`,
      body: payload,
    });

    if (result.ok) {
      set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));
      // FIXME: max id for last row
      return get(planAtom).factors.reduce((prev, current) => {
        return prev.id > current.id ? prev : current;
      });
    }
    actionErrorAndThrow(result);
  },
);

export const updateFactorAtom = atom(
  null,
  async (get, set, id: number, payload: any): Promise<SubmissionErrors | void> => {
    const api = get(requestHandlerAtom);

    const result = await api<Operation[]>({
      method: 'PUT',
      url: `plan/factors/${id}/`,
      body: payload,
    });

    if (result.ok) {
      set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));
    } else {
      return _formFailure(result);
    }
  },
);

export const removeFactorAtom = atom(null, async (get, set, id: number): Promise<void> => {
  const api = get(requestHandlerAtom);

  const result = await api<Operation[]>({
    method: 'DELETE',
    url: `plan/factors/${id}/`,
  });

  if (result.ok) {
    set(planAtom, (current) => result.data.reduce(applyReducer, deepClone(current)));
  } else {
    actionErrorAndThrow(result);
  }
});

export const managerOpenAtom = atom<boolean>(false);
export const planFactorsAtom = reselectAtom((get) => get(planAtom).factors);

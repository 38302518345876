import { ReactElement, useMemo } from 'react';
import createDecorator from 'final-form-calculate';
import { DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { FinalForm, RadioGroupField, Submit } from '@ff-it/form';
import { Linkable } from '../types';
import type { Options } from 'options';
import type { CalcType, FormState } from './types';
import { Fields } from './Fields';
import { parseBig } from 'utilities';

const calcOptions: Options<CalcType> = [
  { value: 'LINKED', label: 'Linked' },
  { value: 'TARGET', label: 'Target' },
];

function initialValues({ bound, target, rate, invoiced, invoiceable }: Linkable): FormState {
  const balance = invoiceable.minus(invoiced);
  return {
    _calc: 'LINKED',
    target,
    rate,
    invoiceable: invoiceable.toFixed(),
    invoiced: invoiced.toFixed(),
    balance: balance.toFixed(),
    bound: bound?.toFixed() || '0',
  };
}

type DialogProps = RenderProps<string> & {
  row: Linkable;
};

export function ManualOverride({ onSubmit, row }: DialogProps): ReactElement {
  const calculator = useMemo(
    () =>
      createDecorator({
        field: /_calc|target|rate|bound/,
        updates: (_value, _name, values): any => {
          if (values) {
            const { _calc, bound, target } = values as FormState;
            if (_calc == 'LINKED') {
              return {
                _calc: 'LINKED',
                target: row.target,
                rate: row.rate,
                invoiceable: row.invoiceable.toFixed(),
                invoiced: row.invoiced.toFixed(),
                balance: row.invoiceable.minus(row.invoiced).minus(parseBig(bound)).toFixed(),
                bound,
              };
            } else if (_calc == 'TARGET') {
              const invoiceable = parseBig(target).mul(parseBig(row.rate).div(100)).round(6, 1);
              const bound = invoiceable.minus(row.invoiced).toFixed();
              return {
                _calc: 'TARGET',
                target: target,
                rate: row.rate,
                invoiceable: invoiceable.toFixed(),
                invoiced: row.invoiced.toFixed(),
                balance: '0',
                bound,
              };
            }
          }
          return values;
        },
      }),
    [row],
  );

  return (
    <FinalForm<FormState>
      noValidate
      onSubmit={({ bound }) => onSubmit(bound)}
      initialValues={initialValues(row)}
      subscription={{}}
      decorators={[calculator as any]}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <DialogHeader title="Manual override" />
          <DialogBody>
            <RadioGroupField name="_calc" options={calcOptions} inline className="mr-auto" />
            <Fields />
          </DialogBody>
          <DialogFooter>
            <Submit>Update</Submit>
            <DialogClose className="ml-auto" />
          </DialogFooter>
        </form>
      )}
    </FinalForm>
  );
}

import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import { Progress } from '@ff-it/ui';
import type { Guarantee } from 'modules/guarantee/types';
import { dateTo } from 'components/columns';
import { sepFormat } from 'utilities';

function progressVariant(progress: number, value: number): 'danger' | 'warning' | 'success' {
  if (value > progress) {
    return 'success';
  }
  if (progress - value >= 10) {
    return 'danger';
  }
  return 'warning';
}

export const columns: Column<Guarantee>[] = [
  {
    content: 'Number',
    key: 'number',
    width: 90,
    render: ({ id, number }) => <Link to={`${id}`}>{number}</Link>,
  },
  {
    content: 'Title',
    key: 'title',
  },
  {
    content: 'Type',
    key: 'type',
  },
  // dateFrom(),
  dateTo(),
  {
    content: 'Time left',
    key: 'execution.time_left',
    className: 'text-right',
    headerClassName: 'text-right',
    sortable: true,
    render: ({ execution: { time_left } }: any) => `${time_left}%`,
  },
  {
    content: 'Target, €',
    className: 'text-right',
    headerClassName: 'text-right',
    key: 'execution.target',
    render: ({ execution: { target } }) => sepFormat(target),
    sortable: true,
  },
  {
    content: 'Planned, €',
    key: 'execution.planned',
    render: ({ execution: { planned } }) => sepFormat(planned),
    className: 'text-right',
    headerClassName: 'text-right',
    sortable: true,
  },
  {
    content: 'Approved',
    key: 'execution.approved_progress',
    width: 140,
    sortable: true,
    render: ({ execution: { time_progress, approved_progress } }: Guarantee) =>
      approved_progress ? (
        <Progress
          variant={progressVariant(parseFloat(time_progress), parseFloat(approved_progress))}
          value={parseFloat(approved_progress)}
        />
      ) : null,
  },
  {
    content: 'Approved,  €',
    key: 'execution.approved',
    className: 'text-right',
    headerClassName: 'text-right',
    sortable: true,
    render: ({ execution: { approved } }: Guarantee) => sepFormat(approved),
  },
];

import { ComponentType, ReactElement, useMemo } from 'react';
import { DrfPageHandler, Column } from '@ff-it/grid';
import { timestamp } from 'components/columns';
import { Entry } from './types';
import { IndexContainer } from 'components';

interface LogProps<T> {
  url: string;
  Description: ComponentType<T>;
  className?: string;
}
export function Log<T extends Entry<any, any>>({ url, Description, className }: LogProps<T>): ReactElement {
  const columns: Column<T>[] = useMemo(
    () => [
      { content: 'User', key: 'actor_repr', width: 200 },
      { content: 'Action', key: 'action', width: 200 },
      {
        content: 'Description',
        key: 'context',
        render: (entry: T) => <Description {...entry} />,
      },
      // FIXME: what is going on in here?
      timestamp<T>('Time', 'created_at' as any, { sortable: true }),
    ],
    [url, Description],
  );

  return (
    <IndexContainer
      url={url}
      columns={columns}
      pageHandler={DrfPageHandler}
      breadCrumb="History log"
      className={className}
    ></IndexContainer>
  );
}

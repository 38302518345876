import { ReactElement } from 'react';
import { ListFilter } from 'components';
import { TextField, SelectField, DateRangePickerField, useOnFieldChange } from '@ff-it/form';
import { useForm } from 'react-final-form';
import { createOptions } from 'options';

const periodFilter = ['ONGOING', 'ENDED', 'CUSTOM'] as const;

const periodOptions = createOptions(periodFilter, {
  ONGOING: 'Ongoing',
  ENDED: 'Ended',
  CUSTOM: 'Custom',
});

function PeriodPicker(): ReactElement | null {
  const form = useForm();
  const period = useOnFieldChange('period', (value) => {
    if (value !== 'CUSTOM' && form.getState().values.date_from) {
      form.batch(() => {
        form.change('date_from', null);
        form.change('date_to', null);
      });
    }
  });

  if (period === 'CUSTOM') {
    return <DateRangePickerField fromName="date_from" toName="date_to" className="ml-1" />;
  }
  return null;
}

export function Filter(): ReactElement {
  return (
    <ListFilter>
      <TextField name="search" placeholder="Search.." />
      <SelectField name="period" placeholder="All" options={periodOptions} isClearable simple className="ml-1" />
      <PeriodPicker />
    </ListFilter>
  );
}

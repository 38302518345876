import type { ReactElement } from 'react';
import { Icon } from 'components';
import type { LinkCandidate } from 'modules/applicator/types';
import { Button, openModal } from '@ff-it/ui';
import { ApplicationCandidateDialog } from './ApplicationCandidateDialog';
import { useFormController } from './useFormController';

export function ImplicitCompensation(): ReactElement | null {
  const controller = useFormController();

  if (controller) {
    return (
      <Button
        testId="add-compensation"
        className="ml-2"
        variant="outline-secondary"
        size="sm"
        title="Compensation from link remainder"
        onClick={() => {
          openModal<LinkCandidate>((props) => (
            <ApplicationCandidateDialog canidates={controller.candidates} {...props} />
          )).then((item) => {
            item && controller.createRow(item);
          });
        }}
      >
        <Icon icon={['far', 'file-invoice-dollar']} /> Compensation
      </Button>
    );
  }
  return null;
}

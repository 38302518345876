import { ReactElement } from 'react';
import { PopupCell } from '../../../Editor';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { Selector } from './Selector';
import { getOptionValue, renderValues } from './util';
import { Editor } from './Editor';
import { useEditableChoiceProps } from '../useEditableChoiceProps';
import { fieldIsEditable, useFieldUpdater } from '../../../../hooks';
import type { Row } from 'modules/campaign/row';

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.format;
  const options = props.row.config.format?.resolved_value as any;
  const update = useFieldUpdater(props.row.id, 'format', false);

  const { edit, select } = useEditableChoiceProps(options, value, update, getOptionValue);

  return (
    <PopupCell {...props} cellContent={renderValues(value, false)} onRequestClose={props.stopEdit}>
      {/* hide if no options */}
      {select.options.length > 0 && <Selector {...select} />}
      <Editor {...edit} />
    </PopupCell>
  );
}

export const format: GridColumn<Row> = {
  key: 'format',
  header: 'Format',
  width: 116,
  renderCell: (props) =>
    props.row.kind === 'MEDIA' ? (
      props.editing ? (
        <Edit {...props} />
      ) : (
        renderValues(props.row.format, !props.isEditable)
      )
    ) : null,
  editable: (row) => fieldIsEditable(row, 'format'),
};

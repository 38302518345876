import { Column } from '@ff-it/grid';
import { bool } from 'components/columns';
import { createSceneRoute, ListFilter } from 'components';
import { Activity } from 'types';
import { departmentKindOptions } from 'options';
import { TextField, SelectField } from '@ff-it/form';
import permissions from 'modules/core/activity/permissions';
import { Link } from 'react-router-dom';
import { Form } from './Form';
import { ListExport } from 'components/ListActions';

const columns: Column<Activity>[] = [
  {
    content: 'Kind',
    key: 'kind',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    width: undefined,
    sortable: true,
    render: ({ id, name }: Activity) => <Link to={`${id}`}>{name}</Link>,
  },
  bool('Non standard', 'non_standard', { sortable: true }),
  bool('Is fee', 'is_fee', { sortable: true }),
];

const initialValues = {
  name: '',
  kind: null,
  non_standard: false,
  is_fee: false,
};

export const route = createSceneRoute('activities', {
  endpoint: 'core/activities/',
  permissions,
  title: 'Activity',
  pluralTitle: 'Activities',
  entityTitle: ({ name }) => name,
  index: {
    columns,
    toolbar: <ListExport permission={permissions.export} />,
    filter: (
      <ListFilter>
        <TextField name="search" placeholder="Search..." size="sm" />
        <SelectField name="kind" placeholder="Kind" size="sm" className="ml-1" options={departmentKindOptions} simple />
      </ListFilter>
    ),
  },
  update: {
    viewDisabled: true,
  },
  form: <Form />,
  updateForm: <Form update />,
  initialValues,
});

import { atom, Atom } from 'jotai';
import { loadable } from 'jotai/utils';
import { blockContextAtom, planningConfigAtom } from './_private';
import { etagAtom, endpointAtom } from './api';
import { BlockSums } from '../types';
import { api } from 'services';

export const blockAtom = atom((get) => {
  const ctx = get(blockContextAtom);
  return ctx.item;
});

export const blockTypeAtom = atom((get) => get(blockAtom).type);

export const planConfigAtom = atom((get) => get(planningConfigAtom)[get(blockTypeAtom)]);

export const blockStateAtom = atom((get) => get(blockAtom).state);
export const blockScopeAtom = atom((get) => get(blockAtom).scope);

export const blockIdAtom = atom((get) => get(blockAtom).id);

export const fetchBlockSumsAtom = loadable(
  atom<Promise<BlockSums | null>>(async (get) => {
    const etag = get(etagAtom);
    const endpoint = get(endpointAtom);

    // should we bail for canceled block (no sums)?
    const res = await api.request<BlockSums | null, unknown>({
      method: 'GET',
      url: `${endpoint}sums/`,
      headers: {
        'If-Match': etag,
      },
    });

    if (res.ok) {
      return res.data;
    }
    throw res.error;
  }),
);

// FIXME: debounce?
export const blockSumsAtom: Atom<BlockSums | null> & {
  init?: null;
} = atom((get) => {
  const pending = get(fetchBlockSumsAtom);
  if (pending.state == 'hasData') {
    return pending.data;
  }
  return get(blockSumsAtom);
});

// HACK to read atom before initialization
blockSumsAtom.init = null;

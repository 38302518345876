import { ReactElement } from 'react';
import { ListFilter, UserField, BoundStateFilterField, ActivityField, AgencyActivityTypesField } from 'components';
import { DatePickerField, SelectField, TextField } from '@ff-it/form';
import { campaignStateOptions, blockStateOptions } from 'configuration';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';

interface FilterProps {
  scope: 'campaign' | 'block';
}

const viewOptions = [
  { label: 'Managed', value: 'MANAGED' },
  { label: 'Planned', value: 'PLANNED' },
  { label: 'Managed OR Planned', value: 'MANAGED_OR_PLANNED' },
];

export function Filter({ scope }: FilterProps): ReactElement {
  return (
    <ListFilter className="align-items-start">
      <div className="flex-column">
        <div className="d-flex flex-row ">
          <TextField name="search" placeholder="Search.." size="sm" />
          <CompanyField name="related_client" placeholder="Client" className="ml-1" simple size="sm" />
          {scope === 'campaign' ? (
            <SelectField
              name="sums__state"
              placeholder="State"
              options={campaignStateOptions}
              className="ml-1"
              simple
              isMulti
              size="sm"
            />
          ) : (
            <SelectField
              name="state"
              placeholder="State"
              options={blockStateOptions}
              className="ml-1"
              simple
              isMulti
              size="sm"
            />
          )}
          <DatePickerField name="date_from" placeholder="Date from" className="ml-1" size="sm" />
          <DatePickerField name="date_to" placeholder="Date to" className="ml-1" size="sm" />
          <SelectField
            placeholder="Visible"
            name="view"
            className="ml-1"
            size="sm"
            simple
            options={viewOptions}
            isClearable={true}
          />
        </div>
        <div className="d-flex flex-row">
          <AgencyActivityTypesField
            name="type"
            placeholder="Type"
            // className="ml-1"
            size="sm"
          />
          <UserField
            name="manager"
            placeholder="Manager"
            className="ml-1"
            size="sm"
            simple
            filter={{ manage_campaign: true }}
          />
          <UserField
            name="planner"
            placeholder="Planner"
            className="ml-1"
            size="sm"
            simple
            filter={{ plan_campaign_block: true }}
          />
          <BoundStateFilterField
            name="sums__income_state"
            placeholder="Income"
            isSearchable={false}
            className="ml-1"
            simple
            size="sm"
          />
          <BoundStateFilterField
            name="sums__expense_state"
            placeholder="Expense"
            isSearchable={false}
            className="ml-1"
            simple
            size="sm"
          />
        </div>
      </div>
    </ListFilter>
  );
}

export function RowFilter(): ReactElement {
  return (
    <ListFilter className="align-items-start">
      <div className="flex-column">
        <div className="d-flex flex-row ">
          <TextField name="search" placeholder="Search.." size="sm" />
          <CompanyField name="related_client" placeholder="Client" className="ml-1" simple size="sm" />
          <ActivityField name="activity" placeholder="Activity" className="ml-1" simple size="sm" />
          <DepartmentField name="product__department" placeholder="Department" className="ml-1" simple size="sm" />
          <CompanyField name="provider" placeholder="Provider" className="ml-1" simple size="sm" />
          <SelectField
            name="block__state"
            placeholder="Block state"
            options={blockStateOptions}
            className="ml-1"
            simple
            isMulti
            size="sm"
          />
          <DatePickerField name="date_from" placeholder="Date from" className="ml-1" size="sm" />
          <DatePickerField name="date_to" placeholder="Date to" className="ml-1" size="sm" />
        </div>
        <div className="d-flex flex-row">
          <AgencyActivityTypesField name="block__type" placeholder="Type" size="sm" />
          <UserField
            name="manager"
            placeholder="Manager"
            className="ml-1"
            size="sm"
            simple
            filter={{ manage_campaign: true }}
          />
          <UserField
            name="planner"
            placeholder="Planner"
            className="ml-1"
            size="sm"
            simple
            filter={{ plan_campaign_block: true }}
          />
          <BoundStateFilterField
            name="income_state"
            placeholder="Income"
            isSearchable={false}
            className="ml-1"
            simple
            size="sm"
          />
          <BoundStateFilterField
            name="expense_state"
            placeholder="Expense"
            isSearchable={false}
            className="ml-1"
            simple
            size="sm"
          />
        </div>
      </div>
    </ListFilter>
  );
}

import { ReactElement } from 'react';
import { TicketFactory } from 'modules/support/TicketFactory';
import initialValues from './initialValues';
import { Form } from 'modules/client/agreement/Form';
import { Company } from 'types';

interface FromTicketProps {
  client: Company;
}

export function FromTicket({ client }: FromTicketProps): ReactElement {
  return (
    <TicketFactory
      category="ADD_CLIENT"
      resolveContext={({ data: { end_client, invoice_payment_days, terms, smart_rates, rates }, attachments }) => [
        {
          ...initialValues,
          client,
          end_client,
          invoice_payment_days,
          terms,
          attachments,
          smart_rates,
          rates,
        },
        null,
      ]}
    >
      <Form />
    </TicketFactory>
  );
}

import { useReslectAtom } from 'utilities';
import { planFactorsAtom } from '../../../../atoms/factors';
import { useCallback } from 'react';
import { useRequest } from '@ff-it/api';
import type { Row } from 'modules/campaign/row';
import { PlanFactor } from 'modules/campaign/block/types';
import { DepartmentFactor } from 'modules/supplier/factors';
import type { EmbeddedProduct } from 'modules/supplier/products';

export function usePlanFactors(row: Row): PlanFactor[] {
  return useReslectAtom(
    planFactorsAtom,
    useCallback(
      (allFactors) => {
        // matches department
        const whiteList = new Set([
          row.supplier?.product.department.id || 0,
          // or already used (FIXME: this should never happen)
          ...row.factors.client.factors,
          ...row.factors.provider.factors,
        ]);
        return allFactors.filter((f) => whiteList.has(f.department.id));
      },
      [row],
    ),
  );
}

export function useDepartmentFactors(product: EmbeddedProduct): DepartmentFactor[] {
  return (
    useRequest<DepartmentFactor[], unknown>({
      url: `supplier/${product.kind}/${product.department.id}/factors/`,
      method: 'GET',
      queryParams: {
        type: product.type,
      },
    }).data || []
  );
}

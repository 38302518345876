import { Fragment, ReactElement, ReactNode } from 'react';
import { Dialog, DialogBody, DialogClose, DialogContent, DialogFooter, DialogHeader } from '@ff-it/ui';
import { Form as FormHandler, Submit, getDRFFormError } from '@ff-it/form';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fieldIsEditable, useFieldUpdater } from '../../../../hooks';
import type { Row } from 'modules/campaign/row';
import { RowFactors } from 'modules/campaign/row';
import { Form } from './Form';
import { actionErrorOrThrow } from 'utilities';
import type { PlanFactor } from 'modules/campaign/block/types';
import { FactorLabel } from './FactorLabel';
import { useDepartmentFactors, usePlanFactors } from './useFactors';
import invariant from 'tiny-invariant';
import { useSetAtom } from 'jotai';
import { createPlanFactorFromDepartmentFactorAtom } from '../../../../atoms/factors';
import { DepartmentFactor } from 'modules/supplier/factors';

function renderDiscounts(discounts: string[] | null, currentDiscount: string | null, isEditable: boolean): ReactNode {
  if (!discounts && currentDiscount) {
    return <span className="contain contain--placeholder">{`${currentDiscount}%`}</span>;
  }

  return (
    <span className="contain contain--triple">
      {discounts ? discounts.map((value, idx) => <div key={idx}>{value}%</div>) : !isEditable ? '—' : ''}
    </span>
  );
}

function View({
  row,
  isEditable,
  direction,
  planFactors,
}: RenderCellProps<Row> & {
  direction: keyof RowFactors;
  planFactors: PlanFactor[];
}): ReactElement {
  const value = row.factors[direction];
  const currentDiscount = direction === 'client' ? row.sums.client_discount_rate : row.sums.provider_discount_rate;

  const factorRates = value.factors.map((factorId) => (planFactors.find((v) => v.id === factorId) as PlanFactor).rate);

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="contain">
        {factorRates.map((rate, idx) => (
          <Fragment key={idx}>
            <FactorLabel rate={rate} direction={direction} />{' '}
          </Fragment>
        ))}
      </div>
      <div className="text-right ml-auto">{renderDiscounts(value.discounts, currentDiscount, isEditable)}</div>
    </div>
  );
}

function Edit({ row, stopEdit, planFactors }: RenderCellProps<Row> & { planFactors: PlanFactor[] }): ReactElement {
  const updater = useFieldUpdater(row.id, 'factors', false);
  invariant(row.supplier);
  const department = row.supplier.product.department;

  const createFactor = useSetAtom(createPlanFactorFromDepartmentFactorAtom);
  const planFactorKeys = planFactors.map(({ name }) => name.toLowerCase().trim());
  // filter local
  const departmentFactors = useDepartmentFactors(row.supplier.product).filter(
    (d) => !planFactorKeys.includes(d.name.toLowerCase().trim()),
  );

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        !open && stopEdit();
      }}
    >
      <DialogContent testId="fator-edit">
        <DialogHeader title="Row discounts & factors" />
        <FormHandler
          initialValues={row.factors}
          onSubmit={async ({
            client,
            provider,
          }: {
            client: {
              discounts: null | number[];
              factors: (number | DepartmentFactor)[];
            };
            provider: {
              discounts: null | number[];
              factors: (number | DepartmentFactor)[];
            };
          }) => {
            // FIXME: we create plan factors from departemnt factors
            const createdFactors: Record<number, number> = {};
            for (let i = 0; i < client.factors.length; i++) {
              const factor = client.factors[i];
              if (typeof factor !== 'number') {
                if (!createdFactors[factor.id]) {
                  createdFactors[factor.id] = (
                    await createFactor({
                      ...factor,
                      department,
                    })
                  ).id;
                }
                client.factors[i] = createdFactors[factor.id];
              }
            }

            for (let i = 0; i < provider.factors.length; i++) {
              const factor = provider.factors[i];
              if (typeof factor !== 'number') {
                if (!createdFactors[factor.id]) {
                  createdFactors[factor.id] = (
                    await createFactor({
                      ...factor,
                      department,
                    })
                  ).id;
                }
                provider.factors[i] = createdFactors[factor.id];
              }
            }

            // FIXME: no null in discount set
            const values = {
              client: {
                factors: client.factors,
                discounts: client.discounts ? client.discounts.filter((v: any) => v !== null) : null,
              },
              provider: {
                factors: provider.factors,
                discounts: provider.discounts ? provider.discounts.filter((v: any) => v !== null) : null,
              },
            };
            const res = await updater(values);
            if (!res.ok) {
              const formError = getDRFFormError(res);
              if (formError) {
                return formError;
              }
              actionErrorOrThrow(res);
            }
          }}
        >
          <DialogBody>
            <Form planFactors={planFactors} departmentFactors={departmentFactors} />
          </DialogBody>
          <DialogFooter>
            <Submit>Update</Submit>
            <DialogClose className="ml-auto" />
          </DialogFooter>
        </FormHandler>
      </DialogContent>
    </Dialog>
  );
}

function Factors(
  props: RenderCellProps<Row> & {
    direction: keyof RowFactors;
  },
): React.ReactElement {
  const planFactors = usePlanFactors(props.row);

  return (
    <>
      <View {...props} planFactors={planFactors} />
      {props.editing && <Edit {...props} planFactors={planFactors} />}
    </>
  );
}

export const client_factors: GridColumn<Row> = {
  key: 'client_factors',
  header: 'Client disc. & factors',
  minWidth: 116,
  renderCell: (props) => (props.row.kind === 'SMART' ? null : <Factors {...props} direction="client" />),
  editable: (row) => fieldIsEditable(row, 'factors'),
};

export const provider_factors: GridColumn<Row> = {
  key: 'provider_factors',
  header: 'Provider disc. & factors',
  minWidth: 116,
  renderCell: (props) => (props.row.kind === 'SMART' ? null : <Factors {...props} direction="provider" />),
  editable: (row) => fieldIsEditable(row, 'factors'),
};

import { ReactElement } from 'react';
import { EmbeddedCompany } from 'types';
import { Column } from '@ff-it/grid';
import { BoundState, Breadcrumb, CompanyWidget, DatePartPickerField, UserField } from 'components';
import { usePeriod } from 'modules/reporting/usePeriod';
import { initialFilter, RowType, startColumns, endColumns, boundStateOptions } from './common';
import { SelectField } from '@ff-it/form';
import { CompanyField } from 'modules/core/company/components';
import { ReportTable } from 'components/ReportTable';

type ExpenseRowType = RowType & {
  provider: EmbeddedCompany;
};

const columns: Column<ExpenseRowType>[] = [
  ...startColumns,
  {
    key: 'provider',
    content: 'Provider',
    render: ({ provider }) => <CompanyWidget {...provider} />,
    className: 'align-middle',
  },
  ...endColumns,
  {
    key: 'state',
    width: 30,
    className: 'align-middle text-right',
    render: ({ state, is_overspent }) => <BoundState variant="expense" state={state} is_overspent={is_overspent} />,
  },
];

export function ExpenseLinks(): ReactElement {
  const { date_from: minDate, date_to: maxDate } = usePeriod();
  return (
    <ReportTable
      url="invoicing/links/expense"
      columns={columns}
      initialFilter={initialFilter}
      filterFields={
        <>
          <Breadcrumb>Expense</Breadcrumb>
          <UserField
            name="manager"
            placeholder="Manager"
            className="ml-1"
            size="sm"
            simple
            filter={{ manage_campaign: true }}
          />
          <CompanyField name="provider" placeholder="Provider" className="ml-2" size="sm" simple />
          <SelectField name="state" placeholder="State" options={boundStateOptions} className="ml-2" size="sm" simple />
          <DatePartPickerField part="month" name="month" className="ml-2" minDate={minDate} maxDate={maxDate} />
        </>
      }
    />
  );
}

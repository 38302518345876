import { ActionMeta } from 'react-select';
import { getDRFFormError, SubmissionErrors } from '@ff-it/form';
import { actionErrorOrThrow, maybeActionErrorOrThrow } from 'utilities';
import { RowFieldUpdater } from '../../../hooks';

export interface SelectProps<T> {
  options: T[];
  value: T[];
  onChange: (v: any, action: ActionMeta<any>) => Promise<void>;
}

export interface EditProps<T> {
  value: T[];
  updateValue: (index: number, value: T) => Promise<SubmissionErrors | void>;
  removeValue: (index: number) => Promise<void>;
  addValue: (v: T) => Promise<SubmissionErrors | void>;
  createOnEmptyOpen: boolean;
}

export function useEditableChoiceProps<T>(
  availableOptions: T[] | null,
  providedValue: T[] | null,
  update: RowFieldUpdater,
  getOptionValue: (v: null | T) => string | null,
): {
  select: SelectProps<T>;
  edit: EditProps<T>;
} {
  const value = providedValue || [];

  const selectOptions: T[] = availableOptions || [];
  const selectValue: T[] = [];
  const editValue: T[] = [];

  value.forEach((v) => {
    if (selectOptions.find((opt) => getOptionValue(opt) === getOptionValue(v))) {
      // selectable
      selectValue.push(v);
    } else {
      editValue.push(v);
      //editable
    }
  });

  const removeValue = async (editIndex: number): Promise<void> => {
    const editKey = getOptionValue(editValue[editIndex]);
    const itemIndex = value.findIndex((v) => getOptionValue(v) === editKey);

    await update(undefined, itemIndex, 'DELETE').then(maybeActionErrorOrThrow);
  };

  const addValue = async (payload: T): Promise<SubmissionErrors | void> => {
    const res = await update(payload as any, undefined, 'POST');
    if (!res.ok) {
      const formError = getDRFFormError(res);
      if (formError) {
        return formError;
      }
      actionErrorOrThrow(res);
    }
  };

  const updateValue = async (editIndex: number, payload: T): Promise<SubmissionErrors | void> => {
    const editKey = getOptionValue(editValue[editIndex]);
    const itemIndex = value.findIndex((v) => getOptionValue(v) === editKey);

    const res = await update(payload as any, itemIndex, 'PUT');
    if (!res.ok) {
      const formError = getDRFFormError(res);
      if (formError) {
        return formError;
      }
      actionErrorOrThrow(res);
    }
  };

  return {
    select: {
      options: selectOptions,
      value: selectValue,
      onChange: async (v: any, action: ActionMeta<any>) => {
        switch (action.action) {
          case 'deselect-option':
            const editKey = getOptionValue(action.option);
            const itemIndex = value.findIndex((v) => getOptionValue(v) === editKey);
            await update(undefined, itemIndex, 'DELETE').then(maybeActionErrorOrThrow);
            return;
          // remove
          case 'select-option':
            await update(action.option as any, undefined, 'POST').then(maybeActionErrorOrThrow);
            return;
          // create
          default:
            throw new Error();
        }
      },
    },

    edit: {
      value: editValue,
      updateValue,
      removeValue,
      addValue,
      createOnEmptyOpen: selectOptions.length == 0,
    },
  };
}

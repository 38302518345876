import { createSelectField } from './createSelectField';
import type { Activity, ActivityType } from 'types';
import { ActivityTitle } from 'modules/core/activity';
import { activityTypeOptions, Option } from 'options';
import { SelectField, SelectFieldProps } from '@ff-it/form';
import { useAgenecyTypeOptions } from 'modules/core/useEnabledTypeOptions';
import { ReactElement } from 'react';

export const activityFieldProps = {
  getOptionLabel: (a: Activity) => a.name,
  formatOptionLabel: ActivityTitle,
  getOptionValue: (a: Activity) => a.id.toString(),
};

export const ActivityField = createSelectField<Activity>('core/activities/', activityFieldProps);

export function AllActivityTypesField(
  props: Omit<SelectFieldProps<Option<ActivityType>, any, any>, 'options' | 'simple'>,
): ReactElement {
  return <SelectField options={activityTypeOptions} simple {...props} />;
}

export function AgencyActivityTypesField(
  props: Omit<SelectFieldProps<Option<ActivityType>, any, any>, 'options' | 'simple'>,
): ReactElement {
  const options = useAgenecyTypeOptions();
  return <SelectField options={options} simple {...props} />;
}

import { useAtomValue } from 'jotai';
import { ReactElement, ReactNode } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fmt, sepFormat } from 'utilities';
import { summaryRowAtom } from '../../../../atoms/summary';
import type { Row } from 'modules/campaign/row';

function Summary(): ReactElement {
  const summary = useAtomValue(summaryRowAtom);
  return <strong>{fmt(summary?.provider_total)}</strong>;
}

export function view({
  row: {
    sums: { planned_expense, target_expense },
  },
}: RenderCellProps<Row>): ReactNode {
  return (
    <div>
      <div data-testid="planned" className={target_expense == planned_expense ? 'font-weight-bold' : undefined}>
        {sepFormat(planned_expense)}
      </div>
      {target_expense != planned_expense && (
        <div data-testid="target" className="font-weight-bold">
          {sepFormat(target_expense)}
        </div>
      )}
    </div>
  );
}

export const provider_total: GridColumn<Row> = {
  key: 'provider_total',
  header: 'Agency total',
  summary: <Summary />,
  minWidth: 96,
  className: 'text-right grd__c--agency',
  renderCell: (props: RenderCellProps<Row>) => {
    const {
      row: { direction, kind },
    } = props;

    if (kind === 'SMART' && direction !== 'AGENCY') {
      return null;
    }

    return view(props);
  },
};

import { getDRFFormError, useSubmitHandler } from '@ff-it/form';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import type { Handler, LinkerData } from './types';
import { type Dispatch, type SetStateAction } from 'react';
import { RequestFailure } from '@ff-it/api';
import type { Invoice } from 'modules/invoicing/types';
import type { Income, IncomeSums } from 'modules/invoicing/income/types';
import type { Expense, ExpenseSums } from 'modules/invoicing/expenses/types';

export function useLinker(
  endpoint: string,
  invoice: Invoice,
  setInvoice: Dispatch<SetStateAction<Income>> | Dispatch<SetStateAction<Expense>>, // @FIXME
): Handler<LinkerData> {
  // invoicing/<direction>/<pk>/link/
  const url = `${endpoint}link/`;

  const {
    data: initialValues,
    isLoading,
    error,
    mutate,
  } = useSWR<LinkerData>({
    url,
    method: 'GET',
  });

  const onSubmit = useSubmitHandler(
    { method: 'POST', url },
    {
      onSuccess: ({ sums, ...data }: LinkerData & { sums: IncomeSums | ExpenseSums }) => {
        setInvoice((i: any) => ({ ...i, sums }));
        mutate(data);
        toast.success('Links updated');
      },
      onFailure: (e: RequestFailure<any>) => {
        toast.warning('Failed to update');
        const err = getDRFFormError(e);
        if (err) {
          return err;
        }
        throw e.error;
      },
    },
  );

  if (error) {
    throw error;
  }
  return {
    disabled: invoice.linked_at !== null,
    initialValues,
    isLoading,
    onSubmit,
    url,
  };
}

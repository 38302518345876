import { useCallback, useRef, useState, useEffect } from 'react';
import { useDebounce } from '@ff-it/ui';
import { useAPI, RequestSuccess, RequestArgs } from '@ff-it/api';
import { OptionLoader, Page, RequestHandler, ResponseHandler, LoaderOptions } from './types';
import { checkActionError } from 'utilities';
export type { OptionLoader, ResponseHandler };

export const defaultRequestHandler: RequestHandler = (
  url: string,
  inputValue: string | undefined,
  filter?: Record<string, unknown>,
): RequestArgs => ({
  url,
  method: 'GET',
  queryParams: {
    search: inputValue,
    page_size: 10,
    ...filter,
  },
});

export const defaultResponseHandler: ResponseHandler = (result: RequestSuccess<Page<any>>): any[] =>
  result.data.results;

export function useOptionLoader<T, R = Page<T>>(
  url: string,
  filter?: Record<string, unknown>,
  options: LoaderOptions = {},
): OptionLoader<T> {
  const api = useAPI();
  const { requestHandler = defaultRequestHandler, responseHandler = defaultResponseHandler } = options;

  return useCallback(
    (inputValue: string | undefined, cb: any) => {
      api.request<R, unknown>(requestHandler(url, inputValue, filter)).then((res) => {
        if (res.ok) {
          cb(responseHandler(res));
        } else {
          throw res.error;
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, url, filter],
  );
}

export function useOptions<T, R = Page<T>>(
  url: string,
  filter?: Record<string, unknown>,
  options: LoaderOptions = {},
): OptionLoader<T> {
  return useDebounce(useOptionLoader<T, R>(url, filter, options), 300);
}

export function useDefaultOptions<T>(url: string | null, filter?: Record<string, unknown>, ...deps: any[]): T[] {
  const isMounted = useRef<boolean>(true);

  const [result, setResult] = useState<T[]>([]);

  const api = useAPI();

  const loadOptions = useCallback(
    async (url: string) => {
      // reset
      if (isMounted.current && result.length !== 0) {
        setResult([]);
      }
      const res = await api.get<Page<T>, unknown>(url, {
        queryParams: {
          page_size: 10,
          ...filter,
        },
      });
      if (isMounted.current) {
        if (res.ok) {
          setResult(res.data.results);
        } else {
          if (checkActionError(res)) {
            setResult([]);
          } else {
            setResult(() => {
              throw res.error;
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  useEffect(() => {
    isMounted.current = true;
    if (url) {
      loadOptions(url);
    }
    return () => {
      isMounted.current = false;
    };
  }, [loadOptions, url]);

  return result;
}

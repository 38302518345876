import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/style.scss';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { ErrorBoundary } from 'components/ErrorBoundary';

if (window.__RUNTIME_CONFIG__.SENTRY_DSN) {
  Sentry.init({
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    dsn: window.__RUNTIME_CONFIG__.SENTRY_DSN,
    // @TODO baked in?
    release: window.__RUNTIME_CONFIG__.APP_VERSION,
    environment: window.__RUNTIME_CONFIG__.AGENCY_GROUP,
  });
}

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </StrictMode>,
  );
}

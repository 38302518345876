import { ReactElement, useCallback, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { menu as items } from 'routes/Agency/menu';
import { has } from 'core/permissions';
import { Button, MenuList, MenuItem, Popover } from '@ff-it/ui';
import invariant from 'tiny-invariant';
import { Icon } from '../ui';
import { NavSubItem } from '../nav';
import { AuthState, SessionScope, useSession } from 'services';

function findItem(segments: string[]): NavSubItem | undefined {
  const path = `/${segments.join('/')}`;
  const itemPath = segments.shift();

  const item = items.find(({ to }) => to === `/${itemPath}`);

  if (item && item.children) {
    return item.children.find(({ to }) => path.startsWith(to as string));
  }
}

function useTranslateNavigation(session: AuthState): (scope: SessionScope) => void {
  /**
   * Reallu ugly hack for cross agency navigation
   */
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return useCallback(
    (newScope: SessionScope) => {
      // Agency to agency, try tralsating path
      if (newScope.kind === 'AGENCY' && session.scope?.kind === 'AGENCY') {
        const segments = pathname.replace(/^\/+/, '').split('/');
        // we assume first segment is always an enterprise slug
        segments.shift();
        if (segments.length > 1) {
          const item = findItem(segments);
          if (item) {
            if (
              has(item.visible)({
                ...session,
                scope: newScope,
              })
            ) {
              navigate(`/${newScope.slug}${item.to}`);
              return;
            }
          }
        }
      }

      navigate(`/${newScope.slug}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname, session],
  );
}

export function EnterpriseSwitcher(): ReactElement {
  const session = useSession();
  const doNavigate = useTranslateNavigation(session);
  const { scope, user, impersonation } = session;
  const currentUser = impersonation || user;
  invariant(currentUser, 'User has to be present');
  const choices: SessionScope[] = useMemo(() => {
    const res: SessionScope[] = [...(currentUser?.membership || [])];
    if (currentUser?.agency_group) {
      res.push(currentUser?.agency_group);
    }
    return res;
  }, [currentUser]);

  return (
    <Popover
      interaction="click"
      placement="bottom-start"
      offsetOptions={6}
      content={
        <MenuList>
          {choices.map((m) => (
            <MenuItem
              key={m.id}
              data-membership-id={m.id}
              active={m.id === scope?.id}
              onClick={() => {
                doNavigate(m);
              }}
              label={m.title}
              prefix={<Icon icon={m.kind === 'AGENCY_GROUP' ? 'cubes' : 'cube'} />}
            />
          ))}
        </MenuList>
      }
    >
      <Button data-membership-id={scope?.id} variant="link" className="border-0">
        {scope ? (
          <span>
            <Icon icon={scope.kind === 'AGENCY_GROUP' ? 'cubes' : 'cube'} className="mr-2" />
            {scope.title}
          </span>
        ) : (
          <span>Choose agency</span>
        )}
      </Button>
    </Popover>
  );
}

import { RouteObject } from 'react-router';
import { Entity } from 'types';
import { ModelPermissions } from './types';
import { ModelScene } from './ModelScene';
import { IndexScene } from './IndexScene';
import { Column } from '@ff-it/grid';
import { EntityScene } from './EntityScene';
import { DetailsScene } from './DetailsScene';
import { UpdateScene } from './UpdateScene';
import { DeleteScene } from './DeleteScene';
import { CreateScene } from './CreateScene';
import { EntityNav } from '../nav/EntityNav';
import { ReactNode } from 'react';

export interface SceneConfig<T extends Entity> {
  endpoint: string;
  title: ReactNode;
  pluralTitle: ReactNode;
  entityTitle: (item: T) => ReactNode;
  index: {
    columns: Column<T>[];
    // rename these?
    filter?: ReactNode;
    endpointFilter?: Record<string, unknown>;
    defaultPageSize?: number;
    toolbar?: ReactNode;
    storageKey?: string;
  };
  update?: {
    viewDisabled?: boolean;
  };
  create?: {
    successPath?: string | ((rv: any) => string);
  };
  details?: ReactNode;
  form: ReactNode;
  // @TODO to update
  updateForm?: ReactNode;
  // @TODO to create
  initialValues?: any;
  permissions?: ModelPermissions;
}

/** @deprecated use explicit routes untill we get something better */
export function createSceneRoute<T extends Entity = any>(
  path: string,
  {
    index: { filter, endpointFilter, ...index },
    update: { viewDisabled, ...update } = {},
    create,
    details,
    form,
    updateForm,
    initialValues,
    ...model
  }: SceneConfig<T>,
): RouteObject {
  const entity: RouteObject = details
    ? {
        path: ':id/',
        element: (
          <EntityScene>
            <EntityNav
              nav={[
                {
                  to: '',
                  end: true,
                  children: 'Details',
                },
                {
                  to: 'update',
                  children: 'Update',
                  perm: model.permissions?.change,
                },
              ]}
            />
          </EntityScene>
        ),
        children: [
          {
            index: true,
            element: <DetailsScene heading={null}>{details}</DetailsScene>,
          },
          {
            path: 'update',
            element: (
              <UpdateScene
                heading={null}
                createPath="../../create"
                deletePath="../delete"
                viewDisabled={viewDisabled}
                {...update}
              >
                {form}
              </UpdateScene>
            ),
          },
          {
            path: 'delete',
            element: <DeleteScene />,
          },
        ],
      }
    : {
        path: ':id/',
        element: <EntityScene />,
        children: [
          {
            index: true,
            element: <UpdateScene viewDisabled={viewDisabled}>{updateForm || form}</UpdateScene>,
          },
          {
            path: 'delete',
            element: <DeleteScene />,
          },
        ],
      };

  return {
    path,
    element: <ModelScene {...model} />,
    children: [
      {
        index: true,
        element: (
          <IndexScene filter={endpointFilter} {...index}>
            {filter}
          </IndexScene>
        ),
      },
      {
        path: 'create',
        element: (
          <CreateScene {...create} initialValues={initialValues}>
            {form}
          </CreateScene>
        ),
      },
      entity,
    ],
  };
}

import { ReactElement } from 'react';
import { Dialog, DialogContent } from '@ff-it/ui';
import type { Supplier } from '../../types';
import { Form as FormHandler } from '@ff-it/form';
import { Form, FormProps, initialState } from './Form';
import { isComplete } from './isComplete';

type SupplierFieldsSelectorProps = {
  value: Supplier | null;
  onChange: (value: Supplier | null) => void;
  onRequestClose: () => void;
} & FormProps;

export function SupplierSelector({
  value,
  onChange,
  onRequestClose,
  ...rest
}: SupplierFieldsSelectorProps): ReactElement {
  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        !open && onRequestClose();
      }}
    >
      <DialogContent testId="supplier-selector">
        <FormHandler<Supplier | typeof initialState>
          initialValues={value || initialState}
          onSubmit={(value) => {
            if (isComplete(value)) {
              onChange(value);
            } else {
              onChange(null);
            }
          }}
        >
          <Form {...rest} />
        </FormHandler>
      </DialogContent>
    </Dialog>
  );
}

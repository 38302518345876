import useLocalStorageState from 'use-local-storage-state';
import cx from 'clsx';
import { Menu, MenuProps } from './Menu';
import { Box, Button } from '@ff-it/ui';
import { Icon } from '../../ui';
import { ReactElement, ReactNode } from 'react';
import * as style from './style.css';

interface SideNavProps extends MenuProps {
  branding?: ReactNode;
  children?: ReactNode;
}

export function SideNav({ menu, branding, children }: SideNavProps): ReactElement {
  const [collapsed, setCollapsed] = useLocalStorageState<boolean>('_collapse_sidebar', false);
  return (
    <nav className={cx(style.sidebar, { collapsed: collapsed })}>
      <div className={style.container}>
        <div className={style.menu}>
          {!collapsed && branding}
          <Menu menu={menu} collapsed={collapsed} />
        </div>
        <Box marginTop="auto" textSize="xs" paddingTop="sm">
          {!collapsed && (
            <Box textAlign="center">
              {children}
              <small>{window.__RUNTIME_CONFIG__.APP_VERSION}</small>
            </Box>
          )}

          <Button
            variant="link"
            onClick={() => setCollapsed((v) => !v)}
            // variant="outline-secondary"
            className="d-block w-100 text-right border-0 mt-2 text-muted"
          >
            <Icon icon={`chevrons-${collapsed ? 'right' : 'left'}`} />
          </Button>
        </Box>
      </div>
    </nav>
  );
}

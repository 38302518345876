import { ReactElement, useMemo } from 'react';
import { Perm, useCheck } from 'core/permissions';
import { RequestArgs } from '@ff-it/api';
import { ActionsProps } from './Actions';
import { EntityContextType, useEntity, useModel } from 'components/scenes';
import { isRequestAction, RequestActions } from './RequestActions';
import type { MaybeRequestAction } from './RequestActions';
import { isPromiseAction } from './PromiseActions';
import invariant from 'tiny-invariant';

type EntityActionProps<T> = {
  visible?: (item: T) => boolean;
  permKey?: null | Perm;
  createRequest?: (itemContext: EntityContextType<T>) => RequestArgs;
};

export type ResolvedEntityAction<T> = EntityActionProps<T> & MaybeRequestAction;
export type ResolvedActionFactory<T> = (
  itemContext: EntityContextType<T>,
  check: (perm?: Perm) => boolean,
) => ResolvedEntityAction<T> | null;

export type EntityAction<T> = ResolvedEntityAction<T> | ResolvedActionFactory<T>;

export function useEntityActions<T>(
  itemContext: EntityContextType<T>,
  actions: EntityAction<T>[],
): MaybeRequestAction[] {
  const { permissions } = useModel();
  const check = useCheck();

  return useMemo(
    () =>
      actions.reduce((filtered, maybeAction) => {
        const action = typeof maybeAction === 'function' ? maybeAction(itemContext, check) : maybeAction;

        // factory bailed
        if (action == null) {
          return filtered;
        }

        // check visiblity
        if (action.visible && !action.visible(itemContext.item)) {
          return filtered;
        }

        // check perms
        if (action.permKey !== null) {
          const perm =
            typeof action.permKey === 'function' ? action.permKey : permissions?.[action.permKey ?? action.action];

          invariant(perm, `Permission key (${action.permKey}) for action ${action.action} missing`);
          if (!check(perm)) {
            return filtered;
          }
        }

        // create request action if not bound
        if (
          // request
          !isRequestAction(action) &&
          // bound priomuse
          !isPromiseAction(action) &&
          // bound action
          !action.button?.onClick &&
          !action.button?.to
        ) {
          filtered.push({
            request: action.createRequest
              ? action.createRequest(itemContext)
              : {
                  url: `${itemContext.endpoint}${action.action}/`,
                  method: 'POST',
                },
            ...action,
          });
        } else {
          filtered.push(action);
        }

        return filtered;
      }, [] as MaybeRequestAction[]),
    [itemContext.item, actions, permissions, check],
  );
}

export interface EntityActionsProps<T> extends Omit<ActionsProps, 'actions'> {
  actions: EntityAction<T>[];
}

export function EntityActions<T = any>({ actions, ...props }: EntityActionsProps<T>): ReactElement {
  const ctx = useEntity<T>();
  const { setItem, setLoading } = ctx;
  const requestActions = useEntityActions(ctx, actions);
  return <RequestActions actions={requestActions} onSuccess={setItem} toggleLoading={setLoading} {...props} />;
}

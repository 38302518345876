import { Dispatch, SetStateAction, useCallback, useState, ReactElement } from 'react';
import { Icon } from '../Icon';
import { saveAs } from 'file-saver';
import { HTTPMethod, RequestBody, useAPI } from '@ff-it/api';
import { Button, ButtonProps, Spinner } from '@ff-it/ui';
import type { IconProp } from 'components';
import { actionErrorOrThrow } from 'utilities';

interface DownloadHandlerProps {
  url: string;
  filename?: string;
  method?: HTTPMethod;
  body?: RequestBody;
  accept?: string;
  queryParams?: any;
  headers?: HeadersInit;
}

interface DownloadButtonProps extends Omit<ButtonProps, 'onClick'>, DownloadHandlerProps {
  title?: string;
  icon?: IconProp;
}

export function useDownloadHandler(
  {
    url,
    filename: providedFilename,
    method = 'GET',
    body,
    accept: Accept = '*/*',
    queryParams,
    headers,
  }: DownloadHandlerProps,
  setLoading?: Dispatch<SetStateAction<boolean>>,
): () => Promise<void> {
  const [, /* state */ setState] = useState();
  const api = useAPI();
  const onClick = useCallback(async () => {
    setLoading && setLoading(true);
    const result = await api.request<Blob, unknown>({
      url,
      method,
      body,
      queryParams,
      headers: {
        Accept,
        ...headers,
      },
    });
    setLoading && setLoading(false);
    if (result.ok) {
      const blob = result.data;
      let filename = providedFilename;
      if (!filename) {
        const attachmentName = result.response.headers
          .get('Content-Disposition')
          ?.split(';')
          .find((n) => n.includes('filename='))
          ?.replace('filename=', '')
          .replaceAll('"', '')
          .trim();

        if (attachmentName) {
          filename = decodeURIComponent(attachmentName);
        }
      }
      saveAs(blob, filename);
    } else {
      try {
        actionErrorOrThrow(result);
      } catch (e) {
        setState(() => {
          throw e;
        });
      }
    }
  }, [api, url, body, providedFilename, queryParams, headers]);

  return onClick;
}

export function DownloadButton({
  url,
  filename,
  method,
  body,
  icon,
  children,
  title,
  accept,
  queryParams,
  ...props
}: DownloadButtonProps): ReactElement {
  const [loading, setLoading] = useState(false);

  const onClick = useDownloadHandler(
    {
      url,
      filename,
      method,
      body,
      accept,
      queryParams,
    },
    setLoading,
  );

  return (
    <Button onClick={onClick} {...props} disabled={loading}>
      {children}{' '}
      {loading ? (
        <Spinner size="sm" className="ml-1" aria-hidden={true} />
      ) : icon ? (
        <Icon className={children ? 'ml-1' : undefined} icon={icon} title={title} />
      ) : null}
    </Button>
  );
}

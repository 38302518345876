import ReactSelect, { ActionMeta, components, OptionProps } from 'react-select';
import eq from 'fast-deep-equal';
import { styles } from './styles';
import { Options } from 'options';
import { formatValue, parseValue, CheckboxOption } from 'components/Select';
import { RequireExactlyOne } from 'type-fest';
import { ComponentType, ReactElement, ReactNode } from 'react';

type ChoiceSelectEditorProps<T> = RequireExactlyOne<
  {
    label?: ReactNode;
    options: T[] | Options<T>;
    getOptionLabel?: (a: any) => string;
    getOptionValue?: (a: any) => string;
    formatOptionLabel?: (data: any) => ReactNode;
    isSimple: boolean;
    isSearchable?: boolean;
    Option?: ComponentType<OptionProps>;
    value: T[] | null;
    setValue: (value: T[]) => Promise<any>;
    onChange: (v: any, action: ActionMeta<any>) => Promise<void>;
  },
  'setValue' | 'onChange'
>;

// @TODO figure out if we can block scroll

const commonProps = {
  isClearable: false,
  tabSelectsValue: false,
  controlShouldRenderValue: false,
  backspaceRemovesValue: false,
  hideSelectedOptions: false,
  placeholder: 'Search...',
  isMulti: true,
  menuIsOpen: true,
  styles,
};

export function ChoiceSelectEditor<T = any>({
  label,
  options,
  value,
  isSimple,
  getOptionLabel,
  getOptionValue = (v) => (v ? v.value : v),
  formatOptionLabel,
  isSearchable,
  Option = CheckboxOption,
  onChange: doChange,
  setValue,
}: ChoiceSelectEditorProps<T>): ReactElement {
  const currentValue = isSimple ? formatValue(options, value, true, getOptionValue) : value;

  const onChange = setValue
    ? (v: any) => {
        const nevValue = isSimple ? parseValue(v, true, getOptionValue) : v;

        if (!eq(currentValue, nevValue)) {
          setValue(nevValue);
        }
      }
    : doChange;

  const props = {
    options,
    onChange,
    getOptionLabel,
    getOptionValue,
    formatOptionLabel,
    autoFocus: isSearchable,
    ...commonProps,
  };

  return (
    <div>
      {label && <label className="block-label">{label}</label>}
      <ReactSelect
        {...props}
        components={{
          Control: isSearchable ? components.Control : () => null,
          Option,
          IndicatorsContainer: () => null,
        }}
        value={currentValue}
      />
    </div>
  );
}

import { ReactElement, useMemo } from 'react';
import { formatISO, startOfYear, endOfYear } from 'date-fns';
import { DateRangePeriodPicker, VisibilityProps, Visibility } from '../common';
import { columns } from './columns';
import { EnterpriseField, ReportingGroupField } from 'components';
import { Row } from './types';
import { SelectField } from '@ff-it/form';
import { useScopedActivityTypeOptions } from '../useScopedActivityTypeOptions';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';
import { ReportTable } from 'components/ReportTable';
import { TableColumns } from '@ff-it/ui';

const resolveColumns = (visibility: Visibility): TableColumns<Row> => {
  switch (visibility) {
    case 'GLOBAL':
      return columns;
    case 'LOCAL':
      // no agency
      return columns.slice(1);
    case 'LOCAL_MANAGED':
    case 'LOCAL_TYPE':
      // no agency, no bonuses
      return columns.slice(1, 10);
  }
};

export function Client({ visibility, ...props }: VisibilityProps): ReactElement {
  const types = useScopedActivityTypeOptions(visibility === 'GLOBAL');
  const initialFilter = {
    date_from: formatISO(startOfYear(new Date()), { representation: 'date' }),
    date_to: formatISO(endOfYear(new Date()), { representation: 'date' }),
    types: types.map(({ value }) => value),
  };

  const url = `reporting/client/${visibility.toLowerCase()}${'type' in props ? `/${props.type}/` : '/'}`;

  const columns = useMemo(() => {
    return resolveColumns(visibility);
  }, [visibility]);

  return (
    <ReportTable<Row>
      url={url}
      columns={columns}
      initialFilter={initialFilter}
      filterFields={
        <div className="flex-column">
          <div className="d-flex flex-row">
            <DateRangePeriodPicker />
            <ReportingGroupField
              name="client_groups"
              isMulti
              placeholder="Client group"
              className="ml-2"
              filter={{ direction: 'CLIENT' }}
              simple
            />
            <CompanyField name="clients" isMulti placeholder="Client" className="ml-2" simple />
            <CompanyField name="end_clients" isMulti placeholder="End client" className="ml-2" simple />
            <CompanyField name="providers" isMulti placeholder="Provider" className="ml-2" simple />
            <DepartmentField name="departments" isMulti placeholder="Deparment" className="ml-2" simple />
            {visibility == 'GLOBAL' && (
              <EnterpriseField
                name="enterprises"
                isMulti
                defaultOptions
                placeholder="Agencies"
                className="ml-2"
                simple
              />
            )}
          </div>

          <div className="d-flex flex-row">
            {visibility != 'LOCAL_TYPE' && (
              <SelectField options={types} name="types" placeholder="Types" simple isMulti />
            )}
          </div>
        </div>
      }
    />
  );
}

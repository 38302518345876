import { createSelectField } from 'components/fields/createSelectField';
import { DepartmentTitle } from './DepartmentTitle';
import { Department } from './types';

export const departmentFieldProps = {
  getOptionLabel: ({ name }: Department) => name,
  formatOptionLabel: DepartmentTitle,
  getOptionValue: (a: Department) => a.id.toString(),
};

export const DepartmentField = createSelectField<Department>('supplier/departments/', departmentFieldProps);

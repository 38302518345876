import { Icon, FormInlineAdd, FormInlineError, AllActivityTypesField } from 'components';
import { DecimalField, useForm, useOnFieldChange } from '@ff-it/form';
import { Button } from '@ff-it/ui';
import { ReactElement } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { SupplierField } from 'modules/supplier/supplier/components';

const initial = {
  type: null,
  activty: null,
  supplier: null,
  agency_amount: null,
};

function Row({ name, index, remove }: { name: string; index: number; remove: (idx: number) => void }): ReactElement {
  const form = useForm();
  const type = useOnFieldChange(`${name}.type`, (value) => {
    if (value && value != form.getState().values[`${name}.supplier`]?.product.type) {
      form.change(`${name}.supplier`, null);
    }
  });
  return (
    <tr>
      <td>
        <AllActivityTypesField name={`${name}.type`} placeholder="Type" required />
      </td>
      <td>
        <SupplierField name={`${name}.supplier`} type={type} required />
      </td>
      <td className="sum">
        <DecimalField name={`${name}.agency_amount`} required />
      </td>
      <td className="tools">
        <Button
          data-test-id="remove-inline"
          variant="outline-danger"
          type="button"
          onClick={() => remove(index)}
          className="border-0"
          size="sm"
        >
          <Icon icon="xmark" />
        </Button>
      </td>
    </tr>
  );
}

export function ExternalSums(): ReactElement {
  return (
    <div className="mb-3">
      <FieldArray name="sums" subscription={{}}>
        {({ fields }) => (
          <>
            <FormInlineError name="sums" />
            <table className="table form-table mb-1">
              <thead>
                <tr>
                  <th>Type</th>
                  <th className="w-50">Supplier</th>
                  <th className="sum">Amount</th>
                  <th className="tools" />
                </tr>
              </thead>
              <tbody>
                {fields.map((name, index) => (
                  <Row key={index} name={name} index={index} remove={fields.remove} />
                ))}
              </tbody>
            </table>
          </>
        )}
      </FieldArray>
      <FormInlineAdd name="sums" initialValues={initial} />
    </div>
  );
}

import { ReactElement } from 'react';
import { DialogBody, DialogClose, DialogFooter, RenderProps } from '@ff-it/ui';
import { Form, Submit, TextField } from '@ff-it/form';

interface CommentRowDialogProps extends RenderProps<string> {
  comment: string;
}

export function CommentRowDialog({ onSubmit, comment }: CommentRowDialogProps): ReactElement {
  const submitHandler = ({ comment }: any): void => {
    onSubmit(comment);
  };

  return (
    <Form onSubmit={submitHandler} noValidate initialValues={{ comment }}>
      <DialogBody>
        <TextField name="comment" label="Comment" multiline autosize required />
      </DialogBody>
      <DialogFooter>
        <Submit>Update</Submit>
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

import { atom, PrimitiveAtom } from 'jotai';
import { blockTypeAtom } from '../../../atoms';
import { rowsAtom, getRow } from './plan';

export const rowSelection: PrimitiveAtom<ReadonlySet<number>> = atom(new Set() as ReadonlySet<number>);

export const controlsStateAtom = atom((get) => {
  const rows = get(rowsAtom);
  const locked = rows.find((row) => row.sums?.is_locked) != undefined;
  const empty = rows.length == 0;
  return {
    locked,
    empty,
    type: get(blockTypeAtom),
  };
});

const selectedRowsAtom = atom((get) => Array.from(get(rowSelection)).map((rowId) => getRow(rowId, get)));

export const selectionAtom = atom((get) => {
  const rows = get(selectedRowsAtom);
  const locked = rows.find((row) => row.sums?.is_locked) != undefined;
  return {
    rows,
    locked,
  };
});

import { FocusEvent, KeyboardEvent, ReactElement } from 'react';
import ReactSelect, { SelectComponentsConfig } from 'react-select';
import { EditorBaseProps } from './types';
import { Options } from 'options';
import { selectStyle } from './selectStyles';
import { PlainOption } from 'components/Select';

export interface SelectEditorProps extends EditorBaseProps<any> {
  options: Options<any>;
  // @TODO V except null
  getOptionLabel?: (a: any) => string;
  getOptionValue?: (a: any) => string;
  isSearchable?: boolean;
}

const components: SelectComponentsConfig<any, any, any> = {
  DropdownIndicator: null,
  Option: PlainOption,
};

const selectProps = {
  styles: selectStyle,
  components,
  menuPosition: 'fixed' as const,
  menuPortalTarget: document.body,
  autoFocus: true,
  // openMenuOnFocus: true,
  menuShouldBlockScroll: true,
  menuIsOpen: true,
  blurInputOnSelect: true,
  // escapeClearsValue: true,
  // isClearable
};

export function SelectEditor({
  value,
  setValue,
  stopEdit,
  options,
  getOptionLabel = (v) => (v ? v.label : v),
  getOptionValue = (v) => (v ? v.value : v),
  isSearchable,
}: SelectEditorProps): ReactElement {
  const currentVlaue = value ? options.find((o) => getOptionValue(o) === value) : null;

  async function onChange(v: any): Promise<void> {
    const nevValue = v ? getOptionValue(v) : null;
    if (currentVlaue !== nevValue) {
      await setValue(nevValue);
    }
    stopEdit();
  }

  function onBlur(e: FocusEvent<any>): void {
    e.preventDefault();
    stopEdit();
  }

  function onKeyDown(event: KeyboardEvent<HTMLInputElement>): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      stopEdit();
    }
  }

  return (
    <ReactSelect
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...selectProps}
      value={currentVlaue}
      onKeyDown={onKeyDown}
      onChange={onChange}
      onBlur={onBlur}
      isSearchable={isSearchable}
    />
  );
}

import { useCallback, ReactElement } from 'react';
import { Canvas, Draw } from '../Canvas';
import type { CalendarContext } from '../types';
import { HEIGHT } from '../const';
import { isWeekend } from 'date-fns';

export function Scale({ width, resolution, ...props }: CalendarContext): ReactElement {
  const drawSelection: Draw = useCallback(
    (ctx) => {
      const key = {
        DAY: 'days',
        WEEK: 'weeks',
        MONTH: 'months',
      }[resolution] as 'days' | 'weeks' | 'months';

      props[key].map((col) => {
        const { left, width, start } = col;

        if (key == 'days' && isWeekend(start)) {
          ctx.fillStyle = '#EFEEEE';
        } else {
          ctx.fillStyle = '#ffffff';
        }

        ctx.fillRect(left, 0, width, HEIGHT);
      });
    },
    [width, resolution],
  );

  return <Canvas width={width} height={HEIGHT} draw={drawSelection} />;
}

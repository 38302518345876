import { useCallback, useState, useEffect, ReactNode, ReactElement, ChangeEvent, useId } from 'react';
import { useField } from 'react-final-form';
import { Attachment } from 'types';
import { LoadingContainer } from '@ff-it/ui';
import { useIsDisabled, FormGroup } from '@ff-it/form';
import { useAPI } from '@ff-it/api';
import FileContainer from './FileContainer';

interface AttachmentFieldProps {
  id?: string;
  name: string;
  label?: ReactNode;
  className?: string;
  required?: boolean;
  isDisabled?: boolean;
  discriminator?: string;
}

export function AttachmentField(props: AttachmentFieldProps): ReactElement {
  const { id, name, label, className, isDisabled: disabledProp, required, discriminator } = props;
  const [deleted, setDeleted] = useState<Attachment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useAPI();

  const {
    input: { value, onChange },
    meta: fieldMeta,
  } = useField<Attachment[], HTMLInputElement>(name, {});
  const _id = useId();
  const fieldId = id || _id;

  const isDisabled = useIsDisabled(disabledProp);

  const onMarkRemove = useCallback(
    (id: number) => {
      const newValue = value.filter((v) => {
        if (v.id === id) {
          setDeleted((deleted) => [...deleted, v]);
          return false;
        }
        return true;
      });
      onChange(newValue);
    },
    [onChange, value],
  );

  useEffect(() => {
    if (!fieldMeta.dirty && deleted.length > 0) {
      setDeleted([]);
    }
  }, [fieldMeta.dirty, deleted]);

  const onFileListChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      if (!e.target.files) {
        return;
      }
      setLoading(true);
      const files = Array.from(e.target.files);
      files.forEach((file): void => {
        api
          .put(`attachments/attachments${discriminator ? `/${discriminator}/` : '/'}`, file, {
            headers: {
              'Content-Disposition': `attachment; filename*="${encodeURIComponent(file.name)}"`,
              'Content-Type': file.type,
            },
          })
          .then((res) => {
            if (res.ok) {
              onChange(value ? [...value, res.data] : [res.data]);
            } else {
              setLoading(() => {
                throw res.error;
              });
            }
            setLoading(false);
          });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, api],
  );

  return (
    <FormGroup className={className} label={label} id={id} required={required} meta={fieldMeta}>
      <LoadingContainer loading={loading}>
        <FileContainer files={value || []} markRemove={onMarkRemove} disabled={isDisabled} />
        <FileContainer files={deleted} />
        <div className="custom-file">
          <input
            type="file"
            className="custom-file-input"
            id={fieldId}
            onChange={onFileListChange}
            disabled={isDisabled}
          />
          <label className="custom-file-label" htmlFor={fieldId}>
            Choose file
          </label>
        </div>
      </LoadingContainer>
    </FormGroup>
  );
}

import { ReactElement, ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  RenderProps,
} from '@ff-it/ui';
import { Form, FormError, SubmissionErrors, Submit } from '@ff-it/form';

type DialogFormProps = {
  dialogHeader?: ReactNode;
  children: ReactNode;
  decorators?: any;
  initialValues: any;
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
  onRemove?: () => Promise<void>;
  className?: string;
};

export function DialogForm({
  onDismiss,
  onSubmit,
  dialogHeader,
  children,
  decorators,
  initialValues,
  submitHandler,
  onRemove,
  className,
}: DialogFormProps & RenderProps): ReactElement {
  return (
    <Form
      noValidate
      autoComplete="off"
      onSubmit={async (values) => {
        const err = await submitHandler(values);
        if (err) {
          return err;
        }
        onSubmit();
      }}
      initialValues={initialValues}
      renderErrors={false}
      decorators={decorators}
      className={className}
    >
      {dialogHeader && <DialogHeader title={dialogHeader} />}
      <DialogBody>
        <FormError />
        {children}
      </DialogBody>
      <DialogFooter>
        <Submit>Save</Submit>
        <DialogClose className="ml-auto" />
        {onRemove && (
          <Button type="button" variant="outline-danger" className="ml-1" onClick={() => onRemove().then(onDismiss)}>
            Delete
          </Button>
        )}
      </DialogFooter>
    </Form>
  );
}

type RenderDialogFormProps = {
  onClose: () => void;
  testId?: string;
};
export function RenderDialogForm({ onClose, testId, ...rest }: RenderDialogFormProps & DialogFormProps): ReactElement {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent testId={testId}>
        <DialogForm {...rest} onDismiss={onClose} onSubmit={onClose} />
      </DialogContent>
    </Dialog>
  );
}

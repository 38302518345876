import { SelectField, SelectFieldProps } from '@ff-it/form';
import { Language } from 'types';
import { useDefaultOptions } from './useOptions';
import type { Option } from 'options';
import { ReactElement } from 'react';

export function LanguageField(
  props: Omit<
    SelectFieldProps<Option<Language>, boolean>,
    'options' | 'loadOptions' | 'getOptionLabel' | 'getOptionValue' | 'simple'
  >,
): ReactElement {
  const languages = useDefaultOptions<Option<Language>>('core/languages/');

  return <SelectField options={languages} {...props} simple />;
}

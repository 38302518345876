import { ReactElement, ReactNode, useCallback } from 'react';
import { ExtendedUserField, Fieldset, Icon } from 'components';
import { useField, useForm } from 'react-final-form';
import { isValidEmail } from 'utilities';
import { Button } from '@ff-it/ui';
import { TextField } from '@ff-it/form';

function InviteForm({ cancel }: { cancel: () => void }): ReactElement {
  return (
    <Fieldset title="Invite user">
      <div className="form-row">
        <TextField name="invite.email" label="E-mail" type="email" required className="col-md-6" />
        <div className="col-md-6 text-right">
          <Button variant="outline-danger" size="sm" className="border-0" onClick={cancel}>
            cancel invite <Icon icon="xmark" className="ml-1" />
          </Button>
        </div>
      </div>
      <div className="form-row">
        <TextField name="invite.first_name" label="First name" className="col-md-6" />
        <TextField name="invite.last_name" label="Last name" className="col-md-6" />
      </div>
    </Fieldset>
  );
}

export function UserOrInvite(): ReactElement {
  const form = useForm();
  const {
    input: { value: invite },
  } = useField('invite', { subscription: { value: true } });

  const noOptionsMessage = useCallback(({ inputValue }: { inputValue: string }): ReactNode => {
    if (isValidEmail(inputValue)) {
      return (
        <Button
          variant="outline-primary"
          className="w-100"
          data-test-id="invite-user"
          onClick={() => {
            form.change('invite', {
              email: inputValue,
            });
          }}
        >
          Send invite to: <strong>{inputValue}</strong>
        </Button>
      );
    }
    return 'Invalid email';
  }, []);

  return invite ? (
    <InviteForm cancel={() => form.change('invite', null)} />
  ) : (
    <div className="form-row">
      <ExtendedUserField
        name="user"
        placeholder="User"
        required
        className="col-md-6"
        noOptionsMessage={noOptionsMessage}
      />
    </div>
  );
}

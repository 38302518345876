import { ReactElement, ReactNode } from 'react';
import { Filter } from './Filter';
import { formatISO, startOfYear } from 'date-fns';
import { reportBlockStateOptions } from 'configuration';
import { LoadingContainer, urlon } from '@ff-it/ui';
import { ReportContainer } from '../common';
import { ResponseData } from './types';
import { useQueryStorageState } from '@ff-it/grid';
import { DownloadButton } from 'components';
import { ReportRenderer } from './ReportRenderer';
import { Options } from 'options';
import { ActivityType } from 'types';
import useSWR from 'swr';

function serialize(state: any): string {
  const str = urlon.stringify(state) || '';
  return str.length > 0 ? '?' + str : '';
}

function unserialize(qs: string): any {
  const s = qs.replace(/^\?/, '');

  if (s.length !== 0) {
    try {
      return urlon.parse(s);
    } catch (_e) {
      return undefined;
    }
  }

  return undefined;
}

interface RevenueProps {
  types: Options<ActivityType>;
  url: string;
  filterFields: ReactNode;
  storageKey: string;
}

export function Revenue({ types, url, filterFields, storageKey }: RevenueProps): ReactElement {
  // FIXME: use ContolledTable with single page or at least storage and state management
  const initialFilter = {
    year: formatISO(startOfYear(new Date()), { representation: 'date' }),
    state: reportBlockStateOptions.map(({ value }) => value).filter((value) => value !== 'CANCELED'),
    types: types.map(({ value }) => value),
  };

  const [filter, setFilter] = useQueryStorageState(
    initialFilter,
    {
      serialize,
      unserialize,
    },
    {
      serialize,
      unserialize,
      key: storageKey,
    },
  );

  const { isLoading, data, error } = useSWR<ResponseData>({
    url,
    method: 'POST',
    body: filter,
  });
  if (error) {
    throw error;
  }

  return (
    <ReportContainer title="Revenue">
      <LoadingContainer loading={isLoading}>
        <div className="mt-3 d-flex">
          <Filter types={types} filter={filter} setFilter={setFilter} url={url} defaultFilter={initialFilter}>
            {filterFields}
          </Filter>
          <DownloadButton
            className="ml-auto mb-auto"
            method="POST"
            size="sm"
            body={filter as any}
            url={url}
            icon="file-excel"
            title="Export"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            Export
          </DownloadButton>
        </div>

        {data && <ReportRenderer data={data} />}
      </LoadingContainer>
    </ReportContainer>
  );
}

import { SelectField } from '@ff-it/form';
import type { ReactElement, ReactNode } from 'react';
import { useFinalClientId } from 'modules/client/useFinalClientId';
import { useDefaultOptions } from 'components';
import { Person } from 'modules/crm/types';

const personFieldProps = {
  getOptionLabel: ({ first_name, last_name }: Person) => `${first_name} ${last_name}`,
  getOptionValue: (a: Person) => a.id.toString(),
};

interface ClientPersonFieldProps {
  name: string;
  className: string;
  label?: ReactNode;
  placeholder?: string;
  help?: string;
}

export function ClientPersonField(props: ClientPersonFieldProps): ReactElement {
  const clientId = useFinalClientId();
  const defaultOptions: any = useDefaultOptions(clientId ? `client/clients/${clientId}/persons/` : null);
  return <SelectField {...personFieldProps} {...props} options={defaultOptions} disabled={!clientId} />;
}

import { ReactElement } from 'react';
import { Form, SelectField, Submit } from '@ff-it/form';
import { Button, DialogBody, DialogFooter, DialogHeader, RenderProps } from '@ff-it/ui';
import { BlockWidget, useOptions } from 'components';
import { SubmissionErrors } from 'final-form';
import { ActivityType } from 'types';
import { BlockDetails } from 'modules/campaign/block/types';

type ImportRowsDialogProps = RenderProps & {
  type: ActivityType;
  exclude: number;
  submitHandler: (values: any) => Promise<SubmissionErrors | void>;
};

export function ImportRowsDialog({ onSubmit, submitHandler, type, exclude }: ImportRowsDialogProps): ReactElement {
  const loadOptions = useOptions<BlockDetails>('campaign/blocks/', {
    type,
    exclude,
  });

  return (
    <Form initialValues={{ block: null }} onSubmit={(v) => submitHandler(v).then(() => onSubmit())}>
      <DialogHeader title="Import rows" />

      <DialogBody>
        <SelectField<any>
          loadOptions={loadOptions}
          getOptionLabel={({ code, title }) => `${title} ${code}`}
          formatOptionLabel={BlockWidget}
          name="block"
          placeholder="Import code..."
          required
        />
      </DialogBody>
      <DialogFooter>
        <Submit>Import</Submit>
        <Button type="button" variant="outline-secondary" size="sm" className="ml-auto" onClick={close}>
          Cancel
        </Button>
      </DialogFooter>
    </Form>
  );
}

import { ActivityIcon } from '../ui/Icon';
import { Link } from 'react-router-dom';
import type { ActivityType } from 'types';
import { ReactElement } from 'react';
import { TitleContainer } from 'components/TitleContainer';
import { Box } from '@ff-it/ui';

export interface BlockWidgetProps {
  id: number;
  code: string;
  title: string;
  campaign_id: number;
  slug: string;
  type: ActivityType;
  flat?: boolean;
}

export function BlockWidget({
  id,
  code,
  title,
  campaign_id,
  slug,
  link,
  type,
  className,
  flat,
}: BlockWidgetProps & { link?: boolean; className?: string }): ReactElement {
  const content = link ? (
    <Link to={`/${slug}/planning/campaigns/${campaign_id}/planning/${id}`} target="_blank">
      {code}
    </Link>
  ) : (
    code
  );

  return (
    <TitleContainer
      testId={`block-${id}`}
      className={className}
      flat={flat}
      title={
        <Box display="flex">
          <ActivityIcon type={type} className="mr-1" /> {content}
        </Box>
      }
      subtitle={title || type}
    />
  );
}

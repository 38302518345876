import { InvoiceRow } from './types';

export const initialValues = {
  credit_source: null,
  type: null,
  state: 'DRAFT' as const,
  payer: null,
  checkout_date: null, // formatISO(new Date(), { representation: 'date' }),
  payment_date: null,
  comment: '',
  // remove these?
  currency: 'EUR',
  currency_rate: '1.00',
  vat_rate: null,
  rows: [],
  date_from: null,
  date_to: null,
};

export const initialRow: InvoiceRow = {
  title: '',
  quantity: '1',
  unit_price: '0.00',
  unit: 'UNIT' as const,
  kind: 'AMOUNT',
  compensated_to: null,
};

import React from 'react';
import { DecimalField } from '@ff-it/form';
import { FactorSelector, FactorSelectorProps } from './FactorSelector';

export function Form(props: FactorSelectorProps): React.ReactElement {
  return (
    <>
      <fieldset>
        <legend>Client discounts</legend>
        <div className="form-row">
          <DecimalField name="client.discounts[0]" placeholder="Discount 1" className="col" size="sm" />
          <DecimalField name="client.discounts[1]" placeholder="Discount 2" className="col" size="sm" />
          <DecimalField name="client.discounts[2]" placeholder="Discount 3" className="col" size="sm" />
        </div>
      </fieldset>
      <fieldset>
        <legend>Provider discount</legend>
        <div className="form-row">
          <DecimalField name="provider.discounts[0]" placeholder="Discount 1" className="col" size="sm" />
          <DecimalField name="provider.discounts[1]" placeholder="Discount 2" className="col" size="sm" />
          <DecimalField name="provider.discounts[2]" placeholder="Discount 3" className="col" size="sm" />
        </div>
      </fieldset>
      <FactorSelector {...props} />
    </>
  );
}

import { ReactElement, ReactNode } from 'react';
import { SmartRow } from 'modules/campaign/row';
import { RenderCellProps } from 'components/Grid';
import { SmartLabel } from './SmartLabel';
import { DecimalEditor } from '../../../Editor';
import { useFieldUpdater } from '../../../../hooks';
import type { Row } from 'modules/campaign/row';

export function viewRate(props: RenderCellProps<Row>): ReactNode {
  const row = props.row as SmartRow;
  const content = <SmartLabel {...row} />;
  return <span className="contain">{content}</span>;
}

export function EditRate(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.rate;
  const price = props.row.config.rate?.resolved_value as any;

  const setValue = useFieldUpdater(props.row.id, 'rate');

  return (
    <DecimalEditor
      value={value}
      stopEdit={props.stopEdit}
      setValue={setValue}
      precision={5}
      resetValue={price}
      normalize
    />
  );
}

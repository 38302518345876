import { ReactElement } from 'react';
import cx from 'clsx';
import { Box } from '@ff-it/ui';
import { Link } from 'react-router-dom';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { formatDate, sepFormat } from 'utilities';
import { container, item, number, listItem, invoiceRatio, meta, collapsed, button, variants } from './style.css';
import { useSessionScope } from 'services';
import { Invoice, Invoices } from '../types';

type InvoiceListProps = {
  invoices: Invoices;
  sizes?: number[];
  onInvoiceSelect?: (invoice: Invoice) => void;
  variant: 'income' | 'expense';
};

export function InvoiceList({ invoices, sizes, onInvoiceSelect, variant }: InvoiceListProps): ReactElement {
  const { slug } = useSessionScope();

  if (invoices.length === 0) {
    return (
      <Box fontSize="xs" display="flex" flexGrow={1} justifyContent="center" alignItems="center" color="muted">
        Nothing linked yet
      </Box>
    );
  }

  return (
    <Box component="ul" className={cx(container, variants[variant])}>
      {invoices.map((invoice, idx) => {
        const onClickHandler = onInvoiceSelect ? () => onInvoiceSelect(invoice) : undefined;
        const size = sizes?.[idx] || 1;

        return (
          <Box
            component="li"
            key={invoice.id}
            className={listItem}
            style={size ? assignInlineVars({ [invoiceRatio]: size.toString() }) : undefined}
          >
            <Box className={item} title={invoice.number} display="flex" flexDirection="column" justifyContent="center">
              {onClickHandler && <Box component="button" className={button} onClick={onClickHandler}></Box>}
              <Link to={`/${slug}/invoices/${variant}/${invoice.id}`} className={number}>
                {invoice.number}
              </Link>
              <Box display="flex" justifyContent="space-between" className={meta}>
                <Box className={collapsed}>{formatDate(invoice.checkout_date)}</Box>
                <Box marginLeft="auto" className={size < 0.8 && collapsed} data-testid="amount">
                  {sepFormat(invoice.amount)}
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}

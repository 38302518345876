import { SelectField } from '@ff-it/form';
import type { ReactElement, ReactNode } from 'react';
import { useFinalClientId } from 'modules/client/useFinalClientId';
import type { Brand } from '../../types';
import { useDefaultOptions, createSelectField } from 'components';

const brandFieldProps = {
  getOptionLabel: ({ title }: Brand) => title,
  getOptionValue: (a: Brand) => a.id.toString(),
};

export const BrandField = createSelectField<Brand>('client/brands/', brandFieldProps);

interface ClientBrandFieldProps {
  name: string;
  className: string;
  label?: ReactNode;
  placeholder?: string;
}

export function ClientBrandField(props: ClientBrandFieldProps): ReactElement {
  const clientId = useFinalClientId();
  const defaultOptions: any = useDefaultOptions('client/brands/', { client: clientId }, clientId);
  return <SelectField {...brandFieldProps} {...props} options={clientId ? defaultOptions : []} disabled={!clientId} />;
}

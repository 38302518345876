import { ReactElement, ReactNode } from 'react';
import { useAtomValue } from 'jotai';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fmt } from 'utilities';
import { summaryRowAtom } from '../../../../atoms/summary';
import { Edit } from './Edit';
import type { Row } from 'modules/campaign/row';
import { fieldIsEditable } from '../../../../hooks';
import { renderValue, renderSmartValue } from './renderValue';

function Summary(): ReactElement {
  const summary = useAtomValue(summaryRowAtom);
  return <strong>{fmt(summary?.client_total)}</strong>;
}

export const client_total: GridColumn<Row> = {
  key: 'client_total',
  header: 'Client total',
  summary: <Summary />,
  minWidth: 190,
  className: 'text-right grd__c--client',
  renderCell: (props: RenderCellProps<Row>): ReactNode => {
    const {
      row: { direction, kind },
    } = props;
    if (kind === 'SMART') {
      if (direction === 'CLIENT') {
        return renderSmartValue(props.row);
      }

      return null;
    }

    return props.editing ? <Edit {...props} /> : renderValue(props.row);
  },
  editable: (row) => fieldIsEditable(row, 'transparency'),
};

import { ReactElement } from 'react';
import { TextField } from '@ff-it/form';

export function DepartmentForm(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="name" label="Name" className="col-md-6" required />
      </div>
    </div>
  );
}

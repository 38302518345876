import type { ReactElement } from 'react';
import { Box, DataTable, Pagination, SortType } from '@ff-it/ui';
import { TableContext } from './context';
import type { FilterType, PageData, RowType, TableController, TablePageProps } from './types';
import useSWR from 'swr';
import { fetcher } from 'services';
import { RequestError, RequestFailure } from '@ff-it/api';
import equal from 'fast-deep-equal';
import { toast } from 'react-toastify';

const drfPageFetcher = async <R extends RowType>(key: any): Promise<PageData<R>> => {
  const { results, count, total } = await fetcher<
    {
      results: R[];
      count: number;
      // FIXME: only reports
      total?: Record<string, string>;
    },
    unknown
  >(key);
  return {
    totals: total,
    rows: results,
    total: count,
  };
};

// @TODO uncouple from DRF
export function TablePage<R extends RowType>({
  columns,
  getKey,
  state,
  setState,
  initialFilter,
  filter,
  pageSizeOptions,
  ...tableProps
}: TablePageProps<R>): ReactElement {
  const { data, isLoading, error } = useSWR<PageData<R>>(getKey(state), {
    keepPreviousData: true,
    fetcher: drfPageFetcher,
    onError: (error: RequestFailure<any>) => {
      if (error.error instanceof RequestError && error.status === 400 && !equal(filter, initialFilter)) {
        toast.error('Resetting filter');
        console.warn(error);
        setState((state) => ({ ...state, filter: initialFilter, pageIndex: 1 }));
      }
    },
  });
  if (error) {
    throw error;
  }

  const setFilter = (filter: FilterType): void => setState((state) => ({ ...state, filter, pageIndex: 1 }));
  const onPageChange = (pageIndex: number, pageSize: number): void =>
    setState((state) => ({ ...state, pageIndex, pageSize }));
  const onSort = (sort?: SortType): void => setState((state) => ({ ...state, sort, pageIndex: 1 }));

  const controller: TableController<R> = {
    ...state,
    columns,
    initialFilter,
    setFilter,
    onPageChange,
    onSort,
    data,
    isLoading,
  };

  const { pageIndex, pageSize, sort } = state;

  return (
    <Box>
      <TableContext.Provider value={controller}>
        <DataTable
          columns={columns}
          loading={isLoading}
          data={data ? data.rows : []}
          sort={sort}
          onSort={onSort}
          before={<Box>{filter}</Box>}
          after={
            <Box marginY="sm">
              <Pagination
                current={pageIndex}
                pageSize={pageSize}
                total={data?.total || 0}
                pageSizeOptions={pageSizeOptions}
                onChange={onPageChange}
                size={tableProps.size}
              />
            </Box>
          }
          {...tableProps}
        />
      </TableContext.Provider>
    </Box>
  );
}

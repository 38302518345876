import { ReactElement } from 'react';
import { useRoutes, Link } from 'react-router-dom';
import {
  ClientTitle,
  CreateScene,
  DeleteScene,
  EntityScene,
  IndexScene,
  ModelScene,
  UpdateScene,
  useEntity,
} from 'components';
import permissions from 'modules/client/agreement/permissions';
import { date } from 'components/columns';
import { Column } from '@ff-it/grid';
import { Nav } from './Nav';
import { Fees } from './Fees';
import initialValues from './initialValues';
import { FromTicket } from './FromTicket';
import type { Client } from 'modules/client/types';
import { canViewClientAgreements } from 'modules/client';
import { ClientAgreement } from 'modules/client/agreement/types';
import { Form } from 'modules/client/agreement/Form';

function EndClientLink({ id, number, client }: ClientAgreement): ReactElement {
  const {
    item: { id: clientId },
  } = useEntity<Client>();

  const to = client.id !== clientId ? `../../${client.id}/agreements/${id}` : `${id}`;
  return <Link to={to}>{number}</Link>;
}

const columns: Column<ClientAgreement>[] = [
  {
    content: 'Number',
    key: 'number',
    width: 180,
    render: EndClientLink,
  },
  {
    content: 'Client',
    key: 'client',
    render: ClientTitle,
  },
  date('Date from', 'date_from', { sortable: true, width: undefined }),
  date('Date to', 'date_to', { sortable: true, width: undefined }),
];

export function Agreements(): ReactElement {
  const { item } = useEntity<Client>();

  const model = {
    endpoint: `client/clients/${item.id}/agreements/`,
    title: 'Client agreement',
    pluralTitle: 'Client agreements',
    entityTitle: (e: ClientAgreement) => e.number,
    permissions: {
      ...permissions,
      view: canViewClientAgreements(item),
    },
  };

  function copy(agreement: ClientAgreement): any {
    return {
      ...agreement,
      id: undefined,
      date_from: null,
      date_to: null,
      number: '',
      attachments: [],
      rates: agreement.rates.map((rate) => ({ ...rate, id: undefined })),
      smart_rates: agreement.smart_rates.map((rate) => ({ ...rate, id: undefined })),
      signature_persons: agreement.signature_persons.map((person) => ({ ...person, id: undefined })),
      type_terms: agreement.type_terms.map((person) => ({ ...person, id: undefined })),
      discounted_departments: agreement.discounted_departments.map((department) => ({
        ...department,
        discounted_products: department.discounted_products.map((product) => ({ ...product, id: undefined })),
        id: undefined,
      })),
    };
  }

  const routes = useRoutes([
    {
      index: true,
      element: <IndexScene columns={columns} />,
    },
    {
      path: 'create',
      element: (
        <CreateScene
          successPath="../:id/fees"
          initialValues={{
            ...initialValues,
            client: item,
          }}
        >
          <Form />
        </CreateScene>
      ),
    },
    {
      path: 'from_ticket/:id',
      element: <FromTicket client={item} />,
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled={true} copy={copy}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'fees',
          element: <Fees />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return <ModelScene {...model}>{routes}</ModelScene>;
}

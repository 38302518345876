import { ReactElement } from 'react';
import { Placement } from 'modules/campaign/row';
import { ChoiceSelectEditor } from '../../../Editor';
import { getOptionLabel, getOptionValue } from './util';
import { SelectProps } from '../useEditableChoiceProps';

export function Selector({ options, value, onChange }: SelectProps<Placement>): ReactElement {
  return (
    <ChoiceSelectEditor<Placement>
      label="Product placements"
      options={options}
      onChange={onChange}
      isSimple={false}
      isSearchable
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      value={value}
    />
  );
}

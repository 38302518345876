import { useGrid } from '@ff-it/grid';
import Big from 'big.js';
import { ReactElement } from 'react';

type InvoiceFooterProps = {
  campaingTotal?: string;
};

export function InvoiceFooter({ campaingTotal }: InvoiceFooterProps): ReactElement {
  const { data } = useGrid<{ campaign_bound: string }>();
  const linkedTotal = data.reduce((acc, { campaign_bound }) => acc.plus(campaign_bound), Big(0));
  return (
    <table className="table w-auto ml-auto">
      <tfoot>
        <tr>
          <td>Linked total</td>
          <td className="text-right">{linkedTotal.toFixed(2)}</td>
        </tr>
        {campaingTotal && (
          <>
            <tr>
              <td>Campaing total</td>
              <td className="text-right">{campaingTotal}</td>
            </tr>
            <tr>
              <th>Remaining</th>
              <th className="text-right">{Big(campaingTotal).minus(linkedTotal).toFixed(2)}</th>
            </tr>
          </>
        )}
      </tfoot>
    </table>
  );
}

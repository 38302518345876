import { ReactElement, memo } from 'react';
import type { EntityMap } from '../types';
import type { Linkable, Side } from './types';
import { CampaignWidget } from 'components';
import { useGroupRows } from './useGroupRows';
import { Block } from './Block';
import { amount, table, control, lcontrol } from './style.css';
import { RowContainer } from './RowContainer';
import { header } from 'styles/style.css';

type CampaignProps = {
  campaignId: string;
  entities: EntityMap;
  side: Side;
  rows: Linkable[];
};

export const Campaign = memo(function Campaign({ campaignId, entities, side, rows }: CampaignProps): ReactElement {
  const campaing = entities.campaign[campaignId];
  const blockRows = useGroupRows(rows, 'block_id');
  return (
    <table className={table} data-testid={`side-${side}`}>
      <colgroup>
        <col className={lcontrol} />
        <col />
        <col className={control} />
        <col className={amount} />
        <col className={amount} />
        <col className={amount} />
        <col className={control} />
      </colgroup>
      <thead>
        <RowContainer
          cell="th"
          className={header.default}
          supplier={<CampaignWidget {...campaing} flat link />}
          invoiceable="Invoiceable"
          invoiced={side == 'linked' ? 'Linked' : 'Invoiced'}
          balance="Balance"
        />
      </thead>
      {Object.keys(blockRows).map((blockId) => (
        <Block key={blockId} blockId={blockId} entities={entities} rows={blockRows[blockId]} side={side} />
      ))}
    </table>
  );
});

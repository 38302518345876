import { ReactElement } from 'react';
import { useModel, ItemLoader, UpdateContainer, useEntity, withHasPerm } from 'components';
import { Company } from 'types';
import { any, useHasPerms } from 'core/permissions';
import { BrandField, CreatableBrandField } from 'modules/client/brand/components';

export const permissions = {
  view: 'client.view_clientbrand',
  change: 'client.change_clientbrand',
  add: 'client.add_clientbrand',
};

function SelectOrCreatableBrand(): ReactElement {
  const [add] = useHasPerms(permissions.add);

  return add ? (
    <CreatableBrandField name="brands" label="Brands" required isMulti className="multiselect" />
  ) : (
    <BrandField name="brands" label="Brands" required isMulti className="multiselect" />
  );
}

export const Brands = withHasPerm(function Brands(): ReactElement {
  const endpoint = useModel().endpoint;
  const entityId = useEntity<Company>().item.id;
  const [hasChange] = useHasPerms(permissions.change);
  const url = `${endpoint}${entityId}/brands/`;

  return (
    <ItemLoader<any> url={url}>
      {({ setItem, item }) => (
        <UpdateContainer url={url} initialValues={item} updateItem={setItem} method="POST" disabled={!hasChange}>
          <div className="container px-0 ml-0">
            <div className="row">
              <div className="col-6">
                <SelectOrCreatableBrand />
              </div>
            </div>
          </div>
        </UpdateContainer>
      )}
    </ItemLoader>
  );
}, any(permissions.view, permissions.change));

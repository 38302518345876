import type { ActivityType, EmbeddedCompany, VATStrategy } from 'types';

export const materialTypes = ['IMAGE', 'VIDEO', 'TEXT', 'AUDIO'] as const;
export type MaterialType = (typeof materialTypes)[number];

export const webDevices = ['MOBILE', 'DESKTOP', 'TABLET', 'TV'] as const;
export type WEBDevice = (typeof webDevices)[number];

export const transparencies = ['PLANNED', 'DELIVERED'] as const;
export type Transparency = (typeof transparencies)[number];

export type MembershipLog = {
  id: number;
  date_from: string;
  date_to: string | null;
  membership_id: number;
  membership_rel: number | null;
  enterprise: number;
  enterprise_repr: string;
  user: number | null;
  user_repr: string;
  created_by: number | null;
  created_by_repr: string;
  removed_by: null | number;
  removed_by_repr: string;
};

export type EmbeddedUser = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  is_superuser: boolean;
  manage_group: boolean;
};

export type AgencyGroup = {
  id: 0;
  title: string;
  slug: string;
  kind: 'AGENCY_GROUP';
  logo_url: null | string;
  accounting_integration: null;
};

export type EmbeddedEnterprise = {
  id: number;
  company: EmbeddedCompany;
  slug: string;
  external: boolean;
};

// @TODO rename to Agency
export interface Enterprise extends EmbeddedEnterprise {
  // eslint-disable-next-line @typescript-eslint/ban-types
  invoice_sequence: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  acceptance_sequence: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  client_agreement_sequence: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  provider_agreement_sequence: object;
  onec_endpoint: string | null;
  vat_strategy: VATStrategy;
}

// @TODO rename
export type Group = {
  id: number;
  name: string;
};

export interface SystemUserMembership {
  id: number;
  enterpris: EmbeddedEnterprise;
  groups: Group[];
}

export interface SessionUser extends EmbeddedUser {
  permissions: string[];
  membership: UserMembership[];
  agency_group: null | AgencyGroup;
  last_enterprise: null | number;
}

export interface SystemUser extends EmbeddedUser {
  permissions: string[];
  membership: SystemUserMembership[];
  has_password: boolean;
}

export interface AgencyMembership {
  id: number;
  user: EmbeddedUser;
  groups: Group[];
  managed_types: undefined;
}

export type UserMembership = {
  id: number;
  company: number;
  country_code: string;
  title: string;
  slug: string;
  permissions: string[];
  kind: 'AGENCY';
  managed_types: ActivityType[];
  logo_url: null | string;
  vat_strategy: VATStrategy;
  accounting_integration: AccountingIntegration | null;
  // @TODO consider moving this, but where?
  planning_config: any;
};

export type AccountingIntegration = 'horizon' | 'onec';

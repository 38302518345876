import { useAPI } from '@ff-it/api';
import { Button } from '@ff-it/ui';
import { Icon } from 'components';
import { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Attachment } from 'types';

export default function CreateFromUploadButton(): ReactElement {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClick = useCallback(() => {
    fileInputRef.current?.click();
  }, [fileInputRef]);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useAPI();

  const onFileChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    setLoading(true);
    const file = e.target.files[0];
    api
      .put<Attachment, any>(`attachments/attachments/`, file, {
        headers: {
          'Content-Disposition': `attachment; filename*="${encodeURIComponent(file.name)}"`,
          'Content-Type': file.type,
        },
      })
      .then((res) => {
        if (res.ok) {
          navigate(`create?from_attachment=${res.data.id}`);
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      <input type="file" hidden ref={fileInputRef} onChange={onFileChange} multiple={false} />
      <Button className="mr-1" variant="outline-primary" size="sm" onClick={handleClick} disabled={loading}>
        Create from attachment <Icon className="ml-1" icon="file-invoice" />
      </Button>
    </>
  );
}

import { ReactElement } from 'react';
import { PaymentState as PaymentStateType } from 'modules/invoicing/common/types';
import { Icon } from 'components';

interface PaymentStateProps {
  state: PaymentStateType | null;
}

const CLASS_MAP: Record<PaymentStateType, string> = {
  PENDING: 'text-muted',
  PARTIAL: 'text-warning',
  PAID: 'text-success',
  LATE: 'text-danger',
};

const TITLE_MAP: Record<PaymentStateType, string> = {
  PENDING: 'Payment pending',
  PARTIAL: 'Partial payment',
  PAID: 'Paid',
  LATE: 'Late',
};

export function PaymentState({ state }: PaymentStateProps): ReactElement | null {
  if (state === null) {
    return null;
  }
  return <Icon icon="coins" className={CLASS_MAP[state]} title={TITLE_MAP[state]} />;
}

import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';

import { ModelScene, IndexScene, CreateScene, EntityScene, UpdateScene, DeleteScene } from 'components';
import { Nav } from './Nav';
import { Form } from './Form';
import { OneCIntegration } from './OneCIntegration';
import { Membership } from './Membership';
import { MembershipLog } from './MembershipLog';
import { Planning } from './Planning';
import { Logos } from './Logos';
import { bool } from 'components/columns';
import type { Enterprise } from 'modules/core/types';

const columns: Column<Enterprise>[] = [
  {
    content: 'Company',
    key: 'company',
    render: ({ id, company: { title } }: Enterprise) => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Slug',
    key: 'slug',
  },
  bool('External', 'external'),
];

const initialValues = {
  company: null,
  external: false,
  invoice_sequence: {
    format: '',
    reset_period: null,
  },
  acceptance_sequence: {
    format: '',
    reset_period: null,
  },
  client_agreement_sequence: {
    format: '',
    reset_period: null,
  },
  vat_strategy: null,
  campaign_block_seperator: '/',
  campaign_sequence: null,
};

function mapValues(v: any): any {
  if (v.external) {
    const { company, slug, external } = v;
    return { company, slug, external };
  }
  return v;
}

const model = {
  endpoint: 'core/enterprises/',
  title: 'Agency',
  pluralTitle: 'Agencies',
  entityTitle: ({ company: { title } }: Enterprise) => title,
  permissions: undefined,
};
export const route = {
  path: 'agencies',
  element: <ModelScene {...model} />,
  children: [
    { index: true, element: <IndexScene columns={columns} /> },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues} mapValues={mapValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene heading={null} mapValues={mapValues}>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'logos',
          element: <Logos />,
        },
        {
          path: 'planning',
          element: <Planning />,
        },
        {
          path: 'membership/*',
          element: <Membership />,
        },
        {
          path: 'membership_log/',
          element: <MembershipLog />,
        },
        {
          path: 'onec/*',
          element: <OneCIntegration />,
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};

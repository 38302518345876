import { ReactElement } from 'react';
import { toast } from 'react-toastify';
import { Button, openModal } from '@ff-it/ui';
import { ActivityIcon, DownloadButton } from 'components';
import { CreateBlockDialog } from 'modules/campaign/block/dialogs';
import type { BlockDetails } from 'modules/campaign/block/types';
import { useAgenecyTypeOptions } from 'modules/core/useEnabledTypeOptions';

interface ToolbarProps {
  addBlock: (block: BlockDetails) => void;
  campaignId: number;
  campaignPeriod: {
    minDate: Date;
    maxDate: Date;
  };
}

export function Toolbar({ campaignId, campaignPeriod, addBlock }: ToolbarProps): ReactElement {
  const activityTypeOptions = useAgenecyTypeOptions();
  return (
    <div className="d-flex">
      <div className="btn-group btn-group-sm" data-test-id="toolbar-add-controls">
        {activityTypeOptions.map(({ label, value: type }) => (
          <Button
            key={type}
            variant="outline-dark"
            data-test-id={`add-block-${type}`}
            onClick={() =>
              openModal<BlockDetails>(
                (props) => (
                  <CreateBlockDialog {...props} type={type} campaignPeriod={campaignPeriod} campaignId={campaignId} />
                ),
                { className: 'right', canDismiss: false },
              ).then((res) => {
                if (res) {
                  addBlock(res);
                  toast.success('Block has been added.', { toastId: 'add-block-toast' });
                }
              })
            }
          >
            {label} <ActivityIcon type={type} />
          </Button>
        ))}
      </div>
      <div className="btn-group btn-group-sm ml-auto my-auto" data-test-id="toolbar-export-controls">
        <DownloadButton
          url={`campaign/campaigns/${campaignId}/export_blocks/`}
          icon="file-excel"
          variant="link"
          title="Export blocks"
        >
          Export blocks
        </DownloadButton>
        <DownloadButton
          url={`campaign/campaigns/${campaignId}/export_rows/`}
          icon="file-excel"
          variant="link"
          title="Export rows"
        >
          Export rows
        </DownloadButton>
      </div>
    </div>
  );
}

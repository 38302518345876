import { ReactElement } from 'react';
import cx from 'clsx';
import { formatDate } from 'utilities';
import './Period.scss';

interface PeriodProps {
  date_from: string | null;
  date_to: string | null;
  flat?: boolean;
  normal?: boolean;
}

export function Period({ date_from, date_to, flat, normal }: PeriodProps): ReactElement {
  return (
    <span
      className={cx('period-container', {
        'period-container-flat': flat,
        'period-container-normal': normal,
      })}
    >
      <div className="period-form">
        {date_from ? formatDate(date_from) : '—'}
        {flat && ' ⇢'}
      </div>
      <div className="period-to">{date_to ? formatDate(date_to) : '—'}</div>
    </span>
  );
}

import { atom } from 'jotai';
import { RequestArgs, RequestResult } from '@ff-it/api';
import Mutex from 'utilities';
import { api } from 'services/api';
import { blockContextAtom } from './_private';
import { selectAtom } from 'jotai/utils';
import { flushSync } from 'react-dom';

// make sure this changes only value change
export const etagAtom = selectAtom(blockContextAtom, (ctx) => ctx.item.etag);
export const endpointAtom = selectAtom(blockContextAtom, (ctx) => ctx.endpoint);

const _MUTEX = new Mutex();
// let _cnt = 0;

export const requestHandlerAtom = atom((get) => {
  async function doRequest<R = any, E = any>({ headers, ...rest }: RequestArgs): Promise<RequestResult<R, E>> {
    // const cnt = _cnt++;
    // console.log(`request fire ${cnt}`);

    const setLoading = get(blockContextAtom).setLoading;
    let isLoading = false;

    const loading = setTimeout(() => {
      isLoading = true;
      setLoading(true);
    }, 150);

    // setLoading(true);
    const unlock = await _MUTEX.lock();
    const etag = get(etagAtom);
    const endpoint = get(endpointAtom);

    // console.log(`request start ${cnt} ${etag}`);
    const args = {
      ...rest,
      url: `${endpoint}${rest.url}`,
      headers: {
        ...headers,
        'If-Match': etag,
      },
    };

    const result = await api.request<R, E>(args);
    if (result.ok) {
      const updatedTag = result.response.headers.get('ETag');
      if (updatedTag && updatedTag !== etag) {
        // FIXME: we have to flush else we get stale etag in etagAtom and block updates
        flushSync(() => {
          get(blockContextAtom).setItem((item) => ({ ...item, etag: updatedTag }));
        });
      }
      // console.log(`request done ${cnt} ${updatedTag}`);
    }
    unlock();
    clearTimeout(loading);
    isLoading && setLoading(false);

    return result;
  }
  return doRequest;
});

import { urlon } from '@ff-it/ui';
import { FetcherState, StoredState } from './types';
import { useRef } from 'react';

export const getStoredState = (key: string): StoredState | undefined => {
  const storedString = localStorage.getItem(key);
  if (storedString) {
    try {
      const { s: pageSize, o: sort, f: filter } = urlon.parse(storedString);
      return { pageSize, sort, filter };
    } catch (e) {
      console.log(e);
      return undefined;
    }
  }
  return undefined;
};

export const setStoredState = (key: string, state: FetcherState | undefined): void => {
  if (state) {
    const {
      pageSize,
      pageIndex: _neverPageIndex,
      sort,
      filter: { search: _neverSearch, ...filter },
    } = state;
    const value = urlon.stringify({
      s: pageSize,
      o: sort,
      f: filter,
    });
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  } else {
    localStorage.removeItem(key);
  }
};

export function useRestoredState(storageKey?: string): StoredState | undefined {
  const ref = useRef(true);

  const firstRender = ref.current;
  ref.current = false;

  if (firstRender && storageKey) {
    return getStoredState(storageKey);
  }
}

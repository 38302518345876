import { Link } from 'react-router-dom';
import { Column } from '@ff-it/grid';
import { dateFrom, dateTo } from 'components/columns';
import { Badge, BoundStates, ClientTitle } from 'components';
import { sepFormat } from 'utilities';
import type { ListCampaign } from 'modules/campaign/campaign/types';

const sumProps = {
  width: 120,
  className: 'text-right',
  headerClassName: 'text-right',
};

export const columns: Column<ListCampaign>[] = [
  {
    content: 'Number',
    key: 'number',
    width: 120,
    render: ({ id, number }: ListCampaign) => <Link to={`${id}/planning`}>{number}</Link>,
    sortable: true,
  },
  {
    content: 'State',
    width: 100,
    key: 'sums.state',
    sortable: true,
    render: ({ sums: { state } }) => <Badge state={state} />,
  },
  {
    content: 'Client',
    key: 'related_client_title',
    render: ClientTitle,
    sortable: true,
  },
  {
    content: 'Title',
    key: 'title',
    sortable: true,
  },
  { ...dateFrom(), sortable: true },
  { ...dateTo(), sortable: true },

  {
    ...sumProps,
    content: 'Income',
    key: 'sums.income_total',
    render: ({ sums: { income_total, state } }) => (state !== 'CANCELED' ? sepFormat(income_total) : null),
    sortable: true,
  },
  {
    ...sumProps,
    content: 'Expense',
    key: 'sums.expense_total',
    render: ({ sums: { expense_total, state } }) => (state !== 'CANCELED' ? sepFormat(expense_total) : null),
    sortable: true,
  },
  {
    width: 60,
    className: 'align-middle',
    key: 'sums',
    render: ({ sums }: ListCampaign) => (sums.state !== 'CANCELED' ? <BoundStates {...sums} /> : null),
  },
  {
    ...sumProps,
    content: 'Revenue',
    key: 'sums.revenue',
    sortable: true,
    render: ({ sums: { revenue, state } }) => (state !== 'CANCELED' ? sepFormat(revenue) : null),
  },
];

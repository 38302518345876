import { ComponentType, ReactElement, ReactNode } from 'react';
import { Form } from '@ff-it/form';
import { LoadingContainer } from '@ff-it/ui';
import { Breadcrumb, Page } from 'components';
import { form } from './style.css';
import { LinksProvider } from './LinksProvider';
import type { LinkerContext, Handler } from './types';

export type ContainerProps<Data, P> = Handler<Data> &
  P & {
    title: ReactNode;
    children: ComponentType<LinkerContext & { url: string } & P>;
  };

export function Container<Data, P>({
  title,
  children: Component,
  isLoading,
  initialValues,
  onSubmit,
  url,
  disabled,
  ...props
}: ContainerProps<Data, P>): ReactElement {
  return (
    <Page className="scene scene-nested flex-grow-1 d-flex flex-column">
      <Breadcrumb to={null}>{title}</Breadcrumb>
      <LoadingContainer loading={isLoading} className="flex-grow-1 flex-column d-flex">
        {initialValues && (
          <Form initialValues={initialValues} onSubmit={onSubmit} className={form} disabled={disabled}>
            <LinksProvider url={url}>
              {(ctx) => <Component {...ctx} url={url} disabled={disabled} {...(props as P)} />}
            </LinksProvider>
          </Form>
        )}
      </LoadingContainer>
    </Page>
  );
}

import { ReactElement, useMemo } from 'react';
import invariant from 'tiny-invariant';
import { toast } from 'react-toastify';
import { Actions } from './Actions';
import type { Action, ActionsProps } from './Actions';

export interface PromiseAction extends Action {
  successMessage?: string;
  promise: () => Promise<any>;
  lock: boolean;
  // patch item in dispatch
  // FIXME: success and failure handlers should be definable on items
  partial?: boolean;
  onSuccess?: (i: any) => void;
}

export type MaybePromiseAction = Action | PromiseAction;

export function isPromiseAction(action: MaybePromiseAction): action is PromiseAction {
  return (action as PromiseAction).promise !== undefined;
}

// Binds promises to onClickHandlers
export function usePromiseActions(
  actions: Array<MaybePromiseAction>,
  toggleLoading: (loading: boolean) => void,
  onSuccess: (i: any) => void,
  onFailure?: (f: any) => void,
): Action[] {
  return useMemo(
    () =>
      actions.map((action) => {
        if (isPromiseAction(action)) {
          const { promise, successMessage = 'Success', lock, ...rest } = action;
          invariant(!action.button?.onClick, "onClick handler present, can't bind action");
          const onClick = async (): Promise<void> => {
            lock && toggleLoading(true);
            try {
              const res = await promise();
              if (typeof res != 'undefined') {
                toast.success(successMessage, { toastId: `action-${action.action}-toast` });
                onSuccess(action.partial ? (current: any) => ({ ...current, ...res }) : res);
              }
            } catch (e) {
              toast.warn('Action canceled', { toastId: `action-${action.action}-toast` });
              onFailure && onFailure(e);
            }
            lock && toggleLoading(false);
          };
          return {
            ...rest,
            button: {
              ...rest.button,
              onClick,
            },
          };
        }
        return action;
      }),
    [actions, toggleLoading, onSuccess, onFailure],
  );
}

export interface PomiseActionsProps extends Omit<ActionsProps, 'actions'> {
  actions: Array<MaybePromiseAction>;
  toggleLoading: (loading: boolean) => void;
  onSuccess: (i: any) => void;
  onFailure?: (f: any) => void;
}

export function PromiseActions({
  actions,
  toggleLoading,
  onSuccess,
  onFailure,
  ...props
}: PomiseActionsProps & Omit<ActionsProps, 'actions'>): ReactElement {
  const boundActions = usePromiseActions(actions, toggleLoading, onSuccess, onFailure);
  return <Actions {...props} actions={boundActions} />;
}

import type { BoundState } from 'modules/campaign/common/types';
import { ReactElement } from 'react';
import type { Direction } from 'types';
import CLIENT_NONE from './CLIENT_NONE.svg';
import CLIENT_PARTIAL from './CLIENT_PARTIAL.svg';
import CLIENT_FULL from './CLIENT_FULL.svg';
import AGENCY_NONE from './AGENCY_NONE.svg';
import AGENCY_PARTIAL from './AGENCY_PARTIAL.svg';
import AGENCY_FULL from './AGENCY_FULL.svg';

type IncomeBoundStateProps = {
  direction: Direction;
  state: BoundState;
  className?: string;
};

const icons = {
  CLIENT_NONE,
  CLIENT_PARTIAL,
  CLIENT_FULL,
  AGENCY_NONE,
  AGENCY_PARTIAL,
  AGENCY_FULL,
} as const;

export function InvoiceBoundState({ direction, state, className }: IncomeBoundStateProps): ReactElement | null {
  if (state == 'EMPTY') {
    return null;
  }
  return <img src={icons[`${direction}_${state}`]} className={className} />;
}

import type { ReactElement } from 'react';
import { useFieldArray } from 'react-final-form-arrays';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Row from './Row';

import { getRowTotal } from 'modules/invoicing/income/calculate';

export function RowContainer(): ReactElement {
  const { fields } = useFieldArray('rows', { subscription: { value: true } });
  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <tbody>
        {fields.map((name, index) => (
          <Row
            key={fields.value[index].id || fields.value[index].__NEW__}
            index={index}
            name={name}
            remove={fields.remove}
            move={fields.move}
            kind={fields.value[index].kind}
            destination={fields.value[index].compensated_to?.destination}
            total={getRowTotal(fields.value[index])}
          />
        ))}
      </tbody>
    </DndProvider>
  );
}

import { ReactElement } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import cx from 'clsx';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  openModal,
} from '@ff-it/ui';
import { DialogForm, Icon } from 'components';
import { Form } from './Form';
import { PlanFactor } from 'modules/campaign/block/types';
import {
  createFactorAtom,
  planFactorsAtom,
  managerOpenAtom,
  removeFactorAtom,
  updateFactorAtom,
} from '../atoms/factors';
import { FactorOption } from './FactorOption';
import './FactorManager.scss';

function FactorsList({ isEditable }: { isEditable: boolean }): React.ReactElement {
  const factors = useAtomValue(planFactorsAtom);
  const updateFactor = useSetAtom(updateFactorAtom);
  const removeFactor = useSetAtom(removeFactorAtom);

  const onUpdate = (factor: PlanFactor): Promise<void> =>
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Update factor"
        initialValues={factor}
        submitHandler={(payload) => updateFactor(factor.id, payload)}
        onRemove={() => removeFactor(factor.id)}
      >
        <Form update />
      </DialogForm>
    ));

  if (factors.length == 0) {
    return (
      <DialogBody>
        <Alert variant="info" className="mb-0">
          This plan has no factors
        </Alert>
      </DialogBody>
    );
  }
  const numFactors = factors.length;

  return (
    <DialogBody>
      {factors.map((factor, idx) => (
        <Box
          key={factor.id}
          display="flex"
          alignItems="flex-start"
          className={cx({ 'border-bottom pb-2 mb-2': numFactors != idx + 1 })}
        >
          <div className="col-3">
            <div>{factor.department.name}</div>
            <small>({factor.department.kind})</small>
          </div>
          <FactorOption className="col" factor={factor} />
          {isEditable && (
            <Button
              variant="outline-primary"
              type="button"
              className="border-0 mx-3"
              size="sm"
              onClick={() => onUpdate(factor)}
            >
              <Icon icon="pencil" />
            </Button>
          )}
        </Box>
      ))}
    </DialogBody>
  );
}

function FactorManagerDialog({ isEditable }: { isEditable: boolean }): React.ReactElement {
  const createFactor = useSetAtom(createFactorAtom);
  const onAdd = (): Promise<void> =>
    openModal((props) => (
      <DialogForm
        {...props}
        dialogHeader="Create factor"
        initialValues={{
          name: '',
          rate: null,
          description: '',
          client: true,
          agency: true,
        }}
        submitHandler={createFactor}
      >
        <Form />
      </DialogForm>
    ));

  return (
    <>
      <DialogHeader title="Factors" />
      <FactorsList isEditable={isEditable} />
      <DialogFooter>
        {isEditable && (
          <Button variant="outline-primary" onClick={onAdd}>
            Add factor
            <Icon icon="plus" className="ml-2" />
          </Button>
        )}
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </>
  );
}

export function FactorManager({ isEditable }: { isEditable: boolean }): ReactElement | null {
  const [isOpen, setIsOpen] = useAtom(managerOpenAtom);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="factor_manager_dialog">
        <FactorManagerDialog isEditable={isEditable} />
      </DialogContent>
    </Dialog>
  );
}

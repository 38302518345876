import { ReactElement } from 'react';
import cx from 'clsx';
import { OptionProps } from 'react-select';

export function PlainOption({
  innerRef,
  isDisabled,
  isFocused,
  isSelected,
  children,
  className,
  innerProps,
}: OptionProps): ReactElement {
  return (
    <div
      className={cx(
        {
          option: true,
          'option--plain': true,
          'option--selectable': true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      ref={innerRef}
      aria-disabled={isDisabled}
      {...innerProps}
    >
      <div className="option__label">{children}</div>
    </div>
  );
}

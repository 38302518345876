import { BoundRowMonthSum } from 'modules/campaign/row/common';
import { InvoiceBoundState } from 'modules/invoicing/common/components';
import { ReactNode } from 'react';
import { sepFormat } from 'utilities';

export function renderValue({
  target_income,
  planned_income,
  income_state,
  target_expense,
  planned_expense,
  expense_state,
}: BoundRowMonthSum): ReactNode {
  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <InvoiceBoundState direction="CLIENT" state={income_state} />
        <div className="ml-auto">
          {planned_income != target_income && (
            <small className="mr-1 text-muted" data-testid="planned_income">
              ({sepFormat(planned_income)})
            </small>
          )}
          <span data-testid="target_income">{sepFormat(target_income)}</span>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center">
        <InvoiceBoundState direction="AGENCY" state={expense_state} />
        <div className="ml-auto">
          {planned_expense !== target_expense && (
            <small className="mr-1 text-muted" data-testid="planned_expense">
              ({sepFormat(planned_expense)})
            </small>
          )}
          <span data-testid="target_expense">{sepFormat(target_expense)}</span>
        </div>
      </div>
    </>
  );
}

import { ReactElement } from 'react';
import { PopupCell, ChoiceSelectEditor } from '../../Editor';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { webDeviceOptions } from 'options';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import { WEBDevice } from 'modules/core/types';
import type { Row } from 'modules/campaign/row';

function View(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.web_device;

  // dash for locked
  const render = value ? value.join(', ') : !props.isEditable ? '—' : '';

  return <span className="contain">{render}</span>;
}

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.web_device as any;
  const options = props.row.config.web_device?.resolved_value as WEBDevice[];
  const setValue = useFieldUpdater(props.row.id, 'web_device');

  const render = value && value.length > 0 ? value.join(', ') : '';
  const availableOptions = options ? webDeviceOptions.filter((o) => options.includes(o.value)) : webDeviceOptions;

  return (
    <PopupCell {...props} cellContent={<span className="contain">{render}</span>} onRequestClose={props.stopEdit}>
      <ChoiceSelectEditor
        value={value}
        setValue={setValue}
        options={availableOptions}
        isSimple
        label={options ? 'Product devices' : 'Devices'}
      />
    </PopupCell>
  );
}

export const web_device: GridColumn<Row> = {
  key: 'web_device',
  header: 'Device',
  width: 86,
  renderCell: (props) =>
    props.row.kind === 'MEDIA' ? props.editing ? <Edit {...props} /> : <View {...props} /> : null,
  editable: (row) => fieldIsEditable(row, 'web_device'),
};

import { ReactElement } from 'react';
import { Placement } from 'modules/campaign/row';
import { getOptionLabel } from './util';
import { EditProps } from '../useEditableChoiceProps';
import { RowChoiceEditor } from '../../../Editor';
import { PlacementForm } from 'modules/supplier/products/rtb';

const initialValues = {
  title: '',
};

export function Editor(props: EditProps<Placement>): ReactElement {
  return (
    <RowChoiceEditor
      initialValues={initialValues}
      dialogHeader="Placement"
      label="Row placements"
      addLabel="Add placement"
      {...props}
      form={<PlacementForm />}
      formatOptionLabel={getOptionLabel}
    />
  );
}

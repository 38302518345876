import * as activities from './Activities';
import * as agencyRoles from './AgencyRoles';
import * as banks from './Banks';
import * as companies from './Companies';
import * as departmetSet from './DepartmentSet';
import * as media from './Media';
import * as services from './Services';
import * as provided from './ProvidedDepartments';
import * as membership from './Membership';
import * as ticketAssignee from './TicketAssignees';
import * as brands from './Brands';

export const route = {
  path: 'preferences',
  children: [
    activities.route,
    agencyRoles.route,
    banks.route,
    companies.route,
    departmetSet.route,
    media.route,
    services.route,
    provided.route,
    ticketAssignee.route,
    membership.route,
    brands.route,
  ],
};

import { ReactElement } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { SelectEditor } from '../../Editor';
import { getUnitOptions } from 'options';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import { blockTypeAtom } from 'modules/campaign/block/atoms';
import { useAtomValue } from 'jotai';
import type { Row } from 'modules/campaign/row';

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.unit;
  const setValue = useFieldUpdater(props.row.id, 'unit');

  const type = useAtomValue(blockTypeAtom);

  return (
    <SelectEditor
      value={value}
      stopEdit={props.stopEdit}
      setValue={setValue}
      options={getUnitOptions(props.row.kind, type)}
    />
  );
}

export const unit: GridColumn<Row> = {
  key: 'unit',
  header: 'Unit',
  minWidth: 76,
  className: 'text-left',
  renderCell: (props) => {
    return props.row.kind === 'SMART' ? (
      <span className="contain">{props.row.application}</span>
    ) : props.editing ? (
      <Edit {...props} />
    ) : (
      <span className="contain">{props.row.unit}</span>
    );
  },
  editable: (row) => fieldIsEditable(row, 'unit'),
};

import { ReactElement } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { PermissionDenied } from './components';
import { Login, Logout, PasswordReset, PasswordResetConfirm, Invite, Agency, AgencyGroup } from './routes';
import { APIProvider } from '@ff-it/api';
import { stringify } from 'qs';
import { api, SessionProvider, SessionScope, SessionScopeResolver, swrOptions, useSession, useUser } from 'services';
import { SWRConfig } from 'swr/_internal';
import { ModalContainer } from '@ff-it/ui';

function ErrorElement(): null {
  throw Error('Sample error');
}

function GuessScope(): ReactElement {
  const user = useUser();
  let s: SessionScope | undefined = user.last_enterprise
    ? user.membership.find((m) => m.id === user.last_enterprise)
    : user.membership[0];
  if (!s && user.agency_group) {
    s = user.agency_group;
  }
  if (s) {
    return <Navigate to={`/${s.slug}/`} replace />;
  }

  return <PermissionDenied>Failed to resolve enterprise</PermissionDenied>;
}

function ProtectedRoute(): ReactElement {
  const s = useSession();
  const { pathname } = useLocation();
  if (!s.isAuthenticated) {
    const to = pathname === '/' ? '/login' : `/login?${stringify({ next: pathname })}`;
    return <Navigate to={to} />;
  }

  return <Outlet />;
}

function App(): ReactElement {
  return (
    <Router>
      <SWRConfig value={swrOptions}>
        <APIProvider client={api}>
          <ModalContainer />
          <SessionProvider>
            <ToastContainer
              theme="colored"
              position="top-center"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              draggable={false}
              transition={Slide}
            />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/password_reset" element={<PasswordReset />} />
              <Route path="/password_reset_confirm/:uidb64/:token/" element={<PasswordResetConfirm />} />
              <Route path="/invite/:token/" element={<Invite />} />

              <Route path="/*" element={<ProtectedRoute />}>
                <Route
                  path=":slug/*"
                  element={
                    <SessionScopeResolver>
                      {({ kind }) => (kind === 'AGENCY' ? <Agency /> : <AgencyGroup />)}
                    </SessionScopeResolver>
                  }
                />
                <Route index element={<GuessScope />} />
              </Route>
              <Route path="/trigger_error" element={<ErrorElement />}></Route>
            </Routes>
          </SessionProvider>
        </APIProvider>
      </SWRConfig>
    </Router>
  );
}

export default App;

import { Action } from 'components';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { getDRFFormError } from '@ff-it/form';
import { openModal } from '@ff-it/ui';
import { useAtomCallback } from 'jotai/utils';
import { actionErrorOrThrow } from 'utilities';
import { ImportRowsDialog } from './ImportCodeDialog';
import { RowActionProps } from './types';
import { blockIdAtom, requestHandlerAtom } from 'modules/campaign/block/atoms';

import { planAtom } from '../../atoms/plan';
import { rowSelection } from '../../atoms/controls';
import { ImportPlanDialog } from './ImportPlanDialog';

export function useImportActions({ empty, locked, type }: RowActionProps): Action[] {
  const onPlanImport = useAtomCallback(
    useCallback(async (get, set) => {
      const submitHandler = async (values: any): Promise<any> => {
        if (!empty && !window.confirm('This is going to rewrite rows. All changes will be lost. Are you sure?')) {
          return;
        }
        const fd = new FormData();
        for (const [key, value] of Object.entries(values)) {
          if (value) {
            fd.append(key, value as any);
          }
        }
        const api = get(requestHandlerAtom);
        const res = await api({
          url: 'plan/rows/xlsx/',
          method: 'POST',
          body: fd,
        });
        if (res.ok) {
          set(rowSelection, new Set());
          set(planAtom, res.data);
          toast.success('Plan has been imported');
        } else {
          const formError = getDRFFormError(res);
          if (formError) {
            return formError;
          }
          actionErrorOrThrow(res);
        }
      };
      await openModal((props) => <ImportPlanDialog {...props} submitHandler={submitHandler} />);
    }, []),
  );
  const onCodeImport = useAtomCallback(
    useCallback(async (get, set) => {
      const exclude = get(blockIdAtom);
      const submitHandler = async ({ block }: any): Promise<any> => {
        const api = get(requestHandlerAtom);
        const res = await api({
          url: `plan/rows/import/${block.id}/`,
          method: 'POST',
        });
        if (res.ok) {
          set(rowSelection, new Set());
          set(planAtom, res.data);
          toast.success('Rows have been imported');
        } else {
          const formError = getDRFFormError(res);
          if (formError) {
            return formError;
          }
          actionErrorOrThrow(res);
        }
      };
      openModal((props) => <ImportRowsDialog type={type} exclude={exclude} submitHandler={submitHandler} {...props} />);
    }, []),
  );

  return useMemo(() => {
    const actions: Action[] = [];

    if (!locked) {
      actions.unshift({
        action: 'import-plan',
        button: {
          variant: 'outline-secondary',
          size: 'sm',
          children: 'Import plan',
          onClick: onPlanImport,
        },
      });
    }
    if (empty) {
      actions.unshift({
        action: 'import-code',
        button: {
          variant: 'outline-secondary',
          size: 'sm',
          children: 'Import rows (code)',
          onClick: onCodeImport,
        },
      });
    }

    return actions;
  }, [empty, locked]);
}

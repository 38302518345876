import { ReactElement } from 'react';
import type { SmartRow } from 'modules/campaign/row';
import './SmartLabel.scss';

export function SmartLabel({
  rate,
  application,
  direction,
}: Pick<SmartRow, 'rate' | 'application' | 'direction'>): ReactElement {
  const content = rate !== null ? (application === 'RATE' ? `${rate}%` : `${rate}×`) : '—';
  return <span className={`smart-label smart-label--${direction}`}>{content}</span>;
}

import { ReactNode } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import type { Row } from 'modules/campaign/row';

export function viewSum(props: RenderCellProps<Row>): ReactNode {
  const value = props.row.sums.quantity;

  return (
    <div data-testid="planned" title="Planned quantity">
      {value ? `${value}` : null}
    </div>
  );
}

// read only quantity for specialized rows
export const sumQuantity: GridColumn<Row> = {
  key: 'quantity',
  header: 'Quantity',
  minWidth: 76,
  className: 'text-center',
  renderCell: (props) => (props.row.kind === 'SMART' ? null : viewSum(props)),
};

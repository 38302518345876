import { Options } from 'options';
import { useMemo } from 'react';
import { useMembership } from 'services';

export function useAgencyVAT(): number {
  const { country_code } = useMembership();
  if (country_code in window.__RUNTIME_CONFIG__.VAT_RATES) {
    return window.__RUNTIME_CONFIG__.VAT_RATES[country_code];
  }
  return 0;
}

export function useAgencyVatOptions(): Options<number> {
  const rate = useAgencyVAT();

  return useMemo(() => {
    const res = [
      {
        label: '0%',
        value: 0,
      },
      {
        label: '5%',
        value: 5,
      },
    ];
    if (!res.some((o) => o.value === rate)) {
      res.push({
        label: `${rate}%`,
        value: rate,
      });
    }

    return res;
  }, [rate]);
}

import { Suspense, type ReactElement } from 'react';
import { Box, Spinner } from '@ff-it/ui';
import { EntityFormActions, useEntity } from 'components';
import { LinkedCampaigns } from './LinkedCampaigns';
import { Invoice } from 'modules/invoicing/types';
import { ConsumedLinks } from 'modules/applicator/ConsumedLinks';
import { credit } from 'modules/invoicing/income/actions';
import { isLinkable } from 'modules/linker/isLinkable';

export function Sidebar(): ReactElement | null {
  const context = useEntity<Invoice>();
  const { item, endpoint } = context;
  if (item.state === 'CANCELED') {
    return null;
  }
  const linkabke = isLinkable(item);

  return (
    <Box
      data-testid="sidebar"
      display="flex"
      paddingLeft="lg"
      alignItems="flex-start"
      flexDirection="column"
      style={{
        flex: 1,
      }}
    >
      <Suspense
        fallback={
          <Box paddingY="xxl" marginX="auto">
            <Spinner color="primary" />
          </Box>
        }
      >
        {linkabke && <LinkedCampaigns endpoint={endpoint} bound={item.sums.link_applied} />}
        {/* @TODO reverse applicalbe or move server side */}
        {item.type == 'INVOICE' && (
          <ConsumedLinks
            type="credit"
            endpoint={endpoint}
            title={
              <Box display="flex" alignItems="center">
                Applied credit notes
                {item.direction === 'income' && (
                  <Box marginLeft="auto" marginY="-sm">
                    <EntityFormActions actions={[credit]} />
                  </Box>
                )}
              </Box>
            }
          />
        )}
        {item.type == 'INVOICE' && item.direction == 'income' && (
          <ConsumedLinks type="advance" endpoint={endpoint} title="Applied advances" />
        )}
        {item.type == 'INVOICE' && item.direction == 'income' && (
          <ConsumedLinks type="compensation" endpoint={endpoint} title="Compensated invoices" />
        )}
      </Suspense>
    </Box>
  );
}

import { sepFormat } from 'utilities';
import { Sums } from './types';
import { TableColumns } from '@ff-it/ui';

const sumProps = {
  // headerClassName: 'text-right',
  // className: 'text-right',
  cellProps: { textAlign: 'end' },
  sortable: true,
} as const;

export const sumColumns: TableColumns<Sums> = [
  {
    content: 'Turnover',
    title: 'client price + agency fee',
    key: 'turnover',
    render: ({ turnover }) => sepFormat(turnover),
    ...sumProps,
  },
  {
    content: 'Client price',
    title: 'client costs',
    key: 'client_planned',
    render: ({ client_planned }) => sepFormat(client_planned),
    ...sumProps,
  },
  {
    content: 'Agency fee',
    title: 'client costs',
    key: 'client_fee_planned',
    render: ({ client_fee_planned }) => sepFormat(client_fee_planned),
    ...sumProps,
  },
  {
    content: 'Provider',
    title: 'agency costs',
    key: 'provider_planned',
    render: ({ provider_planned }) => sepFormat(provider_planned),
    ...sumProps,
  },
  {
    content: 'Revenue',
    title: 'turnover - provider',
    key: 'revenue',
    render: ({ revenue }) => sepFormat(revenue),
    ...sumProps,
  },
  {
    content: '%',
    key: 'revenue_ratio',
    title: 'revenue / turnover',
    width: 80,
    render: ({ revenue_ratio }) => revenue_ratio && `${sepFormat(revenue_ratio)}%`,
    ...sumProps,
  },
  {
    content: 'AVB',
    key: 'avb',
    title: 'agency bonus',
    render: ({ avb }) => sepFormat(avb),
    ...sumProps,
  },
  {
    content: 'CVB',
    key: 'cvb',
    title: 'client bonus',
    render: ({ cvb }) => sepFormat(cvb),
    ...sumProps,
  },
  {
    content: 'Profit',
    key: 'profit',
    title: 'revenue + AVB - CVB',
    render: ({ profit }) => sepFormat(profit),
    ...sumProps,
  },
  {
    content: '%',
    key: 'profit_ratio',
    title: 'profit / turnover',
    width: 80,
    render: ({ profit_ratio }) => profit_ratio && `${sepFormat(profit_ratio)}%`,
    ...sumProps,
  },
  {
    content: 'Target profit',
    key: 'target_profit',
    title: 'revenue + target AVB - target CVB',
    render: ({ target_profit }) => sepFormat(target_profit),
    ...sumProps,
  },
  {
    content: '%',
    key: 'target_profit_ratio',
    title: 'target profit / turnover',
    width: 80,
    render: ({ target_profit_ratio }) => target_profit_ratio && `${sepFormat(target_profit_ratio)}%`,
    ...sumProps,
  },
];

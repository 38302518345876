import { ReactElement } from 'react';
import { Box, Col, Row } from '@ff-it/ui';
import { Submit } from '@ff-it/form';
import { Campaigns } from './Campaigns';
import { useLinkerState } from './useLinkerState';
import { FilterForm, useFilterState } from '../filter';
import { LinkerSummary } from '../LinkerSummary';
import { LinkerFields } from '../LinkerFields';
import { LinkerProps } from './types';
import { FilterFields } from './FilterFields';

export function NetLinker({ invoice, disabled, direction, ...props }: LinkerProps): ReactElement {
  const initialValues = {
    client: (invoice.clients || []).map(({ id }) => id.toString()),
  };

  const [filterState, setFilterState] = useFilterState(initialValues);

  const { summary, linked, candidates } = useLinkerState(invoice, props, filterState);
  return (
    <Row>
      <Col paddingRight="sm">
        <Box display="flex" alignItems="flex-start">
          <Box flexGrow={1}>
            <LinkerFields />
          </Box>
          {!disabled && <Submit className="ml-3" />}
        </Box>
        <LinkerSummary state={summary} direction={direction} marginBottom="sm" />
        <Campaigns side="linked" rows={linked} entities={props.entities} />
      </Col>
      {disabled ? (
        <Col />
      ) : (
        <Col paddingLeft="sm">
          <FilterForm filter={filterState} setFilter={setFilterState}>
            <FilterFields entities={props.entities} clients={initialValues.client} />
          </FilterForm>
          <Campaigns side="candidates" rows={candidates} entities={props.entities} />
        </Col>
      )}
    </Row>
  );
}

import { ControlledTable, ControlledTableProps, KeyGetter, RowType, TableFilter } from 'components/ControlledTable';
import { ReactElement, ReactNode } from 'react';
import { Box } from '@ff-it/ui';
import { TotalsHeader } from './TotalsHeader';
import { XLSXDownloadButton } from './XLSXDownload';
import { reportTable } from './style.css';

type ReportTableProps<R extends RowType> = Pick<ControlledTableProps<R>, 'columns' | 'initialFilter'> & {
  url: string;
  filterFields: ReactNode;
};

export function ReportTable<R extends RowType>({ url, filterFields, ...rest }: ReportTableProps<R>): ReactElement {
  const getKey: KeyGetter = ({ filter, pageSize, pageIndex, sort }) => {
    /*
    Same as `generic DRF key getter` exepct for
      * POST
      * passing sort as object
     */

    return {
      url,
      method: 'POST',
      body: {
        // FIXME
        ...filter,
        page_size: pageSize,
        page: pageIndex,
        ordering: sort
          ? {
              order: sort.direction || 'DESC',
              key: sort.key,
            }
          : undefined,
      },
    };
  };

  return (
    <ControlledTable<R>
      pageSize={50}
      getKey={getKey}
      storageKey={url}
      subHeaders={<TotalsHeader />}
      size="sm"
      fontSize="sm"
      variant="dark"
      hover
      striped
      fixed
      tableClassName={reportTable}
      filter={
        <Box display="flex" marginTop="sm">
          <TableFilter>{filterFields}</TableFilter>
          <XLSXDownloadButton url={url} />
        </Box>
      }
      {...rest}
    />
  );
}

import { ReactElement, ReactNode, useCallback } from 'react';
import cx from 'clsx';
import { Form, Submit, useSubmitHandler, SubmitHandler } from '@ff-it/form';
import { RequestFailure } from '@ff-it/api';
import { Alert, Box } from '@ff-it/ui';
import { useModel, useEntity } from './context';
import { Perm, useHasPerms } from 'core/permissions';
import { Page, Heading, Breadcrumb } from '../layout';
import { PermissionDenied } from './errors';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { actionErrorOrThrow } from 'utilities';

export function DeleteForm({
  onSubmit,
  children,
}: {
  onSubmit: SubmitHandler<any>;
  children?: ReactNode;
}): ReactElement {
  return (
    <Form onSubmit={onSubmit}>
      <Alert variant="danger">
        Are you sure you want to delete this item?
        <hr />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Submit variant="danger">Delete</Submit>
          {children}
        </Box>
      </Alert>
    </Form>
  );
}

interface DeleteSceneProps {
  perm?: Perm;
  successMessage?: ReactNode;
  successPath?: string;
  className?: string;
}

export function DeleteScene(props: DeleteSceneProps): ReactElement {
  const navigate = useNavigate();

  const { permissions, title } = useModel();
  const { endpoint: url } = useEntity<any>();
  const { perm, successMessage = 'Item has been deleted.', successPath = '../../', className } = props;

  const onSuccess = useCallback(
    (): void => {
      toast.success(successMessage, { toastId: 'delete-scene-toast' });
      navigate(successPath);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [successMessage, successPath],
  );

  const onSubmit = useSubmitHandler(
    {
      url,
      method: 'DELETE',
    },
    {
      onSuccess,
      onFailure: (e: RequestFailure<any>) => {
        actionErrorOrThrow(e);
      },
    },
  );

  const [hasDelete] = useHasPerms(perm || permissions?.delete);
  if (!hasDelete) {
    return <PermissionDenied />;
  }
  return (
    <Page className={cx('scene scene-delete', className)}>
      <Breadcrumb to={null}>Delete</Breadcrumb>
      <Heading title={`Delete ${title}`} />
      <DeleteForm onSubmit={onSubmit} />
    </Page>
  );
}

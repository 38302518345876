import { Icon } from 'components';
import type { ReactElement } from 'react';
import type { BookingState, InvoiceState } from './types';

const iconVariantMap: Record<BookingState, string> = {
  NONE: 'text-secondary',
  CREATED: 'text-warning',
  BOOKED: 'text-success',
};

const titleMap: Record<BookingState, string> = {
  NONE: 'Not booked',
  CREATED: 'Created',
  BOOKED: 'Booked',
};

export function BookingStateBadge({
  booking_state,
  state,
}: {
  state: InvoiceState;
  booking_state: BookingState;
}): ReactElement | null {
  if (state === 'CANCELED' && booking_state === 'NONE') {
    return null;
  }
  return <Icon icon="book-open" className={iconVariantMap[booking_state]} title={titleMap[booking_state]} />;
}

import { useMemo } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { openModal } from '@ff-it/ui';
import { Action } from 'components';
import { CommentRowDialog } from 'modules/campaign/block/dialogs';
import { Row } from 'modules/campaign/row';
import { useFieldUpdater } from '../../hooks';
import { createRowAtom, removeRowsAtom, strategyAtom } from '../../atoms/plan';

export function useSelectionActions(
  {
    rows,
    locked,
  }: {
    rows: Row[];
    locked: boolean;
  },
  clear: () => void,
  scrollToRow: (rowId: number) => void,
): Action[] {
  const strategy = useAtomValue(strategyAtom);
  const add = useSetAtom(createRowAtom);
  const remove = useSetAtom(removeRowsAtom);
  // HACK
  const commentUpdater = useFieldUpdater(rows.length == 1 ? rows[0].id : 0, 'comment');

  return useMemo(() => {
    const empty = rows.length === 0;

    return [
      {
        action: 'copy',
        button: {
          children: 'Copy',
          variant: 'outline-secondary',
          icon: ['far', 'copy'],
          size: 'sm',
          onClick: async (): Promise<void> => {
            const created = [];
            for (const i in rows) {
              const payload = strategy.copyRow(rows[i]);
              created.push(await add(payload));
            }
            clear();
            scrollToRow(created[created.length - 1].id);
          },
          disabled: empty,
        },
      },
      {
        action: 'comment',
        button: {
          children: 'Comment',
          variant: 'outline-secondary',
          icon: 'comment-dots',
          size: 'sm',
          onClick: () =>
            openModal((props) => <CommentRowDialog {...props} comment={rows[0].comment} />).then((comment) => {
              if (typeof comment !== 'undefined') {
                return commentUpdater(comment).then((res) => {
                  clear();
                  return res;
                });
              }
            }),
          disabled: rows.length != 1,
        },
      },
      {
        action: 'remove',
        button: {
          children: 'Delete',
          variant: 'outline-danger',
          icon: 'trash',
          size: 'sm',
          className: 'ml-3',
          disabled: empty || locked,
          onClick: async (): Promise<void> => {
            await remove(rows.map(({ id }) => id));
          },
        },
      },
    ];
  }, [rows, locked, strategy]);
}

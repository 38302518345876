import { BoundStates } from 'components';
import { useAtomValue } from 'jotai';
import { GridColumn } from 'components/Grid';
import MEDIA from './MEDIA.svg';
import MEDIA_locked from './MEDIA_locked.svg';
import SERVICE from './SERVICE.svg';
import SERVICE_locked from './SERVICE_locked.svg';
import SMART from './SMART.svg';
import SMART_locked from './SMART_locked.svg';
import { blockSumsAtom } from 'modules/campaign/block/atoms';
import type { Row } from 'modules/campaign/row';

const icons = {
  MEDIA,
  MEDIA_locked,
  SERVICE,
  SERVICE_locked,
  SMART,
  SMART_locked,
} as const;

function StateHeader(): React.ReactElement | null {
  const sums = useAtomValue(blockSumsAtom);
  return sums ? <BoundStates {...sums} /> : null;
}

export const state: GridColumn<Row> = {
  key: 'state',
  width: 50,
  className: 'align-items-center grd__c--flush grd__c--state',
  header: <StateHeader />,
  renderCell: ({ row: { id: rowId, kind, sums, comment } }) => {
    const iconKey = (sums?.is_locked ? `${kind}_locked` : kind) as keyof typeof icons;
    return (
      <>
        <img src={icons[iconKey]} alt={kind} />
        {!sums.canceled && <BoundStates className="d-block mt-1" {...sums} row={rowId} />}
        {comment && (
          <div className="row-comment" title={comment}>
            {comment}
          </div>
        )}
      </>
    );
  },
};

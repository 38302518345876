import { Box } from '@ff-it/ui';
import cx from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { container, header, body } from './style.css';

interface CardProps {
  className?: string;
  title?: ReactNode;
  children: ReactNode;
  testId?: string;
}

export function Card({ className, title, children, testId }: CardProps): ReactElement {
  return (
    <Box className={cx(container, className)} data-testid={testId}>
      {title && <CardHeader>{title}</CardHeader>}
      {children}
    </Box>
  );
}

export function CardHeader({ className, children }: { className?: string; children?: ReactNode }): ReactElement {
  return <Box className={cx(header, className)}>{children}</Box>;
}

export function CardBody({ className, children }: { className?: string; children?: ReactNode }): ReactElement {
  return <Box className={cx(body, className)}>{children}</Box>;
}

import { Period } from 'components';
import { useAtomValue } from 'jotai';
import { ReactElement } from 'react';
import { formatDate, nl2br, sepFormat } from 'utilities';
import { DeadlineWarning } from '../../dialogs/BriefForm/CommonFields';
import { CreativeBlockDetails } from '../../types';
import { blockAtom } from '../../atoms';
import { Creative } from './Creative';
import { Generic } from './Generic';

export function Brief(): ReactElement {
  const block = useAtomValue(blockAtom);

  const { brief_attachments, comment, suggested_media, languages } = block;

  return (
    <div className="container ml-0 brief">
      <div className="row mt-1">
        <div className="col-6">
          <dl>
            <dt>Title</dt>
            <dd data-test-id="field-title">{block.title}</dd>
            {block.target_groups && (
              <>
                <dt>Target groups</dt>
                <dd data-test-id="field-target_groups">{block.target_groups}</dd>
              </>
            )}
            <dt>Period</dt>
            <dd data-test-id="field-period">
              <Period date_from={block.date_from} date_to={block.date_to} flat />
            </dd>

            <dt>Budget</dt>
            <dd data-test-id="field-budget">
              {sepFormat(block.budget)}
              <small className="form-text text-muted">
                Total EUR (incl. prod., fee, etc.) <strong>w/o</strong> VAT
              </small>
            </dd>
          </dl>
          {block.type === 'CREATIVE' ? <Creative {...(block as CreativeBlockDetails)} /> : <Generic {...block} />}
        </div>
        <div className="col-6">
          {comment && (
            <>
              <dt>Comment</dt>
              <dd data-test-id="field-comment">{nl2br(comment)}</dd>
            </>
          )}
          {suggested_media && (
            <>
              <dt>Suggested media</dt>
              <dd data-test-id="field-suggested_media">{nl2br(suggested_media)}</dd>
            </>
          )}
          <dt>Languages</dt>
          <dd data-test-id="field-languges">{languages.join(', ')}</dd>
          <dt>Deadline</dt>
          <dd data-test-id="field-deadline">
            {formatDate(block.deadline)}
            <DeadlineWarning deadline={block.deadline} date_from={block.date_from} />
          </dd>
          {brief_attachments.length > 0 && (
            <dl>
              <dt>Attachments</dt>
              <dd data-test-id="field-attachements">
                <ul className="mb-0">
                  {brief_attachments.map(({ file, filename, id }) => (
                    <li key={id}>
                      <a href={file} target="_blank" rel="noopener noreferrer">
                        {filename}
                      </a>
                    </li>
                  ))}
                </ul>
              </dd>
            </dl>
          )}
        </div>
      </div>
    </div>
  );
}

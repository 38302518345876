import cx from 'clsx';
import { MouseEvent, ReactElement } from 'react';
import { GridColumn } from './types';

import { useRowSelection } from './selection';

function isModifier(e: MouseEvent<HTMLDivElement>): boolean {
  // navigator.userAgentData requires https
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  return isMac ? e.metaKey : e.ctrlKey;
}

export function HeaderHandle(): ReactElement {
  const [selected, select] = useRowSelection();
  return (
    <div
      className={cx('grd__r__hndl', { 'grd__r__hndl--selected': selected })}
      onClick={() =>
        select({
          type: 'HEADER',
          checked: !selected,
        })
      }
    />
  );
}

function RowToggle({ id }: { id: number }): React.ReactElement {
  const [selected, select] = useRowSelection();

  return (
    <div
      className={cx('grd__r__hndl', { 'grd__r__hndl--selected': selected })}
      tabIndex={-1}
      onClick={(e) =>
        select({
          type: 'ROW',
          checked: !selected,
          rowId: id,
          modifier: isModifier(e),
        })
      }
    />
  );
}

export const handleColumn: GridColumn<any> = {
  key: 'handle',
  width: 16,
  className: 'grd__c--flush',
  header: <HeaderHandle />,
  renderCell: (props) => <RowToggle id={props.row.id} />,
};

import { ReactElement } from 'react';
import { getDRFFormError } from '@ff-it/form';
import { SubmissionErrors } from 'final-form';
import { eachMonthOfInterval, format, formatISO, parseISO, startOfMonth } from 'date-fns';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fieldIsEditable, useFieldUpdater } from '../../../../hooks';
import { renderValue } from './util';
import { Form } from './Form';
import { actionErrorOrThrow, maybeActionErrorOrThrow } from 'utilities';
import type { Row } from 'modules/campaign/row';
import { RenderDialogForm } from 'components';

function Edit({ row, column: { key: columnKey }, stopEdit }: RenderCellProps<Row>): ReactElement {
  const updater = useFieldUpdater(row.id, 'delivery', false);
  const delivery = row.delivery[columnKey];
  const monthSum = row.months[columnKey];

  return (
    <RenderDialogForm
      onClose={stopEdit}
      dialogHeader={<strong>{format(parseISO(columnKey), 'MMM. yyyy')}</strong>}
      initialValues={
        delivery
          ? delivery
          : {
              month: columnKey,
              quantity: monthSum ? monthSum.target_expense_quantity : null,
              total: null,
              alt_prices: false,
              alt_quantity: monthSum ? monthSum.target_income_quantity : null,
              alt_total: null,
            }
      }
      submitHandler={async (values: any): Promise<SubmissionErrors | void> => {
        const res = delivery ? await updater(values, columnKey, 'PUT') : await updater(values, undefined, 'POST');

        if (!res.ok) {
          const formError = getDRFFormError(res);
          if (formError) {
            return formError;
          }
          actionErrorOrThrow(res);
        }
      }}
      onRemove={
        delivery
          ? async () => {
              await updater(undefined, columnKey, 'DELETE').then(maybeActionErrorOrThrow);
            }
          : undefined
      }
    >
      <Form />
    </RenderDialogForm>
  );
}

function renderCell(props: RenderCellProps<Row>): React.ReactNode {
  const { column, row } = props;

  const monthSum = row.months[column.key];

  return (
    <>
      {monthSum && renderValue(monthSum)}
      {props.editing && <Edit {...props} />}
    </>
  );
}

export function createMonthColums(interval: Interval): GridColumn<Row>[] {
  return eachMonthOfInterval(interval).map((date) => {
    const month = startOfMonth(date);
    const monthString = formatISO(month, { representation: 'date' });
    return {
      key: monthString,
      header: format(month, 'MMM. yyyy'),
      className: (row) => `text-right${!row.months[monthString] ? ' grd__c--dummy' : ''}`,
      width: 160,
      renderCell,
      editable: (row) => fieldIsEditable(row, 'delivery'),
      testId: `month_${monthString}`,
    };
  });
}

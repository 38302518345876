import useSWR from 'swr';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useMembership } from 'services';
import { useSubmitHandler } from '@ff-it/form';
import type { Income } from 'modules/invoicing/income/types';
import type { BuilderData, Handler } from './types';

export function useBuilder(endpoint: string): Handler<BuilderData> {
  const navigate = useNavigate();
  const { slug } = useMembership();

  // campaign/campaign/<pk>/builder/
  // guarantee/avb/<pk>/builder/
  const url = `${endpoint}builder/`;

  const {
    data: initialValues,
    isLoading,
    error,
  } = useSWR<BuilderData>({
    url,
    method: 'GET',
  });

  const onSubmit = useSubmitHandler(
    { method: 'POST', url },
    {
      onSuccess: ({ id }: Income) => {
        toast.success(`Invoice has been created.`);
        navigate(`/${slug}/invoices/income/${id}`);
      },
    },
  );

  if (error) {
    throw error;
  }

  return {
    disabled: false,
    initialValues,
    isLoading,
    onSubmit,
    url,
  };
}

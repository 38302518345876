import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, openModal } from '@ff-it/ui';
import { useRealUser } from 'services';
import { Icon } from './ui/Icon';
import { Impersonation } from './Impersontation';
import { EnterpriseLink } from './navigation';
import { GlobalSearch } from './GlobalSearch';
import { EnterpriseSwitcher } from './EnterpriseSwitcher';
import { SolverDialog } from './Solver';

export function TopNav(): ReactElement {
  const { email } = useRealUser();

  return (
    <nav className="navbar navbar-light bg-light border-bottom navbar-expand p-0" id="top-nav">
      <div className="mr-auto d-flex align-items-center">
        <EnterpriseSwitcher />
        <Breadcrumbs className="mb-0" />
      </div>
      <Icon
        icon={['far', 'calculator']}
        className="nav-link"
        role="button"
        onClick={() => openModal((props) => <SolverDialog {...props} />)}
      />
      <GlobalSearch />
      <Impersonation />
      <ul className="navbar-nav ml-2">
        <li className="nav-item">
          <EnterpriseLink to="/profile" className="nav-link">
            <Icon icon="user" /> {email}
          </EnterpriseLink>
        </li>
        <li className="nav-item">
          <NavLink to="/logout" className="nav-link">
            <Icon icon="right-from-bracket" /> Sign out
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

import { ReactElement, useMemo } from 'react';
import { useEntity, createSceneRoute, ListFilter } from 'components';
import type { Enterprise } from 'modules/core/types';
import { useRoutes } from 'react-router';
import { columns, Form } from 'modules/core/membership';

export function Membership(): ReactElement | null {
  const { endpoint: entityEndpoint } = useEntity<Enterprise>();
  const endpoint = `${entityEndpoint}membership/`;
  const routes = useMemo(
    () => [
      createSceneRoute(
        // FIXME, index: ?
        '',
        {
          endpoint,
          title: 'Membership',
          pluralTitle: 'Membership',
          entityTitle: ({ user }) => user.email,
          // permissions,
          index: { columns, filter: <ListFilter /> },
          form: <Form />,
          initialValues: {
            user: null,
            groups: [],
          },
        },
      ),
    ],
    [endpoint],
  );

  return useRoutes(routes);
}

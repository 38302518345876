import { ReactElement } from 'react';
import { startOfYear, formatISO } from 'date-fns';
import { Breadcrumb, DownloadButton, EnterpriseField, Heading, Page, PeriodPickerField, withHasPerm } from 'components';
import { CheckField, DateRangePickerField, Form, SelectField } from '@ff-it/form';
import { reportBlockStateOptions } from 'configuration';
import { FormSpy } from 'react-final-form';
import permissions from '../permissions';
import { any, useHasPerm } from 'core/permissions';
import { usePeriod } from 'modules/reporting/usePeriod';
import { useScopedActivityTypeOptions } from 'modules/reporting/useScopedActivityTypeOptions';
import { CompanyField } from 'modules/core/company/components';

const onSubmit = (): void => undefined;

function RenderReport({ global, managed }: { global: boolean; managed?: boolean }): ReactElement {
  const types = useScopedActivityTypeOptions(global);

  const initialValues = {
    date_from: formatISO(startOfYear(new Date()), { representation: 'date' }),
    date_to: null,
    agency: [],
    state: reportBlockStateOptions.map(({ value }) => value).filter((value) => value !== 'CANCELED'),
    type: types.map(({ value }) => value),
    client: [],
    end_client: [],
    provider: [],
  };

  const { date_from: minDate, date_to: maxDate } = usePeriod();
  return (
    <Page>
      <Breadcrumb to={null}>Raw report</Breadcrumb>
      <Heading title="Raw report"></Heading>
      <div className="container px-0 ml-0">
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          <div className="form-row">
            {global && <EnterpriseField name="agency" label="Enterprise" className="col" isMulti />}
            <div className="col-3 mb-3">
              <DateRangePickerField
                label="Period"
                fromName="date_from"
                toName="date_to"
                minDate={minDate}
                maxDate={maxDate}
                className="mb-1"
              />
              <PeriodPickerField fromName="date_from" toName="date_to" minDate={minDate} maxDate={maxDate} />
            </div>
            <SelectField name="state" label="State" options={reportBlockStateOptions} className="col" simple isMulti />
            <SelectField name="type" label="Types" className="col" options={types} simple isMulti />
          </div>
          <div className="form-row">
            <CompanyField name="client" label="Client" className="col" isMulti />
            <CompanyField
              name="end_client"
              label="End client"
              className="col"
              isMulti
              help="Final recipient of proxy service"
            />
            <CompanyField name="provider" label="Provider" className="col" isMulti />
          </div>
          <div className="form-group mb-1 mt-3">
            <CheckField name="income_no" fieldLabel="Income Invoice No" variant="switch" />
          </div>
          <div className="form-group mb-4">
            <CheckField name="expense_no" fieldLabel="Expense Invoice No" variant="switch" className="mb-3" />
          </div>
          <FormSpy subscription={{ values: true }}>
            {({ values }) => (
              <DownloadButton
                method="POST"
                body={values}
                url={`reporting/raw/${global ? 'global' : managed ? 'local_managed' : 'local'}/`}
                icon="file-excel"
                title="Export"
              >
                Export
              </DownloadButton>
            )}
          </FormSpy>
        </Form>
      </div>
    </Page>
  );
}

export const Local = withHasPerm(() => {
  const managed = !useHasPerm(permissions.local);
  return <RenderReport global={false} managed={managed} />;
}, any(permissions.local, permissions.local_managed));
export const Global = withHasPerm(() => <RenderReport global={true} />, permissions.global);

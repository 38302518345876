import type { ReactNode } from 'react';
import type { Format } from 'modules/campaign/row';
import type { MaterialType } from 'modules/core/types';
import type { IconProp } from 'components';

export const getOptionLabel = (v: null | Format): any => (v ? v.size : v);
export const getOptionValue = (v: null | Format): any => (v ? `${v.material_type}:${v.size}:${v.title}` : v);

const ICONS: Record<MaterialType, IconProp> = {
  IMAGE: 'image',
  AUDIO: 'volume-high',
  TEXT: 'text',
  VIDEO: 'video',
};

export const getIcon = (v: Format): IconProp => ICONS[v.material_type];

export function renderValues(value: Format[] | null, locked: boolean): ReactNode {
  return (
    <span className="contain">
      {value && value.length > 0 ? value.map(getOptionLabel).join(', ') : locked ? '—' : ''}
    </span>
  );
}

import { Fragment, ReactNode } from 'react';
import type { SmartFields } from 'modules/campaign/row';
import { SmartLabel } from './SmartLabel';

export const getOptionValue = (v: null | SmartFields): any => (v ? v.id : v);
export const formatOptionLabel = (v: null | SmartFields): any => (v ? <SmartLabel {...v} /> : v);

export function renderValues(value: SmartFields[], locked: boolean): ReactNode {
  return (
    <span className="contain">
      {value.length > 0
        ? value.map((p) => (
            <Fragment key={p.id}>
              <SmartLabel {...p} />{' '}
            </Fragment>
          ))
        : locked
        ? '—'
        : ''}
    </span>
  );
}

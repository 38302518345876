import { memo, CSSProperties, ReactElement } from 'react';
import type { CalculatedColumn } from './types';
import { HeaderCell } from './Cell';

interface SummaryRowProps {
  columns: readonly CalculatedColumn[];
  gridRowStart: number;
}

export const SummaryRow = memo(function SummaryRow({ columns, gridRowStart }: SummaryRowProps): ReactElement {
  const cellElements = [];
  for (let colIdx = 0; colIdx < columns.length; colIdx++) {
    const column = columns[colIdx];
    const { key, summary } = columns[colIdx];
    cellElements.push(
      <HeaderCell key={key} colIdx={colIdx} column={column}>
        {summary}
      </HeaderCell>,
    );
  }

  return (
    <div
      className="grd__r grd__r--summary"
      style={
        {
          ['--grd-row-start']: gridRowStart,
        } as unknown as CSSProperties
      }
    >
      {cellElements}
    </div>
  );
});

import { ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { Box, Sprinkles } from '@ff-it/ui';
import * as style from './style.css';

type TitleContainerProps = {
  className?: string;
  testId?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  htmlTitle?: string;
  flat?: boolean;
} & Sprinkles;

export function TitleContainer({
  title,
  subtitle,
  children,
  className,
  testId,
  htmlTitle,
  flat,
  ...rest
}: TitleContainerProps): ReactElement {
  return (
    <Box
      className={cx(style.container, className, flat ? style.flat : undefined)}
      data-testid={testId}
      title={htmlTitle}
      {...rest}
    >
      {title && <Title>{title}</Title>}
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {children}
    </Box>
  );
}

type TitleProps = {
  className?: string;
  children: ReactNode;
};

export function Title({ children, className }: TitleProps): ReactElement {
  return <Box className={cx(style.title, className)}>{children}</Box>;
}

export function SubTitle({ children, className }: TitleProps): ReactElement {
  return <Box className={cx(style.subtitle, className)}>{children}</Box>;
}

import { RowActionProps } from './types';
import { Action } from 'components';
import { useMemo } from 'react';
import initialRowValues from '../../initialRowValues';
import { useSetAtom } from 'jotai';
import { createRowAtom } from '../../atoms/plan';

export function useRowActions({ scrollToRow }: RowActionProps): Action[] {
  const add = useSetAtom(createRowAtom);

  return useMemo(() => {
    const actions: Action[] = [
      {
        action: 'add-media',
        button: {
          variant: 'outline-primary',
          size: 'sm',
          children: 'Media',
          onClick: () => {
            add({
              ...initialRowValues,
              kind: 'MEDIA',
            }).then((r) => {
              scrollToRow(r.id);
            });
          },
        },
      },
      {
        action: 'add-service',
        button: {
          variant: 'outline-primary',
          size: 'sm',
          children: 'Service',
          onClick: () => {
            add({
              ...initialRowValues,
              kind: 'SERVICE',
            }).then((r) => {
              scrollToRow(r.id);
            });
          },
        },
      },
      {
        action: 'add-smart',
        button: {
          variant: 'outline-primary',
          size: 'sm',
          children: 'Smart service',
          onClick: () => {
            add({
              ...initialRowValues,
              kind: 'SMART',
            }).then((r) => {
              scrollToRow(r.id);
            });
          },
        },
      },
    ];

    return actions;
  }, []);
}

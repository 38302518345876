import { useAtomValue } from 'jotai';
import { ReactElement } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fmt, sepFormat } from 'utilities';
import { summaryRowAtom } from '../../../atoms/summary';
import type { Row } from 'modules/campaign/row';
import { TitleContainer } from 'components/TitleContainer';

function Summary(): ReactElement {
  const summary = useAtomValue(summaryRowAtom);
  if (!summary) {
    return <span />;
  }
  return <TitleContainer title={<strong>{fmt(summary.revenue)}</strong>} subtitle={fmt(summary.margin, true)} />;
}

export const revenue_margin: GridColumn<Row> = {
  key: 'revenue_margin',
  header: 'Revenue, Margin',
  summary: <Summary />,
  width: 96,
  className: 'text-right',
  renderCell: (props: RenderCellProps<Row>) => {
    const {
      row: {
        sums: { margin, revenue },
      },
    } = props;
    return (
      <>
        <div>{sepFormat(revenue)}</div>
        <div className="small text-muted">
          {sepFormat(margin)}
          {margin !== null && '%'}
        </div>
      </>
    );
  },
};

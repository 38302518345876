import { planIntervalAtom, planStrategyAtom } from './plan';
import { type PlanStrategyType } from 'modules/campaign/block/types';
import type { GridColumn } from 'components/Grid';
import * as common from '../PlanGrid/columns/common';
import * as rtb from '../PlanGrid/columns/rtb';
import { createMonthColums } from '../PlanGrid/columns/common/month';
import { atom } from 'jotai';
import type { Row } from 'modules/campaign/row';

const basePrefix = [common.state, common.supplier, common.period];

const columns: Record<PlanStrategyType, GridColumn<Row>[]> = {
  NAIVE: [
    ...basePrefix,
    common.unit,
    common.bruto_price,
    common.sumQuantity,
    common.gross_total,
    common.client_factors,
    common.client_price,
    common.smart,
    common.client_total,
    common.provider_factors,
    common.provider_price,
    common.provider_total,
    common.revenue_margin,
    common.positions,
  ],
  RTB: [
    common.state,
    common.supplier,
    rtb.placement,
    rtb.language,
    rtb.web_device,
    rtb.format,
    rtb.tg,
    rtb.kpi,
    rtb.frequency,
    common.unit,
    common.bruto_price,
    common.sumQuantity,
    common.gross_total,
    common.client_factors,
    common.client_price,
    common.smart,
    common.client_total,
    common.provider_factors,
    common.provider_price,
    common.provider_total,
    common.revenue_margin,
    common.positions,
  ],
};

export const columnsAtom = atom((get) => {
  const res = columns[get(planStrategyAtom)];
  const interval = get(planIntervalAtom);

  const monthColumns = createMonthColums(interval);
  return [...res, ...monthColumns];
});

import { createContext, useContext, SetStateAction, Dispatch } from 'react';
import { CellCoords, SelectRowEvent } from './types';

const RowSelectionContext = createContext<boolean | undefined>(undefined);
export const RowSelectionProvider = RowSelectionContext.Provider;

const RowSelectionChangeContext = createContext<((selectRowEvent: SelectRowEvent) => void) | undefined>(undefined);
export const RowSelectionChangeProvider = RowSelectionChangeContext.Provider;

export function useRowSelection(): [boolean, (selectRowEvent: SelectRowEvent) => void] {
  const rowSelectionContext = useContext(RowSelectionContext);
  const rowSelectionChangeContext = useContext(RowSelectionChangeContext);

  if (rowSelectionContext === undefined || rowSelectionChangeContext === undefined) {
    throw new Error('context missing');
  }

  return [rowSelectionContext, rowSelectionChangeContext];
}

const CellSelectionChangeContext = createContext<Dispatch<SetStateAction<CellCoords | null>> | undefined>(undefined);
export const CellSelectionChangeProvider = CellSelectionChangeContext.Provider;

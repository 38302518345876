import { useEntity, bankFieldProps, Field, Fieldset } from 'components';
import { ReactElement } from 'react';
import { Company } from 'types';

export function ViewCompany(): ReactElement {
  const {
    item: { office_same_as_legal },
  } = useEntity<Company>();
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <Field name="title" label="Title" help="Full legal name" className="col-md-4" />
        <Field name="internal_name" label="Internal name" className="col-md-4" />
        <Field name="code" label="Code" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="kind" label="Kind" className="col-md-2" />
        <Field name="country.name" label="Country" className="col-md-2" />
        <Field name="registration_number" label="Registration number" className="col-md-4" />
        <Field name="vat_number" label="VAT number" className="col-md-4" />
      </div>
      <div className="form-row">
        <Field name="legal_address" label="Legal address" className="col-md-6" />
        {!office_same_as_legal && <Field name="office_address" label="Office address" className="col-md-6" />}
      </div>
      <div className="form-row">
        {/* FIXME only CLIENT */}
        <Field name="managers" label="Client managers" className="col-md-6">
          {(managers: any) => managers.map(({ user: { email } }: any) => email).join(',')}
        </Field>
      </div>

      <Fieldset title="Documents">
        <div className="form-row">
          <Field name="invoice_template" label="Invoice template" className="col-md-6" />
          <Field name="acceptance_act_template" label="Acceptance act template" className="col-md-6" />
        </div>
      </Fieldset>

      <Fieldset title="Account">
        <div className="form-row">
          <Field name="bank" label="Bank" className="col-md-6">
            {(b) => (b ? bankFieldProps.getOptionLabel(b) : '—')}
          </Field>
          <Field name="bank_account_number" label="Account number (IBAN)" className="col-md-6" />
        </div>
      </Fieldset>
    </div>
  );
}

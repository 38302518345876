import type { ReactNode } from 'react';
import type { GridColumn, RenderCellProps } from 'components/Grid';
import type { Row } from 'modules/campaign/row';
import { Period } from 'components';

const renderCell = ({
  row: {
    sums: { date_from, date_to },
  },
}: RenderCellProps<Row>): ReactNode => {
  return <Period date_from={date_from} date_to={date_to} />;
};

export const period: GridColumn<Row> = {
  key: 'period',
  header: 'Period',
  minWidth: 90,
  className: 'text-left',
  renderCell,
};

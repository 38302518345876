import { ReactElement, ReactNode } from 'react';
import { TextEditor } from '../../Editor';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import type { Row } from 'modules/campaign/row';

function renderValue(props: RenderCellProps<Row>): ReactNode {
  const value = props.row.kpi;

  return value ? <span className="contain">{value}</span> : !props.isEditable ? '—' : '';
}

function Edit(props: RenderCellProps<Row>): ReactElement {
  const value = props.row.kpi;
  const setValue = useFieldUpdater(props.row.id, 'kpi');

  return <TextEditor value={value} stopEdit={props.stopEdit} setValue={setValue} />;
}

export const kpi: GridColumn<Row> = {
  key: 'kpi',
  header: 'KPI',
  width: 156,
  renderCell: (props) => (props.row.kind === 'MEDIA' ? props.editing ? <Edit {...props} /> : renderValue(props) : null),
  editable: (row) => fieldIsEditable(row, 'kpi'),
};

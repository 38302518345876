import { ReactNode, Dispatch, SetStateAction, ReactElement, useCallback } from 'react';
import { Entity } from 'types';
import { Form, FormProps, Submit, useSubmitHandler, SubmitHandlerOptions } from '@ff-it/form';
import { HTTPMethod } from '@ff-it/api';
import { Button } from '@ff-it/ui';
import { useModel, useEntity } from './context';
import { Perm, useHasPerms } from 'core/permissions';
import { PermissionDenied } from './errors';
import { toast } from 'react-toastify';
import { Page, Heading, Breadcrumb } from '../layout';
import { Icon } from '../ui';
import cx from 'clsx';
import { Link } from 'react-router-dom';

interface UpdateSceneProps<T extends Entity>
  extends Omit<UpdateContainerProps<T>, 'initialValues' | 'method' | 'url' | 'updateItem'> {
  perm?: Perm;
  canCreate?: Perm;
  createPath?: string | null;
  canDelete?: Perm;
  deletePath?: string | null;
  method?: HTTPMethod;
  viewDisabled?: boolean;
  copy?: (item: T) => any;
}

export function UpdateScene<T extends Entity = any>(props: UpdateSceneProps<T>): ReactElement {
  const { permissions, title } = useModel();
  const { item, setItem, endpoint: url } = useEntity<T>();
  const {
    perm,
    children,
    canDelete,
    deletePath = 'delete',
    canCreate,
    createPath = '../create',
    heading = <Heading title={`Update ${title}`}></Heading>,
    actions,
    className,
    viewDisabled,
    disabled,
    copy,
    ...formProps
  } = props;

  const [hasView, hasUpdate, hasDelete, hasCreate] = useHasPerms(
    permissions?.view,
    perm || permissions?.change,
    canDelete || permissions?.delete,
    canCreate || permissions?.add,
  );

  if (!hasUpdate && !(viewDisabled && hasView)) {
    return <PermissionDenied />;
  }

  return (
    <UpdateContainer
      {...formProps}
      disabled={disabled || !hasUpdate}
      className={className}
      heading={heading}
      initialValues={item}
      updateItem={setItem}
      url={url}
      actions={
        <>
          {hasDelete && deletePath && (
            <Button
              variant="outline-danger"
              className="ml-3"
              to={deletePath}
              component={Link}
              size="sm"
              data-test-id="delete"
              disabled={formProps.disabled}
            >
              <Icon className="mr-1" icon="xmark" /> Delete
            </Button>
          )}
          {hasCreate && createPath && (
            <Button
              variant="outline-primary"
              className="ml-3"
              to={createPath}
              component={Link}
              size="sm"
              data-test-id="create"
            >
              <Icon className="mr-1" icon="circle-plus" /> Create
            </Button>
          )}
          {hasCreate && createPath && copy && (
            <Button
              variant="outline-secondary"
              className="ml-3"
              to={createPath}
              state={{ initialValues: copy(item) }}
              component={Link}
              size="sm"
              testId="copy"
            >
              <Icon className="mr-1" icon={['far', 'copy']} /> Copy
            </Button>
          )}
          {actions}
        </>
      }
    >
      {children}
    </UpdateContainer>
  );
}

interface UpdateContainerProps<T> extends Omit<FormProps<any>, 'onSubmit'>, SubmitHandlerOptions<any, T> {
  className?: string;
  heading?: ReactNode | null;
  breadcrumb?: ReactNode | null;
  actions?: ReactNode;
  successMessage?: ReactNode;
  method?: HTTPMethod;
  url: string;
  updateItem: Dispatch<SetStateAction<T>>;
}

export function UpdateContainer<T>({
  className,
  heading,
  breadcrumb = 'Update',
  actions,
  children,
  mapValues,
  successMessage = 'Item has been updated.',
  method = 'PUT',
  url,
  updateItem,
  ...formProps
}: UpdateContainerProps<T>): ReactElement {
  const onSuccess = useCallback(
    (result: any): void => {
      toast.success(successMessage, { toastId: 'update-scene-toast' });
      if (method === 'PATCH') {
        updateItem((item) => ({ ...item, ...result }));
      } else {
        updateItem(result);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [successMessage, method],
  );

  const onSubmit = useSubmitHandler<any, T>({ url, method }, { mapValues, onSuccess });

  return (
    <Page className={cx('scene scene-update', className)}>
      {breadcrumb && <Breadcrumb to={null}>{breadcrumb}</Breadcrumb>}
      {heading}
      <Form noValidate {...formProps} onSubmit={onSubmit}>
        {children}
        <div className="container px-0 ml-0">
          <div className="btn-toolbar align-items-center mb-3">
            <Submit data-test-id="update">Update</Submit>
            {actions}
          </div>
        </div>
      </Form>
    </Page>
  );
}

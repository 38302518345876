import { StylesConfig } from 'react-select';

export const selectStyle: StylesConfig<any, any, any> = {
  container: () => ({
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
  }),
  control: () => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    justifyContent: 'space-between',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: undefined,
    fontSize: undefined,
    fontWeight: undefined,
    lineHeight: undefined,
    color: undefined,
  }),
  input: (base) => ({
    ...base,
    margin: undefined,
    paddingTop: undefined,
    paddingBottom: undefined,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: undefined,
  }),
  singleValue: (base) => ({
    ...base,
    whiteSpace: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10,
    fontSize: '13px',
    minWidth: '130px',
    marginTop: '4px',
    marginLeft: '-14px',
  }),
};

import {
  AddButton,
  Breadcrumb,
  EnterpriseField,
  Heading,
  Page,
  RemoveButton,
  UserField,
  FormInlineError,
} from 'components';

import { Form, SelectField, Submit, useSubmitHandler } from '@ff-it/form';
import { Alert, LoadingContainer } from '@ff-it/ui';
import { useLoader } from '@ff-it/api';
import { FieldArray } from 'react-final-form-arrays';
import type { RouteObject } from 'react-router';
import type { TicketCategory } from 'modules/support/types';
import type { Option } from 'options';
import type { ReactElement } from 'react';

const emptyRow = {
  category: null,
  enterprise: null,
  user: null,
};

const categoryOptions: Option<TicketCategory>[] = [
  {
    value: 'ADD_COMPANY',
    label: 'Add company',
  },
  {
    value: 'ADD_DEPARTMENT',
    label: 'Add department',
  },
  {
    value: 'ADD_CLIENT',
    label: 'Add client',
  },
];

export function TicketAssignees(): ReactElement {
  const url = `support/assignees/`;
  const {
    loading,
    item,
    setItem: onSuccess,
  } = useLoader<any>({
    url,
    method: 'GET',
  });

  const onSubmit = useSubmitHandler<any, any>({ url, method: 'PUT' }, { onSuccess });

  return (
    <Page>
      <Breadcrumb>Ticket assignees</Breadcrumb>
      <Heading title="Ticket assignees" />
      <Alert variant="info">Concrete agency has precedence over any</Alert>
      <LoadingContainer loading={loading}>
        {item ? (
          <Form initialValues={item} onSubmit={onSubmit} className="container px-0 ml-0">
            <FieldArray name="rows" subscription={{}}>
              {({ fields }) => (
                <>
                  <FormInlineError name="rows" />
                  <table className="table form-table mb-0">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Agency</th>
                        <th>User</th>
                        <th className="tools" />
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((name, index) => (
                        <tr key={index}>
                          <td>
                            <SelectField
                              name={`${name}.category`}
                              placeholder="Category"
                              options={categoryOptions}
                              simple
                              required
                            />
                          </td>
                          <td>
                            <EnterpriseField name={`${name}.enterprise`} placeholder="Agency" isClearable />
                          </td>
                          <td>
                            <UserField name={`${name}.user`} placeholder="User" />
                          </td>
                          <td className="tools">
                            <RemoveButton onClick={() => fields.remove(index)} />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3}>
                          <AddButton
                            onClick={() => {
                              fields.push(emptyRow);
                            }}
                          >
                            add row
                          </AddButton>
                        </td>
                        <td className="tools"></td>
                      </tr>
                    </tbody>
                  </table>
                </>
              )}
            </FieldArray>
            <Submit className="mt-3" />
          </Form>
        ) : null}
      </LoadingContainer>
    </Page>
  );
}

export const route: RouteObject = {
  path: 'ticket_assignees',
  element: <TicketAssignees />,
};

import { ReactElement } from 'react';
import { Format } from 'modules/campaign/row';
import { getOptionLabel, getIcon } from './util';
import { EditProps } from '../useEditableChoiceProps';
import { RowChoiceEditor } from '../../../Editor';
import { FormatForm } from 'modules/supplier/products/rtb';

const initialValues = {
  material_type: null,
  size: '',
  title: '',
  file_format: '',
  file_weight: '',
  description: '',
  specification_url: '',
  example_url: '',
};

export function Editor(props: EditProps<Format>): ReactElement {
  return (
    <RowChoiceEditor
      initialValues={initialValues}
      dialogHeader="Format"
      label="Row formats"
      addLabel="Add format"
      form={<FormatForm />}
      formatOptionLabel={getOptionLabel}
      getIcon={getIcon}
      {...props}
    />
  );
}

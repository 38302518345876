import { selectAtom } from 'jotai/utils';
import eq from 'fast-deep-equal';
import { rowsAtom } from '../../../../atoms/plan';

export const applicableSmartStateAtom = selectAtom(
  rowsAtom,
  (rows) =>
    rows
      .filter(({ kind }) => kind === 'SMART')
      .map(({ id, direction, application, rate, apply_to }) => ({
        id,
        direction,
        application,
        rate,
        apply_to,
      })),
  eq,
);

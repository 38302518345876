import { Component, FC, ComponentType, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

import { RequestError } from '@ff-it/api';
import { Alert } from '@ff-it/ui';
import { NotFound } from './scenes';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, { error: any }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }
  componentDidCatch(error: any, errorInfo: any): void {
    this.setState({ error });
    Sentry.captureException(error, { extra: errorInfo });
  }
  render(): ReactNode {
    if (this.state.error) {
      if (this.state.error?.name === 'RequestError' && this.state.error.status === 403) {
        return (
          <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
              <div className="col-9">
                <Alert variant="danger" heading="Forbidden">
                  {this.state.error.message}
                </Alert>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="container">
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-9">
              <Alert variant="danger" heading="We're sorry — something's gone wrong">
                <div
                  className="btn btn-link px-0"
                  role="button"
                  onClick={() => Sentry.lastEventId() && Sentry.showReportDialog()}
                >
                  Our team has been notified, but click here fill out a report.
                </div>
              </Alert>
            </div>
          </div>
        </div>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

class NotFoundBoundary extends Component<ErrorBoundaryProps, { notFound: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { notFound: false };
  }
  componentDidCatch(error: RequestError): void {
    if (error?.name === 'RequestError' && error?.status === 404) {
      console.log('404');
      return;
    }
    throw error;
  }

  static getDerivedStateFromError(error: RequestError): { notFound: boolean } {
    return {
      notFound: error?.name === 'RequestError' && error?.status === 404,
    };
  }

  render(): ReactNode {
    return this.state.notFound ? <NotFound /> : this.props.children;
  }
}

export const withNotFoundBoundary =
  <P extends object>(Component: ComponentType<P>): FC<P> =>
  (props) =>
    (
      <NotFoundBoundary>
        <Component {...props} />
      </NotFoundBoundary>
    );

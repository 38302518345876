import { ReactElement } from 'react';
import { toast } from 'react-toastify';
import { Column } from '@ff-it/grid';
import { openModal } from '@ff-it/ui';
import { useRoutes, Link, useNavigate } from 'react-router-dom';
import {
  CreateScene,
  DeleteScene,
  EntityAction,
  EntityFormActions,
  EntityScene,
  IndexScene,
  ModelScene,
  UpdateScene,
  useEntity,
} from 'components';

import type { DepartmentProduct, MediaProduct, ServiceProduct, SmartProduct } from 'modules/supplier/products';
import permissions from '../permissions';
import { Form } from './Form';
import { Nav } from './Nav';
import { MoveDepartmentDialog } from './MoveDepartmentDialog';
import { DepartmentKind } from 'types';
import type { Department } from '../types';

const mediaColumns: Column<MediaProduct>[] = [
  {
    content: 'Type',
    key: 'type',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    render: ({ id, name }: MediaProduct) => <Link to={`${id}`}>{name || <em>Generic product</em>}</Link>,
  },
  {
    content: 'Unit',
    key: 'unit',
    width: 100,
  },
  {
    width: 100,
    content: 'Gross price',
    key: 'gross_price',
    className: 'text-right',
  },
];

const serviceColumns: Column<ServiceProduct | SmartProduct>[] = [
  {
    content: 'Type',
    key: 'type',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    render: ({ id, name }) => <Link to={`${id}`}>{name || <em>Generic product</em>}</Link>,
  },
  {
    content: 'Kind',
    key: 'kind',
  },
  {
    content: 'Direction',
    key: 'direction',
  },
  {
    width: 100,
    content: 'Unit',
    key: 'unit',
  },
];

const initialValues = {
  name: '',
  unit: null,
  gross_price: null,
  activities: [],
  configuration: null,
};

type MoveProps = {
  departmentId: number;
  departmentKind: DepartmentKind;
};

function useProductActions(props: MoveProps): EntityAction<DepartmentProduct>[] {
  const { endpoint } = useEntity();
  const navigate = useNavigate();

  return [
    {
      action: 'move',
      permKey: 'change',
      button: {
        icon: 'turn-up',
        children: 'Move',
        variant: 'outline-secondary',
        size: 'sm',
        onClick: () =>
          openModal<Department>((dialogProps) => (
            <MoveDepartmentDialog {...dialogProps} {...props} endpoint={endpoint} />
          )).then((department) => {
            if (department) {
              toast.success('Product has been moved');
              navigate(`../../../${department.id}/products`);
            }
          }),
      },
    },
  ];
}

function ProductActions(props: MoveProps): ReactElement {
  const actions = useProductActions(props);
  return <EntityFormActions className="ml-5" actions={actions} />;
}

export function Products(): ReactElement {
  const {
    item: { id: departmentId, kind: departmentKind },
  } = useEntity();

  const routes = useRoutes([
    { index: true, element: <IndexScene<any> columns={departmentKind === 'MEDIA' ? mediaColumns : serviceColumns} /> },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form kind={departmentKind} create />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: (
        <EntityScene>
          <Nav />
        </EntityScene>
      ),
      children: [
        {
          index: true,
          element: (
            <UpdateScene
              viewDisabled
              heading={null}
              actions={<ProductActions departmentId={departmentId} departmentKind={departmentKind} />}
              copy={(product) => ({ ...product, id: undefined })}
            >
              <Form kind={departmentKind} create={false} />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return (
    <ModelScene
      endpoint={`supplier/${departmentKind.toLocaleUpperCase()}/${departmentId}/products/`}
      title="Product"
      pluralTitle="Products"
      entityTitle={({ name }: DepartmentProduct) => name || <em>Unspecified product</em>}
      // @TODO
      permissions={permissions}
    >
      {routes}
    </ModelScene>
  );
}

import type { ListBlock } from 'modules/campaign/block/types';
import type { RouteObject } from 'react-router';
import { ModelScene, IndexScene } from 'components';
import { columns } from './columns';
import { permissions } from '../Campaigns';
import { never } from 'core/permissions';
import { Filter } from 'modules/campaign/common/Filter';

const model = {
  endpoint: 'campaign/blocks/',
  title: 'Block',
  pluralTitle: 'Blocks',
  entityTitle: (e: ListBlock) => e.code,
  permissions,
};

export const route: RouteObject = {
  path: 'blocks',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene columns={columns} perm={permissions.view} canCreate={never} storageKey="planning_blocks">
          <Filter scope="block" />
        </IndexScene>
      ),
    },
  ],
};

import { Sprinkles } from '@ff-it/ui';
import { ReactElement } from 'react';
import type { IncomeSums } from 'modules/invoicing/income/types';
import type { ApplicationState } from 'core/types';
import { isApplicable } from 'modules/applicator/isApplicable';
import { InvoiceCheck } from './InvoiceCheck';
import { IconBadge } from 'components/IconBadge/IconBadge';
import type { ListInvoice } from 'modules/invoicing/types';
import type { ApplicationType } from 'modules/applicator/types';

type CheckApplicationStateProps = {
  invoice: ListInvoice;
  type?: ApplicationType;
} & Sprinkles;

export function DocumentApplicationState({ invoice, type, ...rest }: CheckApplicationStateProps): ReactElement | null {
  let state: ApplicationState | null = null;

  switch (type || isApplicable(invoice)) {
    case 'advance':
      state = (invoice.sums as IncomeSums).adv_state;
      break;
    case 'credit':
      state = invoice.sums.cred_state;
      break;
    case 'compensation':
      state = (invoice.sums as IncomeSums).comp_state;
      break;
  }
  return (
    <IconBadge {...rest}>
      {state !== null ? (
        <InvoiceCheck
          testId="application-state"
          direction={invoice.direction}
          state={state === 'FULL' ? 'success' : 'warn'}
        />
      ) : null}
    </IconBadge>
  );
}

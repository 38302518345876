import { ReactElement, useMemo } from 'react';
import { formatISO, startOfYear, endOfYear } from 'date-fns';
import { DateRangePeriodPicker, Visibility, VisibilityProps } from '../common';
import { columns } from './columns';
import { Row } from './types';
import { SelectField, TextField } from '@ff-it/form';
import { useScopedActivityTypeOptions } from '../useScopedActivityTypeOptions';
import { CompanyField } from 'modules/core/company/components';
import { BrandField } from 'modules/client/brand/components';
import { TableColumns } from '@ff-it/ui';
import { ReportTable } from 'components/ReportTable';

const resolveColumns = (visibility: Visibility): TableColumns<Row> => {
  switch (visibility) {
    case 'GLOBAL':
      return columns;
    case 'LOCAL':
      // no agency
      return columns.slice(1);
    case 'LOCAL_MANAGED':
    case 'LOCAL_TYPE':
      // no agency, no bonuses
      return columns.slice(1, 10);
  }
};

export function Campaign({ visibility, ...props }: VisibilityProps): ReactElement {
  const types = useScopedActivityTypeOptions(visibility == 'GLOBAL');
  const initialFilter = {
    date_from: formatISO(startOfYear(new Date()), { representation: 'date' }),
    date_to: formatISO(endOfYear(new Date()), { representation: 'date' }),
    types: types.map(({ value }) => value),
  };

  const url = `reporting/campaign/${visibility.toLowerCase()}${'type' in props ? `/${props.type}/` : '/'}`;

  const columns = useMemo(() => {
    return resolveColumns(visibility);
  }, [visibility]);

  return (
    <ReportTable
      url={url}
      initialFilter={initialFilter}
      columns={columns}
      filterFields={
        <div className="flex-column">
          <div className="d-flex flex-row">
            <DateRangePeriodPicker />
            <CompanyField name="clients" isMulti placeholder="Client" className="ml-2" simple />
            <CompanyField name="end_clients" isMulti placeholder="End client" className="ml-2" simple />
            <TextField name="search_campaign" placeholder="Campaign title or number" className="ml-2" />
            <BrandField name="brand" placeholder="Brand" className="ml-2" simple />
          </div>
          <div className="d-flex flex-row">
            {visibility != 'LOCAL_TYPE' && (
              <SelectField options={types} name="types" placeholder="Types" simple isMulti />
            )}
          </div>
        </div>
      }
    />
  );
}

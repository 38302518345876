import { Column } from '@ff-it/grid';
import { TextField } from '@ff-it/form';
import { Company, EmbeddedCompany } from 'types';
import { ListFilter, ModelScene, IndexScene, CreateScene, EntityScene, UpdateScene, DeleteScene } from 'components';
import { timestamp } from 'components/columns';
import { Form } from './Form';
import { RouteObject } from 'react-router';
import { Link } from 'react-router-dom';
import permissions from 'modules/core/company/permissions';
import initialValues from './initialValues';
import { FromTicket } from './FromTicket';
import { ListExport, ListImport } from 'components/ListActions';

export const baseColumns: Column<EmbeddedCompany>[] = [
  {
    content: 'Title',
    key: 'title',
    sortable: true,
    render: ({ id, title }: EmbeddedCompany) => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Internal name',
    sortable: true,
    key: 'internal_name',
  },
  { content: 'Code', key: 'code', sortable: true, width: 120 },

  { content: 'Kind', key: 'kind', sortable: true },
  { content: 'Registration number', sortable: true, key: 'registration_number', width: 250 },
];

const columns: Column<Company>[] = [
  ...baseColumns,
  timestamp('Modifed', 'modified_at', { sortable: true }),
  timestamp('Created', 'created_at', { sortable: true }),
];

const model = {
  endpoint: 'core/companies/',
  title: 'Company',
  pluralTitle: 'Companies',
  entityTitle: ({ title }: Company) => title,
  permissions,
};

export const route: RouteObject = {
  path: 'companies',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: (
        <IndexScene
          columns={columns}
          toolbar={
            <>
              <ListImport permission={permissions.import} />
              <ListExport permission={permissions.export} />
            </>
          }
        >
          <ListFilter>
            <TextField name="search" placeholder="Search.." size="sm" />
          </ListFilter>
        </IndexScene>
      ),
    },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form />
        </CreateScene>
      ),
    },
    {
      path: 'from_ticket/:id',
      element: <FromTicket />,
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene viewDisabled>
              <Form />
            </UpdateScene>
          ),
        },
        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ],
};

import { ReactElement } from 'react';
import { DateRangePickerField, DecimalField } from '@ff-it/form';
import { required } from 'utilities';

export function PositionForm(): ReactElement {
  return (
    <>
      <DecimalField name="quantity" required validate={required} label="Quantity" autoFocus precision={4} />
      <DateRangePickerField fromName="date_from" toName="date_to" required validate={required} label="Period" />
    </>
  );
}

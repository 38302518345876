import { openModal } from '@ff-it/ui';
import { EntityAction } from 'components';
import { MotivationDialog, finializePriceOptions } from '../dialogs';
import { BlockDetails } from '../types';

export function usePlannerActions(): EntityAction<BlockDetails>[] {
  return [
    {
      action: 'submit',
      button: {
        variant: 'success',
        children: 'Submit plan',
        icon: 'circle-check',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}submit/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      successMessage: 'Plan has been submitted.',
      visible: ({ state, role: { plan } }: BlockDetails) => plan && state == 'PLANNING',
      permKey: null, // we look at role
    },
    {
      action: 'unsubmit',
      button: {
        variant: 'outline-warning',
        children: 'Unsubmit plan',
        icon: 'rotate-left',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}unsubmit/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      successMessage: 'Plan has been unsubmitted.',
      visible: ({ state, role: { plan } }: BlockDetails) => plan && state == 'SUBMITTED',
      permKey: null, // we look at role
    },
    {
      action: 'update-prices',
      button: {
        variant: 'outline-secondary',
        children: 'Update prices',
        icon: 'dollar-sign',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}update_prices/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      successMessage: 'Price update started',
      visible: ({ state, scope, role: { plan } }: BlockDetails) =>
        plan && state == 'APPROVED' && scope !== 'PRICES_UPDATE',
      permKey: null, // we look at role
    },
    {
      action: 'finalize-prices',
      button: {
        variant: 'primary',
        children: 'End price update',
        icon: 'dollar-sign',
      },
      createRequest: ({ item, endpoint }) => ({
        url: `${endpoint}finalize_prices/`,
        method: 'POST',
        headers: {
          'If-Match': item.etag,
        },
      }),
      lock: false,
      requestDialog: () =>
        openModal((props) => (
          <MotivationDialog {...props} label="Motivation to update prices" options={finializePriceOptions} />
        )),
      successMessage: 'Prices update',
      visible: ({ state, scope, role: { plan } }: BlockDetails) =>
        plan && state == 'APPROVED' && scope === 'PRICES_UPDATE',
      permKey: null, // we look at role
    },
  ];
}

import { ReactElement } from 'react';
import { useRoutes, Link } from 'react-router-dom';
import { CreateScene, DeleteScene, EntityScene, IndexScene, ModelScene, UpdateScene, useEntity } from 'components';
import { Column } from '@ff-it/grid';
import permissions from '../permissions';
import { DepartmentFactor } from 'modules/supplier/factors';
import { Form } from './Form';

const columns: Column<DepartmentFactor>[] = [
  {
    content: 'Type',
    key: 'type',
    sortable: true,
    width: 100,
  },
  {
    content: 'Name',
    key: 'name',
    sortable: true,
    render: ({ id, name }: DepartmentFactor) => <Link to={`${id}`}>{name}</Link>,
  },
  {
    content: 'Description',
    key: 'description',
  },
  {
    content: 'Rate',
    key: 'rate',
  },
];

const initialValues = {
  type: null,
  name: '',
  rate: null,
  description: '',
};

export function Factors(): ReactElement {
  const {
    item: { id: departmentId, kind },
  } = useEntity();

  const routes = useRoutes([
    { index: true, element: <IndexScene<DepartmentFactor> columns={columns} /> },
    {
      path: 'create',
      element: (
        <CreateScene initialValues={initialValues}>
          <Form create />
        </CreateScene>
      ),
    },
    {
      path: ':id/',
      element: <EntityScene />,
      children: [
        {
          index: true,
          element: (
            <UpdateScene>
              <Form create={false} />
            </UpdateScene>
          ),
        },

        {
          path: 'delete',
          element: <DeleteScene />,
        },
      ],
    },
  ]);
  return (
    <ModelScene
      endpoint={`supplier/${kind.toLocaleUpperCase()}/${departmentId}/factors/`}
      title="Factor"
      pluralTitle="Factors"
      entityTitle={({ name }: DepartmentFactor) => name}
      // @TODO
      permissions={permissions}
    >
      {routes}
    </ModelScene>
  );
}

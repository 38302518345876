import { ReactElement } from 'react';
import { ReportingGroup } from 'types';
import { createSceneRoute, ListFilter } from 'components';
import { Column } from '@ff-it/grid';
import { SelectField, TextField } from '@ff-it/form';
import { Link } from 'react-router-dom';
import { permissions } from './permissions';
import { CompanyField } from 'modules/core/company/components';

const directionChoices = [
  {
    label: 'Client',
    value: 'CLIENT',
  },
  {
    label: 'Supplier',
    value: 'SUPPLIER',
  },
];

const columns: Column<ReportingGroup>[] = [
  {
    content: 'Title',
    key: 'title',
    render: ({ id, title }: ReportingGroup): ReactElement => <Link to={`${id}`}>{title}</Link>,
  },
  {
    content: 'Direction',
    key: 'direction',
  },
  {
    content: 'Members',
    key: 'members',
    render: ({ members }) => members.map(({ title, internal_name }) => internal_name || title).join(', '),
  },
];

function Form(): ReactElement {
  return (
    <div className="container px-0 ml-0">
      <div className="form-row">
        <TextField name="title" label="Title" className="col-md-4" required />
        <SelectField
          name="direction"
          label="Direction"
          className="col-md-2"
          required
          simple
          options={directionChoices}
        />
      </div>
      <div className="form-row">
        <CompanyField
          name="members"
          className="col-md-6"
          label="Members"
          required
          isMulti
          help="Companies which are included in the group"
        />
      </div>
    </div>
  );
}

const initialValues = {
  title: '',
  direction: null,
  members: [],
};

export const route = createSceneRoute('groups', {
  endpoint: 'reporting/groups/',
  title: 'Reporting group',
  pluralTitle: 'Reporting groups',
  entityTitle: ({ title }) => title,
  permissions,
  index: {
    columns,
    filter: (
      <ListFilter>
        <TextField name="search" placeholder="Search.." />
        <SelectField name="direction" placeholder="Direction" className="ml-1" simple options={directionChoices} />
      </ListFilter>
    ),
  },
  form: <Form />,
  initialValues,
});

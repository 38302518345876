import income from 'modules/invoicing/income/permissions';
import expense from 'modules/invoicing/expenses/permissions';
import links from 'modules/invoicing/links/permissions';
import { any } from 'core/permissions';
import type { IconProp } from 'components';

export const menu = {
  to: '/invoices',
  label: 'Invoices',
  icon: ['far', 'file-invoice-dollar'] as IconProp,
  children: [
    { to: '/invoices/income', label: 'Income', visible: income?.view },
    { to: '/invoices/expense', label: 'Expense', visible: expense?.view },
    { to: '/invoices/links', label: 'Link overview', visible: any(links.income, links.expense) },
  ],
};

import { BoundState } from 'components';
import { IconBadge } from 'components/IconBadge/IconBadge';
import type { ListInvoice } from 'modules/invoicing/types';
import { ReactElement } from 'react';

type LinkApplicationStateProps = {
  invoice: ListInvoice;
};

export function LinkApplicationState({ invoice }: LinkApplicationStateProps): ReactElement {
  return (
    <IconBadge>
      {invoice.sums.link_state !== null ? (
        <BoundState
          variant={invoice.direction}
          state={invoice.sums.link_state == 'OVERSPENT' ? 'FULL' : invoice.sums.link_state}
          is_overspent={invoice.sums.link_state == 'OVERSPENT'}
        />
      ) : null}
    </IconBadge>
  );
}

import { Column } from '@ff-it/grid';
import { state, date, timestamp } from 'components/columns';
import { EnterpriseLink } from 'components';
import { BookingStateBadge, PaymentState, InvoiceSource } from 'modules/invoicing/common';
import { sepFormat } from 'utilities';
import type { ListIncome } from './types';
import { DocumentApplicationState, IsLinkedState, LinkApplicationState } from '../common/components';

const sumProps = {
  width: 120,
  className: 'text-right',
  headerClassName: 'text-right',
  sortable: true,
};

export const columns: Column<ListIncome>[] = [
  {
    content: 'Number',
    key: 'number',
    // fixme
    sortable: false,
    width: 160,
    // absolute link since we use it in nested lists
    render: ({ id, number }: ListIncome) => <EnterpriseLink to={`/invoices/income/${id}`}>{number}</EnterpriseLink>,
  },
  state<ListIncome>(),
  {
    key: '_',
    render: (i) => <InvoiceSource {...i} />,
    className: 'px-0 text-center',
    width: 30,
  },
  {
    className: 'pl-0',
    headerClassName: 'pl-0',
    content: 'Payer',
    key: 'payer.title' as any,
    width: undefined,
    sortable: true,
  },
  timestamp('Created', 'created_at', { sortable: true }),
  { content: 'Type', key: 'type', width: 120, sortable: true },
  date('Date', 'checkout_date', { sortable: true }),
  date('P.date', 'payment_date', { sortable: true }),

  { content: 'Sub total', key: 'sub_total', render: ({ sub_total }) => sepFormat(sub_total), ...sumProps },
  // { content: 'VAT', key: 'vat_amount', render: ({ vat_amount }) => sepFormat(vat_amount), ...sumProps },
  { content: 'Total', key: 'total', render: ({ total }) => sepFormat(total), ...sumProps },
  {
    className: 'text-left pl-0',
    key: 'sums',
    render: (invoice: ListIncome) => <PaymentState state={invoice.sums.paym_state} />,
  },
  {
    width: 30,
    className: 'text-center px-0',
    key: 'booking_state',
    render: (invoice: ListIncome) => <BookingStateBadge state={invoice.state} booking_state={invoice.booking_state} />,
  },
  {
    width: 86,
    className: 'align-middle text-right',
    key: 'sums',
    render: (invoice: ListIncome) => (
      <span>
        <IsLinkedState invoice={invoice} />
        <LinkApplicationState invoice={invoice} />
        <DocumentApplicationState invoice={invoice} />
      </span>
    ),
  },
];

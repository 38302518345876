import { ReactElement, useCallback } from 'react';
import { Box, openModal } from '@ff-it/ui';
import { HTTPMethod } from '@ff-it/api';
import { useAtomValue } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { SupplierSelector, SupplierTitle } from 'modules/supplier/supplier/components';
import { GridColumn } from 'components/Grid';
import type { RowField } from 'modules/campaign/row';
import { fieldIsEditable, useFieldUpdater } from '../../../hooks';
import { blockTypeAtom } from 'modules/campaign/block/atoms';
import type { Supplier } from 'modules/supplier/supplier/types';
import { getRow } from '../../../atoms/plan';
import type { Row } from 'modules/campaign/row';
import { TitleContainer } from 'components/TitleContainer';

import { ConfirmDialog } from 'components/Actions/createConfirmDialog';

export function getSupplier({ supplier, activity }: Row): Supplier | null {
  // @FIXME #767
  return supplier
    ? {
        ...supplier,
        activity: activity || supplier.activity,
      }
    : null;
}

// @TODO RTB fields, should be on strategy?
const naiveFields: RowField[] = ['bruto_price', 'client_price', 'provider_price'];
const smartFields: RowField[] = ['application', 'rate'];

function Edit({
  value,
  rowId,
  kind,
  stopEdit,
}: {
  value: Supplier | null;
  rowId: number;
  kind: Row['kind'];
  stopEdit: () => void;
}): ReactElement {
  const updater = useFieldUpdater(rowId, 'supplier');
  const type = useAtomValue(blockTypeAtom);

  const onChange = useAtomCallback(
    useCallback(
      async (get, _set, v: Supplier | null) => {
        const currentRow = getRow(rowId, get);
        // has value
        const fields = currentRow.kind === 'SMART' ? smartFields : naiveFields;

        const hasValue = fields.some((field) => currentRow[field] !== null);

        let method: HTTPMethod = 'PUT';
        if (v && hasValue) {
          const res = await openModal((props) => <ConfirmDialog {...props} title="Keep values?" />, {
            testId: 'confirm-dialog',
          });
          if (typeof res === 'undefined') {
            // bail
            return;
          }
          if (res) {
            method = 'PATCH';
          }
        }

        updater(v as any, undefined, method).then(() => {
          stopEdit();
        });
      },
      [updater],
    ),
  );

  return <SupplierSelector value={value} type={type} kind={kind} onRequestClose={stopEdit} onChange={onChange} />;
}

export const supplier: GridColumn<Row> = {
  key: 'supplier',
  header: (
    <Box display="flex" flexDirection="row">
      <TitleContainer title="Department" subtitle="Product" flexGrow={1} />
      <TitleContainer title="Provider" subtitle="Activity" flexGrow={1} />
    </Box>
  ),
  width: 326,
  editable: (row) => fieldIsEditable(row, 'supplier'),
  renderCell: ({ row, editing, stopEdit }) => {
    const value = getSupplier(row);

    return (
      <>
        {value ? (
          <SupplierTitle {...value} />
        ) : (
          <span className="text-muted">Choose {row.kind === 'MEDIA' ? 'media' : 'service'}…</span>
        )}
        {editing && <Edit value={value} kind={row.kind} rowId={row.id} stopEdit={stopEdit} />}
      </>
    );
  },
};

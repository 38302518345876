import { memo, forwardRef, Ref, ReactElement, ReactNode } from 'react';
import cx from 'clsx';
import { CellCoords, CellProps, GridRow } from './types';

export function HeaderCell({
  children,
  column,
  colIdx,
}: Pick<CellProps<any>, 'column' | 'colIdx'> & { children?: ReactNode }): React.ReactElement {
  const { className, frozen, lastFrozen, key: columnKey } = column;

  return (
    <div
      className={cx(
        'grd__c',
        {
          'grd__c--frozen': frozen,
        },
        className,
      )}
      style={{
        gridColumnStart: colIdx + 1,
        insetInlineStart: frozen ? `var(--grd-frozen-left-${colIdx})` : undefined,
        boxShadow: lastFrozen ? 'rgba(135, 135, 135, 0.3) 2px 0px 5px -2px' : undefined,
      }}
      data-testid={columnKey}
    >
      {children}
    </div>
  );
}

export const Cell = memo(
  forwardRef(function Cell<R extends GridRow>(
    { colIdx, column, empty, onClick, row, selectCell, editing = false }: CellProps<R>,
    ref: Ref<HTMLDivElement>,
  ): ReactElement {
    const {
      className: columnClassname,
      frozen,
      lastFrozen,
      key: columnKey,
      renderCell,
      editable = false,
      testId,
    } = column;
    const className = typeof columnClassname == 'function' ? columnClassname(row) : columnClassname;
    const isEditable = typeof editable === 'function' ? editable(row) : editable;

    function handleClick(event: React.MouseEvent): void {
      if (onClick) {
        onClick(event);
      }
      isEditable &&
        !editing &&
        selectCell({
          rowId: row.id,
          columnKey: columnKey,
        } as CellCoords);
    }

    function stopEdit(): void {
      editing && selectCell(null);
    }

    const content = renderCell({
      editing,
      stopEdit,
      row,
      column,
      isEditable,
    });

    return (
      <div
        ref={ref}
        onClick={handleClick}
        className={cx(
          'grd__c',
          {
            'grd__c--frozen': frozen,
            'grd__c--editable': isEditable || onClick, // fixme
            'grd__c--empty': empty,
            'grd__c--editing': editing,
            'grd__c--dummy': content == null,
          },
          className,
        )}
        style={{
          gridColumnStart: colIdx + 1,
          insetInlineStart: frozen ? `var(--grd-frozen-left-${colIdx})` : undefined,
          boxShadow: lastFrozen ? 'rgba(135, 135, 135, 0.3) 2px 0px 5px -2px' : undefined,
        }}
        data-testid={testId || columnKey}
      >
        {content}
      </div>
    );
  }),
);

import { ReactElement } from 'react';
import { OptionProps } from 'react-select';
import { Check, DialogBody, DialogClose, DialogFooter, DialogHeader, RenderProps, openModal } from '@ff-it/ui';
import { Form } from '@ff-it/form';

import { Icon } from 'components';
import { getIcon } from './util';

import { FormatForm } from 'modules/supplier/products/rtb';

type ViewDialogProps = RenderProps & {
  initialValues: any;
};

export function ViewDialog({ onSubmit, initialValues }: ViewDialogProps): ReactElement {
  return (
    <Form noValidate onSubmit={onSubmit} initialValues={initialValues} disabled>
      <DialogHeader title="Format" />
      <DialogBody>
        <FormatForm />
      </DialogBody>
      <DialogFooter>
        <DialogClose />
      </DialogFooter>
    </Form>
  );
}

export function FormatOption({
  innerRef,
  isDisabled,
  isSelected,
  children,
  innerProps,
  data,
}: OptionProps<any>): ReactElement {
  return (
    <div className="option" ref={innerRef} aria-disabled={isDisabled}>
      <div className="d-flex align-items-center flex-grow-1" role="button" {...innerProps}>
        <Check variant="checkbox" checked={isSelected} onChange={(e) => e.preventDefault()} />
        <div className="option__label">{children}</div>
      </div>
      <div
        className="option__control text-primary"
        role="button"
        onClick={() => openModal((props) => <ViewDialog {...props} initialValues={data} />)}
      >
        <Icon icon={getIcon(data)} />
      </div>
    </div>
  );
}

import { ReactElement, ReactNode, SetStateAction, useCallback } from 'react';
import { LoadingContainer } from '@ff-it/ui';
import { EntityContext } from './context';
import { useModel } from './context';
import { useParams, Outlet } from 'react-router-dom';
import { Breadcrumb } from '../layout';
import { EntityContextType } from './types';
import { NotFound } from './errors';
import { useRequest } from '@ff-it/api';

interface EntitySceneProps {
  children?: ReactNode;
  element?: ReactNode;
}

export function EntityScene<T extends object = any>({
  children,
  element = <Outlet />,
}: EntitySceneProps): ReactElement {
  const { endpoint, entityTitle } = useModel<T>();
  const params = useParams();
  const { id } = params;
  const url = `${endpoint}${id}/`;

  return (
    <ItemLoader<T> url={url}>
      {(ctx) => (
        <EntityContext.Provider value={ctx}>
          <Breadcrumb>{entityTitle(ctx.item)}</Breadcrumb>
          {children}
          {element}
        </EntityContext.Provider>
      )}
    </ItemLoader>
  );
}

interface ItemLoaderProps<T> {
  url: string;
  children: (ctx: EntityContextType<T>) => ReactNode;
  testId?: string;
}

export function ItemLoader<T>({ url, children, testId }: ItemLoaderProps<T>): ReactElement {
  const request = useRequest<T, unknown>({
    url,
    method: 'GET',
  });

  const { data: item, loading, setState } = request;

  const setItem = useCallback((action: SetStateAction<T>) => {
    setState(
      (state) =>
        ({
          ...state,
          data: typeof action === 'function' ? (action as (prev: T) => T)(state.data as T) : action,
        } as any),
    );
  }, []);

  const setLoading = useCallback((action: SetStateAction<boolean>) => {
    setState(
      (state) =>
        ({
          ...state,
          loading: typeof action === 'function' ? action(state.loading) : action,
        } as any),
    );
  }, []);

  if (request.failure) {
    if (request.failure.status === 404) {
      return <NotFound />;
    }
    throw request.failure.error;
  }

  let content = null;

  if (item) {
    const context: EntityContextType<T> = {
      loading,
      endpoint: url,
      item,
      setItem,
      setLoading,
    };

    content = children(context);
  }
  return (
    <LoadingContainer loading={request.loading} className="flex-grow-1 flex-column d-flex" testId={testId}>
      {content}
    </LoadingContainer>
  );
}

import { Link, useRoutes } from 'react-router-dom';
import type { AVB } from 'modules/guarantee/types';
import { IndexContainer, useEntity, Heading, withHasPerm, Icon, Breadcrumb } from 'components';
import { columns } from 'modules/invoicing/expenses';
import permissions from 'modules/guarantee/cvb/permissions';
import { ListPageHandler } from 'utilities';
import { Button } from '@ff-it/ui';
import { ReactElement } from 'react';
import { CreateExpense } from './CreateExpense';

export const Expense = withHasPerm(function Expense(): ReactElement {
  const { endpoint } = useEntity<AVB>();

  const routes = useRoutes([
    {
      path: '',
      element: (
        <IndexContainer url={`${endpoint}expense/`} pageHandler={ListPageHandler} pageSizes={null} columns={columns}>
          <Heading title="Expense">
            <div className="btn-toolbar">
              <Button variant="outline-primary" size="sm" to="create" component={Link}>
                Create Expense <Icon className="ml-1" icon="circle-plus" />
              </Button>
            </div>
          </Heading>
        </IndexContainer>
      ),
    },
    {
      path: 'create',
      element: <CreateExpense />,
    },
  ]);
  return (
    <>
      <Breadcrumb>Expense</Breadcrumb>
      {routes}
    </>
  );
}, permissions.invoice);

import { CSSProperties, ReactElement, memo } from 'react';
import { Box, Button, openModal } from '@ff-it/ui';
import { useField, useForm } from '@ff-it/form';
import { AddButton, BlockWidget, ClientTitle, EnterpriseLink, Icon, RemoveButton } from 'components';
import { EntityMap } from '../types';
import { ProductTitle } from 'modules/supplier/products';
import { TitleContainer } from 'components/TitleContainer';
import { oddRow } from 'styles/style.css';
import { RowContainer } from './RowContainer';
import { PeriodState } from '../PeriodState';
import { Linkable } from './types';
import { ManualOverride } from './ManualOverride';
import { sepFormat } from 'utilities';
import Big from 'big.js';

type AmountProps = {
  name: string;
  bound: Big | null;
};

function Amount({ name, bound }: AmountProps): ReactElement {
  const {
    meta: { submitError, modifiedSinceLastSubmit },
  } = useField(name, { subscription: { submitError: true, modifiedSinceLastSubmit: true } });

  return submitError && !modifiedSinceLastSubmit ? (
    <Box fontWeight="bold" color="error" title={submitError}>
      {sepFormat(bound?.toFixed(), true, false)}
    </Box>
  ) : (
    <Box>{sepFormat(bound?.toFixed(), true, false)}</Box>
  );
}

type RowProps = {
  index: number;
  style: CSSProperties;
  data: {
    rows: Linkable[];
    entities: EntityMap;
    disabled: boolean;
  };
  isScrolling?: boolean | undefined;
};

export const Row = memo(
  function Row({ index, data: { disabled, entities, rows }, style }: RowProps): ReactElement {
    const form = useForm();
    const row = rows[index];
    const {
      id,
      campaign_id,
      client_id,
      end_client_id,
      product_id,
      provider_id,
      activity_id,
      block_id,
      state,
      target,
      source,
      rate,
      invoiceable,
      invoiced,
      balance,
      bound,
    } = row;

    const key = `bound._${id}`;
    const balanceControl =
      !disabled && bound == null ? (
        <AddButton
          testId={`link-${id}`}
          onClick={() => {
            form.change(key, balance.toFixed());
          }}
        />
      ) : undefined;

    const boundControl =
      !disabled && bound !== null ? (
        <RemoveButton
          testId={`unlink-${id}`}
          onClick={() => {
            form.change(key, undefined);
          }}
        />
      ) : undefined;

    const manualControl =
      !disabled && bound !== null ? (
        <Button
          testId={`link-manual-${id}`}
          variant="outline-primary"
          type="button"
          className="border-0"
          size="sm"
          onClick={() =>
            openModal((props) => <ManualOverride {...props} row={row} />).then((amount) => {
              amount && form.change(key, amount);
            })
          }
        >
          <Icon icon="pencil" />
        </Button>
      ) : undefined;

    return (
      <RowContainer
        testId={id}
        style={style}
        className={index % 2 == 0 ? oddRow : undefined}
        rowLink={
          <EnterpriseLink to={`/planning/campaigns/${campaign_id}/planning/${block_id}?rowId=${id}`}>
            <Icon size="sm" icon="link" />
          </EnterpriseLink>
        }
        client={
          <ClientTitle
            client={entities.company[client_id]}
            end_client={end_client_id ? entities.company[end_client_id] : null}
          />
        }
        product={<ProductTitle product={entities.product[product_id]} />}
        provider={
          <TitleContainer title={entities.company[provider_id].title} subtitle={entities.activity[activity_id].name} />
        }
        block={<BlockWidget {...entities.block[block_id]} link />}
        period={<PeriodState {...state} />}
        source={<TitleContainer title={source} subtitle={target != source ? target : undefined} />}
        rate={`${sepFormat(rate, false, true)}%`}
        invoiceable={sepFormat(invoiceable.toFixed(), true, false)}
        invoiced={sepFormat(invoiced.toFixed(), true, false)}
        balance={sepFormat(balance.toFixed(), true, false)}
        balanceControl={balanceControl}
        linked={<Amount name={key} bound={bound} />}
        linkedControl={boundControl}
        manualControl={manualControl}
      />
    );
  },
  (a: RowProps, b: RowProps): boolean => a.data.rows[a.index].hash == b.data.rows[b.index].hash,
);

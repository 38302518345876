import React from 'react';
import { useField } from 'react-final-form';
import { Button, ButtonProps } from '@ff-it/ui';
import { BaseFieldProps, FormGroup, fieldSubscription, useIsDisabled } from '@ff-it/form';
// import { FormGroup } from '../FormGroup';
// import { useIsDisabled } from '../useIsDisabled';
// import { fieldSubscription } from '../const';
//import { RadioGroupFieldProps, GroupRadioProps } from './types';

// export interface GroupRadioProps {
//   name: string;
//   value: string;
//   label: React.ReactNode;
//   disabled?: boolean;
//   inline?: boolean;
//   required?: boolean;
//   valid?: boolean;
//   format?: (value: string, name: string) => any;
//   parse?: (value: any, name: string) => any;
// }

// function GroupRadio({ name, value, parse, format, ...rest }: GroupRadioProps): React.ReactElement {
//   const { input } = useField<any, HTMLInputElement>(name, {
//     subscription: {
//       value: true,
//     },
//     type: 'radio',
//     value,
//     parse,
//     format,
//   });

//   return <Check variant="radio" {...input} {...rest} />;
// }

interface ButtonGroupFieldProps extends Omit<BaseFieldProps, 'placeholder' | 'id' | 'size'> {
  options: {
    value: any;
    label: React.ReactNode;
  }[];
  format?: (value: string, name: string) => any;
  parse?: (value: any, name: string) => any;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

export function ButtonGroupField(props: ButtonGroupFieldProps): React.ReactElement {
  const { name, className, label, options, help, disabled: propDisabled, validate, size, variant, ...rest } = props;
  const disabled = useIsDisabled(propDisabled);

  const {
    input: { value, onChange },
    meta,
  } = useField<any, HTMLInputElement>(name, {
    subscription: fieldSubscription,
    validate,
  });

  return (
    <FormGroup className={className} label={label} help={help} meta={meta} required={rest.required}>
      <div className="btn-group">
        {options.map((option) => (
          <Button
            key={option.value}
            disabled={disabled}
            active={option.value == value}
            onClick={() => onChange(option.value)}
            size={size}
            variant={variant}
          >
            {option.label}
          </Button>
        ))}
      </div>
    </FormGroup>
  );
}

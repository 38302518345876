import { Row } from './types';
import { sumColumns } from '../common';
import { TableColumns } from '@ff-it/ui';

export const columns: TableColumns<Row> = [
  {
    content: 'Agency',
    key: 'enterprise_slug',
    width: 80,
  },
  {
    content: 'Client grp.',
    key: 'client_group_title',
    sortable: true,
  },
  {
    content: 'Client',
    key: 'client_internal_name',
    sortable: true,
  },
  {
    content: 'End client',
    key: 'end_client_internal_name',
    sortable: true,
  },
  ...sumColumns,
];

import { ReactNode } from 'react';
import { GridColumn, RenderCellProps } from 'components/Grid';
import { EditRate, viewRate } from './Rate';
import { EditToggle, ViewToggle } from './Toggle';
import type { Row } from 'modules/campaign/row';
import { fieldIsEditable } from '../../../../hooks';

export const smart: GridColumn<Row> = {
  key: 'smart',
  width: 86,
  header: 'Smart rate',
  renderCell: (props: RenderCellProps<Row>): ReactNode => {
    if (props.row.kind === 'SMART') {
      return props.editing ? <EditRate {...props} /> : viewRate(props);
    }
    return props.editing ? <EditToggle {...props} /> : <ViewToggle {...props} />;
  },
  editable: (row) => (row.kind === 'SMART' ? fieldIsEditable(row, 'rate') : fieldIsEditable(row, 'applied_to')),
  className: 'text-left',
};

import { ReactElement } from 'react';
import { FormSpy } from 'react-final-form';
import { fmt } from 'utilities';
import { calculateTotals, InvoiceLike } from 'modules/invoicing/income/calculate';

const colSpan = 5;

function Totals(): ReactElement {
  return (
    <FormSpy<InvoiceLike> subscription={{ values: true }}>
      {(formProps) => {
        const { totalInvoiced, vatRate, vatAmount, totalFinal } = calculateTotals(formProps.values);
        return (
          <tfoot>
            <tr>
              <td colSpan={colSpan} className="text-right">
                Sub-total
              </td>
              <td className="text-right" data-testid="sub-total">
                {fmt(totalInvoiced)}
              </td>
              <th />
            </tr>
            <tr>
              <td colSpan={colSpan} className="text-right">
                VAT {vatRate}%
              </td>
              <td className="text-right" data-testid="vat-amount">
                {fmt(vatAmount)}
              </td>
              <th />
            </tr>
            <tr>
              <th colSpan={colSpan} className="text-right">
                Total
              </th>
              <th className="text-right" data-testid="total">
                {fmt(totalFinal)}
              </th>
              <th />
            </tr>
          </tfoot>
        );
      }}
    </FormSpy>
  );
}

export default Totals;

import type { ReactElement } from 'react';
import { useField } from '@ff-it/form';
import { FormInlineAdd } from 'components';
import Totals from './Totals';
import { TemplateNotice } from 'modules/invoicing/common';
import { Sidebar } from 'modules/invoicing/common/Sidebar';
import type { Income } from 'modules/invoicing/income/types';
import { initialRow } from 'modules/invoicing/income/initialValues';
import { RowContainer } from './RowContainer';
import { Fields } from './Fields';
import { CreditSource } from './CreditSource';
import { ImplicitCompensation } from 'modules/applicator/ImplicitCompensation';

interface FormProps {
  update?: boolean;
}

export function Form({ update }: FormProps): ReactElement {
  const {
    input: { value: creditSource, onChange: setCreditSource },
  } = useField<Income>('credit_source', {
    subscription: { value: true },
    allowNull: true,
  });
  const creditLock = Boolean(creditSource);
  const {
    input: { value: type },
  } = useField('type', { subscription: { value: true } });

  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="container px-0 ml-0">
        {creditSource && <CreditSource invoice={creditSource} clear={() => setCreditSource(null)} />}
        <Fields type={type} lockType={update || creditLock} lockPayer={creditLock} />

        <div className="mb-3">
          <TemplateNotice />
          <table className="form-table table invoice-table">
            <thead className="thead-dark">
              <tr>
                <th style={{ width: '30px' }} />
                <th>Title</th>
                <th className="sum">Quantity</th>
                <th className="sum">Unit</th>
                <th className="sum">Unit price</th>
                <th className="sum">Total</th>
                <th className="tools" />
              </tr>
            </thead>
            <RowContainer />
            <Totals />
          </table>
          <FormInlineAdd
            testId="add-amount"
            icon={['far', 'paper-plane']}
            name="rows"
            initialValues={{ ...initialRow, kind: 'AMOUNT' }}
            addLabel="Add row"
          />
          <ImplicitCompensation />
        </div>
      </div>

      {update && <Sidebar />}
    </div>
  );
}

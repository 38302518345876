export { CreateBlockDialog } from './CreateBlockDialog';
export { UpdateBlockDialog } from './UpdateBlockDialog';
export { RemoveBlockDialog } from './RemoveBlockDialog';
export { MotivationDialog, otherOption } from './MotivationDialog';
export { MaybeUpdateBlockDialog } from './MaybeUpdateBlockDialog';
import { otherOption } from './MotivationDialog';
export { CommentRowDialog } from './CommentRowDialog';

export const cancelBlockOptions = [
  ...[
    'Created mistakenly',
    'Transferred',
    'Completely canceled campaign',
    'Cancel by client',
    'Client ended collaboration',
  ].map((value) => ({ value, label: value })),
  otherOption,
];

export const finializePriceOptions = [
  ...['Post factum prices', 'Fee corrections', 'Mistake'].map((value) => ({ value, label: value })),
  otherOption,
];

import { ReactElement } from 'react';
import { DatePickerField, DateRangePickerField } from '@ff-it/form';
import { PayerVatRateField, PaymentDateField } from 'components';
import { CompanyField } from 'modules/core/company/components';

export function InvoiceFields({ hideServicePeriod }: { hideServicePeriod?: boolean }): ReactElement {
  return (
    <div className="form-row">
      <CompanyField name="payer" label="Payer" required className="col-md-2" size="sm" />
      <DatePickerField name="checkout_date" label="Invoice date" className="col-md-1" size="sm" required />
      <PaymentDateField name="payment_date" label="Payment date" className="col-md-1" size="sm" required />
      <PayerVatRateField label="VAT rate" className="col-md-1" size="sm" required />
      {!hideServicePeriod && (
        <DateRangePickerField
          fromName="date_from"
          toName="date_to"
          className="col-md-2"
          size="sm"
          label="Service period"
          readOnly
        />
      )}
    </div>
  );
}

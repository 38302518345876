import { ReactElement, Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { CampaignDetails } from '../../campaign/types';
import { Breadcrumb, EntityContext, ItemLoader, useEntity } from 'components';
import { BlockHeader } from './BlockHeader';
import { BlockDetails } from '../types';
import { StateSync } from '../atoms/_private';
import { useMembership } from 'services';
import { Provider } from 'jotai';

export function BlockProvider(): ReactElement {
  const { item: campaign } = useEntity<CampaignDetails>();
  const { planning_config } = useMembership();
  const { block_id } = useParams<'block_id'>();
  invariant(block_id);
  const url = `campaign/blocks/${block_id}/`;

  return (
    <ItemLoader<BlockDetails> url={url}>
      {(ctx) => (
        <EntityContext.Provider value={ctx}>
          <Provider key={url}>
            <StateSync ctx={ctx} campaign={campaign} planning_config={planning_config} />
            <Breadcrumb>{ctx.item.code}</Breadcrumb>
            <Suspense>
              <BlockHeader />
              <Outlet />
            </Suspense>
          </Provider>
        </EntityContext.Provider>
      )}
    </ItemLoader>
  );
}

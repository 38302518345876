import { atom } from 'jotai';
import type { CampaignPeriod } from 'modules/campaign/campaign/types';
import { parseISO } from 'date-fns';
import { campaignAtom } from './_private';
export { campaignAtom } from './_private';

export const campaignPeriodAtom = atom<CampaignPeriod>((get) => {
  // campaign never changes so this should be fine
  const { date_from, date_to } = get(campaignAtom);
  return {
    minDate: parseISO(date_from),
    maxDate: parseISO(date_to),
  };
});

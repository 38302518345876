import { useField } from '@ff-it/form';
import { useSetAtom } from 'jotai';
import React from 'react';
import { managerOpenAtom } from '../../../../atoms/factors';
import { Button, Check } from '@ff-it/ui';
import type { PlanFactor } from 'modules/campaign/block/types';
import './FactorSelector.scss';
import { FactorOption } from '../../../../Factors';
import type { DepartmentFactor } from 'modules/supplier/factors';

type FactorCheckProps = {
  direction: 'client' | 'provider';
} & (
  | {
      scope: 'plan';
      factor: number;
    }
  | {
      scope: 'department';
      factor: DepartmentFactor;
    }
);

function FactorCheck({ scope, factor, direction }: FactorCheckProps): React.ReactElement {
  const {
    input: { value, onChange: controlledOnChange },
  } = useField<FactorCheckProps['factor'][], HTMLInputElement>(`${direction}.factors`, {
    subscription: {
      value: true,
    },
    // type: 'checkbox',
    // value,
  });

  const index =
    scope == 'plan' ? value.indexOf(factor) : value.findIndex((v) => (v as DepartmentFactor)?.id == factor.id);

  const checked = index !== -1;
  function onChange(e: any): void {
    controlledOnChange(e.target.checked ? value.concat(factor) : value.slice(0, index).concat(value.slice(index + 1)));
  }

  // const onChang =

  return (
    <label className={`factor-selector__choice factor-selector__choice--${direction}`} title={direction}>
      <Check checked={checked} onChange={onChange} />
    </label>
  );
}

export type FactorSelectorProps = {
  planFactors: PlanFactor[];
  departmentFactors: DepartmentFactor[];
};

export function FactorSelector({ planFactors, departmentFactors }: FactorSelectorProps): React.ReactElement {
  const manageFactors = useSetAtom(managerOpenAtom);

  return (
    <fieldset className="mt-2 factor-selector">
      <legend className="d-flex align-items-center mb-3">
        Plan factors{' '}
        <Button className="ml-auto" size="sm" variant="outline-primary" onClick={() => manageFactors(true)}>
          Manage factors
        </Button>
      </legend>
      {planFactors.map((factor) => (
        <div className="d-flex mb-3" key={factor.id}>
          <div className="d-flex align-items-start">
            <FactorCheck direction="client" factor={factor.id} scope="plan" />
            <FactorCheck direction="provider" factor={factor.id} scope="plan" />
          </div>
          <FactorOption factor={factor} className="ml-3 flex-grow-1" />
        </div>
      ))}
      {departmentFactors.length > 0 && (
        <>
          <legend className="d-flex align-items-center mb-3">Department factors</legend>
          {departmentFactors.map((factor) => (
            <div className="d-flex mb-3" key={factor.id}>
              <div className="d-flex align-items-start">
                <FactorCheck direction="client" factor={factor} scope="department" />
                <FactorCheck direction="provider" factor={factor} scope="department" />
              </div>
              <FactorOption factor={factor} className="ml-3 flex-grow-1" />
            </div>
          ))}
        </>
      )}
    </fieldset>
  );
}

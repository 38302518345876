import { PlanStrategyType } from '../types';
import type { Row } from 'modules/campaign/row';

export interface PlanStrategy {
  readonly KEY: PlanStrategyType;
  readonly TITLE: string;
  copyRow: (row: Row) => Partial<Omit<Row, 'id'>>;
}

const NAIVE: PlanStrategy = {
  KEY: 'NAIVE',
  TITLE: 'Naive plan',
  copyRow: (row) => {
    // comment?
    switch (row.kind) {
      case 'SMART':
        return {
          kind: row.kind,
          supplier: row.supplier,
          rate: row.rate,
          // apply_to: []
        };

      case 'SERVICE':
      case 'MEDIA':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          bruto_price: row.bruto_price,
          client_price: row.client_price,
          provider_price: row.provider_price,
          applied_to: row.applied_to,
        };
      default:
        throw Error(`Unexpected kind: ${row.kind}`);
    }
  },
};

const RTB: PlanStrategy = {
  KEY: 'RTB',
  TITLE: 'WEB/RTB plan',
  copyRow: (row) => {
    // comment?
    switch (row.kind) {
      case 'SMART':
        return {
          kind: row.kind,
          supplier: row.supplier,
          rate: row.rate,
          // apply_to: []
        };
      case 'SERVICE':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          bruto_price: row.bruto_price,
          client_price: row.client_price,
          provider_price: row.provider_price,
          applied_to: row.applied_to,
        };
      case 'MEDIA':
        return {
          kind: row.kind,
          supplier: row.supplier,
          unit: row.unit,
          bruto_price: row.bruto_price,
          client_price: row.client_price,
          provider_price: row.provider_price,
          applied_to: row.applied_to,
          // RTB fields
          language: row.language,
          web_device: row.web_device,
          placement: row.placement,
          format: row.format,
          tg: row.tg,
          kpi: row.kpi,
          frequency: row.frequency,
        };
      default:
        throw Error(`Unexpected kind: ${row.kind}`);
    }
  },
};

export const strategies: Record<PlanStrategyType, PlanStrategy> = {
  NAIVE,
  RTB,
};

export const strategyLabelMap: Record<PlanStrategyType, string> = Object.keys(strategies).reduce(
  (acc: any, key: any) => {
    acc[key] = strategies[key as PlanStrategyType].TITLE;
    return acc;
  },
  {},
);

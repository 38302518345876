import { useAtomValue, useSetAtom } from 'jotai';
import { blockAtom } from '../../../atoms';
import { ReactElement, startTransition } from 'react';
import { BlockNotice } from '../../../layout';
import { SelectionControls } from './SelectionControls';
import { PlanControls } from './PlanControls';
import { Button } from '@ff-it/ui';
import { Icon } from 'components';
import { managerOpenAtom } from '../atoms/factors';
import { summaryOpenAtom } from '../atoms/summary';

interface ToolbarProps {
  scrollToRow: (rowId: number) => void;
  isEditable: boolean;
}

function ToggleFactors(): ReactElement {
  const setOpen = useSetAtom(managerOpenAtom);
  return (
    <Button
      type="button"
      size="sm"
      variant="outline-primary"
      data-testid="toggle-factors"
      onClick={() =>
        startTransition(() => {
          setOpen(true);
        })
      }
    >
      Plan factors <Icon icon="sparkle" className="ml-1" />
    </Button>
  );
}

function ToggleSummary(): ReactElement {
  const setOpen = useSetAtom(summaryOpenAtom);
  return (
    <Button
      type="button"
      size="sm"
      variant="outline-primary"
      data-testid="toggle-summary"
      className="ml-1"
      onClick={() =>
        startTransition(() => {
          setOpen(true);
        })
      }
    >
      Summary <Icon icon="list-tree" className="ml-1" />
    </Button>
  );
}

export function Toolbar({ isEditable, scrollToRow }: ToolbarProps): ReactElement {
  const block = useAtomValue(blockAtom);

  return (
    <nav className="navbar d-flex px-0 pt-0">
      {isEditable && <SelectionControls scrollToRow={scrollToRow} />}

      <div className="col">
        <BlockNotice {...block} className="d-inline-block" />
      </div>
      {/* FIXME: these are toggled here but renderd in plan tree so we have access to plan atoms */}
      <ToggleFactors />
      <ToggleSummary />
      {isEditable && <PlanControls scrollToRow={scrollToRow} />}
    </nav>
  );
}

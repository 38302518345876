import { ReactElement } from 'react';
import { ActivityIcon, Icon, useEntity } from 'components';
import { CampaignEntry, Log } from 'modules/audit';
import { Link } from 'react-router-dom';
import { parseBig } from 'utilities';
import type { CampaignDetails } from 'modules/campaign/campaign/types';
import { useMembership } from 'services';

function Description(entry: CampaignEntry): ReactElement | null {
  const { slug } = useMembership();
  switch (entry.action) {
    case 'CREATED':
      return <>created campaign</>;

    case 'UPDATED':
      return <>updated campaign</>;

    case 'BLOCK_CREATED':
      return (
        <>
          created {entry.context.type} block{' '}
          <Link to={`/${slug}/planning/campaigns/${entry.object_id}/planning/${entry.context.id}`}>
            {entry.context.code}
          </Link>
        </>
      );

    case 'BLOCK_DELETED':
      return (
        <>
          deleted {entry.context.type} block{' '}
          <Link to={`/${slug}/planning/campaigns/${entry.object_id}/planning/${entry.context.id}`}>
            {entry.context.code}
          </Link>
        </>
      );
    case 'INVOICED':
      const invoice = entry.context;
      return (
        <span>
          invoiced ({invoice.total}) in <Icon icon="file-invoice" className={`text-${invoice.direction}`} />{' '}
          <Link to={`/${slug}/invoices/${invoice.direction}/${invoice.id}`}>{invoice.number}</Link>
        </span>
      );

    case 'BOUND':
      return (
        <ul className="compact-list">
          {entry.context.map(({ amount, invoice, block }) => {
            const [prev, next] = amount;
            const diff = parseBig(next).minus(prev);
            const p = diff.s === 1;

            return (
              <li key={block.id}>
                {prev} → {next} (
                <span className={p ? 'text-success' : 'text-danger'}>
                  {p && '+'}
                  {diff.toFixed(2)}
                </span>
                )
                <span className="ml-2">
                  <ActivityIcon type={block.type} />{' '}
                  <Link to={`/${slug}/planning/campaigns/${entry.object_id}/planning/${block.id}`}>{block.code}</Link>
                </span>
                <span className="ml-1">
                  in <Icon icon="file-invoice" className={`text-${invoice.direction}`} />{' '}
                  <Link to={`/${slug}/invoices/${invoice.direction}/${invoice.id}`}>{invoice.number}</Link>{' '}
                </span>
              </li>
            );
          })}
        </ul>
      );

    case 'DELETED':
      return <>deleted campaign</>;

    default:
      return null;
  }
}

export function History(): ReactElement {
  const { endpoint } = useEntity<CampaignDetails>();

  return <Log<CampaignEntry> url={`${endpoint}history/`} Description={Description} />;
}

import { ReactElement } from 'react';
import type { ActivityType, DepartmentKind } from 'types';
import { applicationOptions, createOptions, directionOptions, getUnitOptions } from 'options';
import { CheckField, DecimalField, SelectField, TextField, useField } from '@ff-it/form';
import { Config, ConfigToggle } from './Config';
import { ComponentType } from 'react';
import { ActivityField, AllActivityTypesField } from 'components';
import { required } from 'utilities';

type FieldsProps = {
  type: ActivityType;
};

function MediaFields({ type }: FieldsProps): ReactElement {
  return (
    <>
      <div className="row">
        <SelectField name="unit" label="Unit" className="col-md-2" options={getUnitOptions('MEDIA', type)} simple />
        <DecimalField name="gross_price" label="Gross price" help="Price of unit" className="col-md-2" precision={16} />
      </div>

      <div className="row">
        <ActivityField
          name="activities"
          label="Activities"
          className="col-md-4"
          isMulti
          defaultOptions
          filter={{
            kind: 'MEDIA',
          }}
          help="Leave empty for any"
        />
        <CheckField
          name="expect_performance"
          label="Expect performance"
          className="col-md-4"
          variant="switch"
          fieldLabel="Has delivery"
          help="Actual amounts and price logged after delivery"
        />
        <ConfigToggle type={type} className="col-md-3" />
      </div>
      <Config type={type} />
    </>
  );
}

const serviceKindOptions = createOptions(['SERVICE', 'SMART'], {
  SERVICE: 'Service',
  SMART: 'Smart service',
});

function ServiceFields({ type }: FieldsProps): ReactElement {
  const {
    input: { value: kind },
  } = useField('kind', { subscription: { value: true }, defaultValue: 'SERVICE' });
  return (
    <>
      <div className="row">
        <SelectField name="kind" label="Kind" className="col-md-2" options={serviceKindOptions} simple required />
        {kind === 'SERVICE' ? (
          <SelectField name="unit" label="Unit" className="col-md-2" options={getUnitOptions('SERVICE', type)} simple />
        ) : (
          <>
            <SelectField
              name="direction"
              label="Result direction"
              className="col-md-2"
              options={directionOptions}
              simple
              required
            />
            <SelectField
              name="application"
              label="Application"
              className="col-md-2"
              options={applicationOptions}
              simple
              required
            />
          </>
        )}
      </div>

      <div className="row">
        <ActivityField
          name="activities"
          label="Activities"
          className="col-md-4"
          isMulti
          defaultOptions
          filter={{
            kind: 'SERVICE',
          }}
          help="Leave empty for any"
        />
      </div>
    </>
  );
}

interface FormProps {
  kind: DepartmentKind;
  create: boolean;
}

const FIELDS: Record<DepartmentKind, ComponentType<FieldsProps>> = {
  SERVICE: ServiceFields,
  MEDIA: MediaFields,
};

export function Form({ kind, create }: FormProps): ReactElement {
  const Fields = FIELDS[kind];
  const {
    input: { value: type },
  } = useField('type', { subscription: { value: true }, allowNull: true });
  return (
    <div className="container px-0 ml-0">
      <div className="row">
        <AllActivityTypesField
          name="type"
          label="Type"
          className="col-md-2"
          disabled={!create}
          required
          validate={required}
        />
        <TextField name="name" label="Name" className="col-md-4" help="Blank for generic" />
      </div>
      {type && <Fields type={type} />}
    </div>
  );
}

import { ReactElement } from 'react';
import cx from 'clsx';
import type { PlanFactor } from 'modules/campaign/block/types';
import type { DepartmentFactor } from 'modules/supplier/factors';

type FactorOptionProps = {
  factor: PlanFactor | DepartmentFactor;
  className?: string;
};

export function FactorOption({ factor: { name, rate, description }, className }: FactorOptionProps): ReactElement {
  return (
    <div className={cx('factor_option', className)}>
      <div className="mb-1 d-flex">
        <div className="mr-2 factor_option__name">{name}</div>
        <div className="ml-auto factor_option__rate">{rate}%</div>
      </div>
      <div className="factor_option__description">{description}</div>
    </div>
  );
}

import { ReactElement } from 'react';
import cx from 'clsx';
import { fmt, parseBig } from 'utilities';
import Big from 'big.js';
import './BudgetProgress.scss';
import { blockSumsAtom } from '../../atoms';
import { useAtomValue } from 'jotai';

interface BudgetProps {
  budget: string;
}

export function BudgetProgress({ budget }: BudgetProps): ReactElement | null {
  const sums = useAtomValue(blockSumsAtom);
  if (!sums) {
    return null;
  }
  const budgetAmount = parseBig(budget);
  if (budgetAmount.eq(0)) {
    return null;
  }
  const plannedAmount = parseBig(sums.income_total);

  const remaining = budgetAmount.minus(plannedAmount);
  const ratio = plannedAmount.div(budget).mul(100).round();
  const overspent = remaining.lt(0);
  const remainderRatio = Big(100).minus(ratio);

  return (
    <div className="budget-progress">
      <strong>{fmt(plannedAmount)}</strong> ({ratio.toFixed(0)}% of budget)
      <div className="progress">
        <div
          className={cx('progress-bar', overspent ? 'bg-danger' : 'bg-success')}
          style={{ width: `${ratio.gt(100) ? 100 : ratio}%` }}
        />
      </div>
      {overspent ? (
        <>
          <strong>{fmt(remaining.mul(-1))}</strong> ({remainderRatio.mul(-1).toFixed(0)}%) overspent
        </>
      ) : (
        <>
          <strong>{fmt(remaining)}</strong> ({remainderRatio.toFixed(0)}%) remaining
        </>
      )}
    </div>
  );
}

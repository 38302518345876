import { ReactElement, useState } from 'react';
import { AuthContainer } from 'components';
import { Link, useParams } from 'react-router-dom';
import { Form, Submit, TextField, useSubmitHandler } from '@ff-it/form';
import { useRequest } from '@ff-it/api';
import { Alert, Button, LoadingContainer } from '@ff-it/ui';

function PasswordForm({ url }: { url: string }): ReactElement {
  const [done, setDone] = useState<boolean>(false);

  const submitHandler = useSubmitHandler(
    {
      url,
      method: 'POST',
    },
    {
      onSuccess: () => {
        setDone(true);
      },
    },
  );

  if (done) {
    return (
      <div className="mt-4">
        <p>Your password has been set. You may go ahead and sign in now.</p>
        <Button variant="outline-primary" size="lg" to="/login" component={Link}>
          Sign in
        </Button>
      </div>
    );
  }

  return (
    <Form onSubmit={submitHandler} autoComplete="off">
      <TextField name="new_password" label="Password" type="password" required size="lg" />
      <TextField name="new_password_repeat" label="Repeat password" type="password" required size="lg" />
      <Submit variant="primary" size="lg" className="w-100" />
    </Form>
  );
}

export function PasswordResetConfirm(): ReactElement {
  const { uidb64, token } = useParams();
  const url = `auth/password_reset_confirm/${uidb64}/${token}/`;
  const { loading, data } = useRequest({
    url,
    method: 'GET',
  });

  return (
    <AuthContainer wide>
      <h3 className="text-center">Reset Password</h3>
      <LoadingContainer loading={loading}>
        {loading ? null : data ? (
          <PasswordForm url={url} />
        ) : (
          <Alert variant="danger" className="mt-4">
            The password reset link was invalid, possibly because it has already been used. <br />
            Please request a new password reset.
          </Alert>
        )}
      </LoadingContainer>
    </AuthContainer>
  );
}

import { ReactElement, useState } from 'react';
import { Box, Check } from '@ff-it/ui';
import cx from 'clsx';
import { Icon } from 'components';
import { SectionType, SectionModel } from './types';
import { usePermissionContext } from './context';

function Model({ title, perms }: SectionModel): ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, permissions: enabledPermissions, toggle } = usePermissionContext();

  return (
    <Box marginBottom="md">
      <button
        className={cx('btn btn-outline-dark btn-block px-3 text-left d-flex align-items-center font-weight-bold mb-1', {
          active: isOpen,
        })}
        type="button"
        onClick={() => {
          setIsOpen((o) => !o);
        }}
      >
        {title} <Icon icon={`chevron-${isOpen ? 'up' : 'down'}` as any} className="ml-auto" />
      </button>
      <div className={cx('collapse', { show: isOpen })}>
        <ul className="list-group">
          {perms.map(({ id, name, description }) => {
            const enabled = Boolean(enabledPermissions[id]);
            return (
              <li
                key={id}
                className="list-group-item d-flex justify-content-between align-items-start list-group-item-action px-3 py-2 borderx-0"
                onClick={(e) => {
                  toggle(id, !enabled);
                  e.preventDefault();
                }}
              >
                <div className="ms-2 me-auto">
                  <div>{name}</div>
                  <div className="small text-muted" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
                <Check variant="switch" disabled={loading} checked={enabled} readOnly />
              </li>
            );
          })}
        </ul>
      </div>
    </Box>
  );
}

export function Section({ title, models, ...rest }: SectionType): ReactElement {
  return (
    <div className="mb-3">
      <h4>{title}</h4>
      <div className="accordion">
        {models.map((model, index) => (
          <Model {...model} key={index} {...rest} />
        ))}
      </div>
    </div>
  );
}

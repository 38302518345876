import { ReactElement } from 'react';
import cx from 'clsx';
import { Dialog, DialogBody, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from '@ff-it/ui';
import { Form, Submit, useSubmitHandler } from '@ff-it/form';
import { useRequest } from '@ff-it/api';
import { AttachmentField, Icon } from 'components';
import './PlanAttachments.scss';
import { Attachment } from 'types';

interface PlanAttachmentsProps {
  endpoint: string;
  className?: string;
}

function AttachmentForm({ onSubmit, initialValues }: { onSubmit: any; initialValues: any }): ReactElement {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      <DialogBody>
        <AttachmentField name="plan_attachments" discriminator="plan" />
      </DialogBody>
      <DialogFooter>
        <Submit />
        <DialogClose className="ml-auto" />
      </DialogFooter>
    </Form>
  );
}

export function PlanAttachments({ endpoint, className }: PlanAttachmentsProps): ReactElement {
  const url = `${endpoint}plan_attachments/`;

  const { data, setState } = useRequest<
    {
      plan_attachments: Attachment[];
    },
    unknown
  >({
    url,
    method: 'GET',
  });

  const onSubmit = useSubmitHandler(
    {
      url,
      method: 'POST',
    },
    {
      onSuccess: (data) => {
        setState((state) => ({ ...state, data } as any));
      },
    },
  );

  return (
    <Dialog>
      <DialogTrigger>
        <a role="button" className={cx('text-small p-0 border-0 plan-attachments', className)}>
          plan attachments <Icon icon={['far', 'paperclip']} />{' '}
          <strong>({data ? data.plan_attachments.length : '?'})</strong>
        </a>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader title="Plan attachments" />
        {data && <AttachmentForm initialValues={data} onSubmit={onSubmit} />}
      </DialogContent>
    </Dialog>
  );
}

import { ReactNode, ReactElement } from 'react';
import { FloatingOverlay, FloatingPortal, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { anchor, container } from './style.css';
import { Box } from '@ff-it/ui';

interface PopupProps {
  children: ReactNode;
  onRequestClose: () => void;
}

interface PopupCellProps extends PopupProps {
  cellContent: ReactNode;
  testId?: string;
}

export function PopupCell({ children, onRequestClose, cellContent }: PopupCellProps): ReactElement {
  const { refs, floatingStyles, context } = useFloating({
    open: true,
    placement: 'bottom-start',
    onOpenChange: (isOpen) => !isOpen && onRequestClose(),
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown', bubbles: false });
  const interactions = useInteractions([dismiss]);

  return (
    <>
      {cellContent}
      <FloatingPortal>
        <FloatingOverlay>
          <Box ref={refs.setFloating} style={floatingStyles} {...interactions.getFloatingProps()} className={container}>
            {children}
          </Box>
        </FloatingOverlay>
      </FloatingPortal>
      <div ref={refs.setReference} {...interactions.getReferenceProps()} className={anchor} />
    </>
  );
}

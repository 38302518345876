import { memo, ReactElement } from 'react';
import { useField } from 'react-final-form';
import { ActivityField, AgencyActivityTypesField, Fieldset, RemoveButton } from 'components';
import { RateFields } from 'components/company';
import { BonusForm, Rows, RowProps } from 'modules/guarantee/common/bonus';
import permissions from 'modules/guarantee/avb/permissions';
import { TextField, useIsDisabled } from '@ff-it/form';
import { CompanyField } from 'modules/core/company/components';
import { DepartmentField } from 'modules/supplier/department/DepartmentField';

const supplierFields = ['provider', 'type', 'activity', 'department'];
supplierFields.reverse();

const props = {
  size: 'sm' as const,
  placeholder: 'All',
};

export const Row = memo(function Row({ name, remove, index }: RowProps): ReactElement {
  const disabled = useIsDisabled();
  // TODO: limit types
  const {
    input: { value },
  } = useField(name, { subscription: { value: true } });

  let lastIndex = supplierFields.map((f) => value[f]).findIndex((v) => v != null);
  lastIndex = lastIndex >= 0 ? supplierFields.length - 1 - lastIndex : lastIndex;

  const filter = {
    type: value.type || undefined,
    activity: value.activity?.id,
    department: value.department?.id,
    provider: value.provider?.id,
  };

  return (
    <tr>
      <td className="provider">
        <CompanyField name={`${name}.provider`} {...props} disabled={lastIndex > 0} />
      </td>
      <td className="type">
        <AgencyActivityTypesField name={`${name}.type`} {...props} disabled={lastIndex > 1} />
      </td>
      <td className="activity">
        <ActivityField name={`${name}.activity`} {...props} disabled={lastIndex > 2} />
      </td>

      <td className="department">
        <DepartmentField name={`${name}.department`} {...props} disabled={lastIndex > 3} filter={filter} />
      </td>

      <td className="client border-left">
        <CompanyField name={`${name}.client`} {...props} />
      </td>
      <td className="end_client">
        <CompanyField name={`${name}.end_client`} {...props} />
      </td>

      <RateFields name={name} />
      <td className="tools">
        <RemoveButton onClick={() => remove(index)} disabled={disabled} />
      </td>
    </tr>
  );
});

export function Form(): ReactElement {
  return (
    <BonusForm sensitive={permissions.sensitive_info}>
      <Fieldset title="Signature person">
        <div className="form-row">
          <TextField name="signature_first_name" label="First name" className="col-md-6" />
          <TextField name="signature_last_name" label="Last name" className="col-md-6" />
        </div>
      </Fieldset>
      <table className="table-rates">
        <thead>
          <tr>
            <th className="provider">Provider</th>
            <th className="type">Type</th>
            <th className="activity">Activity</th>
            <th className="department">Department</th>
            <th className="client">Client</th>
            <th className="end_client">End client</th>
            <th className="rate">Current</th>
            <th className="rate">Target</th>
            <th className="rate">Final</th>
            <th className="tools" />
          </tr>
        </thead>
        <Rows Row={Row} />
      </table>
    </BonusForm>
  );
}

import type { PlanResolution } from 'modules/campaign/block/types';
import { ROW_HEIGHT } from 'components/Grid/const';
import type { PositionError } from './types';

export const DPR = window.devicePixelRatio;

export const DAY_WIDTH: Record<PlanResolution, number> = {
  DAY: 28,
  WEEK: 16,
  MONTH: 12,
};

export const HEIGHT = ROW_HEIGHT - 1;

export const positionErrors: Record<PositionError, string> = {
  overlap: 'Positions overlap',
};

import { permissions } from './Campaigns/permissions';
import type { IconProp } from 'components';

export const menu = {
  to: '/planning',
  label: 'Planning',
  icon: ['far', 'paper-plane'] as IconProp,
  children: [
    { to: '/planning/campaigns', label: 'Campaigns', visible: permissions?.view },
    { to: '/planning/blocks', label: 'Blocks', visible: permissions?.view },
    { to: '/planning/rows', label: 'Rows', visible: permissions?.view },
  ],
};
